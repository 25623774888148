/**
  * This utility smoothly scrolls to a target element specified by its selector.
*/

export function handleScroll(event, selector) {
  const targetElement = document.querySelector(selector);
  if (targetElement) {
    event.preventDefault();
    targetElement.scrollIntoView({ behavior: 'smooth' });
  }
}
