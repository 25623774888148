import { Schema } from './BlogFeaturedPost.schema';
import { Image } from '../../snippets';
import Button from '../../snippets/Button/Button';
import { Headline, AltText, Body } from '../../snippets/Typography';

export function BlogFeaturedPost({ cms }) {
  const { heading, category, articleTime, copy, cta, image } = cms;

  return (
    <div className="bg-accent3O relative">
      <div className="px-contained items-center overflow-hidden py-12 lg:flex lg:py-24">
        <div className="lg:w-7/12">
          {image && (
            <div className="mb-6 lg:mb-0 lg:block lg:h-[620px]">
              <Image
                className="max-h-[270px] w-full object-cover lg:max-h-[620px]"
                sizes="48px"
                width="890px"
                height="620px"
                src={image.src}
              />
            </div>
          )}
        </div>
        <div className="lg:w-5/12 lg:pl-20">
          <AltText className="mb-4 uppercase">
            {category} •{' '}
            <span className="font-shadowGray">{articleTime} Min Read</span>
          </AltText>
          <Headline variant="h3" element="h3">
            {heading}
          </Headline>
          <Body variant="medium" className="mx-auto mb-6 mt-6 lg:mb-12">
            {copy}
          </Body>
          {cta && (
            <div>
              <Button theme="primary" variant="outlined" href={cta.url}>
                {cta.text}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

BlogFeaturedPost.displayName = 'Blog Featured Post';
BlogFeaturedPost.Schema = Schema;
