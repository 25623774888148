import { Headline } from '../../snippets/Typography';
import { Accordion } from './Accordion';
import { Schema } from './ArticleContaminantAccordions.schema';
import { Image } from '../../snippets';

export function ArticleContaminantAccordions({ cms }) {
  const { accordions, heading, title, desktopImage, mobileImage } = cms;

  return (
    <div className="mb-8 lg:mb-16">
      <div className="">
        <div className="mx-auto max-w-[1100px]">
          {heading && (
            <Headline
              variant="h4"
              element="h4"
              className="flex justify-between border border-b-0 border-[#DDE2E7] px-6 pb-4 pt-4 lg:px-10 lg:pb-6 lg:pt-6"
            >
              <span className="!text-primary">{heading}</span>
              <Image
                width="42px"
                height="42px"
                alt="icon"
                className="hidden lg:block"
                src="https://cdn.shopify.com/s/files/1/1011/0318/files/Vector_7.png?v=1688148078"
              />
              <Image
                width="31px"
                height="31px"
                alt="icon"
                className="lg:hidden"
                src="https://cdn.shopify.com/s/files/1/1011/0318/files/Vector_7.png?v=1688148078"
              />
            </Headline>
          )}
          {desktopImage && (
            <div className="relative w-full">
              <Image
                width="765px"
                height="275px"
                className="border border-[#DDE2E7]"
                alt={heading}
                src={desktopImage?.src}
                quality="90"
              />
            </div>
          )}
          <ul className="grid grid-cols-1 gap-4  border border-t-0 border-[#DDE2E7] text-primary">
            {accordions?.map((accordion, index) => {
              return (
                <li key={index}>
                  <Accordion accordion={accordion} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

ArticleContaminantAccordions.displayName = 'ArticleContaminantAccordions';
ArticleContaminantAccordions.Schema = Schema;
