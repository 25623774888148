import { useProductInventoryByHandle } from '@backpackjs/storefront';
import { useMemo, useState, useEffect } from 'react';
import equal from 'fast-deep-equal';
import { ProductColorOptionValue } from './ProductColorOptionValue';
import { ProductOptionValue } from './ProductOptionValue';

export function ProductOptions({
  product,
  selectedOptions,
  selectedVariant,
  setSelectedOptions,
}) {
  const variantGroups = product?.variantGroups;

  const { inventory } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });

  const variantFromOptionValue = useMemo(() => {
    return product?.variants?.find(({ selectedOptionsMap }) => {
      return equal(selectedOptions, selectedOptionsMap);
    });
  }, [selectedOptions, product?.variants]);

  const optionValueIsAvailable =
    inventory?.variants?.[variantFromOptionValue?.id]?.availableForSale ||
    false;
  // const disabled = !variantFromOptionValue;

  const [displayOos, setDisplayOos] = useState(false);

  useEffect(() => {
    if (!inventory) return;

    setDisplayOos(!optionValueIsAvailable);
  }, [inventory, optionValueIsAvailable]);

  return (
    <>
      {inventory && (
        <div className="product-options flex flex-col text-primary">
          {product.options?.map(({ id, name, values }) => {
            const productOrGroupingValues = product.grouping?.isTransformed
              ? product.grouping.optionsMap[name]
              : values;

            return (
              <div
                key={id}
                className="product-option-group border-b border-b-[#DDE2E7] py-6 first:border-t first:border-t-[#DDE2E7]"
              >
                <div className="product-selected-option mb-2 flex items-center justify-between gap-2">
                  <div className="mb-4 flex items-center gap-2">
                    <h3 className="product-selected-option__name text-base font-normal uppercase leading-6">
                      {name === 'Size' ? 'Select a Size' : `${name}:`}
                    </h3>
                    {name !== 'Size' && (
                      <p className="product-selected-option__value text-base font-normal uppercase">
                        {selectedOptions?.[name]}
                      </p>
                    )}
                  </div>
                </div>

                <ul className="product-option-list flex flex-wrap gap-2">
                  {productOrGroupingValues?.map((value) => {
                    const isSelected = selectedOptions?.[name] === value;
                    const newSelectedOptions = {
                      ...selectedOptions,
                      [name]: value,
                    };

                    const variantIsInGroup =
                      variantGroups.length > 0 &&
                      variantGroups.some((group) =>
                        group.variants.some((variant) => {
                          const colorOption = variant.selectedOptions.find(
                            (option) => option.name === 'Color'
                          );
                          return colorOption.value === value;
                        })
                      );
                    if (variantIsInGroup) return null;

                    return name === 'Color' ? (
                      <ProductColorOptionValue
                        inventory={inventory}
                        isSelected={isSelected}
                        newSelectedOptions={newSelectedOptions}
                        product={product}
                        setSelectedOptions={setSelectedOptions}
                        value={value}
                        key={value}
                      />
                    ) : (
                      <ProductOptionValue
                        inventory={inventory}
                        isSelected={isSelected}
                        newSelectedOptions={newSelectedOptions}
                        product={product}
                        setSelectedOptions={setSelectedOptions}
                        value={value}
                        key={value}
                      />
                    );
                  })}
                </ul>

                {variantGroups.length > 0 && name === 'Color' && (
                  <div className="product-option-variant-group flex flex-col gap-2">
                    {variantGroups.map((group) => {
                      const { metaobject } = group;
                      const { title, callout_text: calloutText } = metaobject;
                      const groupValues = group.variants.map((variant) => {
                        const colorOption = variant.selectedOptions.find(
                          (option) => option.name === 'Color'
                        );
                        return colorOption.value;
                      });

                      return (
                        <div key={group.group} className="mt-6">
                          <div className="mb-2 flex items-center justify-between gap-2">
                            <div className="mb-4 flex items-center gap-2">
                              <h3 className="text-base font-normal uppercase leading-6">
                                {title}
                              </h3>
                              <p className="text-base italic text-[--shadow-gray]">
                                {calloutText}
                              </p>
                            </div>
                          </div>
                          <ul className="flex flex-wrap gap-2">
                            {groupValues?.map((value) => {
                              const isSelected =
                                selectedOptions?.[name] === value;
                              const newSelectedOptions = {
                                ...selectedOptions,
                                [name]: value,
                              };

                              return (
                                <ProductColorOptionValue
                                  inventory={inventory}
                                  isSelected={isSelected}
                                  newSelectedOptions={newSelectedOptions}
                                  product={product}
                                  setSelectedOptions={setSelectedOptions}
                                  value={value}
                                  key={value}
                                />
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {displayOos && inventory && (
        <div className="product-options--oos relative mt-4 border border-error p-[10px] text-center text-error before:absolute before:inset-0 before:bg-error before:opacity-[0.15]">
          <div className="relative">This item is currently out of stock</div>
        </div>
      )}
    </>
  );
}

ProductOptions.displayName = 'ProductOptions';
