import Vimeo from '@u-wave/react-vimeo';
import { useEffect, useState } from 'react';
import useMediaQuery from '../../utils/useMediaQuery';

export default function BackgroundVideo({ videoUrl, mobileVideoUrl }) {
  const isVimeo = videoUrl?.includes('vimeo');
  const isMobileVimeo = mobileVideoUrl?.includes('vimeo');
  const isTabletOrDesktop = useMediaQuery('(min-width: 768px)');
  const [isRendered, setIsRendered] = useState(false);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    if (isRendered) {
      setHeight(window.innerHeight);
    }
  }, [isRendered]);

  if (!isRendered) return null;

  if (!videoUrl) return null;

  if (isTabletOrDesktop) {
    if (isVimeo)
      return (
        <div className="vimeo-wrapper">
          <Vimeo video={videoUrl} loop muted background />
        </div>
      );
    return (
      <video
        autoPlay
        className="h-full w-full object-cover"
        src={videoUrl}
        loop
        playsInline
        muted
        controls={false}
      />
    );
  }

  if (isMobileVimeo && height)
    return (
      <div className="vimeo-wrapper">
        <Vimeo video={mobileVideoUrl} loop background />
      </div>
    );

  if (isMobileVimeo && !height) return null;

  return (
    <video
      src={mobileVideoUrl}
      autoPlay
      loop
      muted
      playsInline
      controls={false}
      className="h-full w-full object-cover"
    />
  );
}
