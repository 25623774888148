import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// nextjs link docs: https://nextjs.org/docs/api-reference/next/link

export const Link = forwardRef(
  (
    {
      as,
      children,
      className,
      href = '',
      locale = false,
      newTab = false,
      prefetch = true,
      replace = false,
      scroll = true,
      shallow = false,
      style,
      primary = false,
      secondary = false,
      ...props
    },
    ref
  ) => {
    return href ? (
      <NextLink
        as={as}
        href={href}
        locale={locale}
        passHref
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        {...(!prefetch ? { prefetch: false } : null)}
      >
        <a
          className={[
            primary ? 'text-sm text-[#3383C5] underline' : '',
            secondary ? 'text-sm text-[#202635] underline' : '',
            className,
          ].join(' ')}
          ref={ref}
          style={style}
          {...(newTab ? { target: '_blank' } : null)}
          {...props}
        >
          {children}
        </a>
      </NextLink>
    ) : (
      <div className={className} ref={ref} style={style}>
        {children}
      </div>
    );
  }
);

Link.displayName = 'Link';
Link.propTypes = {
  as: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  locale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  newTab: PropTypes.bool,
  prefetch: PropTypes.bool,
  replace: PropTypes.bool,
  scroll: PropTypes.bool,
  shallow: PropTypes.bool,
  style: PropTypes.object,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
};
