/* eslint-disable react/no-unstable-nested-components */
import { Schema } from './CommunityImpact.schema';
import { Markdown, Headline } from '../../snippets';
import { BeamCommunityImpact } from '../../snippets/Beam';

export function CommunityImpact({ cms }) {
  const { title, body } = cms;

  return (
    <div className="beam-sync-visibility px-contained mx-auto max-w-[960px] overflow-hidden py-12 lg:py-24">
      <div className="header mb-12 text-center">
        <Headline variant="h2" element="h1" className="mb-10 lg:mb-14">
          {title}
        </Headline>

        <Markdown
          components={{
            h1(data) {
              return (
                <Headline element="h1" variant="h2">
                  {data.children}
                </Headline>
              );
            },
            h2(data) {
              return (
                <Headline element="h2" variant="h3">
                  {data.children}
                </Headline>
              );
            },
            h3(data) {
              return (
                <Headline element="h3" variant="h4">
                  {data.children}
                </Headline>
              );
            },
            h4(data) {
              return (
                <Headline element="h4" variant="h5">
                  {data.children}
                </Headline>
              );
            },
            h5(data) {
              return (
                <Headline element="h5" variant="h6">
                  {data.children}
                </Headline>
              );
            },
          }}
        >
          {body}
        </Markdown>
      </div>

      <BeamCommunityImpact />
    </div>
  );
}

CommunityImpact.displayName = 'CommunityImpact';
CommunityImpact.Schema = Schema;
