import { Image } from '../../snippets';

export function CareerIcon({ item }) {
  const { image, header, copy } = item;

  return (
    <div className="relative mb-8 w-full px-4 text-center text-primary md:w-1/3 lg:mb-14 lg:w-1/4">
      <div>
        {image && (
          <Image
            className="mx-auto mb-4"
            sizes="48px"
            width="48px"
            height="48px"
            src={image.src}
          />
        )}
        <p className="letter mb-2 text-[18px] font-normal leading-6 tracking-[.04em] lg:text-lg">
          {header}
        </p>
        <p className="mb-2 text-sm text-[#4D515D]">{copy}</p>
      </div>
    </div>
  );
}

CareerIcon.displayName = 'Career Listing';
