import { useState } from 'react';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link, Image } from '../../snippets';
import { ArrowButton, Button } from '../../snippets/Button';
import { Body, AltText, Headline } from '../../snippets/Typography';
import { Schema } from './CommonContaminants.schema';

function ContaminantSlide(props) {
  const { image, title, cta, category, articleTime } = props;
  return (
    <Link href={cta?.url}>
      <div className="group">
        {image?.src && (
          <div className="relative aspect-video w-full lg:h-[275px]">
            <Image
              src={image.src}
              alt={image.alt || title}
              width="385px"
              height="275px"
              className="absolute h-full w-full object-cover"
            />
          </div>
        )}

        <div className="mb-2 pt-4">
          <AltText className="uppercase tracking-[0.04em] text-white">
            {category} •{' '}
            <span className="text-shadowGray">{articleTime} Min Read</span>
          </AltText>
        </div>

        <Headline variant="h5" className="text-white">
          {title}
        </Headline>
      </div>
    </Link>
  );
}

export function CommonContaminants({ cms }) {
  const { heading, copy, icon, cta, contaminants } = cms;

  const [swiper, setSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(1);

  swiper?.on('slideChange', () => {
    setActiveSlideIndex(swiper.realIndex + 1);
  });

  return (
    <div className="overflow-hidden bg-[#202635] pb-12 pt-12 lg:pt-20">
      <div className="px-contained mx-auto max-w-[var(--content-max-width)]">
        <div className="relative z-10 mb-10 text-center lg:pl-20">
          {icon?.src && (
            <div className="relative mx-auto h-[61px] w-full">
              <Image
                src={icon.src}
                alt="icon"
                width="61px"
                height="61px"
                className="mx-auto mb-4 object-cover"
              />
            </div>
          )}
          <Headline variant="h3" className="mt-4 text-white">
            {heading}
          </Headline>
          <Body
            variant="medium"
            className="mx-auto mb-6 mt-6 max-w-2xl text-white lg:mb-12"
          >
            {copy}
          </Body>
          {cta && (
            <div>
              <Button theme="primary" variant="filled" href={cta.url}>
                {cta.text}
              </Button>
            </div>
          )}
        </div>
        <div className="">
          <div className="hidden justify-end lg:flex">
            <div className="prev">
              <ArrowButton arrowDirection="left" />
            </div>
            <div className="next pl-4">
              <ArrowButton arrowDirection="right" />
            </div>
          </div>

          <Swiper
            onSwiper={setSwiper}
            className="!static mx-auto mt-8 w-full max-w-[90%] overflow-visible lg:mt-4 lg:max-w-[100%]"
            loop
            navigation={{ prevEl: '.prev', nextEl: '.next', clickable: true }}
            pagination={{
              el: '.swiper-pagination',
              type: 'bullets',
              renderBullet(_, className) {
                return `<span class="!bg-white ${className} w-full rounded-none !m-0"></span>`;
              },
            }}
            modules={[Pagination, Navigation]}
            slidesPerView={1}
            spaceBetween={20}
            breakpoints={{
              1024: {
                loop: true,
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {contaminants?.map(
              (
                { image, title, articleTime, cta: contaminantCta, category },
                index
              ) => {
                return (
                  <SwiperSlide key={index}>
                    <ContaminantSlide
                      image={image}
                      title={title}
                      category={category}
                      articleTime={articleTime}
                      cta={contaminantCta}
                      key={index}
                    />
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>

          <div className="swiper-pagination swiper-progress !static mx-auto mt-8 flex w-full flex-nowrap lg:mt-16 lg:max-w-[calc(100%-100px)]" />

          <div className="relative mx-auto hidden w-full pb-2 lg:block">
            <div className="absolute right-0 top-[-13px] text-sm font-normal text-white">
              {activeSlideIndex} / {swiper?.slides.length}
            </div>
          </div>
        </div>
        <div className="mt-12 flex justify-center lg:hidden">
          <div className="prev">
            <ArrowButton arrowDirection="left" />
          </div>
          <div className="next pl-8">
            <ArrowButton arrowDirection="right" />
          </div>
        </div>
      </div>
    </div>
  );
}

CommonContaminants.displayName = 'Common Contaminants';
CommonContaminants.Schema = Schema;
