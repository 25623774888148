export const Schema = () => ({
  // category: 'Article',
  label: 'Community Impact',
  key: 'communityImpact',
  fields: [
    {
      name: 'title',
      component: 'text',
      label: 'Title',
      description: 'Leave Blank to hide from Table of Contents',
    },
    {
      label: 'Body',
      name: 'body',
      component: 'markdown',
    },
  ],
});
