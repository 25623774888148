export function Schema() {
  return {
    category: 'Image',
    label: 'Two Tiles',
    key: 'two-tiles',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/two-tiles-preview.jpg?v=1675730356',
    fields: [
      {
        label: 'Tiles',
        name: 'tiles',
        description: 'Max of 2 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        validate: {
          maxItems: 2,
        },
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
        ],
        defaultItem: {
          alt: 'Rack of green t-shirts',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/keagan-henman-xPJYL0l5Ii8-unsplash_20_281_29.jpg?v=1672349016',
          },
          position: 'object-center',
          heading: 'Headline',
          description: 'Nulla vitae elit libero, a pharetra augue.',
          link: {
            text: '',
            url: '',
          },
        },
        defaultValue: [
          {
            alt: 'Rack of green t-shirts',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/keagan-henman-xPJYL0l5Ii8-unsplash_20_281_29.jpg?v=1672349016',
            },
            position: 'object-center',
            heading: 'Headline',
            description: 'Nulla vitae elit libero, a pharetra augue.',
          },
          {
            alt: 'Dark orange jacket on a hanger',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/tobias-tullius-Fg15LdqpWrs-unsplash.jpg?v=1672348152',
            },
            position: 'object-center',
            heading: 'Headline',
            description: 'Nulla vitae elit libero, a pharetra augue.',
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Full width',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          fullWidth: false,
        },
      },
    ],
  };
}
