import { useSettings } from '@backpackjs/storefront';
import dynamic from 'next/dynamic';

const BeamCommunityImpact = dynamic(
  () => import('@beamimpact/web-sdk/dist/react/community-impact'),
  { loading: () => null, ssr: false }
);

export function BeamCommunityImpactWrapper() {
  const settings = useSettings();
  const beamSettings = settings?.beam;

  return (
    !!beamSettings && (
      <BeamCommunityImpact
        apiKey={beamSettings.beamApiKey}
        chainId={beamSettings.beamChainId}
        storeId={beamSettings.beamStoreId}
        baseUrl="https://api.beamimpact.com"
        lang="en"
        cardStyle="image"
        class="font-sans"
      />
    )
  );
}
