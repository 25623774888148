/* eslint-disable no-nested-ternary */
import { decode } from 'he';
import React, { useMemo, useState } from 'react';
import { useEWGContext } from '../../contexts/ewgContext';
import {
  Body,
  Button,
  ContaminantResultCard,
  ContaminantResultCardPrint,
  Eyebrow,
  Headline,
  Svg,
} from '../../snippets';
import useMediaQuery from '../../utils/useMediaQuery';
import { Schema } from './WIYWFullResults.schema';

export function WIYWFullResults({ cms }) {
  const { eyebrowText, sectionDisclosure } = cms;
  const {
    EWGResultsExceedingGuidelines,
    EWGResultsWithinGuidelines,
    activeEWGSystem,
  } = useEWGContext();
  const [isViewAll, setIsViewAll] = useState(false); // [TODO] - Remove this state and use the state from the EWGContext instead
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [visibleResults, setVisibleResults] = useState('exceeds');

  const slicedContaminants = useMemo(() =>
    [
      ...(EWGResultsExceedingGuidelines || []),
      ...(EWGResultsWithinGuidelines || []),
    ].slice(0, 4)
  );

  const allContaminantsJsx = useMemo(
    () =>
      (EWGResultsExceedingGuidelines || EWGResultsWithinGuidelines) && (
        <>
          <div
            className={`wiyw-exceeds-guidelines py-[60px] ${
              visibleResults === 'exceeds' ? 'block' : 'hidden'
            }`}
          >
            {EWGResultsExceedingGuidelines?.length > 0 && (
              <div className="grid grid-cols-1 gap-[20px] sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                {EWGResultsExceedingGuidelines.map(
                  (
                    contaminantExceedingGuidelines,
                    contaminantExceedingGuidelinesIndex
                  ) => {
                    return (
                      <ContaminantResultCard
                        key={`exceeds-guidelines-card-${contaminantExceedingGuidelinesIndex}`}
                        contaminant={contaminantExceedingGuidelines}
                      />
                    );
                  }
                )}
              </div>
            )}
          </div>
          <div
            className={`wiyw-within-guidelines py-[60px] ${
              visibleResults === 'within' ? 'block' : 'hidden'
            }`}
          >
            {EWGResultsWithinGuidelines?.length > 0 && (
              <div className="grid grid-cols-1 gap-[20px] sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                {EWGResultsWithinGuidelines.map(
                  (
                    contaminantWithinGuidelines,
                    contaminantWithinGuidelinesIndex
                  ) => {
                    return (
                      <ContaminantResultCard
                        alternate
                        key={`within-guidelines-card-${contaminantWithinGuidelinesIndex}`}
                        contaminant={contaminantWithinGuidelines}
                      />
                    );
                  }
                )}
              </div>
            )}
          </div>
        </>
      ),
    [EWGResultsExceedingGuidelines, EWGResultsWithinGuidelines, visibleResults]
  );

  const slicedContaminantsJsx = useMemo(
    () => (
      <div
        className={`wiyw-exceeds-guidelines relative py-[60px] ${
          visibleResults === 'exceeds' ? 'block' : 'hidden'
        }`}
      >
        {slicedContaminants?.length > 0 && (
          <div className="grid grid-cols-1 gap-[20px] sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            {slicedContaminants.map(
              (
                contaminantExceedingGuidelines,
                contaminantExceedingGuidelinesIndex
              ) => {
                return (
                  <ContaminantResultCard
                    key={`exceeds-guidelines-card-${contaminantExceedingGuidelinesIndex}`}
                    contaminant={contaminantExceedingGuidelines}
                  />
                );
              }
            )}
          </div>
        )}
        <div className="absolute bottom-[60px] left-0 h-[calc(25%+16px)] w-full bg-gradient-to-t from-[#F2F7FB] to-transparent" />
      </div>
    ),
    [slicedContaminants, visibleResults]
  );

  return (
    <>
      <div className="px-contained relative mx-auto max-w-[1728px] py-[30px] text-center print:hidden md:py-[60px]">
        <div className="wiyw-full-results-header">
          {eyebrowText && (
            <Eyebrow className="uppercase">{eyebrowText}</Eyebrow>
          )}
          <Headline variant="h3" element="h2">
            All Contaminants Detected in{' '}
            <span className="font-[500]">
              {decode(activeEWGSystem?.SystemName || 'N/A')}
            </span>
          </Headline>

          <div className="result-toggle mt-[30px] flex justify-center gap-[20px]">
            <div className="exceeds-toggle">
              <Button
                variant={visibleResults === 'exceeds' ? 'filled' : 'outlined'}
                theme="dark"
                className={`!border-text !text-text hover:!bg-text hover:!text-white focus:!bg-text active:!bg-text ${
                  visibleResults === 'exceeds' ? '!text-white' : null
                }`}
                onClick={() => {
                  setVisibleResults('exceeds');
                }}
              >
                Exceeds Guidelines
              </Button>
            </div>

            <div className="within-toggle">
              <Button
                variant={visibleResults === 'within' ? 'filled' : 'outlined'}
                theme="dark"
                className={`!border-text !text-text hover:!bg-text hover:!text-white focus:!bg-text active:!bg-text ${
                  visibleResults === 'within' ? '!text-white' : null
                }`}
                onClick={() => {
                  setVisibleResults('within');
                }}
              >
                Others Detected
              </Button>
            </div>
          </div>

          <div className="result-legend mt-[30px] flex justify-center">
            <div className="flex items-center justify-center gap-[13px] rounded-[10px] border border-[#DDE2E7] px-[20px] py-[10px]">
              <div className="relative flex h-[26px] w-[26px] items-center justify-center rounded-full border-[2px] border-secondary">
                <Svg
                  className="w-[14px] text-secondary"
                  src="/svgs/checkmark.svg#checkmark"
                  title="Checkmark"
                  viewBox="0 0 24 24"
                />
              </div>
              <div className="font-mono uppercase tracking-[0.004em]">
                Removed By Clearly Filtered
              </div>
            </div>
          </div>
        </div>
        <div className="wiyw-full-results-body">
          {isMobile
            ? isViewAll
              ? allContaminantsJsx
              : slicedContaminantsJsx
            : allContaminantsJsx}

          {!isViewAll && isMobile && (
            <Button
              theme="primary"
              variant="filled"
              className="mb-4"
              onClick={() => setIsViewAll(true)}
            >
              View All Contaminants
            </Button>
          )}

          {!EWGResultsExceedingGuidelines && !EWGResultsWithinGuidelines && (
            <div className="py-10">
              <p className="mx-auto max-w-[520px] border border-solid border-error bg-[#f1e5e3] py-1 text-center text-error">
                No contaminants found
              </p>
            </div>
          )}
        </div>

        {sectionDisclosure && (
          <div className="wiyw-full-results-footer mx-auto max-w-[1024px]">
            <Body variant="xs" className="text-[#797D86]">
              {sectionDisclosure}
            </Body>
          </div>
        )}
      </div>
      <div className="hidden py-10 print:block">
        <div className="wiyw-full-results-header mb-5 break-inside-avoid">
          <div className="wiyw-dashboard-header text-center font-sans text-xl font-light">
            All Contaminants Detected in{' '}
            <span className="font-[500]">
              {decode(activeEWGSystem?.SystemName || 'N/A')}
            </span>
          </div>

          <div className="result-legend mt-[30px] flex justify-center">
            <div className="flex items-center justify-center gap-[13px] rounded-[10px] border border-[#DDE2E7] px-4 py-2">
              <div className="relative flex h-[26px] w-[26px] items-center justify-center rounded-full border-[2px] border-secondary">
                <Svg
                  className="w-[14px] text-secondary"
                  src="/svgs/checkmark.svg#checkmark"
                  title="Checkmark"
                  viewBox="0 0 24 24"
                />
              </div>
              <div className="font-mono text-sm uppercase tracking-[0.004em]">
                Removed By Clearly Filtered
              </div>
            </div>
          </div>
        </div>
        <div className="wiyw-full-results-body">
          {EWGResultsExceedingGuidelines?.length > 0 && (
            <div>
              {EWGResultsExceedingGuidelines.map(
                (
                  contaminantExceedingGuidelines,
                  contaminantExceedingGuidelinesIndex
                ) => {
                  return (
                    <div
                      key={`exceeds-guidelines-card-${contaminantExceedingGuidelinesIndex}`}
                      className="w-full px-2 py-2"
                    >
                      <ContaminantResultCardPrint
                        contaminant={contaminantExceedingGuidelines}
                      />
                    </div>
                  );
                }
              )}
            </div>
          )}

          {EWGResultsWithinGuidelines?.length > 0 && (
            <div>
              {EWGResultsWithinGuidelines.map(
                (
                  contaminantWithinGuidelines,
                  contaminantWithinGuidelinesIndex
                ) => {
                  return (
                    <div
                      key={`within-guidelines-card-${contaminantWithinGuidelinesIndex}`}
                      className="w-full px-2 py-2"
                    >
                      <ContaminantResultCardPrint
                        contaminant={contaminantWithinGuidelines}
                      />
                    </div>
                  );
                }
              )}
            </div>
          )}

          {!EWGResultsExceedingGuidelines && !EWGResultsWithinGuidelines && (
            <div className="py-10">
              <p className="mx-auto max-w-[520px] border border-solid border-error bg-[#f1e5e3] py-1 text-center text-error">
                No contaminants found
              </p>
            </div>
          )}
        </div>

        {sectionDisclosure && (
          <div className="wiyw-full-results-footer mx-auto max-w-[1024px] pt-10">
            <Body variant="xs" className="text-[#797D86]">
              {sectionDisclosure}
            </Body>
          </div>
        )}
      </div>
    </>
  );
}

WIYWFullResults.displayName = 'WIYWFullResults';
WIYWFullResults.Schema = Schema;
