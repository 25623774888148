import { Schema } from './SimpleHeroBanner.schema';
import { Image } from '../../snippets';
import Button from '../../snippets/Button/Button';
import { Headline } from '../../snippets/Typography';
import { handleScroll } from '../../utils/scrollUtility.js';

export function SimpleHeroBanner({ cms }) {
    const { heading, 
            cta, 
            theme, 
            variant,
            linkId,
            condensedHero, 
            backgroundImage, 
            backgroundImageMobile 
        } = cms;


    return (
        <div className="relative bg-accent3Opacity20">
            <div className={`px-contained relative overflow-hidden ${ condensedHero ? 'py-10' : 'py-72' } max-sm:py-48 text-center`}>
                <div className="bg-content absolute inset-0 z-[1] h-full w-full bg-orange-300/10">
                    {backgroundImage && (
                        <Image
                            src={backgroundImage.src}
                            alt={backgroundImage.alt}
                            width={2000}
                            height={1200}
                            className="hidden h-full w-full object-cover md:block"
                        />
                    )}

                    {backgroundImageMobile && (
                        <Image
                            src={backgroundImageMobile.src}
                            alt={backgroundImageMobile.alt}
                            width={2000}
                            height={1200}
                            className="h-full w-full object-cover md:hidden"
                        />
                    )}
                </div>
                <div className="absolute inset-0 bg-black opacity-50 z-[1]" />
                <div className="p-8 relative z-[1]">
                    <Headline 
                        variant="h2" 
                        element="h2" 
                        className="relative font-light text-white max-sm:text-[30px] max-sm:max-w-72 max-sm:mx-auto"
                    >
                        {heading}
                    </Headline>
                    {cta && (
                        <div className="mt-0 lg:mt-6 max-sm:w-full">
                            <Button 
                                href={linkId ? `#${linkId}` : cta.url}
                                theme={theme} 
                                variant={variant}
                                className="mt-6 max-sm:w-full"
                                wrapperClassName="max-sm:w-full"
                                onClick={(event) => linkId && handleScroll(event, `#${linkId}`)}
                            >
                                {cta?.text}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

SimpleHeroBanner.displayName = 'Simple Hero Banner';
SimpleHeroBanner.Schema = Schema;
