export function Schema() {
  return {
    category: 'Hero',
    label: 'Simple Hero Banner',
    key: 'simpleHeroBanner',
    previewSrc:
    'https://cdn.shopify.com/s/files/1/1011/0318/files/simple-hero-banner.png?v=1721400498',
    fields: [
      {
        label: 'Condense Hero Height',
        name: 'condensedHero',
        description: 'This is a toggle to condense the height of the hero banner.',
        component: 'toggle',
        defaultValue: false,
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Lorem Ipsum Dolor Sit Amet',
      },
      {
        label: 'Call To Action',
        name: 'cta',
        component: 'link',
      },
      {
        label: 'Link',
        name: 'link',
        component: 'link',
      },
      {
        label: 'Link ID',
        name: 'linkId',
        component: 'text',
        description: 'Add the section ID you would like the button to scroll down/up to.',
      },
      {
        label: 'Button Theme',
        name: 'theme',
        component: 'select',
        options: [
          { label: 'Primary', value: 'primary' },
          { label: 'Secondary', value: 'secondary' },
          { label: 'Light', value: 'light' },
        ],
      },
      {
        label: 'Button Variant',
        name: 'variant',
        component: 'select',
        options: [
          { label: 'Filled', value: 'filled' },
          { label: 'Outlined', value: 'outlined' },
        ],
        defaultValue: 'filled',
      },
      {
        label: 'Background Image',
        name: 'backgroundImage',
        component: 'image',
      },
      {
        label: 'Background Image (Mobile)',
        name: 'backgroundImageMobile',
        component: 'image',
      },
    ],
  };
}
  