const buttonLink = [
  {
    label: 'Link',
    name: 'link',
    component: 'link',
  },
  {
    label: 'Button Theme',
    name: 'theme',
    component: 'select',
    options: [
      { label: 'Primary', value: 'primary' },
      { label: 'Secondary', value: 'secondary' },
      { label: 'Light', value: 'light' },
    ],
  },
  {
    label: 'Button Variant',
    name: 'variant',
    component: 'select',
    options: [
      { label: 'Filled', value: 'filled' },
      { label: 'Outlined', value: 'outlined' },
    ],
  },
];

export default buttonLink;
