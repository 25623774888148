import { useState } from 'react';

import { useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { Menu } from './Menu';
import { MenuDrawer } from './MenuDrawer';
import { MenuSidebar } from './MenuSidebar';
import { Promobar } from './Promobar';
import { useMenuDrawer } from './useMenuDrawer';
import { useMenuSidebar } from './useMenuSidebar';
import { useGlobalContext } from '../../contexts';

export function Header({
  promobarDisabled,
  transparentMode,
  transparentModeTextColor,
  promobarHidden,
  setPromoBarHidden,
  scrolledPast,
  page,
}) {
  const {
    state: { menuDrawerContent },
    actions: {
      handleMenuDrawerClose,
      handleMenuDrawerStayOpen,
      handleMenuHoverIn,
      handleMenuHoverOut,
    },
  } = useMenuDrawer();

  const {
    state: { menuSidebarOpen },
    actions: { handleCloseSidebar, handleOpenSidebar },
  } = useMenuSidebar();

  const {
    state: { accountOpen, cartOpen, searchOpen },
  } = useGlobalContext();

  // Only enable transparent mode when content is present for the Drawer
  const transparentModeEnabled =
    searchOpen ||
    accountOpen ||
    cartOpen ||
    menuDrawerContent?.imageLinks?.length > 0 ||
    menuDrawerContent?.links?.length > 0 ||
    menuDrawerContent?.iconMenuLinks?.length > 0
      ? false
      : transparentMode;

  return (
    <header
      className={`fixed left-0 right-0 top-0 z-30 flex flex-col border-b border-b-border transition-[height] transition-opacity duration-300 ease-out print:hidden ${
        promobarHidden || promobarDisabled
          ? 'h-[60px] md:h-[var(--header-height)]'
          : 'h-[calc(60px+var(--promobar-height))] md:h-[calc(var(--header-height)+var(--promobar-height))]'
      }
      ${transparentModeEnabled ? '!border-b-0 bg-transparent' : 'bg-white'}
      ${
        transparentModeEnabled && transparentModeTextColor === 'light'
          ? 'text-white'
          : 'text-primary'
      }
      ${accountOpen ? 'z-[9999]' : 'z-30'}
      ${
        page?.template?.type === 'product' && scrolledPast
          ? 'pointer-events-none opacity-0'
          : 'pointer-events-auto opacity-100'
      }
      `}
    >
      <Promobar
        promobarDisabled={promobarDisabled}
        promobarHidden={promobarHidden}
        setPromobarHidden={setPromoBarHidden}
      />

      <Menu
        transparentMode={transparentModeEnabled}
        transparentModeTextColor={transparentModeTextColor}
        handleOpenSidebar={handleOpenSidebar}
        handleMenuDrawerClose={handleMenuDrawerClose}
        handleMenuHoverIn={handleMenuHoverIn}
        handleMenuHoverOut={handleMenuHoverOut}
        menuDrawerContent={menuDrawerContent}
      />

      <MenuDrawer
        handleMenuDrawerClose={handleMenuDrawerClose}
        handleMenuDrawerStayOpen={handleMenuDrawerStayOpen}
        handleMenuHoverOut={handleMenuHoverOut}
        menuDrawerContent={menuDrawerContent}
      />

      <MenuSidebar
        promobarHidden={promobarHidden}
        promobarDisabled={promobarDisabled}
        handleCloseSidebar={handleCloseSidebar}
        menuSidebarOpen={menuSidebarOpen}
      />
    </header>
  );
}

Header.displayName = 'Header';
