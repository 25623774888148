export function Schema({ pageHandle }) {
  if (pageHandle !== 'whats-in-your-water') return null;
  return {
    label: 'WIYW Full Results',
    key: 'WIYWFullResults',
    category: 'WIYW',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/wiyw-full-results.png',
    fields: [
      {
        label: 'Eyebrow Text',
        name: 'eyebrowText',
        component: 'text',
      },
      {
        label: 'Section Disclosure',
        name: 'sectionDisclosure',
        component: 'textarea',
      },
    ],
  };
}
