export const Schema = () => ({
  label: 'Blog Article List',
  key: 'blogArticleList',
  category: 'Blog',
  //   previewSrc:
  //     'https://cdn.shopify.com/s/files/1/1011/0318/files/blog_list.png?v=1684851075',
  fields: [
    {
      label: 'Desktop Columns',
      name: 'desktopColumns',
      component: 'select',
      options: [
        { label: '4', value: '4' },
        { label: '3', value: '4' },
      ],
      defaultValue: '3',
    },
    // add sort by date or title
    {
      label: 'Sort By',
      name: 'sortBy',
      component: 'select',
      options: [
        { label: 'Date', value: 'date' },
        { label: 'Title', value: 'title' },
      ],
      defaultValue: 'date',
    },
  ],
});
