import { useEffect, useState } from 'react';

import { Markdown, Subheading, Svg } from '../../snippets';
import useMediaQuery from '../../utils/useMediaQuery';

export function Accordion({ accordion, index, activeIndex, handleClick }) {
  const { body, header } = accordion;
  const isOpen = activeIndex === index;
  const isMobile = useMediaQuery('(max-width: 767px)');

  const [variant, setVariant] = useState('small');
  useEffect(() => {
    if (isMobile) {
      setVariant('small');
    } else {
      setVariant('medium');
    }
  }, [isMobile]);

  return (
    <div>
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="flex min-h-[4rem] w-full items-center justify-between gap-x-4 border-t border-[#0000001A] py-3 pb-0 text-left"
        onClick={() => handleClick(index)}
        type="button"
      >
        <Subheading variant={variant}>{header}</Subheading>

        {isOpen ? (
          <Svg
            className="w-4 text-current"
            src="/svgs/chevron-up.svg#chevron-up"
            title="Up"
            viewBox="0 0 24 24"
          />
        ) : (
          <Svg
            className="w-4 text-current"
            src="/svgs/chevron-down.svg#chevron-down"
            title="Down"
            viewBox="0 0 24 24"
          />
        )}
      </button>

      <div className={`pb-2 pr-2 max-sm:pt-4 ${isOpen ? 'block' : 'hidden'}`}>
        <Markdown className="[&_p]:!text-[14px] [&_p]:font-[200] [&_p]:lg:!text-[18px]">
          {body}
        </Markdown>
      </div>
    </div>
  );
}

Accordion.displayName = 'Accordion';
