import { useEffect, useState } from 'react';
import { Schema } from './Environment.schema';
import { Image } from '../../snippets';
import { Headline } from '../../snippets/Typography';

function Stat(props) {
  const { content } = props;
  const [count, setCount] = useState(
    parseFloat(Number(content.number.replace(/,/g, '')))
  );

  useEffect(() => {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    function increment() {
      const newNumber = Number(count++);
      setCount(numberWithCommas(newNumber));
    }

    const interval = setInterval(increment, 2000);
  }, []);

  return (
    <div className="mb-4 w-full rounded-lg border border-[#DDE2E7] p-6 lg:mb-0 lg:px-8 lg:py-8">
      <p className="font-normal text-[#3279B8]">{content.title}</p>
      <p className="mt-2 font-light tracking-[-.02em] max-lg:text-[32px] lg:text-2xl 2xl:text-5xl">
        {count}
      </p>
    </div>
  );
}

export function Environment({ cms }) {
  const { heading, colorHeading, eyebrow, copy, image, stats } = cms;

  return (
    <div className="relative bg-[#e6f0f81a]">
      <div className="px-contained overflow-hidden pt-0 lg:flex lg:pt-20">
        <div className="pt-20 lg:w-1/2">
          <Headline
            variant="h3"
            element="h3"
            className="relative z-10 mb-6 mt-6 max-w-3xl lg:mb-12"
          >
            <span>{heading}</span> <br />
            <span className="text-[#3383C5]">{colorHeading}</span>
          </Headline>
          {image && (
            <div className="pt-12 lg:hidden">
              <Image
                className=""
                sizes="48px"
                width="357px"
                height="357px"
                src={image.src}
              />
            </div>
          )}
          <blockquote className="px-4 pb-10 pt-16 lg:max-w-xl lg:px-16 lg:py-24 lg:pb-0 lg:pt-8">
            <p className="mb-4 font-normal tracking-[-.01em] text-[#000]">
              {eyebrow}
            </p>
            <p className="text-base text-[#000] lg:text-xl">{copy}</p>
          </blockquote>
        </div>
        <div className="lg:w-1/2">
          {image && (
            <div className="hidden lg:block">
              <Image
                className=""
                sizes="48px"
                width="660px"
                height="660px"
                src={image.src}
              />
            </div>
          )}
        </div>
      </div>
      {stats?.statItems.length > 0 && (
        <div className="px-contained grid-cols-4 items-stretch gap-x-8 overflow-hidden pb-16 pt-8 lg:grid">
          {stats.statItems.map((stat, index) => (
            <Stat content={stat} key={index} />
          ))}
        </div>
      )}
    </div>
  );
}

Environment.displayName = 'Environment';
Environment.Schema = Schema;
