export default function Schema() {
  return {
    label: 'Reviews Slider',
    key: 'reviews-slider',
    category: 'Slider',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/reviews.png?v=1684933544',
    fields: [
      {
        label: 'Eyebrow',
        name: 'eyebrow',
        component: 'text',
        defaultValue: 'COMPLETE CONFIDENCE IN YOUR WATER',
      },
      {
        label: 'Title',
        name: 'title',
        component: 'text',
        defaultValue: 'Trusted by more than 10,000+ customers.',
      },
      {
        label: 'Call to action',
        name: 'cta',
        component: 'link',
        defaultValue: {
          href: '/reviews',
          label: 'Read All Reviews',
        },
      },
      {
        label: 'Remove Background Linear Gradient',
        name: 'remove_linear_gradient',
        component: 'toggle',
      },
      {
        label: 'Reviews',
        name: 'reviews',
        component: 'group-list',
        validate: {
          maxItems: 6,
        },
        fields: [
          {
            label: 'Name',
            name: 'name',
            component: 'text',
          },
          {
            label: 'Review',
            name: 'review',
            component: 'textarea',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Rating',
            name: 'rating',
            component: 'number',
            defaultValue: 5,
          },
        ],
      },
    ],
  };
}
