import { useMemo } from 'react';

export function useVariantPrices({ variant, purchaseType, isPdp }) {
  const { id, priceV2, compareAtPriceV2, sellingPlanAllocations } = {
    ...variant,
  };

  return useMemo(() => {
    let amount = 0;
    let compareAtAmount = 0;

    /**
     * return if no price on variant
     */
    if (!priceV2?.amount) {
      return { price: null, compareAtPrice: null };
    }

    /**
     * get subscription amount
     */
    let subscriptionAmount = null;
    let subscriptionCompareAmount = null;
    if (
      sellingPlanAllocations?.length &&
      sellingPlanAllocations[0].priceAdjustments?.length
    ) {
      const adjustment = sellingPlanAllocations[0].priceAdjustments[0];
      subscriptionAmount = parseFloat(adjustment?.price?.amount || 0);
      subscriptionCompareAmount = parseFloat(
        adjustment?.compareAtPrice?.amount || 0
      );
    }

    /**
     * set amount and compareAtAmount
     */
    if (isPdp && purchaseType === 'subscription' && subscriptionAmount) {
      amount = subscriptionAmount;
      compareAtAmount = subscriptionCompareAmount;
    } else if (!isPdp && subscriptionAmount) {
      amount = subscriptionAmount;
    } else {
      amount = parseFloat(priceV2.amount);
      compareAtAmount = parseFloat(compareAtPriceV2?.amount || '0');
    }

    return {
      price: `$${amount.toFixed(2).replace('.00', '')}`,
      compareAtPrice:
        compareAtAmount > amount
          ? `$${compareAtAmount.toFixed(2).replace('.00', '')}`
          : null,
    };
  }, [id, purchaseType]);
}
