import { Schema } from './ImageBehindText.schema';
import { Image } from '../../snippets';
import { Headline, Eyebrow } from '../../snippets/Typography';

export function ImageBehindText({ cms }) {
  const { eyebrow, copy, image } = cms;

  return (
    <div className='bg-[#202635] relative'>
        <div className="lg:px-contained lg:py-72 overflow-hidden max-w-7xl mx-auto relative">
            <div className='py-24 lg: py-0 px-4 lg:px-0'>
                <Eyebrow className="mb-4 text-white uppercase relative z-10 ">{eyebrow}</Eyebrow>
                <Headline variant="h3" element="h3" className="max-w-3xl mb-6 lg:mb-12 mt-6 text-white relative z-10">
                    {copy}
                </Headline>
            </div>
            {image && (
                <>
                    <div className='lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:right-0 z-0'>
                        <Image
                            className=""
                            sizes="48px"
                            width="762px"
                            height="756px"
                            src={image.src}
                        /> 
                    </div>
                
                </>
            )}
        </div>
    </div>
  );
}

ImageBehindText.displayName = 'Image Behind Text';
ImageBehindText.Schema = Schema;
