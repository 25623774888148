import { useProductByHandle } from '@backpackjs/storefront';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import Schema from './OkendoReviews.schema';
import OkendoReviewsWidget from './OkendoReviewsWidget';

/**
 *
 * @param {object} props - props object
 * @param {object} props.cms - cms object
 * @param {object} props.cms.product - product object
 * @param {string} props.cms.product.handle - product handle
 * @param {string} props.cms.product.title - product title
 *
 * @returns {JSX.Element} - OkendoReviews component
 * @example
 * <OkendoReviews
 *  cms={{
 *   product: {
 *    handle: 'product-handle',
 *    title: 'Product Title',
 *   },
 *  }}
 * />
 */
export default function OkendoReviews({ cms }) {
  const [shouldRender, setShouldRender] = useState(false);

  const { product } = useProductByHandle({
    handle: cms?.product?.handle,
  });

  const memoizedId = useMemo(() => {
    const separatedInstances = String(product?.id).split('/');
    return separatedInstances[separatedInstances.length - 1];
  }, [product]);

  useEffect(() => {
    setShouldRender(true);
  }, []);

  if (!shouldRender) return null;

  return (
    <section className="overflow-hidden bg-[#E6F0F8] px-4 py-20">
      <OkendoReviewsWidget productId={memoizedId} />
    </section>
  );
}

OkendoReviews.propTypes = {
  cms: PropTypes.shape({
    product: PropTypes.shape({
      handle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

OkendoReviews.defaultProps = {
  cms: {
    product: {
      handle: '',
      title: '',
    },
  },
};

OkendoReviews.displayName = 'OkendoReviews';
OkendoReviews.Schema = Schema;
