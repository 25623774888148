function isEndPointObject(obj) {
  return Object.values(obj).every(
    (value) => typeof value !== 'object' || Array.isArray(value)
  );
}

function flattenContaminant(obj, prefix = '', flattened = {}) {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    const newKey = prefix ? `${prefix}-${key}` : key;

    if (typeof value === 'object' && !Array.isArray(value)) {
      if (isEndPointObject(value)) {
        flattened[newKey] = value;
      } else {
        flattenContaminant(value, newKey, flattened);
      }
    }
  }

  return flattened;
}

export default flattenContaminant;
