import { useEffect, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { Svg } from '../Svg';
import { useDataLayerActions } from '../../hooks';

export function EmailSignup({ includeFooterContent }) {
  const settings = useSettings();
  const { sendSubscribeEvent } = useDataLayerActions();
  const { heading, subtext } = { ...settings?.footer?.marketing };
  const [formEmail, setFormEmail] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleNewsletterSignup = async (event) => {
    event.preventDefault();
    const klaviyoResponse = await fetch('/api/klaviyo-subscribe', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        emailAddress: formEmail,
      }),
    });

    if (klaviyoResponse.status === 200) {
      setIsSuccessful(true);
      setFormEmail('');
      sendSubscribeEvent({ email: formEmail });
    }
  };

  return (
    <div className="flex flex-wrap items-center justify-between lg:flex-nowrap">
      {includeFooterContent && (
        <div
          id="email-signup-left"
          className="mb-4 flex-[0_0_100%] text-xl font-[300] lg:mb-0 lg:flex-[0_0_55%] lg:text-[28px]"
        >
          <h3 className="text-xl font-[300] lg:text-[28px]">{heading}</h3>
          {subtext && <p className="text-[#A6A8AE]">{subtext}</p>}
        </div>
      )}

      <div
        id="email-signup-right"
        className={`${
          includeFooterContent ? 'lg:flex-[0_0_45%]' : ''
        } flex-[0_0_100%]`}
      >
        <div className="flex justify-between border-b border-b-[#DDE2E71A]">
          <form
            className="klaviyo-form-PAPDgq w-full"
            onSubmit={handleNewsletterSignup}
          >
            <div className="flex justify-between">
              <input
                type="email"
                value={formEmail}
                placeholder="Email address"
                onChange={(event) => {
                  setFormEmail(event.target.value);
                }}
              />
              <button type="submit">
                {' '}
                <Svg
                  className="w-[24px] text-white"
                  src="/svgs/arrow-right.svg#arrow-right"
                  title="Submit"
                  viewBox="0 0 24 24"
                />
              </button>
            </div>
          </form>
        </div>
        {isSuccessful && (
          <div className="py-6">
            Thanks for subscribing! Your submission was received successfully.
          </div>
        )}
      </div>
    </div>
  );
}

EmailSignup.displayName = 'EmailSignup';
