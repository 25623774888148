/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from '../Link';

export default function Button(props) {
  const {
    children,
    theme,
    variant,
    onClick,
    type,
    href,
    className,
    disabled,
    loading,
    wrapperClassName,
    ariaLabel,
  } = props;

  const buttonTheme = useMemo(() => {
    let classStr = '';

    if (theme === 'primary' && variant === 'filled') {
      classStr += `
        bg-textLink
        text-white
        hover:bg-primary focus:bg-primary
      `;
    }

    if (theme === 'primary' && variant === 'outlined') {
      classStr += `
        bg-transparent
        text-textLink
        border border-textLink
        hover:border-primary focus:border-primary
        hover:bg-primary focus:bg-primary
        hover:text-white focus:text-white
      `;
    }

    if (theme === 'secondary' && variant === 'filled') {
      classStr += `
        bg-primary
        text-white
        hover:bg-textLink focus:bg-textLink
      `;
    }

    if (theme === 'secondary' && variant === 'outlined') {
      classStr += `
        bg-transparent
        text-primary
        border border-primary
        hover:bg-primary focus:bg-primary
        hover:text-white focus:text-white
      `;
    }

    if (theme === 'light' && variant === 'filled') {
      classStr += `
        bg-white
        text-primary
        hover:bg-textLink focus:bg-textLink
        hover:text-white focus:text-white`;
    }

    if (theme === 'light' && variant === 'outlined') {
      classStr += `
        bg-transparent
        text-white
        border border-white
        hover:bg-white focus:bg-white
        hover:text-primary focus:text-primary
      `;
    }

    if (theme === 'dark' && variant === 'filled') {
      classStr += `
        bg-text
        text-white
        hover:bg-textLink focus:bg-textLink
        hover:text-white focus:text-white`;
    }

    if (theme === 'dark' && variant === 'outlined') {
      classStr += `
        bg-transparent
        text-text
        border border-text
        hover:bg-white focus:bg-white
        hover:text-text focus:text-text
      `;
    }

    return classStr;
  }, [theme, variant]);
  const Wrapper = useMemo(
    () =>
      href
        ? function LinkWrapper({ children }) {
            return (
              <Link href={href} className={`inline-block ${wrapperClassName}`}>
                {children}
              </Link>
            );
          }
        : function ButtonWrapper({ children }) {
            return children;
          },
    [href]
  );

  return (
    <Wrapper>
      <button
        aria-label={ariaLabel || null}
        disabled={disabled || loading}
        onClick={onClick}
        className={[
          'rounded-full px-[24px] py-[12px] text-center font-sans text-xs font-light disabled:opacity-50 lg:px-[2.125rem] lg:py-[0.906rem] lg:pb-[1.175rem] lg:text-base lg:font-normal',
          buttonTheme,
          className,
        ].join(' ')}
        // eslint-disable-next-line react/button-has-type
        type={type}
      >
        {children}
      </button>
    </Wrapper>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined']).isRequired,
  theme: PropTypes.oneOf(['primary', 'secondary', 'light', 'dark']).isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};
