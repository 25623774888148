export const Schema = () => {
  return {
    category: 'Product',
    label: 'Featured Products',
    key: 'featuredProducts',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/featured-products.png?v=1685571408',
    fields: [
      {
        name: 'sectionTitle',
        component: 'text',
        label: 'Section Title',
        defaultValue: 'Featured Products',
      },
      {
        name: 'sectionLink',
        component: 'link',
        label: 'Section Link',
      },
      {
        name: 'featuredProducts',
        component: 'group-list',
        label: 'Featured Products',
        itemProps: {
          label: '{{item.featuredProduct.title}}',
        },
        validate: {
          maxItems: 4,
        },
        fields: [
          {
            label: 'Featured Product',
            component: 'productSearch',
            name: 'featuredProduct',
          },
        ],
      },
    ],
  };
};
