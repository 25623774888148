import { useEffect, useState } from 'react';

import { useGlobalContext } from '../../contexts';
import { Svg } from '../Svg';

export function Drawer() {
  const {
    state: { drawer },
    actions: { closeDrawer },
  } = useGlobalContext();

  const [animateIn, setAnimateIn] = useState(true);

  useEffect(() => {
    setAnimateIn(true);
  }, [drawer]);

  const { className, ...props } = { ...drawer.props };

  return drawer.children ? (
    <div
      className={`
        fixed top-0 right-0 bottom-0 z-50 w-[calc(100%-2rem)] max-w-[48rem] overflow-hidden bg-background ${className}
        ${animateIn ? 'animate-slideIn' : ''}
      `}
      onAnimationEnd={() => setAnimateIn(false)}
      {...props}
    >
      <div className="max-h-[calc(var(--viewport-height)-2rem)] overflow-y-auto py-[92px] px-[18px] scrollbar-hide lg:px-[100px] lg:py-[125px]">
        <div className="flex justify-end">
          <button
            aria-label="Close drawer"
            className="right-[18px] top-[96px] z-10 flex h-[46px] w-[46px] items-center justify-center bg-transparent lg:right-[120px] lg:top-[127px]"
            onClick={closeDrawer}
            type="button"
          >
            <Svg
              className="w-11 text-text"
              src="/svgs/drawer-close.svg#drawer-close"
              title="Close"
              viewBox="0 0 46 46"
            />
          </button>
        </div>
        <div className="-mt-11">{drawer.children}</div>
      </div>
    </div>
  ) : null;
}

Drawer.displayName = 'Drawer';
