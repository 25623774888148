import { useState } from 'react';
import { Image, Button } from '../../snippets';
import { Schema } from './HowItWorks.schema';

function HowItWorksItem(props) {
  const {
    image,
    header,
    copy,
    index,
    activeIndex,
    setActiveItem,
    setActiveIndex,
    howItWorksItems,
  } = props;

  return (
    <div
      className="mx-auto mb-4 cursor-pointer items-center justify-center lg:flex"
      onClick={() => {
        setActiveIndex(index);
        setActiveItem(howItWorksItems[index]);
      }}
    >
      {image && (
        <div className="lg:hidden">
          <Image
            className="mx-auto mb-6"
            sizes="48px"
            width="355px"
            height="355px"
            src={image.src}
          />
        </div>
      )}
      <div className="w-full rounded-lg bg-[#D9D9D9] bg-gradient-to-l from-[#88b3e0] via-[#8DCEF9] to-[#F1DCC1] p-[1px] lg:h-36 ">
        <div
          className="back flex h-full w-full justify-center rounded-lg lg:py-0"
          style={{
            backgroundColor:
              activeIndex === index ? 'rgba(255,255,255,0.8)' : 'transparent',
            background: activeIndex === index ? '#e1f0fc' : 'var(--clearBlue)',
          }}
        >
          <div className="flex flex-[0_0_100px] items-center justify-center px-4 text-center text-3xl font-light tracking-[-0.01em] lg:px-6 lg:text-4xl">
            0{index + 1}
          </div>
          <div className="w-[1px] bg-[#D9D9D9] bg-gradient-to-t from-[#88b3e0] via-[#8DCEF9] to-[#F1DCC1]" />
          <div className="flex flex-col justify-center py-8  pl-4 pr-3 text-left lg:pl-4 lg:pr-6">
            <p className="mb-2 text-lg font-light lg:text-xl">{header}</p>
            <p className="text-xs font-light lg:text-base">{copy}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function HowItWorks({ cms }) {
  const { header, copy, ctaCopy, ctaUrl, howItWorksItems } = cms;
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeItem, setActiveItem] = useState(
    howItWorksItems.length > 0 ? howItWorksItems[0] : null
  );

  return (
    <div className="px-contained lg:py-contained relative mx-auto flex pb-16 pt-2 lg:pb-4">
      <div className="relative hidden w-1/2 lg:block lg:pr-12">
        {activeItem?.image && (
          <Image
            className="mx-auto mb-4"
            sizes="48px"
            width="815px"
            height="815px"
            src={activeItem.image.src}
          />
        )}
      </div>
      <div className="mx-auto text-center lg:w-1/2 lg:max-w-lg lg:text-left">
        {header && (
          <h3 className="mx-auto mb-6 text-3xl font-light lg:text-5xl">
            {header}
          </h3>
        )}

        {copy && <p className="mb-6 text-sm lg:text-xl">{copy}</p>}

        {ctaCopy && ctaUrl && (
          <div className="mb-4 lg:hidden">
            <Button href={ctaUrl} theme="primary" variant="filled">
              {ctaCopy}
            </Button>
          </div>
        )}

        {howItWorksItems?.length > 0 && (
          <div className="mx-auto flex max-w-none flex-col items-center gap-4 md:gap-6">
            <div className="mt-4 flex flex-wrap justify-center lg:flex">
              {howItWorksItems.map(({ image, header, copy }, index) => {
                return (
                  <HowItWorksItem
                    image={image}
                    header={header}
                    copy={copy}
                    index={index}
                    activeIndex={activeIndex}
                    setActiveItem={setActiveItem}
                    setActiveIndex={setActiveIndex}
                    howItWorksItems={howItWorksItems}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        )}

        {ctaCopy && ctaUrl && (
          <div className="mt-6 hidden lg:block">
            <Button href={ctaUrl} theme="primary" variant="filled">
              {ctaCopy}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

HowItWorks.displayName = 'HowItWorks';
HowItWorks.Schema = Schema;
