import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Headline } from '../../snippets';
import Eyebrow from '../../snippets/Typography/Eyebrow';
import Schema from './ProductSteps.schema';
import ProductStepsContent from './ProductStepsContent';
import ProductStepsImageCarousel from './ProductStepsImageCarousel';
/**
 * @typedef Image
 * @type {object}
 * @property {string} src - The image source.
 * @property {string} alt - The image alt text.
 */

/**
 * Step carousel/slider made for product detail page.
 * @param {object} props - The props that were defined by the caller of this component.
 * @param {object} props.cms - The content that will came from PACK.
 * @param {boolean} props.cms.darkMode - Dark mode toggle.
 * @param {string} props.cms.eyebrow - The eyebrow text.
 * @param {string} props.cms.headline - The headline text.
 * @param {Array.<{ stepText: String, image: Image, heading: String content: String }>} props.cms.slides - The slides array.
 * @param {object} props.cms.slides - The slides array.
 *
 * @component
 */
function ProductSteps({ cms }) {
  const { eyebrow, headline, slides, darkMode } = cms;
  const images = useMemo(
    () => slides?.map((slide) => slide.image) ?? [],
    [slides]
  );

  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <div className={darkMode ? 'dark' : ''}>
      <section className="py-10 text-center dark:bg-[#202635] dark:text-[#ffffff] lg:py-40">
        <div className="px-contained mx-auto max-w-[var(--content-max-width)]">
          <hr className="mb-10 border-white opacity-20" />
          <div className="mx-auto mb-8 max-w-3xl">
            <Eyebrow>{eyebrow}</Eyebrow>
            <Headline variant="h4">{headline}</Headline>
          </div>
          <ProductStepsImageCarousel
            images={images}
            onChange={setCurrentSlide}
            currentSlide={currentSlide}
          />
          <ProductStepsContent
            steps={slides}
            currentStep={currentSlide}
            setCurrentSlide={setCurrentSlide}
          />
        </div>
      </section>
    </div>
  );
}

ProductSteps.propTypes = {
  /**
   * CMS content that came from PACK
   */
  cms: PropTypes.shape({
    eyebrow: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    darkMode: PropTypes.bool,
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        stepText: PropTypes.string.isRequired,
        image: PropTypes.oneOfType([
          PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
          }),
          PropTypes.string,
        ]),
        heading: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      })
    ),
  }),
};

ProductSteps.defaultProps = {
  cms: {
    eyebrow: '',
    headline: '',
    darkMode: false,
    slides: [],
  },
};

ProductSteps.displayName = 'ProductSteps';
ProductSteps.Schema = Schema;

export default ProductSteps;
