import React from 'react';
import { Svg } from '../Svg';
import { Eyebrow, Headline } from '../Typography';

export function ContaminantResultCard({ contaminant, alternate }) {
  const {
    ContaminantName,
    // ContaminantHealthRisks,
    SystemAverage,
    ContaminantHGValue,
    ClearlyFilteredData,
  } = { ...contaminant };
  const { category, healthConcern, percentRemoved } = {
    ...ClearlyFilteredData,
  };
  const multiplier = Math.abs(
    parseFloat(SystemAverage / ContaminantHGValue).toFixed(2)
  );

  const displayUnits = contaminant?.ContaminantDisplayUnits;

  // This assumes that the ewg measurement is always in ppm
  const convertMeasurement = (measurement) => {
    if (displayUnits === 'ppm') {
      return measurement;
    }
    if (displayUnits === 'ppb') {
      return measurement * 1000;
    }
    if (displayUnits === 'ppt') {
      return measurement * 1000000;
    }
    return measurement;
  };

  return (
    <div className="contaminant-card flex h-full bg-gradient-to-l from-[#2179C2] via-[#8DCEF9] to-[#F1DCC1] p-[2px] print:border print:bg-none">
      <div className="relative flex w-full flex-col justify-between bg-white p-[30px]">
        {percentRemoved && (
          <div className="absolute right-[12px] top-[12px] flex h-[26px] w-[26px] items-center justify-center rounded-full border-[2px] border-secondary">
            <Svg
              className="w-[14px] text-secondary"
              src="/svgs/checkmark.svg#checkmark"
              title="Checkmark"
              viewBox="0 0 24 24"
            />
          </div>
        )}
        <div>
          {category && (
            <Eyebrow className="card-eyebrow font-mono !text-sm uppercase tracking-[0.04em]">
              {category}
            </Eyebrow>
          )}
          {ContaminantName && (
            <Headline
              variant="h5"
              element="h4"
              className="card-headline py-[6px] !text-[22px] font-normal"
            >
              {ContaminantName}
            </Headline>
          )}
          {healthConcern && (
            <div className="card-health-concerns">
              Health Risk: {healthConcern}
            </div>
          )}
        </div>
        {alternate && (
          <div className="mt-4 py-[20px] text-left text-sm lg:pb-[60px] lg:pt-[30px]">
            {contaminant?.SystemAverage && (
              <div className="mb-[10px] flex justify-between">
                <div className="uppercase">This Utility</div>
                <div>
                  {parseFloat(
                    convertMeasurement(contaminant.SystemAverage)
                  ).toFixed(2)}{' '}
                  {displayUnits}
                </div>
              </div>
            )}

            {contaminant?.ContaminantHGValue &&
              contaminant?.ContaminantHGValue > 0 && (
                <div className="mb-[10px] flex justify-between">
                  <div className="uppercase">EWG Health Guideline</div>
                  <div>
                    {parseFloat(
                      convertMeasurement(contaminant.ContaminantHGValue)
                    ).toFixed(2)}{' '}
                    {displayUnits}
                  </div>
                </div>
              )}

            {contaminant?.ContaminantHGValue &&
              contaminant?.ContaminantHGValue <= 0 && (
                <div className="mb-[10px] flex justify-between text-[#797D86]">
                  <div className="uppercase">No EWG Guidelines</div>
                </div>
              )}

            {contaminant?.ContaminantMCLValue &&
              parseFloat(contaminant.ContaminantMCLValue) > 0 && (
                <div className="mb-[10px] flex justify-between">
                  <div className="uppercase">Legal Limit</div>
                  <div>
                    {Math.round(
                      convertMeasurement(contaminant.ContaminantMCLValue)
                    ).toFixed(2)}{' '}
                    {displayUnits}
                  </div>
                </div>
              )}

            {contaminant?.ContaminantMCLValue &&
              contaminant?.ContaminantMCLValue <= 0 && (
                <div className="mb-[10px] flex justify-between text-[#797D86]">
                  <div className="uppercase">No Legal Limit</div>
                </div>
              )}
          </div>
        )}

        {!alternate && (
          <div className="py-[20px] lg:pb-[60px] lg:pt-[30px]">
            {' '}
            {alternate}
            <Headline variant="h2" element="h5" className="text-primary">
              {ContaminantHGValue !== -1 ? `${multiplier}x` : 'N/A'}
            </Headline>
            <div>{ContaminantHGValue === -1 && 'No'} EWG Health Guidelines</div>
          </div>
        )}

        <div className="card-percent-removed text-secondary">
          <div className="mb-[24px] h-[1px] bg-gradient-to-l from-[#2179C2] via-[#8DCEF9] to-[#F1DCC1] lg:my-[24px]" />

          {percentRemoved ? (
            <Eyebrow className="!text-[15px] uppercase">
              Clearly Filtered removes up to
            </Eyebrow>
          ) : (
            <Eyebrow className="!text-[15px] uppercase text-shadowGray">
              Have not tested for
            </Eyebrow>
          )}

          {percentRemoved ? (
            <Headline variant="h2" element="h5" className="text-secondary">
              {percentRemoved}
            </Headline>
          ) : (
            <Headline variant="h2" element="h5">
              &nbsp;
            </Headline>
          )}
        </div>
      </div>
    </div>
  );
}
