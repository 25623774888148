export const Schema = () => ({
  category: 'Article',
  label: 'Article Content',
  key: 'articleContent',
  //   previewSrc:
  //     'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-24_20at_202.55.23_20PM.png?v=1684965343',
  fields: [
    {
      name: 'title',
      component: 'text',
      label: 'Title',
    },
    {
      name: 'anchorId',
      component: 'text',
      label: 'Table Of Contents Title',
      description: 'Leave Blank to hide from Table of Contents',
    },
    {
      label: 'Content',
      name: 'content',
      component: 'markdown',
      defaultValue:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
  ],
});
