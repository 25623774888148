import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';

export function useColorSwatches() {
  const settings = useSettings();
  const { swatches } = { ...settings?.product?.colors };

  const swatchesMap = useMemo(() => {
    if (!swatches?.length) return {};
    return swatches.reduce((acc, { name, color, image }) => {
      return {
        ...acc,
        [name?.toLowerCase().trim()]: image?.src || color,
      };
    }, {});
  }, [swatches]);

  return { swatchesMap };
}
