import { useMemo, useState } from 'react';

import { ColorVariantOption } from './ColorVariantOption';

export function ColorVariantOptions({
  enabledColorNameOnHover,
  product,
  setVariantFromColor,
  swatchesMap,
  variantFromColor,
}) {
  const [maxCount, setMaxCount] = useState(14);

  const originalColor = product.optionsMap.Color?.[0];
  const colorOptions = product.grouping?.isTransformed
    ? [
        originalColor,
        ...product.grouping.optionsMap.Color.filter(
          (color) => color !== originalColor
        ),
      ]
    : product.optionsMap.Color;
  const slicedColorOptions = colorOptions.slice(0, maxCount);

  const variantMapByColor = useMemo(() => {
    const variants = product.grouping?.isTransformed
      ? product.grouping.variants
      : product.variants;
    return variants.reduce((acc, variant) => {
      const variantColor = variant.selectedOptionsMap?.Color;
      if (acc[variantColor]) return acc;
      return {
        ...acc,
        [variantColor]: variant,
      };
    }, {});
  }, [product.id]);

  return (
    <ul className="flex flex-wrap gap-[10px]">
      {slicedColorOptions.map((color, index) => {
        return (
          <li key={index}>
            <ColorVariantOption
              color={color}
              enabledColorNameOnHover={enabledColorNameOnHover}
              onClick={() => setVariantFromColor(variantMapByColor[color])}
              onMouseEnter={() => setVariantFromColor(variantMapByColor[color])}
              swatchesMap={swatchesMap}
              variantFromColor={variantFromColor}
            />
          </li>
        );
      })}
    </ul>
  );
}

ColorVariantOptions.displayName = 'ColorVariantOptions';
