export function Schema() {
  return {
    category: 'Article',
    label: 'Article Image',
    key: 'articleImage',
    // previewSrc:
    //   'https://cdn.shopify.com/s/files/1/1011/0318/files/Screenshot_2024-09-04_at_9.51.32_AM.png?v=1725468763',
    fields: [
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Image Link',
        name: 'imageLink',
        component: 'link',
      },
      {
        label: 'Caption',
        name: 'caption',
        component: 'text',
      },
      {
        label: 'Attribution',
        name: 'attribution',
        component: 'text',
      },
      {
        label: 'Attribution Link',
        name: 'attributionLink',
        component: 'link',
      },
    ],
  };
}
