import { useEffect, useState } from 'react';
import { Button, Svg } from '../../snippets';

export default function SignupForm({
  enable,
  placeholder,
  buttonText,
  successMessage,
  listId,
  successCtaLink,
}) {
  const [formEmail, setFormEmail] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  const handleNewsletterSignup = async (event) => {
    event.preventDefault();
    const payload = { emailAddress: formEmail };
    if (listId) {
      payload.listId = listId;
    }

    const klaviyoResponse = await fetch('/api/klaviyo-subscribe', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (klaviyoResponse.status === 200) {
      setIsSuccessful(true);
      setIsErrored(false);
    } else {
      setIsErrored(true);
    }
  };

  const [formFieldClasses, setFormFieldClasses] = useState('');
  useEffect(() => {
    if (isSuccessful) {
      setFormFieldClasses('border-2 border-solid border-[#5ABE8A]');
    } else if (isErrored) {
      setFormFieldClasses('border-2 border-solid border-error');
    } else {
      setFormFieldClasses('');
    }
  }, [isSuccessful, isErrored]);

  return (
    !!enable && (
      <div className="mt-9 w-[620px] max-w-full">
        <form
          className="klaviyo-form w-full px-8"
          onSubmit={handleNewsletterSignup}
        >
          <div
            className={`relative flex justify-between ${
              !isSuccessful && !isErrored ? 'mb-6 lg:mb-9' : ''
            }`}
          >
            <input
              type="email"
              className={`w-full rounded-[0.63rem] bg-[rgba(255,255,255,0.3)] p-3 text-lg font-light placeholder-[var(--primary)] outline-none lg:p-5 lg:text-xl ${formFieldClasses}`}
              disabled={isSuccessful}
              value={formEmail}
              placeholder={placeholder || 'Email address'}
              onChange={(event) => {
                setFormEmail(event.target.value);
              }}
            />

            {isSuccessful && (
              <Svg
                className="absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 transform object-contain text-[#5ABE8A] lg:h-8 lg:w-8"
                src="/svgs/checkmark-with-circle.svg#checkmark-with-circle"
                title="Check"
                viewBox="0 0 32 33"
              />
            )}
          </div>

          {isSuccessful && successMessage && (
            <>
              <div className="py-9">{successMessage}</div>
              {successCtaLink && (
                <Button
                  theme="light"
                  variant="filled"
                  className="w-full"
                  wrapperClassName="w-full"
                  href={successCtaLink.url}
                >
                  {successCtaLink.text}
                  <Svg
                    className="ml-6 inline-block w-4 lg:ml-10"
                    src="/svgs/arrow-right.svg#arrow-right"
                    title="Arrow Right"
                    viewBox="0 0 24 24"
                  />
                </Button>
              )}
            </>
          )}

          {isErrored && (
            <div className="py-9 text-error">
              An error occurred. Please try again.
            </div>
          )}

          {!isSuccessful && buttonText && (
            <Button
              ariaLabel="submit"
              type="submit"
              theme="primary"
              variant="filled"
              className="w-full"
            >
              {buttonText}
            </Button>
          )}
        </form>
      </div>
    )
  );
}
