import { useEffect } from 'react';
import { useProductsFromHandles, useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { Link } from '../Link';
import { SearchItem } from './SearchItem';
import { Svg } from '../Svg';

import { useDataLayerActions } from '../../hooks';

export function SearchResults({
  closeSearch,
  collectionResults,
  productResults,
  rawTerm,
  searchTerm,
}) {
  const settings = useSettings();
  const router = useRouter();
  // products for data layer event
  const { products } = useProductsFromHandles({
    handles: productResults?.slice(0, 10).map(({ item }) => item.handle),
  });
  const { sendViewSearchResultsEvent } = useDataLayerActions();

  const { productsEnabled, collectionsEnabled } = {
    ...settings?.search?.results,
  };

  const handleViewAll = (pathName, term) => {
    closeSearch();
    router.push(`${pathName}?term=${term}`);
  };

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: productResults?.slice(0, 4),
      isSearchPage: false,
      searchTerm,
    });
  }, [productResults, sendViewSearchResultsEvent]);

  return (
    <div className="relative flex flex-1 flex-col gap-4 overflow-y-auto pt-4 scrollbar-hide">
      {productsEnabled && products?.length > 0 && (
        <div>
          <div className="mx-8 flex items-center justify-between border-b border-b-border pb-2">
            <h3 className="text-sm font-light text-primaryLight ">
              {productResults?.length} Product Results for "
              <span className="italic text-primary">{rawTerm}</span>"
            </h3>
            <button
              type="button"
              aria-label="View All"
              className="block flex-[0_0_80px] cursor-pointer items-center text-sm font-normal no-underline"
              onClick={() => handleViewAll('/pages/search', rawTerm)}
            >
              <span className="flex">
                View All
                <Svg
                  className="ml-[12px] w-[14px] text-black"
                  src="/svgs/arrow-right-long.svg#arrow-right-long"
                  title="Arrow Right"
                  viewBox="0 0 14 14"
                />
              </span>
            </button>
          </div>

          <ul>
            {productResults?.slice(0, 4).map(({ item }, index) => {
              return item ? (
                <li
                  key={index}
                  className="mx-8 border-b border-b-border py-4 last:border-none"
                >
                  <SearchItem
                    closeSearch={closeSearch}
                    index={index}
                    item={item}
                    searchTerm={searchTerm}
                  />
                </li>
              ) : null;
            })}
          </ul>
        </div>
      )}

      {collectionsEnabled && collectionResults?.length > 0 && (
        <div className="mb-8 px-8">
          <div className="mb-4 flex items-center justify-between border-b border-b-border pb-2">
            <h3 className="text-sm font-light text-primaryLight">
              {collectionResults?.length} Collection Results for "
              <span className="italic text-primary">{rawTerm}</span>"
            </h3>
            <Link
              aria-label="View All"
              className="block w-[80px] items-center  text-sm font-normal no-underline"
              href={{ pathname: '/pages/search', query: { term: rawTerm } }}
            >
              <span className="inline-block">View All</span>
            </Link>
          </div>

          <ul className="flex flex-col items-start gap-3">
            {collectionResults.map(({ item, refIndex }) => {
              return (
                <li key={refIndex}>
                  <Link
                    aria-label="View All"
                    href={`/collections/${item.handle}`}
                  >
                    <p className="">{item.title}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

SearchResults.displayName = 'SearchResults';
