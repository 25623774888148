export default function Schema() {
  return {
    key: 'okendo-reviews',
    label: 'Okendo Reviews',
    fields: [
      {
        name: 'product',
        label: 'Product',
        component: 'productSearch',
      },
    ],
  };
}
