import { Link, Image } from '../../snippets';
import { AltText, Headline } from '../../snippets/Typography';
import Schema from './DynamicPopularPosts.schema';

import articleList from '../../../public/json/articles-list.json'; // eslint-disable-line

function PopularPost(props) {
  const { image, title, cta, blog, articleTime } = props;
  return (
    <Link href={cta?.url}>
      <div key={cta?.url} className="group mb-8 cursor-pointer">
        <div className="my-4 h-[1px] bg-[#fff] transition-all group-hover:bg-gradient-to-r group-hover:from-[#F1DCC1] group-hover:to-[#2179C2]" />

        <div className="mt-8 flex items-center justify-between lg:items-start">
          <div className="w-full">
            <div className="mb-2">
              <AltText className="uppercase">
                {blog?.title}
                {articleTime ? (
                  <span className="mr-2 text-shadowGray">
                    • {articleTime} Min Read
                  </span>
                ) : (
                  ''
                )}
              </AltText>
            </div>

            <Headline variant="h5" className="max-lg:text-[16px]">
              {title}
            </Headline>
          </div>
          {image?.src && (
            <div className="relative flex h-[85px] w-full max-w-[110px] justify-end lg:h-[210px] lg:max-w-[260px]">
              <Image
                src={image.src}
                alt={image.alt || title}
                width="260px"
                height="210px"
                className="object-cover"
              />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export default function DynamicPopularPosts({ cms }) {
  const { header, articleHandles = [] } = cms;

  const [featuredArticle, ...articles] = articleHandles.reduce(
    (reducer, articleHandle) => {
      const article = articleList.find(
        ({ handle }) => handle === articleHandle
      );
      if (article) {
        const articleTime =
          article.tags
            .find((tag) => tag.includes('minutes:'))
            ?.replace(/\D/g, '') || '';

        reducer.push({
          title: article.title,
          blog: article.blog,
          articleTime,
          image: {
            src: article.seo.image,
            alt: article.title,
          },
          cta: {
            url: `/articles/${article.handle}`,
          },
        });
      }

      return reducer;
    },
    []
  );

  return (
    <div className="pb-8 pt-12 lg:pb-28 lg:pt-20">
      <div className="px-contained mx-auto flex max-w-[var(--content-max-width)] pb-8">
        <Headline variant="h3" className="">
          {header}
        </Headline>
      </div>
      <div className="lg:px-contained mx-auto  max-w-[var(--content-max-width)] lg:grid lg:grid-cols-2 lg:gap-16">
        <Link
          href={featuredArticle.cta?.url}
          className="relative flex cursor-pointer"
        >
          {featuredArticle.image?.src && (
            <div className="absolute flex h-[420px] w-full justify-end lg:h-full">
              <Image
                src={featuredArticle.image.src}
                alt={featuredArticle.image.alt || featuredArticle.title}
                fill
                className="h-[420px] object-cover lg:h-full "
              />
            </div>
          )}
          <div className="flex h-[420px] items-end lg:h-auto">
            <div className="px-contained group relative z-10 mb-8 py-8">
              <div className="mt-8 flex justify-between">
                <div className="w-full">
                  <div className="mb-2">
                    <AltText className="uppercase tracking-[0.04em] text-white">
                      {featuredArticle?.blog?.title}
                      {featuredArticle.articleTime ? (
                        <span className="ml-2">
                          • {featuredArticle.articleTime} Min Read
                        </span>
                      ) : (
                        ''
                      )}
                    </AltText>
                  </div>

                  <Headline variant="h3" className="text-white">
                    {featuredArticle.title}
                  </Headline>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="px-contained lg:px-0">
          {articles?.map(({ image, title, articleTime, cta, blog }, index) => {
            return (
              <PopularPost
                image={image}
                title={title}
                blog={blog}
                articleTime={articleTime}
                cta={cta}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

DynamicPopularPosts.displayName = 'DynamicPopularPosts';
DynamicPopularPosts.Schema = Schema;
