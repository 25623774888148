import { BackgroundVideo } from '../../snippets/BackgroundVideo';
import { Image } from '../../snippets';

export default function HeroBackground({
  video,
  mobileVideo,
  image,
  mobileImage,
}) {
  return (
    <>
      {video && (
        <div className="release-iframe absolute left-0 top-0 z-[-1] h-full w-full overflow-hidden">
          <BackgroundVideo
            videoUrl={video}
            mobileVideoUrl={mobileVideo || video}
          />
        </div>
      )}
      {!video && image && (
        <Image
          src={image.src}
          alt={image.alt ?? 'Hero Banner Image'}
          width={2000}
          height={1200}
          className="hidden h-full w-full object-cover md:block"
        />
      )}
      {!mobileVideo && mobileImage && (
        <Image
          src={mobileImage.src}
          alt={mobileImage.alt ?? 'Hero Banner Image'}
          width={2000}
          height={1200}
          className="h-full w-full object-cover md:hidden"
        />
      )}
    </>
  );
}
