export default {
  label: 'General',
  name: 'general',
  component: 'group',
  description: 'General site settings',
  fields: [
    {
      label: 'Logo',
      name: 'logo',
      component: 'image',
    },
    {
      label: 'Favicon',
      name: 'favicon',
      component: 'image',
    },
  ],
};
