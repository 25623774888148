export function Schema() {
  return {
    category: 'Text',
    label: 'Legal',
    key: 'legal',
    fields: [
      {
        label: 'Body',
        name: 'body',
        component: 'markdown',
      },
      {
        label: 'Legal Menu Item',
        name: 'legalMenuItems',
        component: 'group-list',
        itemProps: {
          label: '{{item.header}}',
        },
        fields: [
          {
            label: 'Header',
            name: 'header',
            component: 'text',
          },
          {
            label: 'Url',
            name: 'url',
            component: 'text',
          },
        ],
        defaultValue: [
          {
            header: 'Privacy',
            url: 'privacy',
          },
        ],
        defaultItem: {
          header: 'Privacy',
          url: 'privacy',
        },
      },
    ],
  };
}
