import { useState } from 'react';
import { Markdown, Subheading, Svg } from '../../snippets';

export function Accordion({ accordion }) {
  const { body, defaultOpen, header } = accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="">
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="flex min-h-[4rem] w-full items-center justify-between gap-x-4 border-t border-[#DDE2E7] py-6 px-6  pb-4 text-left lg:py-8 lg:px-10 lg:pb-6"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <Subheading variant="medium" className="!font-[400] lg:!text-[18px]">
          {header}
        </Subheading>

        {isOpen ? (
          <Svg
            className="w-4 text-current"
            src="/svgs/minus.svg#minus"
            title="Up"
            viewBox="0 0 24 24"
          />
        ) : (
          <Svg
            className="w-4 text-current"
            src="/svgs/plus.svg#plus"
            title="Down"
            viewBox="0 0 24 24"
          />
        )}
      </button>

      <div
        className={`px-6 pb-4 pr-2 lg:px-10 [&_p]:lg:!text-[16px] [&_p]:lg:!leading-[22px] ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <Markdown>{body}</Markdown>
      </div>
    </div>
  );
}

Accordion.displayName = 'Accordion';
