import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Subheading, Image, Link } from '../../snippets';
import { Schema } from './LabResultsNavigation.schema';

export function LabResultsNavigation({ cms }) {
  const { asPath } = useRouter();
  const { links, heading } = cms;
  const [renderClientSideComponent, setRenderClientSideComponent] =
    useState(false);

  useEffect(() => {
    setRenderClientSideComponent(true);
  }, []);
  return (
    <section className="lab-results-navigation py-[50px]">
      {heading && (
        <Subheading className="px-contained mb-2 text-[13px] uppercase lg:text-center lg:text-lg">
          {heading}
        </Subheading>
      )}
      {links?.length ? (
        <ul className="flex gap-3 overflow-auto bg-[#f2f7fb] p-4 lg:justify-center">
          {links.map((item, index) => {
            return (
              <li
                key={index}
                className={`flex h-[163px] flex-[0_0_160px] md:order-none
                ${
                  item?.link?.url === asPath && renderClientSideComponent
                    ? 'order-0'
                    : 'order-1'
                }`}
              >
                <Link
                  className={`flex h-full w-full flex-col justify-center overflow-hidden rounded-[10px] border p-[10px] text-text duration-200 ${
                    item?.link?.url === asPath && renderClientSideComponent
                      ? 'border-primary bg-white'
                      : 'border-[#DDE2E7] bg-[#E6F0F8]'
                  }`}
                  aria-label={item.link?.text}
                  href={item?.link?.url}
                >
                  <div>
                    <div className="mobile-nav-item flex flex-col items-center">
                      {item?.image?.src ? (
                        <div className="mobile-image relative aspect-[1/1] w-full max-w-[85px]">
                          <Image
                            fill
                            alt={item?.link?.text}
                            className="object-contain mix-blend-multiply"
                            sizes="(min-width: 1440px) 900px, (min-width: 768px) 50vw, 100vw"
                            src={item?.image?.src}
                            quality="90"
                          />
                        </div>
                      ) : null}
                      <div className="mobile-text mt-4 text-center text-xs uppercase">
                        {item?.mobileText ? item.mobileText : item?.link?.text}
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      ) : null}
    </section>
  );
}

LabResultsNavigation.displayName = 'LabResultsNavigation';
LabResultsNavigation.Schema = Schema;
