export default function Schema() {
  return {
    label: 'Dynamic Popular Posts',
    category: 'Blog',
    key: 'dynamicPopularPosts',
    fields: [
      {
        name: 'header',
        component: 'text',
        label: 'Header',
        defaultValue: 'Popular Posts',
      },
      {
        label: 'Article Handles',
        name: 'articleHandles',
        description: 'First handle will be the featured article',
        component: 'list',
        field: {
          component: 'text',
        },
        validate: {
          maxItems: 4,
        },
      },
    ],
  };
}
