import { useRouter } from 'next/router';
import { Image, Link } from '../../snippets';
import { Schema } from './CollectionNav.schema';

export function CollectionNav({ cms }) {
  const { links } = cms;
  const { asPath } = useRouter();
  return (
    <div>
      {links?.length ? (
        <ul className="flex gap-3 overflow-auto border-b border-b-[#E9E9EB] bg-[#f2f7fb] p-[20px] lg:justify-center lg:gap-8 lg:bg-white">
          {links.map((item, index) => {
            return (
              <li key={index} className="flex flex-[0_0_125px] lg:flex-initial">
                <Link
                  className={`flex h-full w-full flex-col justify-end overflow-hidden rounded-[10px] border p-[10px] text-text duration-200 lg:rounded-none lg:border-0 lg:bg-transparent lg:px-0 lg:py-0 lg:hover:border-primary lg:hover:bg-white lg:hover:text-[#3383C5] ${
                    item?.link?.url === asPath
                      ? 'border-primary bg-white lg:text-[#3383C5]'
                      : 'border-[#DDE2E7] bg-[#E6F0F8]'
                  }`}
                  aria-label={item.link?.text}
                  href={item?.link?.url}
                >
                  <div>
                    <div className="desktop-text hidden lg:block">
                      {item?.link?.text}
                    </div>
                    <div className="mobile-nav-item flex flex-col items-center lg:hidden">
                      {item?.mobileImage?.src ? (
                        <div className="mobile-image relative aspect-[1/1] w-full max-w-[70px]">
                          <Image
                            fill
                            alt={item?.link?.text}
                            className="object-contain mix-blend-multiply"
                            sizes="(min-width: 1440px) 900px, (min-width: 768px) 50vw, 100vw"
                            src={item?.mobileImage?.src}
                            quality="90"
                          />
                        </div>
                      ) : null}
                      <div className="mobile-text mt-4 text-center text-xs uppercase">
                        {item?.mobileText ? item.mobileText : item?.link?.text}
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
}

CollectionNav.displayName = 'CollectionNav';
CollectionNav.Schema = Schema;
