import { useState, useEffect } from 'react';
import Link from 'next/link';

export function LegalMenuItem({ item }) {
  const { header, url } = item;
  const fullUrl = `/pages/${url}`;

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const findActiveUrl = () => {
      if (fullUrl === window.location.pathname) {
        setIsActive(true);
      }
    };
    findActiveUrl();
  }, []);

  return (
    <li
      className={`relative mb-4 transition duration-300 ${
        isActive ? 'active' : ''
      }`}
    >
      <Link href={fullUrl}>{header}</Link>
    </li>
  );
}

LegalMenuItem.displayName = 'Legal Menu Item';
