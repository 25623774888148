import { useCallback, useEffect, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';

import {
  productTypeKey,
  sortAlphabetically,
  sortCustom,
  sortNumerically,
  updateFilterUrlParams,
} from './utils';

export function useCollectionFilters(
  { enabledFilters, id, products } = {
    enabledFilters: true,
    id: '',
    products: [],
  }
) {
  const { isReady, query } = useRouter();
  const settings = useSettings();
  const filtersSettings = { ...settings?.collection?.filters };

  const [activeFilters, setActiveFilters] = useState({});
  const [filters, setFilters] = useState([]);
  const [filtersMap, setFiltersMap] = useState({});

  const addFilter = useCallback(
    ({ key, value }) => {
      const updatedActiveFilters = { ...activeFilters };
      updatedActiveFilters[key] = updatedActiveFilters[key]
        ? [...updatedActiveFilters[key], value]
        : [value];
      setActiveFilters(updatedActiveFilters);
      updateFilterUrlParams({
        entriesToAdd: Object.entries(updatedActiveFilters),
      });
    },
    [activeFilters]
  );

  const removeFilter = useCallback(
    ({ key, value }) => {
      const updatedActiveFilters = { ...activeFilters };
      updatedActiveFilters[key] = updatedActiveFilters[key].filter(
        (item) => item !== value
      );
      if (updatedActiveFilters[key]?.length === 0)
        delete updatedActiveFilters[key];
      setActiveFilters(updatedActiveFilters);

      if (activeFilters[key]?.length === 1) {
        updateFilterUrlParams({
          keysToRemove: [key],
        });
      } else {
        updateFilterUrlParams({
          entriesToAdd: Object.entries(updatedActiveFilters),
        });
      }
    },
    [activeFilters]
  );

  const clearFilters = useCallback(() => {
    setActiveFilters({});
    updateFilterUrlParams({ keysToRemove: Object.keys(activeFilters) });
  }, [activeFilters]);

  // sets up filters and options on collection load
  useEffect(() => {
    if (!enabledFilters) return;
    const cmsFilters = filtersSettings.filters || [];

    const tagFilters = [];
    const optionFilters = [];

    // set up initial filters map
    const _filtersMap = cmsFilters.reduce(
      (
        acc,
        {
          customOrder,
          defaultOpen,
          isColor,
          label,
          name,
          orderValuesBy,
          source,
        }
      ) => {
        if (source !== 'productType' && !name) return acc;
        const _name = name?.trim();

        if (source === 'tag') {
          tagFilters.push(_name);
        } else if (source === 'option') {
          optionFilters.push(_name);
        }
        const filter = {
          name:
            source === 'productType' ? productTypeKey : `${source}.${_name}`,
          label,
          isColor: isColor || false,
          defaultOpen,
          orderValuesBy,
          customOrder,
          source,
          values: [],
          valuesMap: {},
        };
        return { ...acc, [filter.name]: filter };
      },
      {}
    );

    products.forEach(({ optionsMap, productType, tags }) => {
      // product type options
      if (_filtersMap[productTypeKey]) {
        _filtersMap[productTypeKey].valuesMap = {
          ..._filtersMap[productTypeKey].valuesMap,
          [productType]: {
            value: productType,
            count:
              (_filtersMap[productTypeKey].valuesMap[productType]?.count || 0) +
              1,
          },
        };
      }
      // tag filter options
      if (tagFilters?.length) {
        tags.forEach((tag) => {
          const [_key, _value] = tag.split('::');
          const key = _key.trim();
          const value = _value?.trim();
          if (value && tagFilters.includes(key)) {
            _filtersMap[`tag.${key}`].valuesMap = {
              ..._filtersMap[`tag.${key}`].valuesMap,
              [value]: {
                value,
                count:
                  (_filtersMap[`tag.${key}`].valuesMap[value]?.count || 0) + 1,
              },
            };
          }
        });
      }
      // option filter options
      Object.entries(optionsMap || {}).forEach(([_key, values]) => {
        const key = _key.trim();
        if (optionFilters.includes(key)) {
          _filtersMap[`option.${key}`].valuesMap = {
            ..._filtersMap[`option.${key}`].valuesMap,
            ...values.reduce((acc, value) => {
              return {
                ...acc,
                [value]: {
                  value,
                  count:
                    (_filtersMap[`option.${key}`].valuesMap[value]?.count ||
                      0) + 1,
                },
              };
            }, {}),
          };
        }
      });
    });
    // sort options
    Object.values(_filtersMap).forEach((filter) => {
      const values = Object.values(filter.valuesMap);
      if (filter.orderValuesBy === 'alphabet') {
        _filtersMap[filter.name].values = sortAlphabetically({ values });
      } else if (filter.orderValuesBy === 'number') {
        _filtersMap[filter.name].values = sortNumerically({ values });
      } else if (filter.orderValuesBy === 'custom') {
        _filtersMap[filter.name].values = sortCustom({
          values,
          sortOrder: filter.customOrder,
        });
      } else {
        _filtersMap[filter.name].values = values;
      }
      delete _filtersMap[filter.name].orderValuesBy;
      delete _filtersMap[filter.name].customOrder;
    });

    setFilters(Object.values(_filtersMap));
    setFiltersMap(_filtersMap);
  }, [enabledFilters, filtersSettings.filters, id, products]);

  // sets filters on page load
  useEffect(() => {
    if (!isReady || !filters?.length) return;
    const { origin, search, pathname } = window.location;
    const collectionFiltersURL = localStorage.getItem('collectionFiltersURL');
    let queryObject = {};
    if (collectionFiltersURL) {
      window.history.replaceState(
        window.history.state,
        '',
        `${origin}${pathname}?${collectionFiltersURL.split('?')[1]}`
      );
      const searchParams = new URLSearchParams(
        collectionFiltersURL.split('?')[1]
      );
      queryObject = Object.fromEntries(searchParams.entries());
    } else queryObject = query;
    const filtersFromParams = Object.entries(queryObject || {}).reduce(
      (acc, [key, value]) => {
        if (
          !filtersMap[key]?.values?.some((v) =>
            value.split(',').includes(v.value)
          )
        )
          return acc;
        acc[key] = value.split(',');
        return acc;
      },
      {}
    );
    setActiveFilters(filtersFromParams);
  }, [isReady, filters?.length]);

  return {
    state: {
      activeFilters,
      filters,
      filtersMap,
    },
    actions: {
      addFilter,
      removeFilter,
      clearFilters,
    },
  };
}
