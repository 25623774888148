import { useInView } from 'react-intersection-observer';

import { Image } from '../Image';
import { Link } from '../Link';
import { Button } from '../Button';
import { Headline } from '../Typography';

export function CollectionPromoTile({ tile }) {
  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });

  const { background, link, text } = tile;
  const { bgColor, overlayOpacity, image, videoPoster, videoSrc } = {
    ...background,
  };

  return (
    <Link
      aria-label={text?.heading || link?.text}
      className="h-full"
      href={link?.url}
      newTab={link?.newTab}
      ref={ref}
    >
      <div
        className="relative h-full overflow-hidden"
        style={{
          backgroundColor: image ? 'var(--off-white)' : bgColor,
        }}
      >
        {videoSrc && (
          <video
            autoPlay
            className="absolute inset-0 h-full w-full object-cover"
            controls={false}
            loop
            muted
            playsInline
            poster={videoPoster?.src}
          >
            {inView && <source src={videoSrc} type="video/mp4" />}
          </video>
        )}

        {image?.src && !videoSrc && (
          <Image
            alt={text?.heading ?? 'Promo Tile'}
            fill
            sizes="(max-width: 768px) 33vw, 66vw"
            src={image.src}
          />
        )}

        {(videoSrc || image?.src) && overlayOpacity !== 0 && (
          <div
            style={{ opacity: overlayOpacity / 100 }}
            className="pointer-events-none absolute inset-0 h-full w-full bg-black"
          />
        )}

        {(text?.heading || text?.subtext || text?.supertext) && (
          <div
            className="relative flex h-full items-center justify-center px-4 text-center"
            style={{ color: text?.textColor }}
          >
            <div className="w-full max-w-[672px] px-2 py-6 sm:p-8">
              {text?.icon && (
                <Image
                  className="mx-auto mb-8"
                  sizes="44px"
                  width="44px"
                  height="44px"
                  src={text?.icon?.src}
                />
              )}

              {text?.supertext && (
                <span
                  className={` mb-5 inline-block uppercase max-sm:!text-[10px] ${
                    text?.supertextStyle === 'bubble'
                      ? 'rounded-[50px] border border-white px-[1rem] py-[0.5rem] text-[18px] lg:mt-[200px]'
                      : 'text-sm'
                  }`}
                >
                  {text?.supertext}
                </span>
              )}

              {text?.heading ? (
                <Headline
                  className={
                    text?.headingSize === 'large'
                      ? 'max-sm:!text-[18px]'
                      : 'max-sm:!text-[11px]'
                  }
                  variant={text?.headingSize === 'large' ? 'h2' : 'h5'}
                  element="h3"
                >
                  {text.heading}
                </Headline>
              ) : null}

              {text?.subtext && (
                <p className="mt-4 leading-normal max-sm:text-[7px] lg:text-base">
                  {text?.subtext}
                </p>
              )}

              {text?.bottomAccentEnabled ? (
                <div className="flex h-[80px] items-center justify-center opacity-50 after:block after:h-[32px] after:border-l after:border-l-white after:content-[''] sm:h-[150px] sm:after:h-[80px]" />
              ) : (
                <div className="h-[80px] sm:h-[150px]" />
              )}

              {text?.bottomButton && (
                <Button
                  theme={text?.bottomButtonTheme}
                  variant={text?.bottomButtonVariant}
                  className="text-[11px] sm:text-sm"
                >
                  {text?.bottomButton}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}

CollectionPromoTile.displayName = 'CollectionPromoTile';
