export function Schema() {
  return {
    label: 'Rating Banner',
    key: 'rating-banner',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/rating_banner.png?v=1686314380',
    category: 'Banner',
    fields: [
      {
        label: 'Title',
        name: 'title',
        component: 'text',
        defaultValue: 'Rated 4.9 stars across 10,000+ Reviews',
      },
      {
        component: 'toggle',
        name: 'toggleStars',
        label: 'Show stars',
        description: 'Show/hide stars',
        toggleLabels: {
          true: 'Enable',
          false: 'Disable',
        },
        defaultValue: true,
      },
      {
        component: 'number',
        name: 'starCount',
        label: 'Star Count',
        defaultValue: 5,
        description: 'Decimal numbers supported - rounds to nearest hundredth',
        validate: {
          required: true,
        },
      },
    ],
  };
}
