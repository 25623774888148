import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useCustomer, useCustomerAccessToken } from '@backpackjs/storefront';

import { fetchCustomerOrders, returnKeyValueIfNotEmpty } from './utils';

export function useDataLayerInit({ DEBUG }) {
  const asPathRef = useRef(null);
  const isLatestRef = useRef(false);
  const customer = useCustomer();
  const router = useRouter();
  const asPath = router.asPath.split('?')[0];

  const accessToken = useCustomerAccessToken();
  const [customerReady, setCustomerReady] = useState(false);
  const [userProperties, setUserProperties] = useState(null);

  const generateUserProperties = useCallback(
    async ({ customer: _customer, accessToken: _accessToken }) => {
      let _userProperties = {};
      if (_customer) {
        const orders = await fetchCustomerOrders({
          accessToken: _accessToken,
        });
        _userProperties = {
          visitor_type: 'logged_in',
          user_consent: '',
          ...returnKeyValueIfNotEmpty(
            'customer_address_1',
            _customer.defaultAddress?.address1
          ),
          ...returnKeyValueIfNotEmpty(
            'customer_address_2',
            _customer.defaultAddress?.address2
          ),
          ...returnKeyValueIfNotEmpty(
            'customer_city',
            _customer.defaultAddress?.city
          ),
          ...returnKeyValueIfNotEmpty(
            'customer_country',
            _customer.defaultAddress?.country
          ),
          ...returnKeyValueIfNotEmpty(
            'customer_country_code',
            _customer.defaultAddress?.countryCode
          ),
          ...returnKeyValueIfNotEmpty(
            'customer_phone',
            _customer.defaultAddress?.phone
          ),
          ...returnKeyValueIfNotEmpty(
            'customer_province_code',
            _customer.defaultAddress?.provinceCode
          ),
          ...returnKeyValueIfNotEmpty(
            'customer_province',
            _customer.defaultAddress?.province
          ),
          ...returnKeyValueIfNotEmpty(
            'customer_zip',
            _customer.defaultAddress?.zip
          ),
          customer_email: _customer.email,
          customer_first_name: _customer.firstName,
          customer_id: _customer.id?.split('/').pop() || '',
          customer_last_name: _customer.lastName,
          customer_order_count: `${orders?.length || 0}`,
          customer_tags: _customer.tags?.join(', ') || '',
          customer_total_spent: orders
            ?.reduce((acc, order) => {
              return acc + parseFloat(order.totalPriceV2?.amount || 0);
            }, 0)
            .toFixed(2),
        };
      } else {
        _userProperties = {
          visitor_type: 'guest',
          user_consent: '',
        };
      }
      setUserProperties(_userProperties);
      return _userProperties;
    },
    []
  );

  useEffect(() => {
    const setUserId = async () => {
      const data = await fetch('/api/get-set-userid');
      const dataJson = await data.json();
      return dataJson.id;
    };

    window.ElevarUserIdFn = async () => {
      const userId = await setUserId();
      return userId;
    };
  }, []);

  // accommodates customer logic for both older and newer versions of @backpackjs/storefront
  useEffect(() => {
    if (customerReady) return;
    if (typeof customer === 'undefined') {
      isLatestRef.current = true;
      return;
    }
    if (customer) {
      setCustomerReady(true);
    } else if (isLatestRef.current && customer === null) {
      // if customer was undefined then becomes null, the customer is logged out
      setCustomerReady(true);
    } else {
      // if customer starts off as null, then set a timeout to wait
      setTimeout(() => {
        setCustomerReady(true);
      }, 2000);
    }
  }, [customer]);

  // Set previous paths and current path in session storage
  useEffect(() => {
    const currentPath = sessionStorage.getItem('CURRENT_PATH');
    if (asPath === currentPath) {
      sessionStorage.setItem('PREVIOUS_PATH_INLC_REFRESH', asPath);
      return;
    }
    sessionStorage.setItem('PREVIOUS_PATH', currentPath);
    sessionStorage.setItem('PREVIOUS_PATH_INLC_REFRESH', currentPath);
    sessionStorage.setItem('CURRENT_PATH', asPath);
  }, [asPath]);

  // generate user properties on customer ready and path change
  useEffect(() => {
    if (
      !customerReady || // wait until customer status is ready
      (customer && !accessToken?.token) || // wait until accessToken is ready, if logged in customer
      asPath === asPathRef.current // prevent multiple triggers on same path
    )
      return undefined;
    generateUserProperties({ customer, accessToken: accessToken?.token });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [accessToken?.token, asPath, customerReady]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (!window?.ElevarInvalidateContext) return;
      window.ElevarInvalidateContext();
      if (DEBUG) console.log('DataLayer:elevar:context');
    };

    router?.events?.on('routeChangeComplete', handleRouteChange);

    return () => {
      router?.events?.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return {
    generateUserProperties,
    userProperties,
  };
}
