import { useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Image } from '../../snippets';
import { Body, Eyebrow, Headline } from '../../snippets/Typography';
import { Schema } from './ImageSlider.schema';

function ImageSlide(props) {
  const { image, title, copy } = props;
  return (
    <div className="relative mx-auto max-w-[1260px] rounded-lg lg:px-12 lg:py-12">
      {image && (
        <div className="relative h-0 overflow-hidden pb-[70%] xs:pb-[56.25%]">
          <Image
            className="absolute inset-0 mx-auto h-full w-full object-cover"
            sizes="48px"
            width="1286px"
            height="723px"
            src={image.src}
            priority
          />
        </div>
      )}
      <div className="absolute top-40 flex w-full flex-col items-center justify-center lg:top-1/2 lg:-translate-y-1/2 lg:pr-12">
        <p className="absolute -top-28 rounded-full border border-white px-4 py-1 text-2xs font-normal text-white before:absolute before:left-1/2 before:top-[26px] before:h-[85px] before:w-[1px] before:-translate-x-1/2 before:bg-white after:content-[''] lg:-top-28 lg:px-8 lg:py-2 lg:text-sm lg:before:top-[36px] lg:before:h-[76px]">
          {title}
        </p>
        <p className="border border-white px-3 py-2 text-[20px] leading-tight text-white lg:px-6 lg:py-4 lg:text-[42px]">
          {copy}
        </p>
      </div>
    </div>
  );
}

export function ImageSlider({ cms }) {
  const { heading, eyebrow, copy, slider } = cms;

  const [swiper, setSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(1);
  const uniqID = Math.floor(Math.random() * Date.now());
  swiper?.on('slideChange', () => {
    setActiveSlideIndex(swiper.realIndex + 1);
  });

  return (
    <div className="relative bg-[#b3d1e94d]">
      <div className="px-contained overflow-hidden pb-16 pt-12 text-center lg:pb-24 lg:pt-24">
        <Eyebrow className="mb-4">{eyebrow}</Eyebrow>
        <Headline variant="h3" element="h3">
          {heading}
        </Headline>
        <Body variant="large" className="mx-auto mt-4 max-w-[685px]">
          {copy}
        </Body>

        <Swiper
          onSwiper={setSwiper}
          className="!static mx-auto mt-8 w-full max-w-[1260px] overflow-visible lg:mt-16"
          grabCursor
          loop
          centeredSlides
          pagination={{
            el: `.swiper-pagination-image-${uniqID}`,
            type: 'bullets',
            clickable: true,
            bulletActiveClass: '!bg-[#202635] z-[20] opacity-100',
            renderBullet(index, className) {
              return `<span
                class="absolute bg-[#CCD8E3] h-[1px] ${className} opacity-100"
                style="width: ${
                  (index + 1) * (100 / (slider?.slideItems.length || 1))
                }%"
              ></span>`;
            },
          }}
          modules={[Pagination]}
          slidesPerView={1}
          spaceBetween={16}
          breakpoints={{
            1024: {
              loop: true,
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
        >
          {slider?.slideItems.map(
            ({ image, title, copy: slideCopy }, index) => {
              return (
                <SwiperSlide key={index}>
                  <ImageSlide image={image} title={title} copy={slideCopy} />
                </SwiperSlide>
              );
            }
          )}
        </Swiper>

        <div
          className={`swiper-pagination-image-${uniqID} swiper-progress relative mx-auto mt-8 flex w-full max-w-[calc(940px-64px)] flex-nowrap justify-start lg:mt-16`}
        />

        <div className="relative mx-auto hidden max-w-[940px] lg:block">
          <div className="absolute right-[-25px] top-[-13px] text-sm font-normal">
            {activeSlideIndex} / {swiper?.slides.length}
          </div>
        </div>
      </div>
    </div>
  );
}

ImageSlider.displayName = 'Image Slider';
ImageSlider.Schema = Schema;
