import { Schema } from './CareerListings.schema';
import { CareerListing } from './CareerListing';

export function CareerListings({ cms }) {
  const { careerListingItems } = cms;

  return (
    <div className="bg-clearBlue">
        <div className="px-contained  pt-16 pb-4 overflow-hidden max-w-3xl mx-auto">
            <h4 className='mb-8 text-4xl font-light tracking-[-.01em]'>Open Listings</h4>
            {careerListingItems && (
                <div className=''>
                    {careerListingItems.map((item, index) => {
                        return (
                            <CareerListing key={index} item={item} />
                        )
                    })}
                </div>
            )}
        </div>
    </div>

  );
}

CareerListings.displayName = 'Career Listings';
CareerListings.Schema = Schema;
