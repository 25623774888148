import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Eyebrow } from '../../snippets';

export default function QuizContaminantsFound({
  percentage,
  cityName,
  contaminantsFound,
}) {
  return (
    <div className="relative z-10 text-white">
      <div className="relative mb-5 flex h-full w-full justify-center lg:mb-12">
        <div className="absolute top-0 w-[200px] lg:w-[444px]">
          <CircularProgressbar
            value={percentage}
            circleRatio={0.5}
            strokeWidth={2}
            styles={buildStyles({
              rotation: 3 / 4,
              strokeLinecap: null,
              pathTransitionDuration: 0.5,
              textSize: '1.5rem',
              trailColor: '#ECF7FF33',
              pathColor: '#2179C2',
            })}
          />
        </div>
        <p className="flex h-[102px] items-end text-[4.75rem] leading-none lg:h-[220px] lg:text-[8.75rem]">
          {contaminantsFound}
        </p>
      </div>
      <div className="pb-10 lg:pb-0">
        <Eyebrow>TOTAL CONTAMINANTS FOUND IN:</Eyebrow>
        <p className="text-2xl font-medium lg:text-3xl">{cityName}</p>
      </div>
    </div>
  );
}
