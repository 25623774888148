import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export function ProductVideo({ media }) {
  const videoRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { sources, preview } = media;
  const videoSources = sources?.filter(
    ({ mimeType }) => mimeType === 'video/mp4'
  );

  useEffect(() => {
    if (inView) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className="flex aspect-square items-center justify-center bg-[#E6F0F8] lg:aspect-[893/595]"
    >
      <video
        ref={videoRef}
        muted
        playsInline
        loop
        controls={false}
        poster={preview?.image?.src}
        className="w-full"
      >
        {inView && videoSources?.length
          ? videoSources.map((source) => {
              return (
                <source
                  key={source.url}
                  src={source.url}
                  type={source.mimeType}
                />
              );
            })
          : null}
      </video>
    </div>
  );
}

ProductVideo.displayName = 'ProductVideo';
