import { useSettings } from '@backpackjs/storefront';
import { useMemo } from 'react';

export function useMergedContaminants(contaminants) {
  const settings = useSettings();
  const { contaminantSettings } = { ...settings };
  const { contaminants: globalContaminants, contaminantCategories } = {
    ...contaminantSettings,
  };

  const cleanName = (name) => {
    return name.replace('.', '-').replace('[', '-').replace(']', '-');
  };

  return useMemo(() => {
    const finalContaminants = {};
    globalContaminants
      // Step 1.) Filter out Locally Disabled Contaminants
      ?.filter((globalContaminant) => {
        // replace any characters that are not allowed in a variable name
        return contaminants?.[cleanName(globalContaminant.name)]?.enabled;
      })

      // Step 2.) Merge Global & Local Settings
      ?.map((globalContaminant) => {
        const mergedContaminant = {
          ...globalContaminant,
          ...contaminants?.[cleanName(globalContaminant.name)],
        };

        // Make sure after merging, no properties are empty Locally, if they are use the Global values
        Object.keys(mergedContaminant).forEach((key) => {
          if (mergedContaminant[key] === '')
            mergedContaminant[key] = globalContaminant[key];
        });

        return mergedContaminant;
      })
      ?.sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
      })

      // Step 3.) Add Category Data & Normalize Fields
      ?.forEach((contaminant) => {
        const currentContaminantCategory = contaminant.category;

        // Normalize our contaminant aliases
        contaminant.aliases = contaminant?.aliases?.map((alias) => alias.alias);

        // Check to see if the current contaminant category matches one from our Global List of Categories
        contaminantCategories.forEach((category) => {
          if (category.categoryName === currentContaminantCategory) {
            // If this Category hasn't been defined yet in our final contaminants list, let's set it
            if (!finalContaminants[category.categoryName]) {
              finalContaminants[category.categoryName] = {
                ...category,
                contaminants: [],
              };
            }

            // Add our contaminant to the category's "contaminants" list property.
            finalContaminants[category.categoryName].contaminants.push(
              contaminant
            );
          }
        });

        if (
          !finalContaminants?.[currentContaminantCategory] ||
          !Object?.keys(finalContaminants?.[currentContaminantCategory])
            ?.length > 0
        ) {
          // If this Category hasn't been defined yet in our final contaminants list, let's set it
          if (!finalContaminants.Uncategorized) {
            finalContaminants.Uncategorized = {
              contaminants: [],
            };
          }

          // Add our contaminant to the category's "contaminants" list property.
          finalContaminants.Uncategorized.contaminants.push(contaminant);
        }
      });
    return Object.keys(finalContaminants)
      .sort()
      .reduce((obj, key) => {
        obj[key] = finalContaminants[key];
        return obj;
      }, {});
  }, [contaminants, contaminantSettings]);
}
