import eyebrow from '../../utils/schemas/eyebrow';
import headline from '../../utils/schemas/headline';

export default function Schema() {
  return {
    key: 'product-comparison-table',
    label: 'Product Comparison Table',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screenshot_2.png?v=1686860394',
    category: 'Product',

    fields: [
      { ...eyebrow, defaultValue: 'US VS THEM' },
      {
        ...headline,
        defaultValue: 'Setting a new standard in portable filtration',
      },
      {
        label: 'Disclaimer text',
        name: 'disclaimerText',
        component: 'text',
      },
      {
        label: 'CTA',
        name: 'cta',
        component: 'link',
      },
      {
        label: 'Header items',
        name: 'headerItems',
        itemProps: {
          label: '{{item.columnHeading}}',
        },
        component: 'group-list',
        fields: [
          {
            label: 'Column heading',
            name: 'columnHeading',
            component: 'text',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
        ],
      },
      {
        label: 'Rows',
        name: 'rows',
        component: 'group-list',
        itemProps: {
          label: '{{item.rowHeading}}',
        },
        fields: [
          {
            label: 'Row heading',
            name: 'rowHeading',
            component: 'text',
          },
          {
            label: 'Row columns',
            name: 'rowColumns',
            component: 'group-list',
            itemProps: {
              label: '{{item.text}}',
            },
            fields: [
              {
                label: 'Background',
                name: 'background',
                component: 'select',
                options: [
                  {
                    label: 'Transparent',
                    value: 'transparent',
                  },
                  {
                    label: 'Gradient',
                    value: 'gradient',
                  },
                ],
              },
              {
                label: 'Text',
                name: 'text',
                component: 'text',
              },
              {
                label: 'Image',
                name: 'image',
                component: 'image',
              },
            ],
          },
        ],
      },
    ],
  };
}
