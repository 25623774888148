import { Image } from '../../../snippets';

export function ProductImage({ media, priority }) {
  const { image, product } = media;

  return (
    <div className="relative aspect-square bg-[#E6F0F8] lg:aspect-[893/595]">
      {image?.src && (
        <Image
          alt={product.title}
          fill
          priority={priority}
          className="!object-contain mix-blend-multiply"
          sizes="(min-width: 1440px) 900px, (min-width: 768px) 50vw, 100vw"
          src={image.src}
          // IMPORTANT: for naturally large, high resolution images from Shopify,
          // reduce quality back to the default 75 for better page performance
          quality={90}
        />
      )}
    </div>
  );
}

ProductImage.displayName = 'ProductImage';
