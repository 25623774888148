export const Schema = () => {
  return {
    category: 'Product',
    label: 'Featured Product',
    key: 'featuredProduct',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/featuredproductlayout.png?v=1721666072',
    fields: [
      {
        label: 'Section ID',
        name: 'sectionId',
        component: 'text',
        description:
          'Add an ID to your section for linking purposes. Please make sure to not use the same ID twice.',
      },
      {
        label: 'Custom Save Percentage Text',
        name: 'customSavePercentageText',
        component: 'text',
        description: 'Use {{ amount }} to insert the savings percentage value into your text. You can include any additional text around this value to customize your message.',
      },
      {
        name: 'featuredProduct',
        component: 'productSearch',
        label: 'Select Product',
      },
      {
        label: 'Linked Product Groups',
        component: 'group-list',
        name: 'linkedProductGroups',
        itemProps: {
          label: '{{item.groupName}}',
        },
        fields: [
          {
            label: 'Group Name',
            name: 'groupName',
            component: 'text',
            defaultValue: 'Options',
          },
          {
            label: 'Linked Products',
            component: 'group-list',
            name: 'linkedProducts',
            itemProps: {
              label: '{{item.linkedProductTitle}}',
            },
            fields: [
              {
                label: 'Product Title',
                name: 'linkedProductTitle',
                component: 'text',
              },
              {
                label: 'Product',
                name: 'linkedProduct',
                component: 'productSearch',
              },
            ],
          },
        ],
      },
      {
        name: 'productBannerText',
        component: 'text',
        label: 'Product Banner Text',
      },
      {
        name: 'bottomBannerHeading',
        component: 'text',
        label: 'Bottom Banner Heading',
      },
      {
        name: 'bottomBannerText',
        component: 'markdown',
        label: 'Bottom Banner Text',
      },
      {
        label: 'Product Display Type',
        name: 'productDisplayType',
        component: 'select',
        options: [
          {
            label: 'Featured',
            value: 'featured',
          },
          {
            label: 'One-time Only',
            value: 'onetimeOnly',
          },
        ],
        defaultValue: 'featured',
      },
      {
        label: 'Product Description',
        name: 'productDescription',
        component: 'markdown',
      },
      {
        label: 'Product Features',
        name: 'productFeatures',
        component: 'group-list',
        validate: {
          maxItems: 6,
        },
        fields: [
          {
            label: 'Product Feature Icon',
            name: 'productFeatureIcon',
            component: 'image',
          },
          {
            label: 'Feature Description',
            name: 'featureDescription',
            component: 'text',
          },
        ],
      },
      {
        component: 'group',
        name: 'discount',
        label: 'Discount',
        fields: [
          {
            name: 'enableDiscount',
            component: 'toggle',
            label: 'Enable Discount',
            defaultValue: false,
          },
          {
            name: 'discountCode',
            component: 'text',
            label: 'Discount Code',
            description: 'Discount code to be applied to cart on Add To Cart',
          },
          {
            name: 'discountType',
            component: 'select',
            label: 'Discount Type',
            options: [
              {
                label: 'Percentage',
                value: 'percentage',
              },
              {
                label: 'Fixed Amount',
                value: 'fixed',
              },
            ],
          },
          {
            name: 'discountAmount',
            component: 'number',
            label: 'Amount off',
            description: 'Discount amount to be applied to cart on Add To Cart',
          },
        ],
      },
    ],
  };
};
