/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { AltText, Button, Headline, Markdown } from '../../snippets';
import Schema from './Quiz.schema';
import QuizContaminantsFound from './QuizContaminantsFound';
import QuizContaminantsWeRemoveBox from './QuizContaminantsWeRemoveBox';

export default function Quiz({ cms }) {
  const {
    eyebrowText,
    heading,
    body,
    actionButton,
    backgroundVideoUrl,
    backgroundImage,
    contaminantsBoxTitle,
    contaminantsBoxContent,
    cities,
  } = cms;

  const [selectedSlide, setSelectedSlide] = useState(0);
  const slides = cities?.length || 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedSlide((prev) => (prev + 1) % slides);
    }, 5000);
    return () => clearInterval(interval);
  }, [selectedSlide]);

  return (
    <section>
      <div className="mx-auto max-w-[var(--max-content-width)]">
        <div className="grid lg:grid-cols-2">
          <div className="row-start-2 flex justify-end bg-[#E6F0F8] lg:row-start-1">
            <div className="px-contained my-10 text-left lg:my-[9rem] lg:w-[calc(var(--content-max-width)/2)] lg:px-0">
              <div className="grid gap-3 px-4 md:px-8 xl:px-12">
                <AltText>{eyebrowText}</AltText>
                <Headline variant="h3">{heading}</Headline>
                <div className="lg:max-w-[520px]">
                  <Markdown>{body}</Markdown>
                </div>
                {(cities?.[selectedSlide]?.contaminantsBoxContent ||
                  cities?.[selectedSlide]?.contaminantsBoxTitle) && (
                  <QuizContaminantsWeRemoveBox
                    contaminantsBoxContent={
                      cities?.[selectedSlide]?.contaminantsBoxContent
                    }
                    contaminantsBoxTitle={
                      cities?.[selectedSlide]?.contaminantsBoxTitle
                    }
                  />
                )}
                {actionButton && (
                  <div className="">
                    <Button
                      theme="primary"
                      variant="filled"
                      href={actionButton.url}
                    >
                      {actionButton.text}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row-start-1">
            <div className="h-full select-none text-center">
              <div className="relative flex h-full w-full items-center justify-center">
                {backgroundVideoUrl && (
                  <video
                    src={backgroundVideoUrl}
                    autoPlay
                    loop
                    playsInline
                    muted
                    className="z-1 absolute left-0 top-0 h-full w-full object-cover"
                  />
                )}
                {!backgroundVideoUrl && backgroundImage && (
                  <img
                    src={backgroundImage.src}
                    alt={backgroundImage.alt}
                    className="z-1 absolute left-0 top-0 h-full w-full object-cover"
                  />
                )}
                <div className="flex aspect-square w-full items-center justify-center lg:aspect-auto lg:w-[444px]">
                  {cities?.length > 0 && (
                    <QuizContaminantsFound
                      cityName={cities[selectedSlide]?.city}
                      contaminantsFound={
                        cities[selectedSlide]?.totalContaminantsFound
                      }
                      percentage={
                        cities[selectedSlide]?.percentageOfContaminants
                      }
                    />
                  )}
                  <div className="absolute bottom-10 z-20 flex w-full justify-center gap-x-4">
                    {Array(slides)
                      .fill()
                      .map((_, i) => (
                        <div
                          key={i}
                          className={[
                            i === selectedSlide
                              ? 'bg-[#ffffff]'
                              : 'bg-[#ffffff33]',
                            'h-3 w-3 cursor-pointer rounded-full',
                          ].join(' ')}
                          onClick={() => setSelectedSlide(i)}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Quiz.displayName = 'Quiz';
Quiz.Schema = Schema;
