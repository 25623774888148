import { useMemo } from 'react';
import Button from '../../snippets/Button/Button';
import { Headline, Eyebrow, Body } from '../../snippets/Typography';
import { Link, Markdown } from '../../snippets';
import { useGlobalContext } from '../../contexts';

export function HalfHeroContent({ aboveTheFold, content }) {
  const {
    alignmentDesktop,
    alignmentMobile,
    alignmentMobileOverImage,
    buttons,
    color,
    heading,
    maxWidthDesktop,
    subtext,
    superheading,
  } = { ...content };
  const {
    actions: { openAccount },
  } = useGlobalContext();
  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;

  const headingWithBreaks = useMemo(() => {
    const splitHeading = heading?.split('\n');
    if (splitHeading?.length === 1) return heading;
    return splitHeading?.reduce((acc, line, index, arr) => {
      acc.push(<span key={index}>{line}</span>);
      if (index < arr.length - 1) acc.push(<br key={`br${index}`} />);
      return acc;
    }, []);
  }, [heading]);

  const onClick = () => {
    openAccount();
  };

  return (
    <div className="px-contained py-contained w-full">
      <div
        className={`mx-auto flex flex-col gap-4 ${alignmentClasses} ${maxWidthDesktop} ${
          alignmentMobileOverImage ? 'text-[#fff] lg:text-[#121D29]' : color
        }`}
      >
        <div>
          {superheading && (
            <Eyebrow className="mb-4 uppercase">{superheading}</Eyebrow>
          )}

          {aboveTheFold ? (
            <Headline variant="h2" element="h2">
              {headingWithBreaks}
            </Headline>
          ) : (
            <Headline variant="h2" element="h2">
              {headingWithBreaks}
            </Headline>
          )}
        </div>

        {subtext && (
          <div className="[&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base">
            <Markdown>{subtext}</Markdown>
          </div>
        )}

        {buttons?.length > 0 && (
          <ul className="flow-row mt-4 flex flex-wrap justify-center gap-4">
            {buttons.slice(0, 2).map(({ link, style }, index) => {
              return (
                <li key={index}>
                  <Button
                    href={link?.url}
                    theme={style}
                    variant={`${index === 0 ? 'filled' : 'outlined'}`}
                    onClick={link?.text === 'Login' ? () => onClick() : null}
                  >
                    {link?.text}
                  </Button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

HalfHeroContent.displayName = 'HalfHeroContent';
