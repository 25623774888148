import { useEffect, useState } from 'react';

export const useScrollPosition = (limit = 50) => {
  const [scrolledPast, setScrolledPast] = useState(false);

  useEffect(() => {
    const updatePosition = () => {
      if (window.pageYOffset >= limit && !scrolledPast) {
        setScrolledPast(true);
      } else if (window.pageYOffset < limit && scrolledPast) {
        setScrolledPast(false);
      }
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, [scrolledPast]);

  return scrolledPast;
};
