import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { AddToCart } from '../../AddToCart';
import { QuickShopOptions } from './QuickShopOptions';

export function QuickShop({ enabledColorSelector, product, variant }) {
  const settings = useSettings();
  const { quickShopMultiText, quickShopSingleText } = {
    ...settings?.collection?.productItem,
  };

  const qualifiesForQuickShop = useMemo(() => {
    if (!product) return false;

    const initialOptions = product.grouping?.isTransformed
      ? product.grouping.options
      : product.options;
    const options = enabledColorSelector
      ? initialOptions.filter((option) => option.name !== 'Color')
      : initialOptions;

    const hasOnlySingleValueOptions =
      options.every((option) => {
        return option.values.length === 1;
      }) || false;
    const hasOnlyOneOptionWithMultipleValues =
      options.reduce((acc, option) => {
        return acc + (option.values.length > 1 ? 1 : 0);
      }, 0) === 1 || false;
    const hasColorOptionWithMultipleValues = enabledColorSelector
      ? false
      : product.grouping?.optionsMap?.Color?.length > 1 || false;

    return (
      (hasOnlySingleValueOptions || hasOnlyOneOptionWithMultipleValues) &&
      !hasColorOptionWithMultipleValues
    );
  }, [enabledColorSelector, product?.id, variant?.id]);

  const hasOneVariant =
    !product?.grouping?.isTransformed && product?.variants?.length === 1;

  return qualifiesForQuickShop ? (
    <div className="mt-5 hidden opacity-0 transition group-hover:opacity-100 md:block lg:mt-6">
      {hasOneVariant && (
        <AddToCart
          addToCartText={quickShopSingleText}
          className="btn-inverse-dark"
          selectedVariant={product?.variants?.[0]}
        />
      )}

      {!hasOneVariant && variant && (
        <QuickShopOptions
          quickShopMultiText={quickShopMultiText}
          product={product}
          variant={variant}
        />
      )}
    </div>
  ) : null;
}

QuickShop.displayName = 'QuickShop';
