import { useState } from 'react';
import {
  Image,
  Button,
  Headline,
  Eyebrow,
  Body,
  Markdown,
} from '../../snippets';
import { Schema } from './ProductSupport.schema';
import { ProductResourceDownload } from './ProductResourceDownload';
import { ProductSupportVideoBanner } from './ProductSupportVideoBanner';

export function ProductSupport({ cms }) {
  const { header, eyebrow, productSupportItems, emptyVideoText } = cms;
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeItem, setActiveItem] = useState(
    productSupportItems.length > 0 ? productSupportItems[0] : null
  );

  return (
    <>
      <div className="lg:px-contained lg:py-contained relative mx-auto max-w-screen-2xl pb-0 pt-12 lg:pb-4 lg:pt-4">
        {eyebrow && (
          <Eyebrow className="mb-6 text-center uppercase">{eyebrow}</Eyebrow>
        )}
        {productSupportItems.length > 0 && (
          <div className="px-4">
            <div className="overflow-auto">
              <div className="flex flex-row flex-nowrap gap-4 max-lg:pb-[36px] lg:mx-auto lg:items-center lg:justify-center lg:pl-0">
                {productSupportItems
                  .slice(0, 5)
                  .map(({ header: buttonHeader, productImage }, index) => (
                    <button
                      type="button"
                      className={`min-w-[128px] cursor-pointer rounded-lg px-2 py-[0.706rem] pb-[0.75rem] text-center  hover:bg-[#fff] focus:bg-[#fff] lg:w-auto lg:max-w-[160px] ${
                        activeIndex === index
                          ? 'border bg-[#fff]'
                          : 'border border-[#DDE2E7] bg-[#E6F0F8] text-text'
                      }`}
                      key={index}
                      onClick={() => {
                        setActiveIndex(index);
                        setActiveItem(productSupportItems[index]);
                      }}
                      onKeyDown={() => {
                        setActiveIndex(index);
                        setActiveItem(productSupportItems[index]);
                      }}
                    >
                      {productImage && (
                        <Image
                          className="mx-auto mb-4"
                          sizes="48px"
                          width="77px"
                          height="77px"
                          src={productImage.src}
                        />
                      )}
                      <p className="text-xs font-light uppercase tracking-[0.04em]">
                        {buttonHeader}
                      </p>
                    </button>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <hr className="border-[#D9DDE8] max-lg:mt-[28px]" />

      <div className="lg:px-contained relative mx-auto mt-12 max-w-screen-2xl lg:mb-12 lg:flex">
        <div className="relative overflow-hidden lg:block lg:w-3/5 lg:pr-6">
          {activeItem.videoResources ? (
            <>
              {activeItem.videoResources?.map((videoContent) => (
                <ProductSupportVideoBanner
                  videoContent={videoContent}
                  emptyVideoText={emptyVideoText}
                />
              ))}
            </>
          ) : (
            emptyVideoText && (
              <Headline variant="h5" element="h5">
                {emptyVideoText}
              </Headline>
            )
          )}
        </div>
        <div className="relative lg:w-2/5">
          <div className=" top-[60px] mt-10 flex-col items-center overflow-hidden bg-[#E6F0F8] px-4 py-14 lg:sticky lg:mt-0 lg:px-10">
            <Headline
              variant="h5"
              className="mx-auto mb-7 w-full border-b border-[#2026351a] pb-12 font-light"
            >
              {header}
            </Headline>
            {activeItem.resourceSettings?.resources?.map((item) => (
              <ProductResourceDownload
                resourceTitle={item.resourceTitle}
                resourceLink={item.resourceLink}
                resourceSize={item.resourceSize}
              />
            ))}
            <Headline variant="h5" className="mb-7 w-full text-left font-light">
              {activeItem.faqSettings?.faqTitle}
            </Headline>
            <Markdown className="[&_p]:!text-[16px]">
              {activeItem.faqSettings?.faqCopy}
            </Markdown>
            {activeItem.faqSettings?.faqCta && (
              <div className="mt-8 flex w-full items-start">
                <Button
                  href={activeItem.faqSettings?.faqCta.url}
                  theme="primary"
                  variant="outlined"
                >
                  {activeItem.faqSettings?.faqCta.text}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

ProductSupport.displayName = 'ProductSupport';
ProductSupport.Schema = Schema;
