import { useEffect, useCallback, useRef, useState } from 'react';
import { useCart, useCartCount, useCartItems } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import equal from 'fast-deep-equal';
import { useGlobalContext } from '../../../contexts';
import { getIdFromString, mapLineItem } from './utils';
import { useBeamCartUpdate } from '../../../hooks/useBeamCartUpdate';

export function useDataLayerCart({
  baseEcommerce,
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const asPathRef = useRef(null);
  const cart = useCart();
  const cartCount = useCartCount();
  const cartItems = useCartItems();
  const router = useRouter();
  const asPath = router.asPath.split('?')[0];
  const {
    state: { cartOpen },
  } = useGlobalContext();

  const { cartChangeTrigger } = useBeamCartUpdate();

  const [previousCartCount, setPreviousCartCount] = useState(null);
  const [previousCartItems, setPreviousCartItems] = useState(null);
  const [previousCartItemsMap, setPreviousCartItemsMap] = useState(null);

  const klaviyoAddToCart = useCallback(({ cart: _cart }) => {
    const _learnq = window._learnq || [];
    _learnq.push([
      'track',
      'Added to Cart',
      {
        total_price: _cart.estimatedCost.totalAmount.amount,
        $value: _cart.estimatedCost.totalAmount.amount,
        original_total_price: _cart.estimatedCost.subtotalAmount.amount,
        items: _cart.lines,
      },
    ]);
  }, []);

  const postscriptAddToCart = useCallback(({ lineItem }) => {
    if (!window.postscript) return;

    const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
    const list = previousPath?.startsWith('/collections') ? previousPath : '';
    const { variant } = lineItem;
    const { product } = variant;

    window.postscript.event('add_to_cart', {
      shop_id: '11478', // your Postscript Shop ID
      url: window.location.href, // the current page
      search_params: { variant: getIdFromString(variant.id) },
      page_type: 'product',
      referrer: `${window.location.origin}${list}`, // the referring page
      resource: {
        // information about the product
        category: product.productType,
        name: product.title,
        price_in_cents:
          parseFloat(lineItem.estimatedCost.subtotalAmount.amount) * 100,
        resource_id: parseInt(getIdFromString(product.id), 10),
        resource_type: 'product',
        sku: null,
        variant_id: parseInt(getIdFromString(variant.id), 10),
        vendor: 'Clearly Filtered',
      },
    });
  }, []);

  const addToCartEvent = useCallback(
    ({
      cart: _cart,
      ecommerce,
      lineItems,
      userProperties: _userProperties,
    }) => {
      if (!lineItems?.length) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list =
        (window.location.pathname.startsWith('/collections') &&
          window.location.pathname) ||
        (previousPath?.startsWith('/collections') && previousPath) ||
        '';
      const event = {
        event: 'dl_add_to_cart',
        user_properties: _userProperties,
        ecommerce: {
          ...ecommerce,
          cart_id: _cart.id?.split('/').pop(),
          cart_total: _cart.estimatedCost?.totalAmount?.amount,
          cart_count:
            _cart.lines?.reduce((acc, line) => acc + line.quantity, 0) || 0,
          add: {
            actionField: { list },
            products: lineItems.slice(0, 7).map(mapLineItem(list)),
          },
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  const removeFromCartEvent = useCallback(
    ({
      cart: _cart,
      ecommerce,
      lineItems,
      userProperties: _userProperties,
    }) => {
      if (!lineItems?.length) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list =
        (window.location.pathname.startsWith('/collections') &&
          window.location.pathname) ||
        (previousPath?.startsWith('/collections') && previousPath) ||
        '';
      const event = {
        event: 'dl_remove_from_cart',
        user_properties: _userProperties,
        ecommerce: {
          ...ecommerce,
          cart_id: _cart.id?.split('/').pop(),
          cart_total: _cart.estimatedCost?.totalAmount?.amount,
          cart_count:
            _cart.lines?.reduce((acc, line) => acc + line.quantity, 0) || 0,
          remove: {
            actionField: { list },
            products: lineItems.map(mapLineItem(list)),
          },
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  const viewCartEvent = useCallback(
    ({ cart: _cart, ecommerce, userProperties: _userProperties }) => {
      if (!_cart) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list =
        (window.location.pathname.startsWith('/collections') &&
          window.location.pathname) ||
        (previousPath?.startsWith('/collections') && previousPath) ||
        '';
      const event = {
        event: 'dl_view_cart',
        user_properties: _userProperties,
        cart_total: _cart.estimatedCost?.totalAmount?.amount,
        ecommerce: {
          ...ecommerce,
          actionField: { list: 'Shopping Cart' },
          cart_id: _cart.id?.split('/').pop(),
          cart_count:
            _cart.lines?.reduce((acc, line) => acc + line.quantity, 0) || 0,
          impressions: _cart.lines?.map(mapLineItem(list)) || [],
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  // Trigger 'dl_view_cart' event on cart page
  useEffect(() => {
    if (
      !asPath.startsWith('/cart') ||
      !cart ||
      !baseEcommerce ||
      !userProperties ||
      asPath === asPathRef.current
    )
      return undefined;
    userDataEvent({ ecommerce: baseEcommerce, userProperties });
    viewCartEvent({ cart, ecommerce: baseEcommerce, userProperties });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [asPath, !!cart, !!baseEcommerce, !!userProperties]);

  // Trigger 'dl_view_cart' event when cart is opened
  useEffect(() => {
    if (
      !baseEcommerce ||
      !cartOpen ||
      !userDataEventTriggered ||
      !userProperties
    )
      return;
    userDataEvent({ ecommerce: baseEcommerce, userProperties });
    viewCartEvent({ cart, ecommerce: baseEcommerce, userProperties });
  }, [!!baseEcommerce, cartOpen, userDataEventTriggered]);

  // Determine if a cart item was added, removed, or updated for events
  useEffect(() => {
    if (!cart || !baseEcommerce || !userDataEventTriggered) return;

    const cartItemsMap = cartItems.reduce((acc, line) => {
      if (!line.variant) return acc;
      const variantId = line.variant.id;
      if (!acc[variantId]) {
        return { ...acc, [variantId]: [line] };
      }
      return { ...acc, [variantId]: [...acc[variantId], line] };
    }, {});

    if (!previousCartItems || previousCartCount === cartCount) {
      setPreviousCartItems(cartItems);
      setPreviousCartCount(cartCount);
      setPreviousCartItemsMap(cartItemsMap);
      return;
    }

    const isAddedItems = [];
    const isIncreasedItems = [];
    const isRemovedItems = [];
    const isDecreasedItems = [];

    if (cartCount > previousCartCount) {
      cartItems.forEach((line, index) => {
        const variantId = line.variant?.id;
        if (!variantId) return;

        const previousLine = previousCartItemsMap[variantId]?.find(
          (prevLine) => {
            const hasSameSellingPlanSelection =
              (!prevLine.sellingPlanAllocation &&
                !line.sellingPlanAllocation) ||
              prevLine.sellingPlanAllocation?.sellingPlan?.id ===
                line.sellingPlanAllocation?.sellingPlan?.id;
            return (
              hasSameSellingPlanSelection &&
              equal(prevLine.attributes, line.attributes)
            );
          }
        );
        if (!previousLine) {
          isAddedItems.push({ ...line, index });
          return;
        }
        if (line.quantity > previousLine.quantity) {
          isIncreasedItems.push({
            ...line,
            quantity: line.quantity - previousLine.quantity,
            index,
          });
        }
      });
    } else if (cartCount < previousCartCount) {
      previousCartItems.forEach((prevLine, index) => {
        const variantId = prevLine.variant?.id;
        if (!variantId) return;

        const currentLine = cartItemsMap[variantId]?.find((line) => {
          const hasSameSellingPlanSelection =
            (!prevLine.sellingPlanAllocation && !line.sellingPlanAllocation) ||
            prevLine.sellingPlanAllocation?.sellingPlan?.id ===
              line.sellingPlanAllocation?.sellingPlan?.id;
          return (
            hasSameSellingPlanSelection &&
            equal(prevLine.attributes, line.attributes)
          );
        });
        if (!currentLine) {
          isRemovedItems.push({ ...prevLine, index });
          return;
        }
        if (currentLine.quantity < prevLine.quantity) {
          isDecreasedItems.push({
            ...prevLine,
            quantity: prevLine.quantity - currentLine.quantity,
            index,
          });
        }
      });
    }

    if (isAddedItems.length || isIncreasedItems.length) {
      [...isAddedItems, ...isIncreasedItems].forEach((line) => {
        addToCartEvent({
          cart,
          ecommerce: baseEcommerce,
          lineItems: [line],
          userProperties,
        });
        klaviyoAddToCart({ cart });
        postscriptAddToCart({ lineItem: line });
      });
    }
    if (isRemovedItems.length || isDecreasedItems.length) {
      removeFromCartEvent({
        cart,
        ecommerce: baseEcommerce,
        lineItems: [...isRemovedItems, ...isDecreasedItems],
        userProperties,
      });
    }

    if (
      isAddedItems.length ||
      isIncreasedItems.length ||
      isRemovedItems.length ||
      isDecreasedItems.length
    ) {
      cartChangeTrigger();
    }

    setPreviousCartItems(cartItems);
    setPreviousCartCount(cartCount);
    setPreviousCartItemsMap(cartItemsMap);
  }, [cart?.updatedAt, !!baseEcommerce, userDataEventTriggered]);
}
