import { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import Head from 'next/head';
import { FormField } from './FormField';
import { ContactFormContent } from './ContactFormContent';
import { Schema } from './ContactFormBuilder.schema';
import { useForm } from './useForm';
import { Button } from '../../snippets';

const isBrowser = typeof window !== 'undefined';

export function ContactFormBuilder({ cms }) {
  const formRef = useRef(null);
  const captchaRef = useRef(null);

  const {
    heading,
    subHeading,
    fields,
    liveChat,
    callUs,
    smsText,
    commonQuestions,
    submitText,
  } = cms;

  const { parsedFields } = useForm({ fields });

  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);

  const renderCaptcha = isBrowser && window?.grecaptcha?.render;
  const captchaReady = typeof renderCaptcha === 'function';
  const recaptchaEnabled =
    cms.recaptchaEnabled && !!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

  const submitGorgiasForm = async (payload) => {
    const apiEndpoint = '/api/gorgias';

    const response = await fetch(apiEndpoint, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (!data) {
      throw new Error('No data returned from fetchReviewAggregate');
    }

    return data;
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        setErrors([]);
        setSuccessMessage(null);

        const formIsValid = formRef.current?.checkValidity();

        // if (!recaptchaEnabled || !captchaLoaded || !formIsValid) return;
        if ((recaptchaEnabled && !captchaLoaded) || !formIsValid) return;

        if (recaptchaEnabled) {
          const captchaResponse =
            isBrowser && (await window?.grecaptcha?.getResponse());

          if (!captchaResponse) {
            setErrors(['Please verify you are not a robot']);
            return;
          }
        }

        const payload = Object.fromEntries(new FormData(formRef.current));
        const res = await submitGorgiasForm(payload);

        if (res.error) {
          setErrors([res.error]);
        } else {
          formRef.current.reset();
          setSuccessMessage('Message sent successfully!');
        }
      } catch (error) {
        console.error(error);
      }
    },
    [recaptchaEnabled, captchaLoaded]
  );

  // Render captcha if recaptcha is enabled and captcha is ready
  // Must add NEXT_PUBLIC_RECAPTCHA_SITE_KEY to env variables
  // See: https://developers.google.com/recaptcha/intro
  useEffect(() => {
    try {
      if (!captchaReady || !recaptchaEnabled) return;
      renderCaptcha('form-captcha-widget', {
        sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
      });
    } catch (error) {
      console.error(error);
    }
  }, [captchaReady, recaptchaEnabled]);

  // Observe when captcha is loaded to permit form submission
  useEffect(() => {
    if (!captchaRef.current || !recaptchaEnabled) return undefined;
    const observer = new MutationObserver(() => {
      setCaptchaLoaded(true);
      observer.disconnect();
    });
    observer.observe(captchaRef.current, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true,
    });
    return () => {
      observer.disconnect();
    };
  }, [recaptchaEnabled]);

  return (
    <div className="bg-clearBlue">
      <div className="px-contained py-contained flex flex-col-reverse lg:flex-row">
        <Head>
          <script src="https://www.google.com/recaptcha/api.js" />
        </Head>

        <div className="lg:w-1/2 lg:pr-8">
          {heading && (
            <h5 className="mb-2 text-3xl font-light tracking-[-.01em]">
              {heading}
            </h5>
          )}

          {subHeading && (
            <p className=" mb-4  text-lg text-[#3383C5] md:mb-6 lg:mb-12">
              {subHeading}
            </p>
          )}

          <form
            className="grid grid-cols-2 gap-8 [&_input]:font-extralight [&_textarea]:font-extralight"
            encType="multipart/form-data"
            method="POST"
            ref={formRef}
            onSubmit={handleSubmit}
          >
            {parsedFields?.map((field) => (
              <Fragment key={field.name}>
                <FormField field={field} />
              </Fragment>
            ))}

            {fields?.some((field) => field.required) && (
              <div className="col-span-2 m-0 block text-xs opacity-50">
                * Required field
              </div>
            )}

            <div className="col-span-2 flex flex-col gap-4">
              {recaptchaEnabled && (
                <div ref={captchaRef} className="my-4">
                  <div id="form-captcha-widget" />
                </div>
              )}

              <Button
                theme="secondary"
                variant="outlined"
                type="submit"
                className="mt-6 flex w-full max-w-[210px] items-center justify-center bg-[#fff]"
              >
                {submitText || 'Submit'}
              </Button>

              {errors?.length > 0 && (
                <div className="flex flex-col gap-1">
                  {errors.map((error) => (
                    <p key={error} className="text-red-500">
                      {error}
                    </p>
                  ))}
                </div>
              )}

              {successMessage && (
                <p className="rounded border border-solid border-green-700 bg-green-50 p-3 text-center text-green-700">
                  {successMessage}
                </p>
              )}
            </div>
          </form>
        </div>
        <div className="lg:w-1/2 lg:pl-8">
          <ContactFormContent content={liveChat} />
          <ContactFormContent content={callUs} />
          <ContactFormContent content={smsText} />
          <ContactFormContent content={commonQuestions} />
        </div>
      </div>
    </div>
  );
}

ContactFormBuilder.displayName = 'ContactFormBuilder';
ContactFormBuilder.Schema = Schema;
