import { useCartCount } from '../../hooks';

import { Svg } from '../Svg';

export function CartHeader({ closeCart, announcement, isCartPage }) {
  const cartCount = useCartCount();

  return (
    <>
      <div className="relative flex h-[77px] items-center justify-center border-b border-b-[#DDE2E7] px-16">
        <button
          aria-label="Close cart"
          className={`absolute left-4 top-1/2 -translate-y-1/2 ${
            isCartPage ? 'hidden' : null
          }`}
          onClick={closeCart}
          type="button"
        >
          <Svg
            className="w-5 text-text"
            src="/svgs/close.svg#close"
            title="Close Cart"
            viewBox="0 0 24 24"
          />
        </button>

        <h3 className="cart-header-heading text-center text-[18px] font-[400] uppercase text-primary">
          Your Cart{' '}
          <span className={`${isCartPage ? '' : 'lg:hidden'}`}>
            ({cartCount || 0})
          </span>
        </h3>

        <div
          className={`absolute right-4 top-1/2 flex -translate-y-1/2 items-center ${
            isCartPage ? 'hidden' : null
          }`}
        >
          <Svg
            className="mr-2 w-5 pt-[5px] text-text"
            src="/svgs/cart.svg#cart"
            title="Cart"
            viewBox="0 0 24 24"
          />

          <p className=" w-4 whitespace-nowrap pl-px text-[20px] font-[350]">
            {cartCount || 0}
          </p>
        </div>
      </div>
      {announcement?.enabled && (
        <div>
          <p className="bg-[#CCE0F1] px-3 py-[.6rem] text-center text-xs font-light text-black">
            {announcement?.text}
          </p>
        </div>
      )}
    </>
  );
}

CartHeader.displayName = 'CartHeader';
