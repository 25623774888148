export function Schema() {
  return {
    category: 'Text',
    label: 'How It Works',
    key: 'howItWorks',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2010.47.30_20AM.png?v=1684864578',
    fields: [
      {
        label: 'Header',
        name: 'header',
        component: 'text',
        defaultValue: 'How it works',
      },
      {
        label: 'Copy',
        name: 'copy',
        component: 'text',
        defaultValue:
          'Subscribe now to ensure you always have a replacement filter ready and you never run out of clean water.',
      },
      {
        label: 'CTA Copy',
        name: 'ctaCopy',
        component: 'text',
      },
      {
        label: 'CTA Url',
        name: 'ctaUrl',
        component: 'text',
      },
      {
        label: 'How It Works Items',
        name: 'howItWorksItems',
        component: 'group-list',
        itemProps: {
          label: '{{item.header}}',
        },
        fields: [
          {
            label: 'Header',
            name: 'header',
            component: 'text',
          },
          {
            label: 'Copy',
            name: 'copy',
            component: 'text',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
        ],
        defaultValue: [
          {
            header: 'Subscribe',
            copy: 'Subscribe & within minutes, you"ll receive an email to activate your account & set up your subscription.',
          },
          {
            header: 'Schedule',
            copy: 'Once you"ve activated your account, log in to update your information, pause, or adjust frequency, & cancel any time.',
          },
          {
            header: 'Save',
            copy: 'Save 10% on every order & get free shipping in the USA. No long-term commitment, surprises, or hidden fees.',
          },
        ],
        defaultItem: {
          header: 'Subscribe',
          copy: 'Subscribe & within minutes, you"ll receive an email to activate your account & set up your subscription.',
          ctaCopy: 'Shop & Subscribe',
          ctaUrl: '#',
        },
      },
    ],
  };
}
