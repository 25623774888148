import eyebrow from '../../utils/schemas/eyebrow';
import headline from '../../utils/schemas/headline';

export default function Schema() {
  return {
    label: 'Video Section',
    key: 'video-section',
    fields: [
      { ...eyebrow, defaultValue: 'THE CLEARLY FILTERED DIFFERENCE' },
      { ...headline, defaultValue: 'Advanced Filters that Set a New Standard' },
      {
        label: 'Content',
        name: 'content',
        component: 'markdown',
      },
      {
        label: 'Vimeo URL',
        name: 'videoUrl',
        component: 'text',
      },
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
    ],
  };
}
