import React from 'react';

export function CartItemSubscriptionDropdown(props) {
  const { selectedOption, setSelectedOption, options, updateSubscription } =
    props;

  const handleSelectChange = (event) => {
    setSelectedOption(options[event.target.selectedIndex]);
    updateSubscription(options[event.target.selectedIndex]);
  };

  return (
    <div className="relative text-primary before:pointer-events-none before:absolute before:right-2 before:top-0 before:scale-x-75 before:scale-y-[0.40] before:content-['▲'] after:pointer-events-none after:absolute after:bottom-[0] after:right-2 after:scale-x-75 after:scale-y-[0.4] after:content-['▼'] ">
      <div className="border-rounded rounded border  p-[2px] pb-[4px] pr-[6px]">
        <select
          className="w-full appearance-none pl-2 pr-4 text-[14px] font-light"
          onChange={(event) => handleSelectChange(event)}
          value={selectedOption?.sellingPlan?.id}
        >
          {options.map((option, index) => {
            return (
              <option value={option.sellingPlan.id} key={index}>
                {option.sellingPlan.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
