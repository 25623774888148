import PropTypes from 'prop-types';
import React from 'react';
import { Body, Eyebrow, Headline, Markdown } from '../../snippets';

/**
 * @typedef Image
 * @type {object}
 * @property {string} src - The image source.
 * @property {string} alt - The image alt text.
 */

/**
 * @typedef Step
 * @type {object}
 * @property {string} stepText - The step text.
 * @property {Image} image - The image.
 * @property {string} heading - The heading.
 * @property {string} content - The content.
 */

/**
 * Step carousel/slider made for product detail page.
 * @param {object} props - The props that were defined by the caller of this component.
 * @param {Array.<Step>} props.steps - The content that will came from PACK.
 * @param {number} props.currentStep - The current slide.
 * @param {function} props.setCurrentSlide - The current slide setter.
 *
 * @component
 * @example
 * const images = cms.images;
 * return (
 *  <ProductStepsContent
 *    images={images}
 *    onChange={console.log}
 *  />
 * )
 */
function ProductStepsContent({ steps, currentStep, setCurrentSlide }) {
  return (
    <div>
      <div className="relative mx-auto mb-8 flex max-w-[680px] items-center justify-between gap-x-2">
        <div className="absolute inset-0 flex items-center  opacity-10">
          <div className="h-[1px] w-full bg-black dark:bg-white dark:opacity-30" />
        </div>
        {steps.map((step, index) => (
          <span
            key={index}
            role="button"
            onClick={() => currentStep !== index && setCurrentSlide(index)}
            tabIndex={0}
            onKeyPress={(e) =>
              e.key === 'Enter' &&
              currentStep !== index &&
              setCurrentSlide(index)
            }
            className={[
              'relative inline-block cursor-pointer select-none rounded-full border px-4 py-1 pb-1.5 text-center lg:min-w-[120px] lg:pb-2',
              index === currentStep
                ? 'border-[#121D29] bg-[#121D29] text-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#202635]'
                : 'border-[#121d2970] bg-background text-[#121D2970] dark:border-[#ffffff3d] dark:bg-[#202635] dark:text-[#ffffff3d]',
            ].join(' ')}
          >
            <Eyebrow>{step.stepText?.toUpperCase()}</Eyebrow>
          </span>
        ))}
      </div>
      <div className="mx-auto max-w-[680px]">
        <div className="text-left">
          {steps?.[currentStep]?.heading && (
            <Headline variant="h5">{steps[currentStep].heading}</Headline>
          )}
          <div className="mt-4">
            <Body element="div" variant="medium">
              {steps?.[currentStep]?.content && (
                <Markdown>{steps[currentStep].content}</Markdown>
              )}
            </Body>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductStepsContent.propTypes = {
  /**
   * Images array
   */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepText: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      image: PropTypes.oneOfType([
        PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        }),
        PropTypes.string,
      ]),
    })
  ),

  currentStep: PropTypes.number,
};

ProductStepsContent.defaultProps = {
  steps: [],
  currentStep: 0,
};

export default ProductStepsContent;
