import Script from 'next/script';

import { useDataLayer } from './useDataLayer';

// Envs to set:
// * NEXT_PUBLIC_SITE_TITLE // provides backup brand name

export function DataLayer({ pageTitle, template }) {
  useDataLayer({
    pageTitle,
    template,
  });

  return (
    <Script
      id="elevar-script"
      type="module"
      dangerouslySetInnerHTML={{
        __html: `
      try {
        const response = await fetch("https://shopify-gtm-suite.getelevar.com/configs/c30da8adf1b4d36a351ff6ae1d7ccc7768e583e4/config.json");
        const config = await response.json();
        const scriptUrl = config.script_src_custom_pages;

        if (scriptUrl) {
          const { handler } = await import(scriptUrl);
          await handler(config);
        }
      } catch (error) {
        console.error("Elevar Error:", error);
      }
      `,
      }}
    />
  );
}

DataLayer.displayName = 'DataLayer';
