/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Link } from '../Link';
import { Image } from '../Image';

export function MenuDrawerSubnavCard({ cardDetails, onClick }) {
  const { link, image, title, enableGradient } = { ...cardDetails };
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={onClick}
      className="subnav-card-wrapper w-full max-w-[50%] [&_.hover-line]:hover:bg-[#D9D9D9] [&_.hover-line]:hover:bg-gradient-to-l [&_.hover-line]:hover:from-[#2179C2] [&_.hover-line]:hover:via-[#8DCEF9] [&_.hover-line]:hover:to-[#FECD86] [&_img]:duration-200 [&_img]:hover:scale-110"
    >
      <div className="subnav-card ">
        <Link aria-label={link?.text} href={link?.url}>
          {image?.src && (
            <>
              <div
                className={`relative flex items-center justify-center overflow-hidden ${
                  enableGradient ? 'p-[20px]' : ''
                }`}
              >
                {enableGradient && (
                  <div className="bg-hover absolute inset-0 bg-[#D9D9D9] bg-gradient-to-r from-[#2179C2] via-[#8DCEF9] to-[#F1DCC1] opacity-20" />
                )}

                <div className="relative aspect-[525/348] w-full max-w-[650px]">
                  <Image
                    width={650}
                    height={430}
                    className="absolute inset-0 m-auto box-border block h-0 max-h-full min-h-full w-0 min-w-full max-w-full border-none object-contain p-0 mix-blend-multiply"
                    alt={link?.text || title}
                    src={image?.src}
                  />
                </div>
              </div>
              <div className="hover-line mb-[18px] mt-8 h-[1px] w-full bg-[#E9E9EB]" />
            </>
          )}

          <h4 className="font-sans text-xl font-[300] text-[#0D141C]">
            {title}
          </h4>
          {link?.text ? (
            <div className="mt-[10px] text-[#0D141C] underline">
              {link.text}
            </div>
          ) : null}
        </Link>
      </div>
    </div>
  );
}

MenuDrawerSubnavCard.displayName = 'MenuDrawerSubnavCard';
