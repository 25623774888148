import { useCallback, useMemo, useState } from 'react';
import { useCartAddItem } from '@backpackjs/storefront';

import { Spinner } from '../../Spinner';
import { useGlobalContext } from '../../../contexts';

export function QuickShopOption({
  inventory,
  inventoryStatus,
  optionName,
  value,
  variant,
  variants,
}) {
  const {
    actions: { openCart },
  } = useGlobalContext();
  const { cartAddItem } = useCartAddItem();

  const [isAdding, setIsAdding] = useState(false);

  const variantToAdd = useMemo(() => {
    return variants.find((_variant) => {
      const color = _variant.selectedOptionsMap.Color;
      return (
        _variant.product?.handle === variant?.product?.handle &&
        _variant.selectedOptionsMap[optionName] === value &&
        (!color || color === variant?.selectedOptionsMap.Color)
      );
    });
  }, [variant?.id, value]);

  const variantInventory = inventory?.variants?.[variantToAdd?.id];
  const variantIsSoldOut =
    inventoryStatus?.success && !variantInventory?.availableForSale;
  // const variantIsPreorder =
  //   !variantIsSoldOut && variantInventory?.currentlyNotInStock;

  const handleAddToCart = useCallback(async () => {
    if (!variantToAdd?.id || !inventoryStatus?.success || isAdding) return;
    setIsAdding(true);
    await cartAddItem({
      merchandiseId: variantToAdd.id,
      quantity: 1,
    });
    setIsAdding(false);
    openCart();
  }, [variantToAdd?.id, inventoryStatus?.success, isAdding]);

  const disabled = !variantToAdd || variantIsSoldOut;

  return (
    <button
      aria-label={value}
      className={`group/option flex h-full w-full items-center justify-center whitespace-nowrap text-center text-sm transition ${
        disabled
          ? 'cursor-not-allowed text-gray'
          : 'cursor-pointer hover:bg-black hover:text-white'
      }`}
      disabled={disabled}
      onClick={handleAddToCart}
      type="button"
    >
      {isAdding ? (
        <div className="text-mediumGray group-hover/option:text-white">
          <Spinner />
        </div>
      ) : (
        value
      )}
    </button>
  );
}

QuickShopOption.displayName = 'QuickShopOption';
