export const Schema = () => ({
  label: 'Page Banner',
  category: 'Banner',
  key: 'page-banner',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Heading',
    },
    {
      label: 'Copy',
      name: 'copy',
      component: 'text',
      defaultValue:
        'Donec ultrices elit a ligula aliquet facilisis. Pellentesque ultrices aliquet lacinia. Aenean suscipit tortor venenatis ullamcorper pharetra. Cras ac pharetra purus biam.',
    },
    {
      label: 'Background Gradient Start Color',
      name: 'bgGradientStart',
      component: 'color',
      defaultValue: '#2179C2',
      description:
        'Select the starting color of the background gradient. (#2179C2) A 20% opacity will be applied to the color selected.',
    },
    {
      label: 'Background Gradient End Color',
      name: 'bgGradientEnd',
      component: 'color',
      defaultValue: '#F1DCC1',
      description:
        'Select the ending color of the background gradient. (#F1DCC1) A 20% opacity will be applied to the color selected.',
    },
  ],
  defaultItem: {
    header: 'Get in Touch',
    copy: 'Monday - Friday 9AM to 4PM Pacific Time.',
  },
});
