/* eslint-disable no-use-before-define */
import { Schema } from './RatingBanner.schema';
import { ReviewStars } from '../../snippets';

export function RatingBanner({ cms }) {
  const { title, toggleStars, starCount = '5' } = cms;
  return (
    <section className="h-[3.375rem] bg-gradient-to-r from-[#C1EFFA99] to-[#8DCEF999] text-[#000000]">
      <div className="flex h-full flex-wrap items-center justify-center gap-x-[0.625rem] text-center text-xs font-extralight md:text-sm">
        {title}
        {toggleStars && (
          <ReviewStars
            color="rgb(33, 121, 194)"
            rating={parseFloat(starCount)}
            size="small"
          />
        )}
      </div>
    </section>
  );
}

RatingBanner.displayName = 'RatingStars';
RatingBanner.Schema = Schema;
