export function Schema() {
  return {
    label: 'Product Overview & Features',
    key: 'productOverviewFeatures',
    category: 'Product',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/product-overview-features.png',
    fields: [
      {
        name: 'eyebrow',
        component: 'text',
        label: 'Eyebrow Text',
      },
      {
        name: 'heading',
        component: 'text',
        label: 'Heading',
      },
      {
        label: 'Featured Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Image Alignment',
        name: 'imageAlignment',
        component: 'select',
        options: [
          {
            label: 'Left',
            value: 'left',
          },
          {
            label: 'Right',
            value: 'right',
          },
        ],
        defaultValue: 'left',
      },
      {
        name: 'promoHeading',
        component: 'text',
        label: 'Promo Heading',
      },
      {
        name: 'promoItems',
        component: 'group-list',
        label: 'Promo Items',
        itemProps: {
          label: '{{item.promoItem}}',
        },
        fields: [
          {
            name: 'promoItem',
            component: 'text',
            label: 'Promo Item',
          },
        ],
      },
    ],
  };
}
