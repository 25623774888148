const BUTTON_COLOR_FIELDS = [
  {
    label: 'Background Color',
    name: 'bgColor',
    component: 'color',
  },
  {
    label: 'Border Color',
    name: 'borderColor',
    component: 'color',
  },
  {
    label: 'Text Color',
    name: 'textColor',
    component: 'color',
  },
  {
    label: 'Hover Background Color',
    name: 'hoverBgColor',
    component: 'color',
  },
  {
    label: 'Hover Border Color',
    name: 'hoverBorderColor',
    component: 'color',
  },
  {
    label: 'Hover Text Color',
    name: 'hoverTextColor',
    component: 'color',
  },
];

export default {
  label: 'Theme',
  name: 'theme',
  component: 'group',
  description: 'Theme colors, button colors',
  fields: [
    {
      label: 'Buttons',
      name: 'buttons',
      component: 'group',
      fields: [
        {
          label: 'Primary',
          name: 'primary',
          component: 'group',
          fields: BUTTON_COLOR_FIELDS,
          defaultValue: {
            bgColor: '#202635',
            borderColor: '#202635',
            textColor: '#FFFFFF',
            hoverBgColor: '#0064B7',
            hoverBorderColor: '#0064B7',
            hoverTextColor: '#FFFFFF',
          },
        },
        {
          label: 'Secondary',
          name: 'secondary',
          component: 'group',
          fields: BUTTON_COLOR_FIELDS,
          defaultValue: {
            bgColor: '#0064B7',
            borderColor: '#0064B7',
            textColor: '#FFFFFF',
            hoverBgColor: '#202635',
            hoverBorderColor: '#202635',
            hoverTextColor: '#FFFFFF',
          },
        },
        {
          label: 'Inverse Light',
          name: 'inverseLight',
          component: 'group',
          fields: BUTTON_COLOR_FIELDS,
          defaultValue: {
            bgColor: 'transparent',
            borderColor: '#FFFFFF',
            textColor: '#FFFFFF',
            hoverBgColor: '#FFFFFF',
            hoverBorderColor: '#FFFFFF',
            hoverTextColor: '#111111',
          },
        },
        {
          label: 'Inverse Dark',
          name: 'inverseDark',
          component: 'group',
          fields: BUTTON_COLOR_FIELDS,
          defaultValue: {
            bgColor: 'transparent',
            borderColor: '#111111',
            textColor: '#111111',
            hoverBgColor: '#111111',
            hoverBorderColor: '#111111',
            hoverTextColor: '#FFFFFF',
          },
        },
      ],
    },
    {
      label: 'Colors',
      name: 'colors',
      component: 'group',
      fields: [
        {
          label: 'Background',
          name: 'background',
          component: 'color',
        },
        {
          label: 'Text',
          name: 'text',
          component: 'color',
        },
        {
          label: 'Border',
          name: 'border',
          component: 'color',
        },
        {
          label: 'Primary',
          name: 'primary',
          component: 'color',
        },
        {
          label: 'Secondary',
          name: 'secondary',
          component: 'color',
        },
        {
          label: 'Accent 1',
          name: 'accent1',
          component: 'color',
        },
        {
          label: 'Accent 2',
          name: 'accent2',
          component: 'color',
        },
        {
          label: 'Accent 3',
          name: 'accent3',
          component: 'color',
        },
        {
          label: 'Success',
          name: 'success',
          component: 'color',
        },
        {
          label: 'Error',
          name: 'error',
          component: 'color',
        },
        {
          label: 'Black',
          name: 'black',
          component: 'color',
        },
        {
          label: 'Off Black',
          name: 'offBlack',
          component: 'color',
        },
        {
          label: 'Dark Gray',
          name: 'darkGray',
          component: 'color',
        },
        {
          label: 'Medium Dark Gray',
          name: 'mediumDarkGray',
          component: 'color',
        },
        {
          label: 'Medium Gray',
          name: 'mediumGray',
          component: 'color',
        },
        {
          label: 'Gray',
          name: 'gray',
          component: 'color',
        },
        {
          label: 'Light Gray',
          name: 'lightGray',
          component: 'color',
        },
        {
          label: 'Off White',
          name: 'offWhite',
          component: 'color',
        },
        {
          label: 'White',
          name: 'white',
          component: 'color',
        },
      ],
      defaultValue: {
        background: '#ECF7FF',
        text: '#202635',
        border: '#ECF7FF',
        primary: '#202635',
        secondary: '#0064b7',
        accent1: '#F1DCC1',
        accent2: '#8DCEF9',
        accent3: '#C1EFFA',
        success: '#5ABE8A',
        error: '#ED7C5C',
        black: '#111111',
        offBlack: '#202635',
        darkGray: '#484848',
        mediumDarkGray: '#707070',
        mediumGray: '#989898',
        gray: '#C0C0C0',
        lightGray: '#E8E8E8',
        offWhite: '#ECF7FF',
        white: '#FFFFFF',
      },
    },
  ],
};
