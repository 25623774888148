import { useState, useEffect } from 'react';
import Link from 'next/link';

export function BlogHeroSearchLink({ item }) {
  const { text, url } = item;

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const findActiveUrl = () => {
      const newUrl = `${url}`;
      if (newUrl === window.location.pathname) {
        setIsActive(true);
      }
    };
    findActiveUrl();
  }, []);

  return (
    <div
      className={`relative w-auto rounded-full py-2 px-4 transition duration-300 lg:py-3 lg:px-6 ${
        isActive ? 'group bg-[#fff] text-primary' : ''
      }`}
    >
      <Link href={url}>
        <span
          className={`group:text-primary duration block w-max  cursor-pointer text-base font-[400] tracking-[-.01em] transition hover:text-[#fff] group-hover:text-primary lg:text-[20px] ${
            isActive ? 'text-primary' : 'text-[#BCBEC2]'
          }`}
        >
          {text}
        </span>
      </Link>
    </div>
  );
}

BlogHeroSearchLink.displayName = 'BlogHeroSearchLink';
