import { useEffect, useState } from 'react';
import { Link } from '../Link';
import { MenuDrawerSubnavCards } from './MenuDrawerSubnavCards';
import { Image } from '../Image';

export function MenuDrawer({
  handleMenuDrawerClose,
  handleMenuDrawerStayOpen,
  handleMenuHoverOut,
  menuDrawerContent,
}) {
  const { imageLinks, links, iconMenuLinks } = { ...menuDrawerContent };
  const hasContent =
    imageLinks?.length > 0 || links?.length > 0 || iconMenuLinks?.length > 0;
  const [hoveredLink, setHoveredLink] = useState(0);

  useEffect(() => {
    setHoveredLink(0);
  }, [menuDrawerContent]);

  return (
    <div
      className={`absolute left-0 top-[100%] hidden w-full origin-top border-border bg-white text-primary transition duration-200 lg:block ${
        hasContent ? 'scale-y-100 border-b' : 'scale-y-0'
      }`}
      onMouseEnter={handleMenuDrawerStayOpen}
      onMouseLeave={handleMenuHoverOut}
    >
      {
        /* Icon Menu Links */
        hasContent && iconMenuLinks?.length > 0 && (
          <div className="flex items-center justify-center border-t border-t-border py-[40px]">
            <ul className="flex gap-[75px]">
              {iconMenuLinks.map((iconMenuItem, iconMenuItemIndex) => {
                return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <li
                    onClick={handleMenuDrawerClose}
                    key={`icon-menu-item-${iconMenuItemIndex}`}
                  >
                    <Link
                      aria-label={iconMenuItem?.link?.text}
                      className="flex w-full flex-col items-center gap-[15px] hover:!text-[#66A2D4] [&:not(:hover)_img]:!filter-none"
                      href={iconMenuItem?.link?.url}
                      newTab={iconMenuItem?.link?.newTab}
                    >
                      {iconMenuItem?.icon?.src && (
                        <div className="relative aspect-square w-[35px]">
                          <Image
                            style={{
                              filter:
                                'invert(54%) sepia(71%) saturate(312%) hue-rotate(165deg) brightness(100%) contrast(83%)',
                            }}
                            alt={iconMenuItem?.link?.text}
                            fill
                            sizes="100vw"
                            className="!object-contain"
                            src={iconMenuItem?.icon?.src}
                          />
                        </div>
                      )}

                      {iconMenuItem?.link?.text}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        )
      }

      {
        /* Standard Submenu Links */
        hasContent &&
          links?.length > 0 &&
          (iconMenuLinks?.length === 0 || !iconMenuLinks?.length) && (
            <div className="px-contained mx-[auto] flex">
              <div className="flex-[0_1_455px] border-r border-r-[#0000001A] py-14">
                <ul className="flex flex-col gap-2">
                  {links?.map(({ link }, index) => {
                    return (
                      <li key={index} className="[&:not(:first-child)>a]:mt-4">
                        <Link
                          aria-hidden={!hasContent}
                          aria-label={link?.text}
                          className={` ${
                            index === hoveredLink
                              ? ' text-[#3383c5] underline'
                              : 'text-primary'
                          } inline-block font-sans text-[22px] font-[300]`}
                          href={link?.url}
                          newTab={link?.newTab}
                          onClick={handleMenuDrawerClose}
                          onMouseOver={() => {
                            setHoveredLink(index);
                          }}
                          tabIndex={hasContent ? '0' : '-1'}
                        >
                          {link?.text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="flex-[1_1_100%] p-14 pr-0">
                <MenuDrawerSubnavCards
                  onClick={handleMenuDrawerClose}
                  hoveredLink={hoveredLink}
                  menuDrawerContent={menuDrawerContent}
                />
              </div>
            </div>
          )
      }
    </div>
  );
}

MenuDrawer.displayName = 'MenuDrawer';
