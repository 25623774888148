import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Image } from '../../snippets';
import { Schema } from './ValueProps.schema';

const valuePropItem = (image, header, copy, index) => {
  return (
    <div
      className="relative mb-4 px-6 text-center lg:mb-8 lg:w-1/4"
      key={`value-prop-item-${index}`}
    >
      <div className="">
        {image && (
          <Image
            className="mx-auto mb-6"
            sizes="48px"
            width="44px"
            height="44px"
            src={image.src}
          />
        )}
        {header && (
          <p className="letter mb-4 text-lg font-light leading-6 tracking-[-.01em] lg:text-xl">
            {header}
          </p>
        )}
        {copy && <p className="text-base">{copy}</p>}
      </div>
    </div>
  );
};

export function ValueProps({ cms }) {
  const { valueProp, sectionTitle } = cms;

  return (
    <div className="px-contained lg:py-contained mx-auto mb-10 bg-[#B3D1E94D] pb-14 pt-4 lg:mb-16 lg:border-b lg:border-zinc-200 lg:bg-clearBlue">
      {sectionTitle && (
        <h2 className="text-center text-[3rem] font-light max-sm:text-[1.625rem] md:mb-6">
          {sectionTitle}
        </h2>
      )}
      {valueProp?.length > 0 && (
        <div className="mx-auto flex max-w-none flex-col items-center gap-4 text-center md:gap-6">
          <div className="mt-4 hidden flex-wrap justify-center lg:flex">
            {valueProp.map(({ image, header, copy }, index) => {
              return valuePropItem(image, header, copy, index);
            })}
          </div>

          <Swiper
            className="!static mt-10 w-full lg:hidden"
            grabCursor
            loop={valueProp.length > 1}
            pagination={{
              el: '.swiper-pagination__value-props',
              type: 'bullets',
              clickable: true,
              renderBullet(_, className) {
                return `<span class="!bg-primary ${className}"></span>`;
              },
            }}
            modules={[Pagination]}
            slidesPerView={1}
            spaceBetween={16}
            breakpoints={{
              1024: {
                loop: valueProp.length >= 6,
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
          >
            {valueProp.map(({ image, header, copy }, index) => {
              return (
                <SwiperSlide key={`value-prop-${index}`}>
                  {valuePropItem(image, header, copy)}
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="swiper-pagination swiper-pagination__value-props !static lg:!hidden" />
        </div>
      )}
    </div>
  );
}

ValueProps.displayName = 'ValueProps';
ValueProps.Schema = Schema;
