import { Fragment, useEffect, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { CollectionPromoTile } from './CollectionPromoTile';
import { ProductItem } from '../ProductItem';

export function CollectionGrid({
  activeFilters,
  collectionProductsData,
  isSearchPage,
  promoTiles,
  swatchesMap,
  showHeading,
}) {
  const settings = useSettings();
  const { productItem, productTypeGrids } = { ...settings?.collection };
  const hasActiveFilters = Object.keys(activeFilters).length > 0;
  const [finalFilteredProducts, setFinalFilteredProducts] = useState([]);

  // Generate a map of Product Type -> Grid Columns
  const productTypeGridMap = {};
  productTypeGrids?.forEach((gridItem) => {
    productTypeGridMap[gridItem.productType] = gridItem.productTypeGridCount;
  });

  const {
    state: { filteredProducts, productsLimit },
  } = collectionProductsData;

  // Group Product Cards into their respective Product Type Bins, include Promotion Cards
  // Fires Whenever "filteredProducts" changes - Usually on Filter Changing
  useEffect(() => {
    if (!filteredProducts.length) setFinalFilteredProducts([]);
    else {
      setFinalFilteredProducts([]);
      filteredProducts.slice(0, productsLimit).forEach((product, index) => {
        let maxGridSize = 3;
        let gridSpan = 1;
        const promoTile = promoTiles?.find(
          ({ position }) => position === index + 1
        );
        if (promoTile) {
          maxGridSize = productTypeGridMap[product.productType] ?? maxGridSize;
          gridSpan =
            promoTile.span > maxGridSize ? maxGridSize : promoTile.span;
        }
        const tileFragment = (
          <Fragment key={product.id}>
            {promoTile && JSON.stringify(activeFilters) === '{}' && (
              <li
                data-mobile-span={`${gridSpan >= 2 ? 2 : 1}`}
                className="promo-tile max-lg:[&.promo-tile[data-mobile-span!='2']]:!col-span-1 min-h-[256px] max-lg:[&.promo-tile[data-mobile-span='2']]:!col-span-2"
                style={{
                  gridColumn: `span ${gridSpan} / span ${gridSpan}`,
                }}
                key={`promo-tile-${product.id}`}
              >
                <CollectionPromoTile tile={promoTile} />
              </li>
            )}
            <li
              data-prod-tags={JSON.stringify(product.tags)}
              data-prod={product.handle}
              key={`collection-tile-${product.id}`}
            >
              <ProductItem
                enabledColorNameOnHover={productItem?.enabledColorNameOnHover}
                enabledColorSelector={productItem?.enabledColorSelector}
                enabledQuickShop={productItem?.enabledQuickShop}
                enabledStarRating={productItem?.enabledStarRating}
                handle={product.handle}
                index={index}
                isSearchPage={isSearchPage}
                swatchesMap={swatchesMap}
              />
            </li>
          </Fragment>
        );

        // Update the state of Final Filtered Products
        setFinalFilteredProducts((previousState) => {
          if (previousState[product.productType]) {
            return {
              ...previousState,
              [product.productType]: [
                ...previousState[product?.productType],
                tileFragment,
              ],
            };
          }
          return {
            ...previousState,
            [product.productType]: [tileFragment],
          };
        });
      });
    }
  }, [filteredProducts]);

  // Return the Template for Collection Grid - Looping over 'finalFilteredProducts'
  return (
    <>
      {
        /* With Product Type Headings */
        Object.keys(finalFilteredProducts).length > 0 &&
          (showHeading ?? true) &&
          Object.keys(finalFilteredProducts).map(
            (productType, productTypeIndex) => {
              return (
                <ul
                  style={{
                    gridTemplateColumns: `repeat(${
                      productTypeGridMap[productType] ?? 3
                    }, 1fr)`,
                  }}
                  key={`product-type-heading-${productTypeIndex}`}
                  className="mx-auto grid gap-x-4 gap-y-8 overflow-hidden px-4 max-lg:!grid-cols-2 xs:gap-x-5 md:px-0 [&:first-child_li:first-child]:mt-0 [&:first-child_li:first-child]:border-0"
                >
                  {Object.keys(finalFilteredProducts).length > 1 && (
                    <li
                      style={{
                        gridColumn: `span ${
                          productTypeGridMap[productType] ?? 3
                        } / span ${productTypeGridMap[productType] ?? 3}`,
                      }}
                      className="col-span-3 mt-[20px] border-t border-[#E9E9EB] py-4 text-[20px] font-light max-lg:!col-span-2 md:mt-[72px] md:py-[36px] md:text-[36px]"
                    >
                      {productType}
                    </li>
                  )}

                  {finalFilteredProducts[productType].map((collectionCard) => {
                    return collectionCard;
                  })}
                </ul>
              );
            }
          )
      }

      {
        /* Without Product Type Headings */
        Object.keys(finalFilteredProducts).length > 0 && !showHeading && (
          <ul className="mx-auto grid grid-cols-3 gap-x-4 gap-y-8 overflow-hidden px-4 max-lg:!grid-cols-1 xs:gap-x-5 md:px-0 [&:first-child>li:first-child]:mt-0 [&:first-child>li:first-child]:border-0 [&>li:first-child]:!border-0 [&>li]:border-t [&>li]:border-t-[#DDE2E7] [&>li]:pt-8 lg:[&>li]:border-t-0">
            {Object.keys(finalFilteredProducts).map(
              (productType, productTypeIndex) => {
                return finalFilteredProducts[productType].map(
                  (collectionCard) => {
                    return collectionCard;
                  }
                );
              }
            )}
          </ul>
        )
      }

      {!filteredProducts?.length && hasActiveFilters && (
        <div className="flex min-h-[12rem] items-center justify-center text-center">
          <p>No products found matching these filters.</p>
        </div>
      )}
    </>
  );
}

CollectionGrid.displayName = 'CollectionGrid';
