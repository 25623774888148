import { Markdown } from '../../snippets';
import Button from '../../snippets/Button/Button';
import { Eyebrow, Headline } from '../../snippets/Typography';
import useMediaQuery from '../../utils/useMediaQuery';
import FiltrationTechnologyVideo from './FiltrationTechnologyVideo';

export function FiltrationTechnologyDrawer({ content, className }) {
  const isMobile = useMediaQuery('(max-width: 767px)');
  return (
    <div className={`${className}`}>
      {content?.eyebrowText && (
        <Eyebrow className="mb-3">{content.eyebrowText}</Eyebrow>
      )}

      {content?.heading && (
        <Headline variant={isMobile ? 'h4' : 'h3'} className="mb-4">
          {content?.heading}
        </Headline>
      )}
      {content?.videoUrl && (
        <div className="relative mb-10 aspect-video">
          <FiltrationTechnologyVideo
            poster={content?.videoBackground?.src}
            videoUrl={content?.videoUrl}
            controls
            playsInline
            className={`absolute inset-0 block h-full w-full object-cover ${className}`}
          />
        </div>
      )}
      {content?.content && (
        <div className="text-sm">
          <Markdown>{content?.content}</Markdown>
        </div>
      )}
      {content?.ctas && content?.ctas?.length && (
        <div className="mt-6">
          {content.ctas.map((cta, index) => {
            if ('link' in cta) {
              return (
                <Button
                  key={index}
                  href={cta.link.url}
                  variant={cta.variant}
                  theme={cta.theme}
                >
                  {cta.link.text}
                </Button>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}
