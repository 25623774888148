import React from 'react';
import { Svg } from '../Svg';

export function ContaminantResultCardPrint({ contaminant }) {
  const {
    ContaminantName,
    // ContaminantHealthRisks,
    SystemAverage,
    ContaminantHGValue,
    ClearlyFilteredData,
  } = { ...contaminant };
  const { category, healthConcern, percentRemoved } = {
    ...ClearlyFilteredData,
  };
  const multiplier = Math.abs(
    parseFloat(SystemAverage / ContaminantHGValue).toFixed(2)
  );
  return (
    <div className="relative w-full break-inside-avoid overflow-y-visible border p-4">
      {percentRemoved && (
        <div className="absolute top-[12px] right-[12px] flex h-[26px] w-[26px] items-center justify-center rounded-full border-[2px] border-secondary">
          <Svg
            className="w-[14px] text-secondary"
            src="/svgs/checkmark.svg#checkmark"
            title="Checkmark"
            viewBox="0 0 24 24"
          />
        </div>
      )}
      <div className="float-left w-1/2">
        {category && <p className="text-xs">{category}</p>}
        {ContaminantName && <p className="py-1">{ContaminantName}</p>}
        {healthConcern && (
          <p className="text-sm">Health Risk: {healthConcern}</p>
        )}
      </div>

      <div className="float-left w-1/2">
        <p className="mb-1 text-sm">
          <span className="font-semibold">
            {ContaminantHGValue !== -1 ? `${multiplier}x` : 'N/A'}
          </span>{' '}
          {ContaminantHGValue === -1 && 'No'} EWG Health Guidelines
        </p>

        {percentRemoved ? (
          <p className="text-sm">
            Clearly Filtered removes up to{' '}
            <span className="text-base text-secondary">{percentRemoved}</span>
          </p>
        ) : (
          <div className="text-sm text-[#797D86]">Have not tested for</div>
        )}
      </div>
      <div className="clear-both table overflow-auto"></div>
    </div>
  );
}
