import { Schema } from './CareerIcons.schema';
import { CareerIcon } from './CareerIcon';

export function CareerIcons({ cms }) {
  const { careerIconItems } = cms;

  return (
    <div className="bg-accent3Opacity20">
      <div className="px-contained mx-auto max-w-[var(--content-max-width)] overflow-hidden pb-6 pt-16">
        <h4 className="mb-14 text-center text-[24px] font-light tracking-[-.02em] text-primary sm:text-4xl">
          Why work with us?
        </h4>
        {careerIconItems && (
          <div className="flex flex-wrap">
            {careerIconItems.map((item, index) => {
              return <CareerIcon item={item} key={index} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
}

CareerIcons.displayName = 'Career Icons';
CareerIcons.Schema = Schema;
