import React from 'react';
import { Link, Image } from '../../snippets';
import Schema from './CollectionsList.schema';

export function CollectionsList({ cms }) {
  const { collections, darkMode } = cms;
  return (
    <div className={darkMode ? 'dark' : ''}>
      <section className="custom-scroll mx-contained flex justify-center border-b border-[#0000001A] bg-background pb-8 pt-8 lg:pt-16">
        <div className="px-contained flex max-w-[var(--content-max-width)] gap-2 overflow-x-scroll pb-8 lg:items-center lg:gap-x-0">
          {collections.map((collection, index) => (
            <React.Fragment key={index}>
              <Link
                href={collection.link.url}
                className="lg:group shrink-0 cursor-pointer rounded-[10px] border border-[#DDE2E7] bg-[#E6F0F8] p-3 lg:border-none lg:bg-transparent lg:p-0"
              >
                <div className="flex flex-col items-center justify-center text-center lg:gap-y-5">
                  <Image
                    src={collection.image.src}
                    alt={collection.image.alt ?? collection.link.text}
                    width={200}
                    height={200}
                    className="h-20 w-20 object-contain mix-blend-multiply lg:mx-16 lg:h-24 lg:w-24 lg:transition-transform lg:duration-300 lg:ease-in-out lg:group-hover:scale-110"
                  />
                  <span className="text-xs font-extralight uppercase text-[#24293B] lg:text-lg lg:normal-case lg:transition-colors lg:duration-300 lg:ease-in-out lg:group-hover:text-secondary">
                    {collection.link.text}
                  </span>
                </div>
              </Link>
              {index < collections.length - 1 && (
                <div className="hidden h-32 w-[1px] shrink-0 bg-[#0000001A] lg:block" />
              )}
            </React.Fragment>
          ))}
        </div>
      </section>
    </div>
  );
}

CollectionsList.displayName = 'CollectionsList';
CollectionsList.Schema = Schema;
