export function Schema() {
  return {
    label: 'Full Bleed Hero',
    key: 'fullBleedHero',
    category: 'Product',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/full-bleed-hero.png',
    fields: [
      {
        name: 'headline',
        component: 'text',
        label: 'Hero Headline',
      },
      {
        label: 'Background Image',
        name: 'backgroundImage',
        component: 'image',
      },
      {
        label: 'Background Video URL',
        name: 'backgroundVideoUrl',
        description:
          'Video will always be muted and autoplay with loop and override the background image if present.',
        component: 'text',
      },
    ],
  };
}
