export default function Schema() {
  return {
    label: 'Promo Banner',
    key: 'promo-banner',
    category: 'Banner',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/promo_banner.png?v=1684355227',
    fields: [
      {
        label: 'Eyebrow Text',
        name: 'eyebrowText',
        component: 'text',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },

      {
        label: 'Promo Eyebrow Text',
        name: 'promoEyebrowText',
        component: 'text',
      },
      {
        label: 'Promo Heading',
        name: 'promoHeading',
        component: 'text',
      },
      {
        label: 'Promo Description',
        name: 'promoDescription',
        component: 'text',
      },
      {
        label: 'Call to Action Buttons',
        name: 'ctas',
        component: 'group-list',
        description: 'Max of two buttons',
        itemProps: {
          label: '{{item.link.text}}',
        },
        defaultItem: {
          link: { text: 'Shop All', url: '' },
          theme: 'primary',
          variant: 'filled',
        },
        validate: {
          maxItems: 2,
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Regular Link',
            name: 'isRegularLink',
            component: 'toggle',
            defaultValue: false,
            description:
              'Display the link as a regular underlined link. Ignores the button Theme and Variant settings below when enabled.',
          },
          {
            label: 'Button Theme',
            name: 'theme',
            component: 'select',
            options: [
              { label: 'Primary', value: 'primary' },
              { label: 'Secondary', value: 'secondary' },
              { label: 'Light', value: 'light' },
            ],
          },
          {
            label: 'Button Variant',
            name: 'variant',
            component: 'select',
            options: [
              { label: 'Filled', value: 'filled' },
              { label: 'Outlined', value: 'outlined' },
            ],
          },
        ],
      },
    ],
  };
}
