import { useCallback, useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { useSettings } from '@backpackjs/storefront';

export function useSearchPageResults({ term, mounted = true }) {
  const settings = useSettings();

  const [pagesFuse, setPagesFuse] = useState(null);
  const [pageResults, setPageResults] = useState(null);
  const { pagesEnabled } = { ...settings?.search?.results };

  const setPagesFuseOnMount = useCallback(async () => {
    try {
      if (pagesFuse) return;
      const response = await fetch('/json/pages-list.json');
      const list = await response.json();

      setPagesFuse(
        new Fuse(list, {
          keys: ['title'],
          ignoreLocation: true,
          minMatchCharLength: 3,
          threshold: 0.3,
        })
      );
    } catch (error) {
      console.error(error.message);
    }
  }, [pagesFuse]);

  const searchPagesOnTermChange = useCallback(() => {
    if (!pagesFuse) return;
    if (!term) {
      setPageResults([]);
      return;
    }
    const results = pagesFuse.search(term);
    setPageResults(results);
  }, [pagesFuse, term]);

  useEffect(() => {
    // if (!mounted || !pagesEnabled) return;
    setPagesFuseOnMount();
  }, [mounted, pagesEnabled]);

  useEffect(() => {
    searchPagesOnTermChange();
  }, [term, pagesFuse]);

  return { pageResults };
}
