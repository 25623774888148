import { COLORS } from '../../settings/common';

export function Schema({ product, settings }) {
  if (!product) return null;

  /* Get Global Frequency Calculator Settings */
  const globalCalculatorSettings =
    settings?.settings?.filterReplacementCalculator;
  const { questions } = globalCalculatorSettings;
  let question1Answers = [];
  let question2CountOptions = [];
  questions?.forEach((question, questionIndex) => {
    // Init the question 1 answers
    if (questionIndex === 0) {
      question1Answers = question?.answers?.map((answer, answerIndex) => {
        return {
          label: answer.answer,
          value: answerIndex + 1,
        };
      });
    }

    // Init the question 2 option counts
    if (questionIndex === 1) {
      question2CountOptions = question?.answers?.map((answer, answerIndex) => {
        return {
          label: answerIndex + 1,
          value: answerIndex + 1,
        };
      });
      question2CountOptions = [
        {
          label: 0,
          value: 0,
        },
        ...question2CountOptions,
      ];
    }
  });

  // Set up the response mapping object that will be used in the Frequency Calculator Response Map group-list below.
  const responseMap = [
    {
      label: 'First Question Answer',
      name: 'firstQuestionAnswer',
      component: 'select',
      options: question1Answers,
      description: 'What answer was provided for the first question?',
      defaultValue: 1,
    },
    {
      label: '2nd Question Answers Selected',
      name: 'secondQuestionCount',
      component: 'select',
      description: `How many options were provided for the second question? (max: ${question2CountOptions?.length})`,
      options: question2CountOptions,
      defaultValue: 1,
    },
    {
      label: 'Frequency Value',
      name: 'frequencyValue',
      component: 'number',
      description: 'Enter the filter replacement frequency in months.',
    },
  ];

  return {
    category: 'Product',
    label: 'Product Detail Accordions',
    key: 'product-detail-accordions',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/product-detail-accordions.png?v=1686164567',
    fields: [
      {
        label: 'View Test Results',
        name: 'viewTestResults',
        component: 'link',
      },
      {
        label: 'Product Byline',
        name: 'productByLine',
        component: 'text',
      },
      {
        label: 'Disclaimer',
        name: 'disclaimer',
        component: 'text',
      },
      {
        label: 'Disclaimer Alert Color',
        name: 'disclaimerColor',
        component: 'color',
        defaultValue: '#F1DCC1',
      },
      {
        label: 'Disclaimer URL (optional)',
        name: 'disclaimerUrl',
        component: 'text',
      },
      {
        label: 'Disclaimer Open in New Tab (optional)',
        name: 'disclaimerOpenNewTab',
        component: 'toggle',
      },
      {
        label: 'Out of stock message',
        name: 'outOfStockMessage',
        component: 'group',
        defaultValue: {
          disclaimer: 'Out of Stock - Restock available soon',
          disclaimerColor: '#ED7C5C',
          disclaimerUrl: '',
          disclaimerOpenNewTab: false,
        },
        fields: [
          {
            label: 'Out of Stock Disclaimer',
            name: 'disclaimer',
            defaultValue: 'Out of Stock - Restock available soon',
            component: 'text',
          },
          {
            label: 'Out of Stock Disclaimer Alert Color',
            name: 'disclaimerColor',
            component: 'color',
            defaultValue: '#ED7C5C',
          },
          {
            label: 'Out of Stock Disclaimer URL (optional)',
            name: 'disclaimerUrl',
            component: 'text',
          },
          {
            label: 'Out of Stock Disclaimer Open in New Tab (optional)',
            name: 'disclaimerOpenNewTab',
            component: 'toggle',
          },
        ],
      },
      {
        label: 'Linked Product Groups',
        component: 'group-list',
        name: 'linkedProductGroups',
        itemProps: {
          label: '{{item.groupName}}',
        },
        fields: [
          {
            label: 'Group Name',
            name: 'groupName',
            component: 'text',
            defaultValue: 'Size',
          },
          {
            label: 'Linked Products',
            component: 'group-list',
            name: 'linkedProducts',
            itemProps: {
              label: '{{item.linkedProductTitle}}',
            },
            fields: [
              {
                label: 'Product Title',
                name: 'linkedProductTitle',
                component: 'text',
                defaultValue: '32oz Bottle',
              },
              {
                label: 'Product',
                name: 'linkedProduct',
                component: 'productSearch',
              },
            ],
          },
        ],
      },
      {
        label: 'Frequency Calculator',
        name: 'frequencyCalculator',
        component: 'group',
        description:
          'Configuration for the filter replacement frequency calculator can be set up here.',
        fields: [
          {
            label: 'Enable Frequency Calculator?',
            name: 'enableFrequencyCalculator',
            component: 'toggle',
            defaultValue: false,
          },
          {
            label: 'Title',
            name: 'title',
            component: 'text',
            defaultValue: 'Need help choosing a replacement plan?',
          },
          {
            label: 'Content',
            name: 'content',
            component: 'text',
            defaultValue:
              'Use our filter change calculator to estimate your usage and find out how often you need to change your filter.',
          },
          {
            label: 'CTA',
            name: 'cta',
            component: 'text',
            defaultValue: 'Filter Replacement Calculator',
          },
          {
            label: 'Recommendation Name',
            name: 'recommendationName',
            component: 'text',
            defaultValue: 'Recommended Frequency',
          },
          {
            label: 'Recommendation Image',
            name: 'recommendationImage',
            component: 'image',
          },
          {
            label: 'Response Map',
            name: 'responseMap',
            component: 'group-list',
            description:
              'How should the quiz responses map to a filter replacement frequency? (A) First Question Answer, (C) Second Question Option Count, (F) Frequency',
            itemProps: {
              label:
                'A: {{item.firstQuestionAnswer}} - C: {{item.secondQuestionCount}} - F: {{item.frequencyValue}}',
            },
            fields: responseMap,
          },
        ],
      },
      {
        label: 'Accordions',
        name: 'accordions',
        component: 'group-list',
        itemProps: {
          label: '{{item.header}}',
        },
        fields: [
          {
            label: 'Header',
            name: 'header',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
          {
            label: 'Display Upsells',
            name: 'isUpsell',
            component: 'toggle',
            description: 'Display Re-Buy product upsells.',
          },
          {
            label: 'Cross Sell Products',
            name: 'crossSellProducts',
            description:
              'Feature additional products to purchase with this one.',
            validate: {
              maxItems: 2,
            },
            component: 'group-list',
            itemProps: {
              label: '{{item.product.title}}',
            },
            fields: [
              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
            ],
          },
          {
            label: 'Default Open',
            name: 'defaultOpen',
            component: 'toggle',
            description: 'Sets accordion to be open by default',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: [
          {
            header: 'Sizing',
            body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            defaultOpen: false,
          },
          {
            header: 'Care',
            body: `* Lorem ipsum dolor sit amet\n* Consectetur adipiscing elit\n* Sed do eiusmod tempor`,
            defaultOpen: false,
          },
        ],
        defaultItem: {
          header: 'Details',
          body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          defaultOpen: false,
        },
      },
      {
        label: 'Accordion Header Background Color',
        name: 'headerBgColor',
        component: 'select',
        options: COLORS,
        defaultValue: 'var(--off-white)',
      },
      {
        label: 'Accordion Header Text Color',
        name: 'headerTextColor',
        component: 'select',
        options: COLORS,
        defaultValue: 'var(--text)',
      },
    ],
  };
}
