import { useState } from 'react';

export function ArticleTableOfContents(props) {
  const { titles, scrollToView, isInViewport } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="sticky top-16 z-10 text-left md:top-[83px] lg:hidden">
      <div>
        <button
          type="button"
          onClick={toggleDropdown}
          className="z-[10] flex w-full  items-center justify-between bg-[#fff] px-4 py-4 text-[13px] font-[400] uppercase focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          id="dropdown-menu"
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          Table of Contents
          <svg
            className={`-mr-1 ml-2 h-5 w-5 ${isOpen ? 'rotate-180' : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div
          className="absolute left-0 w-full origin-top-right divide-y bg-white shadow-lg"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="dropdown-menu"
        >
          <div className="py-1" role="none">
            {titles.map((item, index) => {
              return (
                <button
                  type="button"
                  id={`test-${item.handle}`}
                  className={`group w-full cursor-pointer border-[#DDE2E7] px-4 pb-4 text-left text-[16px] font-[400] text-shadowGray last:border-b-0 hover:text-[#202635] lg:border-b ${
                    isInViewport(`#${item.handle}`) ? '!text-[#202635]' : null
                  }`}
                  key={index}
                  onClick={() => scrollToView(item.handle)}
                >
                  <div
                    className={`group:first:opacity-0 h-[1px] w-full bg-gradient-to-r from-[#F1DCC1] via-[#8DCEF9] to-[#2179C2] opacity-20 hover:opacity-100 ${
                      isInViewport(`#${item.handle}`) ? '!opacity-100' : null
                    }`}
                  />
                  <span className="block pt-4">{item.title}</span>
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
