import { Schema } from './BlogFeaturedBanner.schema';
import { Headline } from '../../snippets/Typography';
import { Image, Button } from '../../snippets';

export function BlogFeaturedBanner({ cms }) {
  const { title, category, articleTime, image, cta } = cms;

  return (
    <div className="relative">
      <div className="relative h-[600px] w-full lg:h-[905px]">
        {image?.src ? (
          <Image
            fill
            alt={title}
            sizes="(min-width: 1024px) 40vw, 100vw"
            src={image?.src}
          />
        ) : null}
        <div className="absolute left-1/2 bottom-0 mx-auto min-h-[100px] w-11/12 -translate-x-1/2 bg-white px-8 py-6 text-center lg:w-[1025px] lg:px-28 lg:py-14">
          <p className="font-momo mb-6 text-sm uppercase">
            {category} •{' '}
            <span className="text-shadowGray">{articleTime} MIN READ</span>
          </p>
          <Headline variant="h3" element="h3" className="mb-8">
            {title}
          </Headline>
          {cta && (
            <div>
              <Button theme="secondary" variant="outlined" href={cta.url}>
                {cta.text}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

BlogFeaturedBanner.displayName = 'Blog Featured Banner';
BlogFeaturedBanner.Schema = Schema;
