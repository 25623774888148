import { useSettings } from '@backpackjs/storefront';
import { useEffect, useState } from 'react';

export function useSubscriptionWidget(product) {
  const settings = useSettings();

  const [productSubscriptionWidget, setProductSubscriptionWidget] =
    useState(null);

  useEffect(() => {
    if (!product || !settings) {
      return;
    }

    // Get all products with subscriptions set in settings
    const subscriptionProducts =
      settings?.subscriptions?.subscriptionProducts ?? [];

    // clone list of subscription products
    const subscriptionWidgetDetails = [...subscriptionProducts];

    // find the subscription product that matches the current product
    const widget =
      subscriptionWidgetDetails?.length &&
      subscriptionWidgetDetails?.find((productSubscription) => {
        const currentProductHandle = product?.handle;
        const subscriptionProductHandle = productSubscription?.product?.handle;
        return currentProductHandle === subscriptionProductHandle;
      });

    setProductSubscriptionWidget(widget);
  }, [settings, product]);

  return { productSubscriptionWidget };
}
