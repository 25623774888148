import React from 'react';
import { useCollection } from '@backpackjs/storefront';
import { Schema } from './CollectionHero.schema';
import { Image, Subheading, Body, Headline, Button } from '../../snippets';

export function CollectionHero({ cms }) {
  const {
    superTitle,
    title,
    content,
    mediaImage,
    mediaVideo,
    mediaPlacement,
    leftCTA,
    rightCTA,
  } = cms;
  const collection = useCollection();
  const collectionTitle = collection?.title;
  const collectionDescription = collection?.description;
  const hasMedia = mediaImage || mediaVideo;
  return (
    <div
      className={`${hasMedia ? 'md:flex' : ''} ${
        mediaPlacement && mediaPlacement === 'left' ? 'flex-row-reverse' : ''
      } relative overflow-hidden`}
    >
      <div
        className={`relative z-10 flex items-center p-[18px] md:px-[89px] md:pb-[110px] md:pt-[72px] ${
          hasMedia
            ? 'min-h-[276px] pb-6 pt-[127px] md:flex-[0_0_50%]'
            : 'min-h-[180px] text-left md:text-center'
        }`}
      >
        <div
          className={`absolute inset-0 bg-gradient-to-t md:bg-[#D9D9D9]  md:from-[#2179C2] md:via-[#8DCEF9] md:to-[#F1DCC1] md:opacity-20 ${
            hasMedia
              ? 'from-[#00000080] to-[#00000000]'
              : 'bg-[#D9D9D9] from-[#2179C2] via-[#8DCEF9] to-[#F1DCC1] text-center opacity-20'
          }`}
        />

        <div
          className={`relative mx-auto w-full max-w-[975px] md:text-text ${
            hasMedia ? 'text-white' : 'text-text'
          }`}
        >
          {superTitle && (
            <Subheading className="mb-2 uppercase">{superTitle}</Subheading>
          )}

          {(title || collectionTitle) && (
            <Headline className="mb-6 capitalize" variant="h3" element="h1">
              {title || collectionTitle}
            </Headline>
          )}

          {(content || collectionDescription) && (
            <Body variant="medium" className="mx-auto max-w-[765px]">
              {content || collectionDescription}
            </Body>
          )}

          {(leftCTA || rightCTA) && (
            <div
              className={`mt-8 flex gap-6 ${
                hasMedia ? 'justify-start' : 'justify-center'
              }`}
            >
              {leftCTA && (
                <Button href={leftCTA?.url} variant="filled" theme="primary">
                  {leftCTA?.text}
                </Button>
              )}

              {rightCTA && (
                <Button href={rightCTA?.url} variant="filled" theme="primary">
                  {rightCTA?.text}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      {hasMedia ? (
        <div className="absolute inset-0 z-0 md:relative md:flex-[0_0_50%]">
          <div className="relative aspect-[864/400] min-h-full max-w-full">
            {mediaImage?.src && !mediaVideo ? (
              <Image
                fill
                alt={title || collectionTitle}
                sizes="(min-width: 1024px) 40vw, 100vw"
                src={mediaImage?.src}
              />
            ) : null}

            {mediaVideo ? (
              <video
                src={mediaVideo}
                autoPlay
                muted
                loop
                playsInline
                className="h-full w-full object-cover"
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

CollectionHero.displayName = 'CollectionHero';
CollectionHero.Schema = Schema;
