export function Schema({ pageHandle }) {
  if (pageHandle !== 'whats-in-your-water') return null;
  return {
    label: 'WIYW Dashboard',
    key: 'WIYWDashboard',
    category: 'WIYW',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/wiyw-dashboard.png',
    fields: [
      {
        label: 'Background Image',
        name: 'backgroundImage',
        component: 'image',
      },
      {
        label: 'Background Video URL',
        name: 'backgroundVideoUrl',
        description:
          'Video will always be muted and autoplay with loop and override the background image if present.',
        component: 'text',
      },
    ],
  };
}
