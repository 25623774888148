import PropTypes from 'prop-types';
import React from 'react';

export default function Subheading(props) {
  const { variant, element, children, className } = props;

  const Element = element || 'p';

  const variants = {
    large:
      'tracking-[-1%] text-[1.25rem] leading-[1.625rem] lg:text-[1.375rem] lg:leading-[1.788rem]',
    medium:
      'tracking-[-1%] text-[1.125rem] leading-[1.463rem] lg:text-[1.25rem] lg:leading-[1.625rem]',
    small:
      'text-[1rem] leading-[1.4rem] lg:text-[1.125rem] lg:leading-[1.575rem]',
  };

  return (
    <Element
      className={`font-sans font-light ${variants[variant]} ${className}`}
    >
      {children}
    </Element>
  );
}

Subheading.propTypes = {
  variant: PropTypes.oneOf(['large', 'medium', 'small']),
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'p']),
  children: PropTypes.node.isRequired,
};
