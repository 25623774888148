import { useCallback, useEffect, useRef, useState } from 'react';

import { mapProductItemProduct, mapProductItemVariant } from './utils';

export function useDataLayerCollection({
  baseEcommerce,
  DEBUG,
  isPageAsCollection,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const collectionHandleRef = useRef(null);

  const [collectionProducts, setCollectionProducts] = useState(null);
  const [collectionId, setCollectionId] = useState(null);
  const [clickedCollectionItem, setClickedCollectionItem] = useState(null);
  const [clickedBundleItemVariants, setClickedBundleItemVariants] =
    useState(null);

  const viewCollectionEvent = useCallback(
    ({ ecommerce, products, userProperties: _userProperties }) => {
      if (!products?.length) return;
      const list = window.location.pathname.startsWith('/collections')
        ? window.location.pathname
        : '';
      const event = {
        event: 'dl_view_item_list',
        user_properties: _userProperties,
        ecommerce: {
          ...ecommerce,
          impressions: products.slice(0, 7).map(mapProductItemProduct(list)),
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  const clickCollectionItemEvent = useCallback(
    ({ ecommerce, variant }) => {
      if (!variant) return;
      const list = variant.list || '';
      const event = {
        event: 'dl_select_item',
        user_properties: userProperties,
        ecommerce: {
          ...ecommerce,
          click: {
            actionField: {
              list: window.location.pathname.startsWith('/collection')
                ? window.location.pathname
                : '',
              action: 'click',
            },
            products: [variant].map(mapProductItemVariant(list)),
          },
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    [isPageAsCollection, userProperties]
  );

  const clickBundleItemEvent = useCallback(
    ({ ecommerce, variants }) => {
      if (!variants?.length) return;
      const list = variants[0].list || '';
      const event = {
        event: 'dl_select_item',
        user_properties: userProperties,
        ecommerce: {
          ...ecommerce,
          click: {
            actionField: {
              list:
                window.location.pathname.startsWith('/collection') ||
                isPageAsCollection
                  ? window.location.pathname
                  : '',
              action: 'click',
            },
            products: variants.map(mapProductItemVariant(list)),
          },
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    [isPageAsCollection, userProperties]
  );

  // Subscribe to PubSub topic for 'dl_view_item_list' and 'dl_select_item' events
  useEffect(() => {
    const viewCollection = PubSub.subscribe(
      'VIEW_COLLECTION_PAGE',
      async (event, { products, id }) => {
        setCollectionProducts(products);
        setCollectionId(id);
      }
    );
    const clickCollectionItem = PubSub.subscribe(
      'CLICK_COLLECTION_ITEM',
      async (event, variant) => {
        setClickedCollectionItem(variant);
      }
    );
    const clickBundleItem = PubSub.subscribe(
      'CLICK_BUNDLE_ITEM',
      async (event, variants) => {
        setClickedBundleItemVariants(variants);
      }
    );
    return () => {
      if (viewCollection) {
        PubSub.unsubscribe(viewCollection);
      }
      if (clickCollectionItem) {
        PubSub.unsubscribe(clickCollectionItem);
      }
      if (clickBundleItem) {
        PubSub.unsubscribe(clickBundleItem);
      }
    };
  }, []);

  // Trigger 'dl_user_data' and view 'dl_view_item_list'
  // events on collection page and after base data is ready
  useEffect(() => {
    const pageHandle = window.location.pathname.startsWith('/pages')
      ? `/pages/${window.location.pathname.split('/').pop()}`
      : window.location.pathname.split('/').pop();

    if (
      !collectionProducts?.length ||
      !baseEcommerce ||
      !userProperties ||
      collectionHandleRef.current === pageHandle
    )
      return;
    userDataEvent({ ecommerce: baseEcommerce, userProperties });
    viewCollectionEvent({
      ecommerce: {
        ...baseEcommerce,
        collection_id: collectionId,
      },
      products: collectionProducts,
      userProperties,
    });
    collectionHandleRef.current = pageHandle;
  }, [collectionId, collectionProducts, !!baseEcommerce, !!userProperties]);

  // Trigger 'dl_select_item' event on clicked collection
  // item and after user event
  useEffect(() => {
    if (!clickedCollectionItem || !baseEcommerce || !userDataEventTriggered)
      return;
    clickCollectionItemEvent({
      ecommerce: baseEcommerce,
      variant: clickedCollectionItem,
    });
  }, [clickedCollectionItem, !!baseEcommerce, userDataEventTriggered]);

  // Trigger 'dl_select_item' event on clicked collection
  // item and after user event
  useEffect(() => {
    if (
      !clickedBundleItemVariants?.length ||
      !baseEcommerce ||
      !userDataEventTriggered
    )
      return;
    clickBundleItemEvent({
      ecommerce: baseEcommerce,
      variants: clickedBundleItemVariants,
    });
  }, [clickedBundleItemVariants, !!baseEcommerce, userDataEventTriggered]);
}
