import buttonLink from '../../utils/schemas/button-link';

const drawerSettings = {
  label: 'Drawer Settings',
  name: 'drawerSettings',
  component: 'group',
  description: 'Drawer title, video url, cta, etc.',
  fields: [
    {
      label: 'Eyebrow Text',
      name: 'eyebrowText',
      component: 'text',
    },
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Video URL',
      name: 'videoUrl',
      component: 'text',
    },
    {
      component: 'markdown',
      name: 'content',
      label: 'Content',
      description: 'Content to display under video',
    },
    {
      label: 'Call to Action Buttons',
      name: 'ctas',
      component: 'group-list',
      description: 'Max of two buttons',
      itemProps: {
        label: '{{item.link.text}}',
      },
      validate: {
        maxItems: 2,
      },
      fields: buttonLink,
    },
  ],
};

export function Schema() {
  return {
    label: 'Product 3 Column Drawer',
    key: 'ProductThreeColumnDrawers',
    category: 'Product',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/product-three-column-drawers.png',
    fields: [
      {
        label: 'Eyebrow Text',
        name: 'eyebrowText',
        component: 'text',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Columns',
        name: 'columns',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        validate: {
          maxItems: 3,
        },
        fields: [
          {
            name: 'image',
            label: 'Image',
            component: 'image',
          },
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'subHeading',
            label: 'Subheading',
            component: 'text',
          },
          {
            name: 'cta',
            label: 'CTA',
            component: 'text',
          },
          drawerSettings,
        ],
      },
    ],
  };
}
