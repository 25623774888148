import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { BlogHeroSearchLink } from './BlogHeroSearchLink';
import { Svg } from '../../snippets';

export function BlogHeroSearch({ categories }) {
  const router = useRouter();
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const searchRef = useRef();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.target.value.length) {
      const search = {
        searchType: 'blog-articles',
        value: event.target.value,
      };

      router.push(
        {
          pathname: `/pages/search?term=${event.target.value}&searchType=blog-articles`,
          query: search,
        },
        `/pages/search?term=${event.target.value}&searchType=blog-articles`
      );
    }
  };

  const handleSearch = () => {
    const search = {
      searchType: 'blog-articles',
      value: searchRef.current.value,
    };

    router.push(
      {
        pathname: `/pages/search?term=${searchRef.current.value}&searchType=blog-articles`,
        query: search,
      },
      `/pages/search?term=${searchRef.current.value}&searchType=blog-articles`
    );
  };

  return (
    categories && (
      <div className="relative col-span-12 flex w-full flex-col-reverse px-4 py-3 lg:col-span-12 lg:flex lg:rounded-full lg:border lg:border-transparent lg:bg-[#4D515D] lg:py-4 lg:pr-48">
        <div className="flex-no-wrap no-scrollbar flex flex-row overflow-auto">
          {categories.map((cta, index) => {
            if ('link' in cta) {
              return <BlogHeroSearchLink item={cta.link} key={index} />;
            }

            return null;
          })}
        </div>

        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div
          className="absolute right-8 top-1/2 hidden -translate-y-1/2 cursor-pointer lg:flex"
          onClick={() => setSearchBarOpen(!searchBarOpen)}
        >
          <span className="mr-4 font-[400] lg:text-[20px]">Search</span>
          <Svg
            className="w-5 cursor-pointer text-text"
            src="/svgs/search-white.svg#search-white"
            title="Seach"
            viewBox="0 0 15 15"
          />
        </div>
        <div
          className={`mb-8 flex -translate-y-1/2 justify-between gap-3 overflow-hidden rounded-full bg-[#4D515D] pl-3 pr-4 lg:absolute lg:top-1/2 lg:mb-0 lg:w-full lg:max-w-[calc(100%-2rem)] lg:border lg:border-[#4D515D] lg:border-border lg:border-transparent ${
            searchBarOpen ? 'lg:flex' : 'lg:hidden'
          }`}
        >
          <input
            ref={searchRef}
            aria-label="Search here"
            className="min-w-0 flex-1 bg-transparent px-2 py-3 text-base tracking-[-0.01em] text-white outline-none placeholder:text-[#fff] lg:text-[20px]"
            placeholder="Search here"
            onKeyDown={(event) => handleKeyDown(event)}
          />
          <Svg
            className="hidden w-5 cursor-pointer text-text lg:block"
            src="/svgs/close-white.svg#close-white"
            title="Close"
            viewBox="0 0 24 24"
            onClick={() => setSearchBarOpen(!searchBarOpen)}
          />
          <Svg
            className="mr-2 w-5 cursor-pointer text-text lg:hidden"
            src="/svgs/search-white.svg#search-white"
            title="Seach"
            viewBox="0 0 15 15"
            onClick={handleSearch}
          />
        </div>
      </div>
    )
  );
}

BlogHeroSearch.displayName = 'BlogHeroSearch';
