/* eslint-disable react/no-unstable-nested-components */
import { Markdown } from '../../snippets';
import { Headline } from '../../snippets/Typography';
import handleize from '../../utils/handleize';
import { Schema } from './ArticleContent.schema';

export function ArticleContent({ cms }) {
  const { title, anchorId, content } = cms;

  return (
    <div className="article-content relative mb-8 font-light lg:mb-16">
      {anchorId && <div id={handleize(anchorId)} className="absolute top-16" />}
      {title && (
        <Headline
          variant="h3"
          element="h2"
          className="border-t border-[#DDE2E7] pb-4 pt-12 text-primary lg:pb-16"
        >
          {title}
        </Headline>
      )}

      <Markdown
        className="text-primary"
        components={{
          h1(data) {
            return (
              <Headline variant="h1" element="h1">
                {data.children}
              </Headline>
            );
          },
          h2(data) {
            return (
              <Headline variant="h3" element="h2">
                {data.children}
              </Headline>
            );
          },
          h3(data) {
            return (
              <Headline variant="h4" element="h3">
                {data.children}
              </Headline>
            );
          },
          h4(data) {
            return (
              <Headline variant="h5" element="h4">
                {data.children}
              </Headline>
            );
          },
          h5(data) {
            return (
              <Headline variant="h6" element="h5">
                {data.children}
              </Headline>
            );
          },
        }}
      >
        {content}
      </Markdown>
    </div>
  );
}

ArticleContent.displayName = '/ArticleContent';
ArticleContent.Schema = Schema;
