import { useSettings } from '@backpackjs/storefront';

import { Fragment } from 'react';
import { Link } from '../Link';
import { Svg } from '../Svg';
import { useGlobalContext } from '../../contexts';
import { Image } from '../Image';

import { useCartCount } from '../../hooks';

export function Menu({
  handleOpenSidebar,
  handleMenuDrawerClose,
  handleMenuHoverIn,
  handleMenuHoverOut,
  menuDrawerContent,
  transparentMode,
  transparentModeTextColor,
}) {
  const {
    actions: { openCart, openSearch, openAccount },
  } = useGlobalContext();
  const settings = useSettings();
  const { menuItems } = { ...settings?.header?.menu };
  const { logo } = { ...settings?.general };
  const cartCount = useCartCount();

  let imageColor = {};
  if (transparentMode) {
    if (transparentModeTextColor === 'light') {
      imageColor = {
        filter: 'brightness(0) invert(1)',
      };
    } else if (transparentModeTextColor === 'dark') {
      imageColor = {
        filter: 'brightness(0) invert(0)',
      };
    }
  }

  // Only allow the first item to display the Logo before if enabled.
  let usedLogo = false;
  const normalizedMenuItems = menuItems?.map((item) => {
    const normalizedItem = { ...item };
    if (normalizedItem?.logoBefore && !usedLogo) {
      usedLogo = true;
    } else {
      normalizedItem.logoBefore = false;
    }
    return normalizedItem;
  });

  return (
    <div className="px-contained relative z-[1] flex h-[60px] items-center justify-between transition md:h-[var(--header-height)]">
      <div className="order-1 flex flex-[1_1_100%] items-center lg:order-2">
        <nav className="order-1 h-full w-full">
          <ul className="flex w-full">
            {normalizedMenuItems?.map((item, index) => {
              const isHovered =
                item.menuItem?.text === menuDrawerContent?.menuItem?.text;

              return (
                <Fragment key={index}>
                  {item?.logoBefore ? (
                    <li className="flex flex-[1_1_100%] justify-center">
                      <Link
                        aria-label="Go to homepage"
                        className="w-[140px] py-1 md:w-[200px]"
                        href="/"
                      >
                        <Image
                          style={imageColor}
                          priority
                          height="82"
                          width="400"
                          className="h-auto w-full"
                          alt="Clearly Filtered"
                          sizes="(min-width: 1440px) 900px, (min-width: 768px) 50vw, 100vw"
                          src={
                            logo?.src ||
                            'https://cdn.shopify.com/s/files/1/1011/0318/files/Primary_Logo_Horizontal_c3aceaee-7ecc-4fb8-90a6-98df0ee6f7c8.png?v=1690837284'
                          }
                          quality="90"
                        />
                      </Link>
                    </li>
                  ) : null}

                  <li className="hidden lg:flex lg:flex-[1_0_auto]">
                    <Link
                      aria-label={item.menuItem?.text}
                      className={`group relative flex items-center px-4 text-[17px] ${
                        isHovered ? 'text-[#66A2D4]' : ''
                      }`}
                      href={item.menuItem?.url}
                      onClick={handleMenuDrawerClose}
                      onMouseEnter={() => handleMenuHoverIn(index)}
                      onMouseLeave={handleMenuHoverOut}
                    >
                      {item.menuItem?.text}
                    </Link>
                  </li>
                </Fragment>
              );
            })}
          </ul>
        </nav>
        <div className="order-0 flex flex-[0_0_50px] items-center gap-4 md:flex-[0_0_150px] lg:flex-auto">
          <button
            aria-label="Open menu"
            className="w-5 lg:hidden"
            onClick={handleOpenSidebar}
            type="button"
          >
            <Svg
              className="w-full"
              src="/svgs/menu.svg#menu"
              title="Menu"
              viewBox="0 0 24 24"
            />
          </button>

          <button
            aria-label="Open search"
            className="block w-5 md:hidden"
            onClick={openSearch}
            type="button"
          >
            <Svg
              className="w-full"
              src="/svgs/search.svg#search"
              title="Search"
              viewBox="0 0 24 24"
            />
          </button>
        </div>
      </div>

      <div className="order-3 flex flex-[0_0_50px] items-center justify-end gap-4 md:flex-[0_0_150px] md:gap-5">
        <button
          aria-label="Open search"
          className="hidden w-5 md:block"
          onClick={openSearch}
          type="button"
        >
          <Svg
            className="w-full"
            src="/svgs/search.svg#search"
            title="Search"
            viewBox="0 0 24 24"
          />
        </button>

        <button
          aria-label="Open Account"
          className="w-[17px]"
          onClick={openAccount}
          type="button"
        >
          <Svg
            className="w-full"
            src="/svgs/account.svg#account"
            title="Account"
            viewBox="0 0 17 17"
          />
        </button>

        <div className="relative flex items-center">
          <button
            aria-label="Open cart"
            className="relative w-[21px]"
            onClick={openCart}
            type="button"
          >
            <Svg src="/svgs/cart.svg#cart" title="Cart" viewBox="0 0 21 19" />
            {cartCount > 0 ? (
              <div className="absolute right-[-2px] top-[-4px] h-[16px] w-[16px] rounded-full bg-secondary text-xs text-white">
                {cartCount < 99 ? cartCount : ''}
              </div>
            ) : (
              ''
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

Menu.displayName = 'Menu';
