import { useProductByHandle } from '@backpackjs/storefront';
import { Image, Link } from '..';
import { useVariantPrices } from '../../hooks';

export function CrossSellProduct({ product: crossSellProduct }) {
  // const product = crossSellProduct?.product;
  const handle = crossSellProduct?.product?.handle;
  const { product } = useProductByHandle({ handle });

  const { price } = useVariantPrices({
    variant: product?.variants?.[0],
  });

  return (
    <div>
      <Link aria-label={product?.title} href={`/products/${product?.handle}/`}>
        {product?.featuredImage?.src && (
          <div className="relative aspect-square bg-[#E6F0F8]">
            <Image
              className="!object-contain mix-blend-multiply"
              alt={product?.title}
              fill
              sizes="(min-width: 768px) 33vw, 50vw"
              src={product.featuredImage.src}
            />
          </div>
        )}

        <div className="mt-4 text-sm font-light">
          <h4 className="font-light">{product?.title}</h4>
          From {price}
        </div>
      </Link>
    </div>
  );
}
