import { useProduct } from '@backpackjs/storefront';
import { useCallback, useEffect } from 'react';
import { useGlobalProductPageContext } from '../../contexts/globalProductPageContext';
import { AddToCart } from '../../snippets';
import { BeamProductDetailsPage } from '../../snippets/Beam';
import { FrequencyCalculatorCTA } from '../../snippets/FrequencyCalculator';
import { ProductGroupItem } from '../../snippets/ProductGroupItem';
import { RechargeSubscriptionWidget } from '../../snippets/RechargeSubscriptionWidget/RechargeSubscriptionWidget';
import { ProductDetailAccordion } from './ProductDetailAccordion';
import { Schema } from './ProductDetailAccordions.schema';

export function ProductDetailAccordions({ cms }) {
  const {
    accordions,
    headerBgColor,
    headerTextColor,
    frequencyCalculator,
    linkedProductGroups,
    disclaimer,
    disclaimerColor,
    viewTestResults,
    productByLine,
    disclaimerUrl,
    disclaimerOpenNewTab,
    outOfStockMessage,
  } = cms;
  const product = useProduct();

  const {
    selectedSellingPlan,
    purchaseType,
    setRecommendedFilterFrequency,
    recommendedProducts,
    selectedVariant,
    setRecommendedProducts,
    scrollReference,
    productSubscriptionWidget,
    setProductTestResults,
    setProductByline,
  } = useGlobalProductPageContext();

  useEffect(() => {
    if (productByLine && setProductByline) {
      setProductByline(productByLine);
    }
  }, [productByLine, setProductByline]);

  // set the default recommended frequency if it exists
  const defaultRecommendedFrequency =
    productSubscriptionWidget?.defaultRecommendedFrequency;
  useEffect(() => {
    if (defaultRecommendedFrequency) {
      setRecommendedFilterFrequency(defaultRecommendedFrequency);
    }
  }, [defaultRecommendedFrequency]);

  const fetchRebuyRecommendations = useCallback(async () => {
    try {
      const response = await fetch('/api/rebuy', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          productID: product?.legacyResourceId,
        }),
      });
      const data = await response.json();
      setRecommendedProducts(data);
      if (!data) {
        throw new Error('No data returned from Out of Stock Notifications API');
      }
    } catch (error) {
      console.error(`Out of Stock Notifications Error: ${error.message}`);
      throw error;
    }
  }, [product]);

  useEffect(() => {
    fetchRebuyRecommendations();
  }, [product]);

  useEffect(() => {
    if (viewTestResults && setProductTestResults) {
      setProductTestResults(viewTestResults);
    }
  }, [viewTestResults]);

  return (
    <>
      {linkedProductGroups && linkedProductGroups?.length > 0 && (
        <div className="linked-product-groups">
          {linkedProductGroups.map((group, groupIndex) => {
            return (
              <div
                className="mb-[0.4rem] border-b border-b-[#DDE2E7] pb-3 md:mb-6 md:pb-6 "
                key={`linked-product-group-${groupIndex}`}
              >
                {group?.groupName && (
                  <h3 className="mt-0 text-[11px] font-normal uppercase leading-6 md:text-base">
                    {group?.groupName}
                  </h3>
                )}

                {group?.linkedProducts && group?.linkedProducts?.length && (
                  <div className="overflow-x-auto md:overflow-x-visible md:pt-4">
                    <ul className="flex gap-2 md:flex-wrap">
                      {group?.linkedProducts?.map(
                        (linkedProduct, linkedProductIndex) => {
                          return (
                            <ProductGroupItem
                              product={linkedProduct}
                              key={`linked-product-${linkedProductIndex}`}
                            />
                          );
                        }
                      )}
                    </ul>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {productSubscriptionWidget && (
        <RechargeSubscriptionWidget widgetContent={productSubscriptionWidget} />
      )}

      <AddToCart
        oosDisclaimer={outOfStockMessage?.disclaimer}
        oosDisclaimerColor={outOfStockMessage?.disclaimerColor}
        oosDisclaimerUrl={outOfStockMessage?.disclaimerUrl}
        oosDisclaimerOpenNewTab={outOfStockMessage?.disclaimerOpenNewTab}
        disclaimer={disclaimer}
        disclaimerColor={disclaimerColor}
        disclaimerUrl={disclaimerUrl}
        disclaimerOpenNewTab={disclaimerOpenNewTab}
        isPdp
        showPrice
        purchaseType={purchaseType}
        selectedSellingPlan={selectedSellingPlan}
        selectedVariant={selectedVariant}
        product={product}
      />

      <div ref={scrollReference} />

      {frequencyCalculator && Object.keys(frequencyCalculator).length > 0 && (
        <FrequencyCalculatorCTA
          frequencyCalculatorSettings={frequencyCalculator}
        />
      )}
      <BeamProductDetailsPage />
      {
        /* Accordions */
        accordions?.length ? (
          <ul className="pdp-accordion mt-[40px] grid grid-cols-1 p-0">
            {accordions.map((accordion, index) => {
              return (
                <li className="pdp-accordion-item" key={index}>
                  <ProductDetailAccordion
                    recommendedProducts={recommendedProducts}
                    accordion={accordion}
                    headerBgColor={headerBgColor}
                    headerTextColor={headerTextColor}
                  />
                </li>
              );
            })}
          </ul>
        ) : null
      }
    </>
  );
}

ProductDetailAccordions.displayName = 'ProductDetailAccordions';
ProductDetailAccordions.Schema = Schema;
