export default {
  label: 'Blog',
  name: 'blog',
  component: 'group',
  description: 'Global blog settings',
  fields: [
    {
      label: 'Blog Categories',
      name: 'categories',
      description: `Categories to display in the blog hero navigation.`,
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
      ],
      defaultItem: {
        link: { text: 'New Category', url: '' },
      },
      defaultValue: [
        { link: { text: 'All', url: '/blogs/all' } },
        { link: { text: 'Contaminants', url: '/blogs/contaminants' } },
        { link: { text: 'Health & Safety', url: '/blogs/health-safety' } },
        { link: { text: 'News', url: '/blogs/news' } },
        { link: { text: 'Us vs Them', url: '/blogs/us-vs-them' } },
        { link: { text: 'Water 101', url: '/blogs/water-101' } },
      ],
    },
  ],
};
