import { useMemo } from 'react';

export function useProductItemMedia({ product, variant }) {
  const mediaMapByHandle = useMemo(() => {
    if (
      !product?.grouping?.isTransformed ||
      !(product?.grouping?.optionsMap?.Color?.length > 1)
    )
      return null;
    return product.grouping.media.reduce((acc, item) => {
      return {
        ...acc,
        [item.product.handle]: [...(acc[item.product.handle] || []), item],
      };
    }, {});
  }, [product?.id]);

  return useMemo(() => {
    if (!product || !variant) {
      return {
        aspectRatio: 'var(--product-image-aspect-ratio)',
        primary: {
          media: null,
          isVideo: false,
        },
        hover: {
          media: null,
          isVideo: false,
        },
      };
    }

    const hasGroupColorVariants =
      product?.grouping?.isTransformed &&
      product.grouping.optionsMap.Color?.length > 1;
    const hasNonGroupColorVariants =
      !product?.grouping?.isTransformed &&
      product?.optionsMap.Color?.length > 1;

    const medias = hasGroupColorVariants
      ? mediaMapByHandle[variant.product.handle]
      : product.media;
    let firstMediaIndex = 0;
    let secondMediaIndex = 1;

    // if multiple color variants from same product, use first media w/ color as alt
    if (hasNonGroupColorVariants) {
      const colorOptions = product.optionsMap.Color?.map((color) =>
        color.toLowerCase()
      );
      const selectedColor = variant.selectedOptionsMap.Color?.toLowerCase();
      firstMediaIndex =
        medias.findIndex((item) => {
          const alt = item.alt?.trim().toLowerCase();
          return alt === selectedColor || !colorOptions?.includes(alt);
        }) || 0;
      const secondMediaAlt = medias[firstMediaIndex + 1]?.alt
        ?.trim()
        .toLowerCase();
      secondMediaIndex =
        secondMediaAlt === selectedColor ||
        !colorOptions?.includes(secondMediaAlt)
          ? firstMediaIndex + 1
          : -1;
    }

    const primaryMedia = medias?.[firstMediaIndex];
    const primaryMediaImage = primaryMedia?.preview?.image;
    const secondMedia = medias?.[secondMediaIndex];
    const hoverMedia =
      secondMediaIndex >= 1 &&
      secondMedia?.product?.handle === variant.product.handle
        ? secondMedia
        : null;

    return {
      aspectRatio: primaryMediaImage
        ? primaryMediaImage.width / primaryMediaImage.height
        : 'var(--product-image-aspect-ratio)',
      primary: {
        media: primaryMedia,
        isVideo: primaryMedia?.mediaContentType === 'VIDEO',
      },
      hover: {
        media: hoverMedia,
        isVideo: hoverMedia?.mediaContentType === 'VIDEO',
      },
    };
  }, [mediaMapByHandle, product?.id, variant?.id]);
}
