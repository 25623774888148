import { useCallback, useMemo } from 'react';
import { useCustomerLogin, useSettings } from '@backpackjs/storefront';
import { useForm } from 'react-hook-form';
import { Button } from '../Button';
import { Spinner } from '../Spinner';
import { Headline, Body } from '../Typography';
import { Svg } from '../Svg';
import PasswordlessLogin from './passwordless-login';
import { useDataLayerActions } from '../../hooks';

export function Login({ setActiveForm }) {
  const { sendLogInEvent } = useDataLayerActions();
  const settings = useSettings();
  const {
    flyoutHeading,
    subheading,
    forgotText,
    createText,
    createLinkText,
    unidentifiedCustomerText,
  } = {
    ...settings?.account?.login,
  };

  const regex = /_([^_]+)_/g;
  const heading = flyoutHeading?.replace(
    regex,
    '<span class="text-[#202635] opacity-50">$1</span>'
  );

  const {
    customerLogin,
    started,
    finished,
    errors: loginErrors,
    success,
    reset,
    resetTimer,
  } = useCustomerLogin();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const buttonText = useMemo(() => {
    if (finished) {
      resetTimer.current = setTimeout(() => {
        if (reset) reset();
        clearTimeout(resetTimer.current);
      }, 3000);
      return success ? 'Logged In' : 'Failed';
    }
    return started ? 'Logging In' : 'Log In';
  }, [started, success, finished]);

  const handleLogin = useCallback(
    async (data) => {
      if (started) return;
      const { email, password } = data;

      await customerLogin({
        email,
        password,
      });

      sendLogInEvent();
    },
    [started]
  );

  return (
    <div className="flex flex-col  overflow-y-auto rounded px-4 pb-40 pt-3 md:px-8">
      <Headline variant="h5" className="lg:max-w-[350px]">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: heading }} />
      </Headline>

      <p className="mt-2 text-left text-sm font-light">{subheading}</p>

      <div className="my-12">
        <PasswordlessLogin />
      </div>

      <div className="mb-4 flex items-center">
        <hr className="grow border-[var(--text)]" />
        <Body variant="medium" className="px-4 text-center">
          or use your password
        </Body>
        <hr className="grow border-[var(--text)]" />
      </div>

      <form
        className="mx-auto flex w-full flex-col gap-4"
        onSubmit={handleSubmit(handleLogin)}
      >
        <label
          className="relative flex w-full flex-col-reverse  gap-x-1 gap-y-1.5 justify-self-stretch"
          htmlFor="email"
        >
          <input
            className={`text-md w-full !min-w-0 border border-[#DDE2E7] px-3 py-[19px] font-light !outline-0 ${
              errors.email ? '!border-[#ED7C5C] !text-[#ED7C5C]' : null
            }`}
            name="email"
            placeholder="email"
            type="text"
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.email && (
            <span className="absolute right-4 top-1/2  -translate-y-1/2 transform">
              <Svg
                className="w-5 text-text"
                src="/svgs/error.svg#error"
                title="Error icon"
                viewBox="0 0 16 16"
              />
            </span>
          )}
        </label>
        {errors.email && (
          <p className="text-[14px] text-[#ED7C5C]">
            *Please enter a valid email address
          </p>
        )}

        <label
          className="relative flex w-full flex-col-reverse  gap-x-1 gap-y-1.5 justify-self-stretch"
          htmlFor="password"
        >
          <input
            className={`text-md w-full !min-w-0 border border-[#DDE2E7] px-3 py-[19px] font-light !outline-0 ${
              errors.password ? '!border-[#ED7C5C] !text-[#ED7C5C]' : null
            }`}
            name="password"
            placeholder="••••••••"
            type="password"
            {...register('password', { required: true })}
          />
          {errors.password && (
            <span className="absolute right-4 top-1/2  -translate-y-1/2 transform">
              <Svg
                className="w-5 text-text"
                src="/svgs/error.svg#error"
                title="Error icon"
                viewBox="0 0 16 16"
              />
            </span>
          )}
        </label>
        {errors.password && (
          <p className="text-[14px] text-[#ED7C5C]">*Please enter a password</p>
        )}

        <Button
          theme="primary"
          variant="filled"
          type="submit"
          className="mx-auto mt-12 flex w-full max-w-[210px] items-center justify-center"
        >
          {started ? <Spinner /> : buttonText}
        </Button>
      </form>

      <button
        aria-label="Forgot Password?"
        className="mt-6 text-center text-sm font-light tracking-[-.01em] underline"
        onClick={() => setActiveForm('password')}
        type="button"
      >
        {forgotText}
      </button>

      <button
        aria-label="Sign Up"
        className="mt-6 text-center text-sm font-light tracking-[-.01em]"
        onClick={() => setActiveForm('create')}
        type="button"
      >
        {createText}{' '}
        <span className="text-underline font-base">{createLinkText}</span>
      </button>

      {loginErrors?.length > 0 && (
        <ul className="mt-4 flex flex-col items-center gap-1">
          {loginErrors.map((error, index) => {
            return (
              <li
                key={index}
                className="text-center text-[14px] text-[#ED7C5C]"
              >
                {error === 'Unidentified customer'
                  ? unidentifiedCustomerText
                  : error}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

Login.displayName = 'LoginForm';
