export const Schema = () => ({
  category: 'Article',
  label: 'Article Video',
  key: 'articleVideo',
  //   previewSrc:
  //     'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-24_20at_202.55.23_20PM.png?v=1684965343',
  fields: [
    {
      name: 'title',
      component: 'text',
      label: 'Title',
    },
    {
      name: 'anchorId',
      component: 'text',
      label: 'TOC Anchor Title',
      description: 'Leave Blank to hide from Table of Contents',
    },
    {
      name: 'video',
      component: 'text',
      label: 'Video ID',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
    },
  ],
});
