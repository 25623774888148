import { useProductsFromHandles } from '@backpackjs/storefront';

import { Collection } from '../../snippets';

export function SearchEmptyResults({ collectionProducts, term }) {
  const { products } = useProductsFromHandles({
    handles: collectionProducts?.map((item) => item.handle),
  });

  return (
    <div className="px-contained mb-12">
      <h4 className="mb-8 text-[26px] font-light tracking-[-.01em] text-primary md:text-4xl">
        Uh-oh. We couldn’t find anything for <br />“{term}”
      </h4>
      <p className="mb-6 text-[14px] text-primary md:text-lg ">
        Try a different search term or take a peek at some of these <br />
        popular products & collections we think you might like!
      </p>
      {products && (
        <Collection handle="search" products={products} showHeading={false} />
      )}
    </div>
  );
}

SearchEmptyResults.displayName = 'SearchEmptyResults';
