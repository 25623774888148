import { COLORS } from '../../settings/common';

export function Schema() {
  return {
    category: 'Text',
    label: 'Value Props',
    key: 'value-props',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2010.47.21_20AM.png?v=1684864574',
    fields: [
      {
        label: 'Section Title',
        name: 'sectionTitle',
        component: 'text',
      },
      {
        label: 'Value Prop',
        name: 'valueProp',
        component: 'group-list',
        itemProps: {
          label: '{{item.header}}',
        },
        fields: [
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Header',
            name: 'header',
            component: 'text',
          },
          {
            label: 'Copy',
            name: 'copy',
            component: 'text',
          },
        ],
        defaultValue: [
          {
            header: 'Save 10% on Every Replacement Filter',
            copy: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.',
          },
          {
            header: 'Get Free Shipping (in the USA)',
            copy: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.',
          },
          {
            header: 'Customize Your Schedule',
            copy: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.',
          },
          {
            header: 'Cancel Anytime',
            copy: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.',
          },
        ],
        defaultItem: {
          header: 'Save 10% on Every Replacement Filter',
          copy: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.',
        },
      },
    ],
  };
}
