import { useEffect, useRef } from 'react';

export function OkendoStarRating({ productId, className }) {
  const widgetContainer = useRef(null);

  const initialiseWidget = () =>
    window.okeWidgetApi.initWidget(widgetContainer.current);

  useEffect(() => {
    if (window.okeWidgetApi?.initWidget) {
      initialiseWidget();

      // manually set product id if the script is already loaded
      window.okeWidgetApi.setProduct(
        widgetContainer.current,
        `shopify-${productId}`
      );
    } else {
      document.addEventListener('oke-script-loaded', initialiseWidget);
    }

    return () => {
      document.removeEventListener('oke-script-loaded', initialiseWidget);
    };
  }, [productId]);

  return (
    <div
      className={className}
      ref={widgetContainer}
      data-oke-star-rating
      data-oke-reviews-product-id={`shopify-${productId}`}
    />
  );
}
