import { useMemo } from 'react';
import { useCartItems as useBPCartItems } from '@backpackjs/storefront';

export const useCartItems = () => {
  const cartItems = useBPCartItems();

  return useMemo(() => {
    return cartItems?.filter(
      (item) => item.variant.product.handle !== 'routeins'
    );
  }, [cartItems]);
};
