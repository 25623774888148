/* eslint-disable no-use-before-define */
import { chunk } from 'lodash';
import { useMemo } from 'react';
import { Button, Image } from '../../snippets';
import Carousel from '../../snippets/Carousel/Carousel';
import Headline from '../../snippets/Typography/Headline';
import Subheading from '../../snippets/Typography/Subheading';
import useMediaQuery from '../../utils/useMediaQuery';
import Schema from './ImageActionsCarousel.schema';

export default function ImageActionsCarousel({ cms }) {
  const { title, actionItems, darkMode, mobileCta } = cms;
  const isMobile = useMediaQuery('(max-width: 767px)');
  const remappedActionItemsDesktop = useMemo(
    () => chunk(actionItems, 3),
    [actionItems]
  );
  return (
    <div className={`${darkMode ? 'dark' : ''} overflow-hidden text-primary`}>
      <section className="py-10 dark:bg-[#202635] dark:text-[#ffffff] [&_.carousel-slider]:overflow-visible [&_.slider-wrapper]:overflow-visible">
        <div className="px-contained mx-auto max-w-[var(--content-max-width)]">
          <div className="relative z-10 mb-10">
            <Headline variant="h3">{title}</Headline>
          </div>
          <div className="hidden lg:block [&_.control-dots]:!bottom-[-20px] [&_.control-dots]:!ml-0 [&_.control-dots]:!w-full [&_.control-dots]:!max-w-full">
            <Carousel
              className="!px-0"
              topArrows
              itemsPerPageMultiplier={3}
              overwriteTotalItems={actionItems?.length}
            >
              {remappedActionItemsDesktop?.map((actionsChunk, index) => (
                <div className="grid grid-cols-3" key={index}>
                  {actionsChunk.map((item, _index) => (
                    <ImageAction key={_index} item={item} />
                  ))}
                </div>
              ))}
            </Carousel>
          </div>
          <div className="image-actions-carousel-mobile flex overflow-x-auto overflow-y-hidden pb-10 lg:hidden">
            {actionItems?.map((item, index) => (
              <div className="w-full min-w-[300px]" key={index}>
                <ImageAction item={item} />
              </div>
            ))}
          </div>
        </div>

        {isMobile && mobileCta?.url && (
          <div className="px-contained mx-auto mt-10 max-w-[var(--content-max-width)]">
            <div className="flex justify-center">
              <Button href={mobileCta?.url} theme="light" variant="outlined">
                {mobileCta?.text}
              </Button>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

function ImageAction({ item }) {
  return (
    <a href={item.link?.url}>
      <div className="bg-gray-300 mb-2 mr-4 aspect-square flex-shrink-0">
        {item.image?.src && (
          <Image
            className="h-full w-full object-cover"
            src={item.image.src}
            alt={item.image.alt ?? item.title}
            width={500}
            height={500}
          />
        )}
      </div>
      <Subheading variant="large">{item.title}</Subheading>
    </a>
  );
}

ImageActionsCarousel.Schema = Schema;
