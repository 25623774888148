import { useMemo } from 'react';

import { Image } from '../../Image';
import { Svg } from '../../Svg';

export function CollectionFilterOption({
  activeFilters,
  addFilter,
  count,
  isColor,
  name,
  removeFilter,
  showCount,
  swatchesMap,
  value,
}) {
  const isActive = useMemo(() => {
    return Object.entries(activeFilters).some(([key, values]) => {
      return key === name && values.some((v) => v === value);
    });
  }, [activeFilters, value]);

  let swatch = null;
  let hasImage = false;

  if (isColor) {
    swatch = swatchesMap[value.toLowerCase().trim()];
    hasImage = swatch?.startsWith('http');
  }

  const colorBackground =
    isActive && swatch === '#FFFFFF'
      ? 'var(--off-white)'
      : swatch || 'var(--off-white)';
  const nonColorBackground = isActive ? 'var(--black)' : 'var(--off-white)';

  return (
    <button
      aria-label={`Add ${value} to filters`}
      className={`flex gap-4 text-left text-base max-md:h-12 max-md:w-full max-md:items-center max-md:px-4 md:gap-2 ${
        isActive ? 'text-text max-md:font-bold' : 'text-primary'
      }`}
      onClick={() => {
        if (isActive) {
          removeFilter({ key: name, value });
        } else {
          addFilter({ key: name, value });
        }
      }}
      type="button"
    >
      <div
        className={`relative flex h-6 w-6 items-center justify-center overflow-hidden border transition md:mt-[0.125rem] md:h-5 md:w-5 md:hover:border-text ${
          isActive ? 'border-text' : ''
        }`}
        style={{
          backgroundColor: isColor ? colorBackground : nonColorBackground,
        }}
      >
        {hasImage && (
          <Image
            alt={value}
            className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
            height="24"
            width="24"
            src={swatch}
          />
        )}

        <div
          className={`absolute left-1/2 top-1/2 flex h-full w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center transition-[border-width] duration-100 ${
            isActive
              ? 'border-[2.5px] bg-primary md:border-[2px]'
              : 'border-[0px] bg-white '
          }`}
        >
          {isActive ? (
            <Svg
              className="absolute h-full w-full object-contain text-white"
              src="/svgs/checkmark.svg#checkmark"
              title="Check"
              viewBox="0 0 24 24"
            />
          ) : null}
        </div>
      </div>

      <p className="flex-1">{value}</p>
    </button>
  );
}

CollectionFilterOption.displayName = 'CollectionFilterOption';
