import { useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { BeamSelectNonprofit } from '../Beam';
import { CartEmpty } from './CartEmpty';
import { CartItem } from './CartItem';
import { CartTotals } from './CartTotals';
import { CartHeader } from './CartHeader';
import { CartDiscount } from './CartDiscount';
import { FreeShippingMeter } from './FreeShippingMeter';
import { useGlobalContext } from '../../contexts';
import { useCartItems } from '../../hooks';

export function CartPage() {
  const settings = useSettings();
  const cartItems = useCartItems();
  const { asPath } = useRouter();
  const beamSettings = settings?.beam;

  const isCartPage = () => {
    return asPath === '/cart';
  };

  const {
    actions: { closeCart },
  } = useGlobalContext();

  return (
    <div className="md-px-contained py-contained bg-[#fff] [&_.cart-header-heading]:normal-case md:[&_.cart-header-heading]:text-[36px] md:[&_.cart-header-heading]:font-light">
      <div className="mx-auto lg:max-w-[710px]">
        <CartHeader
          closeCart={closeCart}
          heading={settings?.cart?.heading}
          isCartPage={isCartPage()}
        />

        <FreeShippingMeter />

        <ul className="relative flex-1 overflow-y-auto scrollbar-hide">
          {cartItems?.length ? (
            cartItems.map((item) => {
              return (
                <li key={item.id} className="">
                  <CartItem item={item} closeCart={closeCart} />
                </li>
              );
            })
          ) : (
            <CartEmpty closeCart={closeCart} />
          )}
        </ul>

        {!!beamSettings && !!cartItems?.length && (
          <div className="px-4 py-6">
            <BeamSelectNonprofit />
          </div>
        )}

        <div className="[&_.discount-code-wrapper]:bg-transparent">
          <CartDiscount />
        </div>

        <CartTotals />
      </div>
    </div>
  );
}

CartPage.displayName = 'CartPage';
