const article = {
  label: 'Article',
  name: 'article',
  component: 'group',
  fields: [
    {
      label: 'Title',
      name: 'title',
      component: 'text',
    },
    {
      label: 'Category',
      name: 'category',
      component: 'text',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
    },
    {
      name: 'articleTime',
      component: 'text',
      label: 'Article Read Time',
      defaultValue: '5',
    },
    {
      label: 'Link',
      name: 'link',
      component: 'link',
    },
  ],
};

export default function Schema() {
  return {
    label: 'Blog list',
    key: 'blogList',
    category: 'Content',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/blog_list.png?v=1684851075',
    fields: [
      {
        label: 'Title',
        name: 'title',
        component: 'text',
      },
      {
        label: 'View all link',
        name: 'viewAllLink',
        component: 'link',
      },
      {
        label: 'Articles',
        name: 'articles',
        component: 'group-list',
        itemProps: ({ id: key, title }) => ({
          key,
          label: title,
        }),
        defaultItem: () => ({
          title: 'New article',
        }),
        validate: {
          maxItems: 3,
        },
        fields: [...article.fields],
      },
    ],
  };
}
