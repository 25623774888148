/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import Button from '../../../snippets/Button/Button';
import { Svg } from '../../../snippets/Svg';
import { Eyebrow, Headline, Subheading } from '../../../snippets/Typography';

// generate jsdoc for props based on WIYWQuiz.jsx that is sended to this component
/**
 * @typedef {Object} QuizQuestionOption
 * @property {string} optionText
 *
 * @typedef {Object} StepProps
 * @property {QuizQuestionOption[]} options
 * @property {string} subheading
 * @property {() => void} onSelect
 * @property {() => void} onBack
 * @property {() => void} onSubmit
 * @property {number} step
 * @property {number} steps
 * @property {string} heading
 * @property {'single' | 'multiple'} choices
 * @property {string} columns
 *
 * @param {StepProps} props
 * @returns {JSX.Element}
 * @example
 */
export default function Step({
  options = [],
  subheading = '',
  onSelect = () => {},
  onBack = () => {},
  onSubmit = () => {},
  step = 1,
  choices = 'multiple',
  steps = 1,
  heading = '',
  columns = '2',
}) {
  /**
   * DO NOT change the local storage key! This will be used further to process the data
   */
  const [selected, setSelected] = useLocalStorageState(`WIYWQuiz-${heading}`, {
    defaultValue: [],
  });

  const select = useCallback(
    (el) =>
      choices === 'single' ? setSelected([el]) : setSelected([...selected, el]),
    [selected, setSelected, choices]
  );

  const unselect = useCallback(
    (el) => setSelected(selected.filter((item) => item !== el)),
    [selected, setSelected]
  );

  useEffect(() => {
    onSelect(selected);
  }, [selected]);

  return (
    <section className="px-contained py-5 text-[#202635]">
      <span
        role="button"
        className="flex gap-x-3"
        onClick={onBack}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onBack();
        }}
        tabIndex={0}
      >
        <Svg
          className="w-[1.25rem] text-black"
          src="/svgs/arrow-left.svg#arrow-left"
          title="Arrow Left"
          viewBox="0 0 24 24"
        />
        <span className="text-sm font-light">Back</span>
      </span>

      <div className="heading mt-11 space-y-3 text-center">
        <Eyebrow>
          QUESTION {step} of {steps}
        </Eyebrow>

        <Headline variant="h3">{heading}</Headline>

        <Subheading>
          <div className="font-mono text-xs font-light uppercase">
            {subheading}
          </div>
        </Subheading>
      </div>

      <div
        className={[
          'body mx-auto mt-10 grid max-w-[640px] gap-5',
          // eslint-disable-next-line no-nested-ternary
          columns === '3'
            ? 'md:grid-cols-3'
            : columns === '1'
            ? 'md:grid-cols-1'
            : 'md:grid-cols-2',
        ].join(' ')}
      >
        {options.map(({ optionText }, index) => (
          <div
            key={index}
            role="checkbox"
            aria-checked={selected.includes(optionText)}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (selected.includes(optionText)) {
                  unselect(optionText);
                } else {
                  select(optionText);
                }
              }
            }}
            className={[
              'flex h-14 cursor-pointer items-center gap-x-2 rounded-md border border-[#DDE2E7] px-4 py-3',
              selected.includes(optionText) ? 'bg-[#FFFFFF]' : 'bg-[#E6F0F8]',
            ].join(' ')}
            onClick={() => {
              if (selected.includes(optionText)) {
                unselect(optionText);
              } else {
                select(optionText);
              }
            }}
          >
            <CheckBox checked={selected.includes(optionText)} />
            <span className="text-sm font-light md:text-base">
              {optionText}
            </span>
          </div>
        ))}
      </div>

      <div className="my-10 flex justify-center">
        <Button
          variant="filled"
          theme="primary"
          disabled={selected.length === 0}
          onClick={onSubmit}
        >
          Continue
        </Button>
      </div>
    </section>
  );
}

function CheckBox({ checked = false, className = '', onClick = () => {} }) {
  return (
    <div
      role="checkbox"
      aria-checked={checked}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onClick();
      }}
      onClick={onClick}
      className={[
        `flex h-5 w-5 items-center justify-center border border-primary ${className}`,
        checked ? 'bg-primary' : '',
      ].join(' ')}
    >
      {checked && (
        <Svg
          className="w-3 text-white"
          src="/svgs/checkmark.svg#checkmark"
          viewBox="0 0 24 24"
        />
      )}
    </div>
  );
}
