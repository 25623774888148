export const Schema = () => ({
  category: 'Article',
  label: 'Article Contaminant Table',
  key: 'articleContaminantTable',
  //   previewSrc:
  //     'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-24_20at_202.55.23_20PM.png?v=1684965343',
  fields: [
    {
      name: 'title',
      component: 'text',
      label: 'Title',
      description: 'Leave Blank to hide from Table of Contents',
    },
    {
      name: 'anchorId',
      component: 'text',
      label: 'Table Of Contents Title',
      description: 'Leave Blank to hide from Table of Contents',
    },
    {
      label: 'Table Group Header',
      name: 'tableGroupHeader',
      validate: {
        maxItems: 2,
      },
      component: 'group-list',
      itemProps: {
        label: '{{item.header}}',
      },
      fields: [
        {
          label: 'Header',
          name: 'header',
          component: 'text',
        },
      ],
      defaultValue: [
        {
          header:
            'Clearly Filtered Products with Affinity Filtration Technology',
        },
        {
          header: 'Flouride in tap water Removal',
        },
      ],
      defaultItem: {
        header: 'Clearly Filtered Products with Affinity Filtration Technology',
      },
    },
    {
      label: 'Table Group Rows',
      name: 'tableGroupRows',
      component: 'group-list',
      itemProps: {
        label: '{{item.leftColumn}}',
      },
      fields: [
        {
          label: 'Left Column',
          name: 'leftColumn',
          component: 'text',
        },
        {
          label: 'Right Column',
          name: 'rightColumn',
          component: 'text',
        },
      ],
      defaultValue: [
        {
          leftColumn: 'Water Pitcher Filter',
          rightColumn: '98.3%',
        },
        {
          leftColumn: 'Under the Sink Filter',
          rightColumn: '98.3%',
        },
        {
          leftColumn: 'Refrigerator Filter',
          rightColumn: '98.3%',
        },
      ],
      defaultItem: {
        leftColumn: 'Water Pitcher Filter',
        rightColumn: '98.3%',
      },
    },
  ],
});
