import { useEffect } from 'react';

import { Svg } from '../Svg';
import { useBodyScrollLock } from '../../hooks';
import { Button } from '../Button';

export function CollectionMenuSidebar({
  children,
  isOpen,
  setIsOpen,
  title,
  totalFilters,
  clearFilters,
}) {
  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock();

  useEffect(() => {
    if (isOpen) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
  }, [isOpen]);

  return (
    <div
      className={`${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } position fixed inset-0 z-[100] flex h-full w-full flex-col bg-white pt-[calc(60px+var(--promobar-height))] transition md:!hidden md:pt-[calc(var(--header-height)+var(--promobar-height))]`}
    >
      {isOpen && (
        <>
          <div className="relative flex h-14 items-center justify-between gap-4 border-b border-border px-4">
            <h3 className="text-center text-lg text-[20px] font-light">
              {title} {totalFilters ? `(${totalFilters})` : null}
            </h3>

            <div>
              <button
                className=""
                onClick={() => setIsOpen(false)}
                type="button"
              >
                <Svg
                  className="w-[1.25rem] text-black"
                  src="/svgs/close.svg#close"
                  title="Close"
                  viewBox="0 0 24 24"
                />
              </button>
            </div>
          </div>

          <div className="relative flex-1 overflow-y-auto p-4 scrollbar-hide">
            {children}
          </div>

          <div className="flex w-full gap-[20px] p-4">
            <Button
              variant="outlined"
              theme="primary"
              className="flex-1 !border-primary text-[14px] !text-primary hover:!text-white focus:!text-white active:!text-white"
              onClick={clearFilters}
            >
              Clear All
            </Button>
            <Button
              className="flex-1 text-[14px]"
              variant="filled"
              theme="primary"
              onClick={() => setIsOpen(false)}
            >
              Apply Filters
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

CollectionMenuSidebar.displayName = 'CollectionMenuSidebar';
