import { Schema } from './ArticleContaminantTable.schema';
import { Headline } from '../../snippets/Typography';
import handleize from '../../utils/handleize';

export function ArticleContaminantTable({ cms }) {
  const { title, anchorId, tableGroupHeader, tableGroupRows } = cms;

  return (
    <div className="relative mb-8 font-light lg:mb-16">
      {anchorId && <div id={handleize(anchorId)} className="absolute top-16" />}
      {title && (
        <Headline
          variant="h4"
          element="h4"
          className="border-t border-[#DDE2E7] pb-4 pt-12 lg:pb-16"
        >
          {title}
        </Headline>
      )}
      <div
        className="grid grid-cols-2 items-center justify-around gap-6 gap-y-10"
        style={{
          background:
            'linear-gradient(270deg, #5B71A1 0%, #536896 10.94%, #4D618E 19.27%, #354974 33.84%, #354C7A 50.38%, #3C5280 65.88%, #3A4C79 77.84%, #232F54 100%)',
        }}
      >
        {tableGroupHeader.map(({ header }, index) => {
          return (
            <div key={index} className="">
              <p className="font-white px-4 pt-4 pb-4 font-light text-white lg:pr-8">
                {header}
              </p>
            </div>
          );
        })}
      </div>
      {tableGroupRows.map(({ leftColumn, rightColumn }, index) => {
        return (
          <div
            className="grid grid-cols-2 items-center justify-around gap-6 gap-y-10 border border-[#DDE2E7]"
            key={index}
          >
            <div className="">
              <p className="border-r border-[#DDE2E7] px-4 pt-6 pb-6 font-light lg:pr-8">
                {leftColumn}
              </p>
            </div>
            <div className="">
              <p className="px-4 pt-6 pb-6 font-light lg:pr-8">{rightColumn}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

ArticleContaminantTable.displayName = 'ArticleContaminantTable';
ArticleContaminantTable.Schema = Schema;
