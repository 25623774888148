import { useMemo } from 'react';
import { useCurrency, useCustomer, useSettings } from '@backpackjs/storefront';
import {
  sendShopifyAnalytics,
  getClientBrowserParameters,
  AnalyticsEventName,
  useShopifyCookies,
} from '@shopify/hydrogen-react';

import { useShopifyAnalyticsAddToCart } from './useShopifyAnalyticsAddToCart';
import { useShopifyAnalyticsPageView } from './useShopifyAnalyticsPageView';

// Envs to set:
// * NEXT_PUBLIC_SHOPIFY_SHOP_ID // see readme on where to obtain
// * NEXT_PUBLIC_SITE_TITLE // provides brand name

const DEBUG = true;

const sendShopifyAnalyticsEvent = async (
  analyticsPageData,
  eventName,
  callback
) => {
  try {
    if (!AnalyticsEventName[eventName]) return;
    const payload = {
      ...getClientBrowserParameters(),
      ...analyticsPageData,
    };
    const event = {
      eventName: AnalyticsEventName[eventName],
      payload,
    };

    await sendShopifyAnalytics(event);
    if (typeof callback === 'function') callback(event);
    if (DEBUG) console.log('sendShopifyAnalytics:event', event);
  } catch (error) {
    console.error('sendShopifyAnalyticsEvent:error', error.message);
  }
};

const hasUserConsent = true;

export function useShopifyAnalytics({ collection, product }) {
  const currency = useCurrency();
  const customer = useCustomer();
  const settings = useSettings();
  const { enabled } = { ...settings?.analytics };

  const customerPending = typeof customer === 'undefined';

  const basePayload = useMemo(() => {
    if (
      !currency ||
      customerPending ||
      !enabled ||
      !process.env.NEXT_PUBLIC_SHOPIFY_SHOP_ID
    )
      return null;

    return {
      hasUserConsent,
      shopId: `gid://shopify/Shop/${process.env.NEXT_PUBLIC_SHOPIFY_SHOP_ID}`,
      currency,
      acceptedLanguage: 'en',
      shopifySalesChannel: 'headless',
      ...(customer
        ? { customerId: parseInt(customer.id.split('/').pop(), 10) }
        : null),
    };
  }, [currency, customer, enabled, hasUserConsent]);

  useShopifyCookies({ hasUserConsent: hasUserConsent && enabled });

  useShopifyAnalyticsPageView({
    basePayload,
    collection,
    product,
    sendShopifyAnalyticsEvent,
  });

  useShopifyAnalyticsAddToCart({
    basePayload,
    sendShopifyAnalyticsEvent,
  });
}
