import { Schema } from './ProductOverview.schema';
import { Body, Eyebrow, Headline, Image, Svg } from '../../snippets';

export function ProductOverview({ cms }) {
  const { eyebrow, heading, image, imageAlignment, promoHeading, promoItems } =
    cms;
  return (
    <div className="px-contained mx-auto max-w-[var(--content-max-width)] pb-[30px] pt-[60px] md:pt-[90px] md:pb-[45px]">
      <div className="overview-header mx-auto max-w-[1024px] pb-[60px] text-center md:pb-[90px]">
        {eyebrow && <Eyebrow className="mb-4 uppercase">{eyebrow}</Eyebrow>}
        {heading && (
          <Headline variant="h4" element="h2">
            {heading}
          </Headline>
        )}
      </div>
      <div className="overview-content grid grid-cols-1 lg:grid-cols-2">
        <div
          className={`content-column order-1 flex items-center bg-[#E6F0F8] p-[36px] md:px-[50px] md:py-[100px] lg:aspect-square ${
            imageAlignment === 'left' ? 'lg:order-1' : 'lg:order-0'
          }`}
        >
          <div>
            {promoHeading && (
              <Headline variant="h4" element="h2">
                {promoHeading}
              </Headline>
            )}

            {promoItems?.length > 0 && (
              <ul className="mt-[40px] grid grid-cols-1 gap-[28px]">
                {promoItems.map((item, itemIndex) => {
                  return (
                    <li
                      key={`promo-item-${itemIndex}`}
                      className="flex items-start gap-[20px] text-lg font-[350] leading-[1]"
                    >
                      <Svg
                        className="flex-[0_0_16px] text-secondary"
                        src="/svgs/circle-check.svg#circle-check"
                        title={item?.promoItem}
                        viewBox="0 0 18 18"
                      />
                      <Body variant="medium">{item?.promoItem}</Body>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>

        {image && (
          <div
            className={`image-column order-0 relative max-lg:aspect-square ${
              imageAlignment === 'left' ? 'lg:order-0' : 'lg:order-1'
            }`}
          >
            <Image
              src={image?.src}
              alt={heading}
              fill
              sizes="(min-width: 991px) 60vw, 100vw"
              className="object-cover"
            />
          </div>
        )}
      </div>
    </div>
  );
}

ProductOverview.displayName = 'ProductOverview';
ProductOverview.Schema = Schema;
