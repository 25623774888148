import React, { useEffect, useState } from 'react';
import Button from '../../snippets/Button/Button';
import { Body, Eyebrow } from '../../snippets/Typography';
import Headline from '../../snippets/Typography/Headline';
import { Schema } from './Hero.schema';
import CountdownTimer from './CountdownTimer';
import HeroBackground from './HeroBackground';
import SignupForm from './SignupForm';
import useMediaQuery from '../../utils/useMediaQuery';
import { handleScroll } from '../../utils/scrollUtility.js';

export function HeroBanner({ cms }) {
  const {
    backgroundImageMobile,
    backgroundImage,
    horizontalAlignment,
    verticalAlignment,
    enableFullWidthButton,
    backgroundVideoUrl,
    backgroundVideoUrlMobile,
    countdown,
    color,
    ctas,
    extraTopPadding,
    eyebrowText,
    heading,
    headingVariant,
    mobileFixedBannerFontSize,
    mobileFixedBannerHeight,
    mobileSubheading,
    scrollIndicator,
    signup,
    subheading,
    enableButtonSpacing,
  } = cms;

  const isMobile = useMediaQuery('(max-width: 767px)');

  const textColor = color ?? 'white';

  const [sectionStyles, setSectionStyles] = useState({});
  useEffect(() => {
    if (isMobile) {
      setSectionStyles({
        color: textColor,
        minHeight: mobileFixedBannerHeight
          ? `${mobileFixedBannerHeight}px`
          : 'auto',
      });
    } else {
      setSectionStyles({
        color: textColor,
      });
    }
  }, [isMobile]);


  return (
    <section
      className={`relative flex h-auto min-h-[480px] flex-col justify-end bg-primary md:min-h-screen ${verticalAlignment} ${
        extraTopPadding ? 'pt-[96px] md:pt-[120px]' : ''
      }`}
      style={sectionStyles}
    >
      <div className="bg-content absolute inset-0 z-[1] h-full w-full bg-orange-300/10">
        <HeroBackground
          image={backgroundImage}
          mobileImage={backgroundImageMobile}
          video={backgroundVideoUrl}
          mobileVideo={backgroundVideoUrlMobile}
        />
      </div>
      <div
        className={`px-contained lg:text-eye relative z-[2] flex h-full w-full max-w-[--var(content-max-width)] flex-col justify-end ${horizontalAlignment} ${enableButtonSpacing ? "md:gap-y-12" : "gap-y-4"}  py-12 md:py-28`}
      >
        <CountdownTimer {...countdown} />
        {eyebrowText && (
          <Eyebrow className="max-sm:!text-[14px]">{eyebrowText}</Eyebrow>
        )}
        <div className="heading-text m">
          <div className="grid grid-cols-12">
            <div className="col-span-12 max-w-3xl items-center lg:col-span-12">
              {heading && (
                <Headline
                  variant={headingVariant || 'h1'}
                  element="h2"
                  className="mb-4 max-w-[785px]"
                  style={
                    isMobile && mobileFixedBannerFontSize
                      ? {
                          fontSize: mobileFixedBannerFontSize,
                        }
                      : {}
                  }
                >
                  {heading}
                </Headline>
              )}
              <div className="hidden lg:block">
                {subheading && <Body variant="large">{subheading}</Body>}
              </div>
              <div className="lg:hidden">
                {mobileSubheading && (
                  <Body variant="medium">{mobileSubheading}</Body>
                )}
              </div>
            </div>
          </div>
        </div>
        {ctas && (
          <div className={`cta-group flex flex-wrap gap-3 ${enableFullWidthButton ? 'max-sm:w-full' : ''}`}>
            {ctas.map((cta, index) => {
              if ('link' in cta) {
                return (
                  <Button
                    key={index}
                    href={cta.linkId ? `#${cta.linkId}` : cta.link.url}
                    variant={cta.variant}
                    theme={cta.theme}
                    wrapperClassName={`shrink-0 ${enableFullWidthButton ? 'max-sm:w-full' : ''} ${
                      ctas.length > 1 ? 'max-sm:max-w-1/2' : ''
                    }`}
                    className={`${enableFullWidthButton ? 'max-sm:w-full' : ''} max-sm:text-[14px]`}
                    onClick={(event) => cta.linkId && handleScroll(event, `#${cta.linkId}`)}
                  >
                    {cta.link.text}
                  </Button>
                );
              }

              return null;
            })}
          </div>
        )}
        <SignupForm {...signup} />
        {scrollIndicator && !isMobile ? (
          <>
            <div className="absolute bottom-[88px] left-1/2 h-[24px] w-[1px] -translate-x-1/2 bg-[#fff] lg:bottom-[108px]" />
            <div className="absolute bottom-[30px] left-1/2 h-[72px] w-[1px] -translate-x-1/2 bg-[#fff] opacity-50 lg:bottom-[60px]" />
          </>
        ) : null}
      </div>
    </section>
  );
}

HeroBanner.Schema = Schema;
