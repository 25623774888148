export default {
  label: 'Recharge Subscriptions',
  name: 'subscriptions',
  component: 'group',
  description: 'Settings pertaining to the Recharge subscription widget.',
  fields: [
    {
      label: 'Subscription Products',
      name: 'subscriptionProducts',
      component: 'group-list',
      itemProps: {
        label: '{{item.product.title}}',
      },
      fields: [
        {
          label: 'Product',
          name: 'product',
          component: 'productSearch',
        },
        {
          label: 'Subscribe & Save Subtext',
          name: 'subscribeSaveSubText',
          component: 'text',
          defaultValue: 'Free Shipping USA. Delay or cancel anytime.',
        },
        {
          label: 'Default Recommended Frequency',
          name: 'defaultRecommendedFrequency',
          component: 'number',
          description:
            'Must match one of the frequency options available in the Recharge app.',
        },
        {
          label: 'Override Selection Text',
          name: 'overrideSelectionText',
          component: 'text',
          description:
            'Replaces the "Delivery every" string in the select box text.',
        },
        {
          label: 'Override Recurring Price',
          name: 'overrideRecurringPrice',
          component: 'number',
          description:
            'For product swap subscriptions where the next price doesn\'t match the "Ships Now" price.',
        },
        {
          label: 'Recurring Price Title Override',
          name: 'overrideRecurringPriceTitle',
          component: 'text',
          description: 'Replaces the "Recurring Price" text.',
        },
        {
          label: 'Ships Now',
          component: 'group',
          name: 'shipsNow',
          description: 'Which items ship now?',
          fields: [
            {
              label: 'Show Ships Now?',
              name: 'show',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Image',
              name: 'image',
              component: 'image',
              description: "Leave blank to default to the product's image",
            },
            {
              label: 'Items',
              name: 'items',
              component: 'markdown',
              description:
                'Use {qty} as a variable to replace it with the quantity used in the cart.',
            },
          ],
        },
        {
          label: 'Ships Recurringly ',
          component: 'group',
          name: 'shipsRecurringly',
          description: 'Which items ship on a recurring basis?',
          fields: [
            {
              label: 'Show Ships Recurringly?',
              name: 'show',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Image',
              name: 'image',
              component: 'image',
            },
            {
              label: 'Items',
              name: 'items',
              component: 'markdown',
              description:
                'Use {qty} as a variable to replace it with the qty used in the cart.',
            },
          ],
        },
      ],
    },
  ],
};
