export const Schema = () => ({
  label: 'Blog Popular Posts',
  category: 'Blog',
  key: 'blogPopularPosts',
  fields: [
    {
      name: 'header',
      component: 'text',
      label: 'Header',
      defaultValue: 'Popular Posts',
    },
    {
      label: 'Featured Post',
      name: 'featuredPost',
      description: '',
      component: 'group',
      fields: [
        {
          label: 'Image',
          name: 'image',
          component: 'image',
        },
        {
          name: 'heading',
          component: 'text',
          label: 'Heading',
          defaultValue:
            'Effects of drinking water infrastructure on public health',
        },
        {
          name: 'category',
          component: 'text',
          label: 'Category',
          defaultValue: 'HEALTH & SAFETY',
        },
        {
          name: 'articleTime',
          component: 'text',
          label: 'Article Read Time',
          defaultValue: '5',
        },
        {
          label: 'Call to action',
          name: 'cta',
          component: 'link',
        },
      ],
    },
    {
      label: 'Popular Posts',
      name: 'popularPosts',
      component: 'group-list',
      validate: {
        maxItems: 3,
      },
      itemProps: {
        label: '{{item.title}}',
      },
      fields: [
        {
          label: 'Image',
          name: 'image',
          component: 'image',
        },
        {
          name: 'title',
          component: 'text',
          label: 'Title',
          defaultValue: 'Hydration Tips for Athletes',
        },
        {
          name: 'category',
          component: 'text',
          label: 'Category',
          defaultValue: 'WATER 101',
        },
        {
          name: 'articleTime',
          component: 'text',
          label: 'Article Read Time',
          defaultValue: '5',
        },
        {
          label: 'Call to action',
          name: 'cta',
          component: 'link',
        },
      ],
      defaultValue: [
        {
          title: 'Hydration Tips for Athletes',
          category: 'Water 101',
          articleTime: '5',
        },
        {
          title: 'Drinking Water Standards and Maximum Contaminant Levels',
          category: 'US VS THEM',
          articleTime: '5',
        },
        {
          title:
            'PFAS "Forever Chemicals" Contamination Worse than Previously Thought',
          category: 'NEWS',
          articleTime: '5',
        },
      ],
    },
  ],
});
