import { useState, useEffect } from 'react';

function Separator() {
  return (
    <span className="mt-[0.88rem] inline-block align-top text-lg lg:mt-5">
      :
    </span>
  );
}

function IntervalBlock({ time, label }) {
  return (
    <div className="mx-4 inline-block">
      <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-[0.63rem] bg-[rgba(255,255,255,0.3)] text-[1.38rem] lg:h-[4.5rem] lg:w-[4.5rem] lg:text-[1.75rem]">
        {time}
      </div>
      <div className="block text-sm uppercase">{label}</div>
    </div>
  );
}

export default function CountdownTimer({ enable, datetime }) {
  // check if is valid datetime - MM/dd/yyyy 12:00:00
  const isValidDatetime = (string) => {
    const datetimeRegex = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/;
    return datetimeRegex.test(string);
  };

  const shouldShow = !!enable && isValidDatetime(datetime);

  // get days hours minutes seconds
  const getTimeRemaining = (endtime) => {
    const total = Date.parse(endtime) - Date.parse(new Date());

    if (total <= 0) {
      return { total: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeRemaining, setTimeRemaining] = useState({
    total: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(getTimeRemaining(datetime));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timeRemaining]);

  return (
    !!shouldShow && (
      <div className="countdown-timer mb-9 lg:mb-[4.5rem]">
        <IntervalBlock time={timeRemaining.days} label="days" />
        <Separator />
        <IntervalBlock time={timeRemaining.hours} label="hrs" />
        <Separator />
        <IntervalBlock time={timeRemaining.minutes} label="min" />
        <Separator />
        <IntervalBlock time={timeRemaining.seconds} label="sec" />
      </div>
    )
  );
}
