import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Svg } from '../../snippets/Svg';

export function SearchFilterMenu({
  productResults,
  collectionResults,
  articleResults,
  pageResults,
  searchType,
  setSearchType,
}) {
  const [activeItem, setActiveItem] = useState(null);
  const [activeMenu, setActiveMenu] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const findActiveItem = () => {
      if (router?.query?.searchType) {
        setSearchType(router?.query?.searchType);
      }
      if (
        searchType === 'products' ||
        router?.query?.searchType === 'products'
      ) {
        setActiveItem('Product Results');
      }
      if (
        searchType === 'collections' ||
        router?.query?.searchType === 'collections'
      ) {
        setActiveItem('Collection Results');
      }
      if (
        searchType === 'blog-articles' ||
        router?.query?.searchType === 'blog-articles'
      ) {
        setActiveItem('Blog Articles Results');
      }
      if (searchType === 'pages' || router?.query?.searchType === 'pages') {
        setActiveItem('Page Results');
      }
    };

    findActiveItem();
  }, []);

  const updateMenu = (type, searchTypeName, active) => {
    setSearchType(type);
    setActiveMenu(!active);
    setActiveItem(searchTypeName);
  };

  return (
    <div className="w-[50%] lg:w-2/12">
      <div className="hidden flex-col lg:flex ">
        <button
          type="button"
          className={`mb-2 cursor-pointer text-left font-normal uppercase tracking-[.02em] ${
            searchType === 'products' ? 'text-secondary' : ''
          } ${
            productResults?.length === 0
              ? 'pointer-events-none cursor-not-allowed'
              : ''
          }`}
          onClick={() => setSearchType('products')}
        >
          PRODUCTS ({productResults?.length})
        </button>
        <button
          type="button"
          className={`mb-2 cursor-pointer text-left font-normal uppercase tracking-[.02em] ${
            searchType === 'collections' ? 'text-secondary' : ''
          } ${
            collectionResults?.length === 0
              ? 'pointer-events-none cursor-not-allowed'
              : ''
          }`}
          onClick={() => setSearchType('collections')}
        >
          COLLECTIONS ({collectionResults?.length})
        </button>
        <button
          type="button"
          className={`mb-2 cursor-pointer text-left font-normal uppercase tracking-[.02em] ${
            searchType === 'blog-articles' ? 'text-secondary' : ''
          } ${
            articleResults?.length === 0
              ? 'pointer-events-none cursor-not-allowed'
              : ''
          }`}
          onClick={() => setSearchType('blog-articles')}
        >
          BLOG ARTICLES ({articleResults?.length})
        </button>
        <button
          type="button"
          className={`mb-2 cursor-pointer text-left font-normal uppercase tracking-[.02em] ${
            searchType === 'pages' ? 'text-secondary' : ''
          } ${
            pageResults?.length === 0
              ? 'pointer-events-none cursor-not-allowed'
              : ''
          }`}
          onClick={() => setSearchType('pages')}
        >
          PAGES ({pageResults?.length})
        </button>
      </div>
      <div className="overflow-hidden rounded-3xl border border-border bg-white lg:hidden">
        <button
          type="button"
          className="flex h-[48px] w-full cursor-pointer items-center justify-between rounded-full px-4 py-3"
          onClick={() => setActiveMenu(!activeMenu)}
        >
          <span className="text-sm font-light tracking-[-.01em]">
            {activeItem}
          </span>
          <span>
            <Svg
              className={`${!activeMenu ? 'block' : 'hidden'} w-5 text-text`}
              src="/svgs/chevron-down.svg#chevron-down"
              title="Close"
              viewBox="0 0 24 24"
            />
            <Svg
              className={`${activeMenu ? 'block' : 'hidden'} w-5 text-text`}
              src="/svgs/chevron-up.svg#chevron-up"
              title="Close"
              viewBox="0 0 24 24"
            />
          </span>
        </button>
        <div className={`${activeMenu ? 'block' : 'hidden'}`}>
          <button
            type="button"
            onClick={() => updateMenu('products', 'Product Results', true)}
            className="flex w-full cursor-pointer items-center justify-between border-t border-zinc-200 bg-white p-4 text-sm font-light  tracking-[-.01em]"
          >
            Product Results ({productResults?.length})
          </button>
          <button
            type="button"
            onClick={() => updateMenu('collections', 'Collection Result', true)}
            className="flex w-full cursor-pointer items-center justify-between border-t border-zinc-200 bg-white p-4 text-sm font-light  tracking-[-.01em]"
          >
            Collection Results ({collectionResults?.length})
          </button>
          <button
            type="button"
            onClick={() =>
              updateMenu('blog-articles', 'Blog Articles Results', true)
            }
            className="flex w-full cursor-pointer items-center justify-between border-t border-zinc-200 bg-white p-4 text-sm font-light  tracking-[-.01em]"
          >
            Blog Articles Results ({articleResults?.length})
          </button>
          <button
            type="button"
            onClick={() => updateMenu('pages', 'Page Results', true)}
            className="flex w-full cursor-pointer items-center justify-between border-t border-zinc-200 bg-white p-4 text-sm font-light  tracking-[-.01em]"
          >
            Page Results ({pageResults?.length})
          </button>
        </div>
      </div>
    </div>
  );
}
