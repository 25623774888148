import PropTypes from 'prop-types';
import React from 'react';

export default function Hero(props) {
  const { variant, element, children } = props;

  const Element = element || variant;

  const variants = {
    h1: 'text-[2.625rem] leading-[2.625rem] lg:text-[5.625rem] lg:leading-[5.625rem]',
  };

  return (
    <Element
      className={`font-sans font-light tracking-[-2%] ${variants[variant]}`}
    >
      {children}
    </Element>
  );
}

Hero.propTypes = {
  variant: PropTypes.oneOf(['h1']).isRequired,
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
  children: PropTypes.node.isRequired,
};
