export default function Schema() {
  return {
    label: 'Dynamic Blog list',
    key: 'dynamicBlogList',
    category: 'Content',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/blog_list.png?v=1684851075',
    fields: [
      {
        label: 'Title',
        name: 'title',
        component: 'text',
      },
      {
        label: 'View all link',
        name: 'viewAllLink',
        component: 'link',
      },
      {
        label: 'Article Handles',
        name: 'articleHandles',
        component: 'list',
        field: {
          component: 'text',
        },
        validate: {
          maxItems: 3,
        },
      },
    ],
  };
}
