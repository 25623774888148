import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useCustomerCreate, useSettings } from '@backpackjs/storefront';
import { Headline } from '../Typography';
import { Button } from '../Button';
import { Spinner } from '../Spinner';
import { Svg } from '../Svg';
import { useDataLayerActions } from '../../hooks';

export function CreateAccount({ setActiveForm }) {
  const { sendRegisterEvent } = useDataLayerActions();
  const {
    customerCreate,
    errors: customerErrors,
    finished,
    reset,
    resetTimer,
    setErrors,
    started,
    success,
  } = useCustomerCreate();
  const settings = useSettings();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { flyoutHeading, subheading, loginText, loginLinkText } = {
    ...settings?.account?.register,
  };

  const regex = /_([^_]+)_/g;
  const heading = flyoutHeading?.replace(
    regex,
    '<span class="text-[#202635] opacity-50">$1</span>'
  );

  const buttonText = useMemo(() => {
    if (finished) {
      resetTimer.current = setTimeout(() => {
        if (reset) reset();
        clearTimeout(resetTimer.current);
      }, 3000);
      return success ? 'Account Created' : 'Failed';
    }
    return started ? 'Creating Account' : 'Create Account';
  }, [finished, started, success]);

  const handleCustomerCreate = useCallback(
    async (data) => {
      if (started) return;

      const { name, lastName, email, password, passwordConfirm } = data;

      if (password !== passwordConfirm) {
        setErrors(['Passwords do not match. Please try again.']);

        if (finished) {
          resetTimer.current = setTimeout(() => {
            if (reset) reset();
            clearTimeout(resetTimer.current);
          }, 3000);
        }
        return;
      }

      await customerCreate({
        firstName: name,
        lastName,
        email,
        password,
        acceptsMarketing: false,
      });

      sendRegisterEvent();
    },
    [finished, reset, started]
  );

  return (
    <div className="flex flex-col overflow-y-auto rounded p-40 px-4 pt-3 md:px-8">
      <Headline variant="h5" className="lg:max-w-[350px]">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: heading }} />
      </Headline>

      <p className="mt-2 text-left text-sm font-light">{subheading}</p>

      <form
        className="mx-auto mt-6 flex w-full flex-col gap-4"
        onSubmit={handleSubmit(handleCustomerCreate)}
      >
        <label
          className="relative flex w-full flex-col-reverse  gap-x-1 gap-y-1.5 justify-self-stretch"
          htmlFor="firstName"
        >
          <input
            className={`w-full !min-w-0 border border-[#DDE2E7] px-3 py-[19px] text-sm font-light !outline-0 ${
              errors.lastName ? '!border-[#ED7C5C] !text-[#ED7C5C]' : null
            }`}
            name="firstName"
            placeholder="first name"
            type="text"
            {...register('name', { required: true, maxLength: 80 })}
          />
          {errors.name && (
            <span className="absolute right-4 top-1/2  -translate-y-1/2 transform">
              <Svg
                className="w-5 text-text"
                src="/svgs/error.svg#error"
                title="Error icon"
                viewBox="0 0 16 16"
              />
            </span>
          )}
        </label>
        {errors.name && (
          <p className="text-[14px] text-[#ED7C5C]">
            *Please enter a first name
          </p>
        )}

        <label
          className="relative flex w-full flex-col-reverse  gap-x-1 gap-y-1.5 justify-self-stretch"
          htmlFor="lastName"
        >
          <input
            className={`w-full !min-w-0 border border-[#DDE2E7] px-3 py-[19px] text-sm font-light !outline-0 ${
              errors.lastName ? '!border-[#ED7C5C] !text-[#ED7C5C]' : null
            }`}
            name="lastName"
            placeholder="last name"
            type="text"
            {...register('lastName', { required: true, maxLength: 100 })}
          />
          {errors.lastName && (
            <span className="absolute right-4 top-1/2  -translate-y-1/2 transform">
              <Svg
                className="w-5 text-text"
                src="/svgs/error.svg#error"
                title="Error icon"
                viewBox="0 0 16 16"
              />
            </span>
          )}
        </label>
        {errors.lastName && (
          <p className="text-[14px] text-[#ED7C5C]">
            *Please enter a last name
          </p>
        )}

        <label
          className="relative flex w-full flex-col-reverse  gap-x-1 gap-y-1.5 justify-self-stretch"
          htmlFor="email"
        >
          <input
            className={`w-full !min-w-0 border border-[#DDE2E7] px-3 py-[19px] text-sm font-light !outline-0 ${
              errors.email ? '!border-[#ED7C5C] !text-[#ED7C5C]' : null
            }`}
            name="email"
            placeholder="email"
            type="text"
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.email && (
            <span className="absolute right-4 top-1/2  -translate-y-1/2 transform">
              <Svg
                className="w-5 text-text"
                src="/svgs/error.svg#error"
                title="Error icon"
                viewBox="0 0 16 16"
              />
            </span>
          )}
        </label>
        {errors.email && (
          <p className="text-[14px] text-[#ED7C5C]">
            *Please enter a valid email address
          </p>
        )}

        <label
          className="relative flex w-full flex-col-reverse  gap-x-1 gap-y-1.5 justify-self-stretch"
          htmlFor="password"
        >
          <input
            className={`w-full !min-w-0 border border-[#DDE2E7] px-3 py-[19px] text-sm font-light !outline-0 ${
              errors.password ? '!border-[#ED7C5C] !text-[#ED7C5C]' : null
            }`}
            name="password"
            placeholder="password"
            type="password"
            {...register('password', { required: true })}
          />
          {errors.password && (
            <span className="absolute right-4 top-1/2  -translate-y-1/2 transform">
              <Svg
                className="w-5 text-text"
                src="/svgs/error.svg#error"
                title="Error icon"
                viewBox="0 0 16 16"
              />
            </span>
          )}
        </label>
        {errors.password && (
          <p className="text-[14px] text-[#ED7C5C]">*Please enter a password</p>
        )}

        <label
          className="relative flex w-full flex-col-reverse  gap-x-1 gap-y-1.5 justify-self-stretch"
          htmlFor="passwordConfirm"
        >
          <input
            className={`w-full !min-w-0 border border-[#DDE2E7] px-3 py-[19px] text-sm font-light !outline-0 ${
              errors.passwordConfirm
                ? '!border-[#ED7C5C] !text-[#ED7C5C]'
                : null
            }`}
            name="passwordConfirm"
            placeholder="password confirm"
            type="password"
            {...register('passwordConfirm', { required: true })}
          />
          {errors.passwordConfirm && (
            <span className="absolute right-4 top-1/2  -translate-y-1/2 transform">
              <Svg
                className="w-5 text-text"
                src="/svgs/error.svg#error"
                title="Error icon"
                viewBox="0 0 16 16"
              />
            </span>
          )}
        </label>
        {errors.passwordConfirm && (
          <p className="text-[14px] text-[#ED7C5C]">
            *Please confirm your password
          </p>
        )}

        <Button
          theme="primary"
          variant="filled"
          type="submit"
          className="mx-auto mt-12 flex w-full max-w-[210px] items-center justify-center"
        >
          {started ? <Spinner /> : 'Create Account'}
        </Button>
      </form>

      <button
        aria-label="Log In"
        className="mt-6 text-center text-sm font-light tracking-[-.01em]"
        onClick={() => setActiveForm('login')}
        type="button"
      >
        {loginText} <span className="text-underline">{loginLinkText}</span>
      </button>

      {customerErrors?.length > 0 && (
        <ul className="mt-4 flex flex-col items-center gap-1">
          {customerErrors.map((error, index) => {
            return (
              <li
                key={index}
                className="text-center text-[14px] text-[#ED7C5C]"
              >
                {error}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

CreateAccount.displayName = 'CreateAccount';
