import activate from './activate';
import forgot from './forgot';
import login from './login';
import menu from './menu';
import register from './register';
import reset from './reset';

export default {
  label: 'Account',
  name: 'account',
  component: 'group',
  description: 'Menu, login, register, password reset',
  fields: [menu, login, register, forgot, reset, activate],
};
