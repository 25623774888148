import account from './account';
import analytics from './analytics';
import beam from './beam';
import blog from './blog';
import cart from './cart';
import collection from './collection';
import contaminants from './contaminants';
import filterReplacementCalculator from './filter-replacement-calculator';
import footer from './footer';
import general from './general';
import header from './header';
import product from './product';
import search from './search';
import subscriptions from './subscriptions';
import theme from './theme';

export default [
  account,
  analytics,
  beam,
  blog,
  cart,
  collection,
  contaminants,
  filterReplacementCalculator,
  footer,
  general,
  header,
  product,
  search,
  subscriptions,
  theme,
];
