import eyebrow from '../../utils/schemas/eyebrow';
import headline from '../../utils/schemas/headline';

const slides = {
  label: 'Slides',
  name: 'slides',
  component: 'group-list',
  fields: [
    {
      label: 'Step text',
      name: 'stepText',
      component: 'text',
      description: 'e.g. Step 01, Step 02, Step 03',
      defaultValue: 'Step ##',
      required: true,
    },

    {
      label: 'Image',
      name: 'image',
      component: 'image',
      defaultValue:
        'https://cdn.shopify.com/s/files/1/1011/0318/files/larq-bottle-insulated_promo-card_anim_v_201.png',
      required: true,
    },
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Step One: Woven Mesh Screen',
      required: true,
    },
    {
      label: 'Content',
      name: 'content',
      component: 'markdown',
      defaultValue:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.',
      required: true,
    },
  ],
};

export default function Schema() {
  return {
    key: 'product-steps',
    label: 'Product Steps',
    category: 'Product',
    fields: [
      {
        label: 'Dark mode',
        name: 'darkMode',
        component: 'toggle',
        description: 'Use dark mode for this slide',
        defaultValue: false,
      },
      { ...eyebrow, defaultValue: 'HOW IT WORKS', required: true },
      {
        ...headline,
        defaultValue:
          'Dual-stage filtration technology removes harmful contaminants in 3 easy steps',
        required: true,
      },
      slides,
    ],
  };
}
