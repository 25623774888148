import { useCallback } from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ArrowButton } from '../Button';

export default function Carousel({
  children,
  onChange,
  centerMode = false,
  centerSlidePercentage = 30,
  showIndicators = true,
  renderArrowNext,
  renderArrowPrev,
  topArrows = false,
  infiniteLoop = false,
  itemsPerPageMultiplier = 1,
  overwriteTotalItems = false,
  showArrows = true,
  emulateTouch = false,
  selectedItem = undefined,
  className = '',
}) {
  const { length } = children || { length: 0 };

  const getPage = useCallback(
    (index) => {
      if (!itemsPerPageMultiplier || !overwriteTotalItems) return index + 1;

      return (index + 1) * itemsPerPageMultiplier > overwriteTotalItems
        ? overwriteTotalItems
        : (index + 1) * itemsPerPageMultiplier;
    },
    [itemsPerPageMultiplier, overwriteTotalItems]
  );

  return (
    <>
      <style>
        {`
          .carousel .control-dots {
            width: calc(100% - 10rem);
            margin-left: 5rem;
          }

          .carousel-slider {
            position: relative;
            padding-bottom: 40px;
          }
        `}
      </style>
      <ResponsiveCarousel
        onChange={onChange}
        className={topArrows ? '-mt-20' : 'pt-0'}
        showStatus={false}
        showThumbs={false}
        showArrows={showArrows}
        emulateTouch={emulateTouch}
        centerMode={centerMode}
        infiniteLoop={centerMode || infiniteLoop}
        showIndicators={showIndicators}
        selectedItem={selectedItem}
        centerSlidePercentage={centerMode ? centerSlidePercentage : undefined}
        renderItem={(item) => {
          return (
            <div
              className={[
                centerMode ? 'px-0' : 'px-20',
                topArrows ? 'pt-20' : 'pt-0',
                !showArrows && '!px-0',
                className,
              ].join(' ')}
            >
              {item}
            </div>
          );
        }}
        renderArrowNext={
          renderArrowNext ||
          function (onClickHandler, hasNext) {
            return (
              hasNext && (
                <div
                  className={
                    topArrows
                      ? 'absolute right-0 top-0 z-10 flex h-full items-start'
                      : 'absolute right-0 top-0 z-10 flex h-full items-center'
                  }
                >
                  <ArrowButton
                    onClick={onClickHandler}
                    arrowDirection="right"
                  />
                </div>
              )
            );
          }
        }
        renderArrowPrev={
          renderArrowPrev ||
          function (onClickHandler, hasPrev) {
            return (
              hasPrev && (
                <div
                  className={
                    topArrows
                      ? 'absolute right-20 top-0 z-10 flex h-full items-start'
                      : 'absolute left-0 top-0 z-10 flex h-full items-center'
                  }
                >
                  <ArrowButton onClick={onClickHandler} arrowDirection="left" />
                </div>
              )
            );
          }
        }
        renderIndicator={(onClickHandler, isSelected, index) => {
          return (
            <div className="">
              <div
                className={[
                  'absolute bottom-2 left-0 h-[1px] flex-1 transition-all duration-300',
                  isSelected
                    ? 'z-[1] bg-[#202635] dark:bg-[#ffffff] dark:bg-opacity-50'
                    : 'z-[0] bg-[#C7D5E1] dark:bg-opacity-10',
                ]}
                style={{
                  width: `calc(${`${(100 / length) * (index + 1)}%`} - 50px)`,
                  zIndex: isSelected ? 1 : 0,
                }}
              />
              {isSelected && (
                <div className="absolute bottom-0 right-0 w-[50px] font-mono text-xs">
                  {getPage(index)}/
                  {overwriteTotalItems || length * itemsPerPageMultiplier}
                </div>
              )}
            </div>
          );
        }}
      >
        {children}
      </ResponsiveCarousel>
    </>
  );
}
