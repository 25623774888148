export function Schema() {
  return {
    category: 'Text',
    label: 'JazzHRListings',
    key: 'jazzHrListings',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2011.49.49_20AM.png?v=1684867877',
    fields: [],
  };
}
