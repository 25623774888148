const product = [
  {
    name: 'product',
    label: 'Product',
    component: 'productSearch',
  },
  {
    name: 'headline',
    label: 'Headline',
    component: 'text',
  },
  {
    name: 'body',
    label: 'Body',
    component: 'textarea',
  },
];
export const Schema = () => {
  return {
    category: 'WIYW',
    label: 'Recommended Products',
    key: 'recommendedProducts',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screenshot_202023-07-10_20at_2013.33.28.png?v=1689006849',
    fields: [
      {
        name: 'eyebrowText',
        component: 'text',
        label: 'Eyebrow Text',
        defaultValue: 'GET PEACE OF MIND',
      },
      {
        name: 'sectionLink',
        component: 'link',
        label: 'Section Link',
      },
      {
        label: 'ON THE GO',
        component: 'group',
        name: 'onTheGoProduct',
        fields: product,
      },
      {
        label: 'MOST POPULAR',
        component: 'group',
        name: 'mostPopularProduct',
        fields: product,
      },
      {
        label: 'LARGE FAMILY',
        component: 'group',
        fields: product,
        name: 'largeFamilyProduct',
      },
    ],
  };
};
