import { useCallback, useState } from 'react';
import { useCustomer, useSettings } from '@backpackjs/storefront';

import { useGlobalContext } from '../../contexts';
import { Body, Eyebrow, Headline } from '../Typography';
import { Button } from '../Button';
import { useDataLayerActions } from '../../hooks';

export function BackInStockModal({ selectedVariant, product }) {
  const { sendSubscribeEvent } = useDataLayerActions();
  const customer = useCustomer();
  const settings = useSettings();
  const {
    actions: { closeModal },
  } = useGlobalContext();

  const [email, setEmail] = useState(customer?.email || '');
  const [submissionMessage, setSubmissionMessage] = useState();
  const { heading, subtext, submitText } = {
    ...settings?.product?.backInStock,
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const response = await fetch('/api/out-of-stock', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email,
            variant: selectedVariant.legacyResourceId,
          }),
        });
        const data = await response.json();
        if (!data) {
          throw new Error(
            'No data returned from Out of Stock Notifications API'
          );
        }
        setSubmissionMessage("You're in! We'll let you know when it's back");
        sendSubscribeEvent({ email });
      } catch (error) {
        console.error(`Out of Stock Notifications Error: ${error.message}`);
        throw error;
      }
    },
    [email, selectedVariant]
  );

  return (
    <div className="text-center">
      <div className="w-screen max-w-[700px] px-12 py-[50px]">
        <div className="mx-auto max-w-[550px]">
          <Eyebrow className="mb-4 uppercase">{heading}</Eyebrow>

          <div className="mb-4">
            <Headline variant="h4" element="h2" className="mb-4">
              {selectedVariant.product.title}
            </Headline>
          </div>

          {subtext && <p className="mt-6">{subtext}</p>}

          {selectedVariant.title !== 'Default Title' && (
            <Body variant="large" className="mt-4 !font-bold">
              {selectedVariant.title}
            </Body>
          )}

          <form
            className="mx-auto mt-[50px] flex w-full max-w-[386px] flex-col items-center"
            onSubmit={handleSubmit}
          >
            <input
              className="w-full border border-[#DDE2E7] bg-white p-[18px] md:max-w-[30rem]"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email..."
              required
              type="email"
              value={email}
            />

            {submissionMessage ? (
              <div className="relative mt-[40px] w-full border border-success p-[10px] text-center text-success before:absolute before:inset-0 before:bg-success before:opacity-[0.15]">
                <div>{submissionMessage}</div>
              </div>
            ) : (
              <Button
                aria-label="Notify Me"
                className="mt-[40px] max-md:w-full"
                type="submit"
                variant="filled"
                theme="primary"
              >
                {submitText}
              </Button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

BackInStockModal.displayName = 'BackInStockModal';
