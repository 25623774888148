import { useProductByHandle } from '@backpackjs/storefront';
import { decode } from 'he';
import { useState } from 'react';
import { useEWGContext } from '../../contexts/ewgContext';
import { useColorSwatches } from '../../hooks';
import { Body, Button, Eyebrow, Headline, Link } from '../../snippets';
import { ColorVariantSelector } from '../../snippets/ProductItem/ColorVariantSelector';
import { ProductItemMedia } from '../../snippets/ProductItem/ProductItemMedia/ProductItemMedia';
import { Schema } from './WIYWRecommendedProducts.schema';

/**
 * @typedef ProductSearch - The product object.
 * @property {String} handle - The product handle.
 * @property {String} title - The product title.
 *
 * @typedef Product - The product object.
 * @property {String} headline - The product handle.
 * @property {String} body - The product title.
 * @property {ProductSearch} product - The product price.
 *
 * Document the recommended products component based on WIYWRecommendedProducts.schema.js
 *
 * @param {Object} props - The props object.
 * @param {Object} props.cms - The CMS object containing the data to loop through and display.
 * @param {String} props.cms.eyebrowText - The eyebrow text.
 * @param {Object} props.cms.sectionLink - The section link.
 * @param {String} props.cms.sectionLink.text - The section link text.
 * @param {String} props.cms.sectionLink.url - The section link URL.
 * @param {Boolean} props.cms.sectionLink.newTab - The section link new tab.
 * @param {Product} props.cms.onTheGoProduct
 * @param {Product} props.cms.mostPopularProduct
 * @param {Product} props.cms.largeFamilyProduct
 * @returns {JSX.Element} - The recommended products component.
 */
export function RecommendedProducts({ cms }) {
  const {
    eyebrowText,
    sectionLink,
    largeFamilyProduct,
    mostPopularProduct,
    onTheGoProduct,
  } = cms;

  const { activeEWGSystem } = useEWGContext();

  return (
    <section className="featured-products bg-[#D9E8F4] print:hidden">
      <div className="px-contained mx-auto max-w-[var(--content-max-width)] py-[32px] md:py-[62px]">
        <div className="mb-4 text-center">
          {eyebrowText && (
            <Eyebrow className="uppercase">{eyebrowText}</Eyebrow>
          )}
        </div>

        <div className="mb-10 text-center">
          {activeEWGSystem?.SystemName && (
            <Headline variant="h3" element="h2">
              Recommended Products for{' '}
              <span className="font-[500]">
                {decode(activeEWGSystem?.SystemName)}
              </span>
            </Headline>
          )}
        </div>

        <div className="grid gap-6 md:grid-cols-1 lg:grid-cols-3">
          <div className="bg-[#E6F0F8] p-8">
            <ProductItem
              eyebrowText="MOST POPULAR"
              productHandle={mostPopularProduct?.product?.handle}
              body={mostPopularProduct?.body}
              headline={mostPopularProduct?.headline}
            />
          </div>
          <div className="bg-[#E6F0F8] p-8">
            <ProductItem
              eyebrowText="ON THE GO"
              productHandle={onTheGoProduct?.product?.handle}
              body={onTheGoProduct?.body}
              headline={onTheGoProduct?.headline}
            />
          </div>
          <div className="bg-[#E6F0F8] p-8">
            <ProductItem
              eyebrowText="LARGE FAMILY"
              productHandle={largeFamilyProduct?.product?.handle}
              body={largeFamilyProduct?.body}
              headline={largeFamilyProduct?.headline}
            />
          </div>
        </div>

        {sectionLink?.url && (
          <div className="cta-group order-3 mt-10 flex items-center justify-center gap-4">
            <Button href={sectionLink.url} theme="primary" variant="filled">
              {sectionLink.text}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
}

function ProductItem({
  productHandle,
  headline,
  body,
  multiplyImage,
  eyebrowText,
}) {
  const { swatchesMap } = useColorSwatches();

  const { product } = useProductByHandle({
    handle: productHandle,
  });

  const [variantFromColor, setVariantFromColor] = useState(null);

  const variant = variantFromColor || product?.variants?.[0];
  const productUrl = variant
    ? `/products/${variant.product?.handle}?variant=${variant.legacyResourceId}`
    : '';

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex h-full w-full flex-1 items-center justify-center bg-[#E6F0F8] px-5 py-10 lg:px-0 lg:py-0">
        <div className="max-w-[520px]">
          <Link href={productUrl} className="flex flex-col gap-6">
            <div className="mb-10 text-center text-[#0064B7]">
              <Eyebrow>{eyebrowText}</Eyebrow>
            </div>
            <div className="order-2 grid gap-4 text-center lg:order-1">
              {headline && <Headline variant="h5">{headline}</Headline>}
              {body && <Body variant="small">{body}</Body>}
              {product?.price && <Body variant="small">{product?.price}</Body>}
            </div>
            <div
              className="pointer-events-none relative order-1 h-48 select-none lg:order-2 lg:h-60"
              style={{
                mixBlendMode: multiplyImage ? 'multiply' : 'normal',
              }}
            >
              <div className="relative flex h-full items-center justify-center">
                <ProductItemMedia
                  disableHoverEffect
                  disableBackground
                  product={product}
                  variant={variant}
                />
              </div>
            </div>

            <div className="order-2  mt-[-20px] flex justify-center lg:order-3 lg:mt-0">
              <ColorVariantSelector
                className="mb-2 mt-[22px]"
                enabledColorNameOnHover={false}
                product={product}
                setVariantFromColor={setVariantFromColor}
                swatchesMap={swatchesMap}
                variantFromColor={variantFromColor}
              />
            </div>

            <div className="cta-group order-3 flex items-center justify-center gap-4">
              <Button theme="primary" variant="filled">
                Shop now
              </Button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

RecommendedProducts.displayName = 'RecommendedProducts';
RecommendedProducts.Schema = Schema;
