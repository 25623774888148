import { useSettings } from '@backpackjs/storefront';
import { useEffect, useState } from 'react';

import { Body, Eyebrow, Headline } from '../Typography';
import { Button } from '../Button';
import { Image } from '../Image';

export default function FrequencyCalculator({
  responseMap,
  recommendationName,
  recommendationImage,
  closeModal,
  setRecommendedFilterFrequency,
}) {
  const settings = useSettings();
  const globalCalculatorSettings = settings?.filterReplacementCalculator;
  const { eyebrow, title, content, questions, cta } = globalCalculatorSettings;
  const [question1AnswerIndex, setQuestion1AnswerIndex] = useState(null);
  const [question2Options, setQuestion2Options] = useState([]);
  const [calculatorFrequency, setCalculatorFrequency] = useState(null);
  const [showingResults, setShowingResults] = useState(false);

  // Update the calculator frequency whenever form options change
  useEffect(() => {
    const frequency =
      responseMap.filter((map) => {
        const firstQuestionAnswer =
          parseInt(map?.firstQuestionAnswer, 10) ?? null;
        const secondQuestionCount =
          parseInt(map?.secondQuestionCount, 10) ?? null;

        return (
          question1AnswerIndex !== null &&
          firstQuestionAnswer === question1AnswerIndex + 1 &&
          secondQuestionCount === question2Options?.length
        );
      })[0]?.frequencyValue ?? null;

    setCalculatorFrequency(frequency);
  }, [question1AnswerIndex, question2Options]);

  // Update the primary recommended frequency whenever the calculator's recommended frequency changes
  useEffect(() => {
    setRecommendedFilterFrequency(calculatorFrequency);
  }, [calculatorFrequency]);

  // Send an event to Google Tag Manager when the user submits the calculator
  useEffect(() => {
    if (showingResults) {
      const people = questions[0]?.answers[question1AnswerIndex]?.answer;
      const uses = question2Options
        .map((option) => questions[1]?.answers[option]?.answer)
        ?.join(', ');

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'dl_frequency_quiz_submitted',
        frequency: calculatorFrequency,
        people,
        uses,
      });
    }
  }, [showingResults]);

  const handleFormInputChange = (e) => {
    const questionIndex = parseInt(e.target.dataset.question, 10) ?? null;
    const answerIndex = parseInt(e.target.dataset.answer, 10) ?? null;

    // If we've received an answer for the first question
    if (questionIndex === 0) {
      setQuestion1AnswerIndex(answerIndex);
    }
    // If we've received an answer for the second question
    if (questionIndex === 1) {
      if (e.target.checked && !question2Options.includes(answerIndex)) {
        setQuestion2Options([...question2Options, answerIndex]);
      } else if (question2Options.includes(answerIndex)) {
        const foundAnswerIndex = question2Options.indexOf(answerIndex);
        const newQuestion2Options = question2Options.filter(
          (value, index) => index !== foundAnswerIndex
        );
        setQuestion2Options(newQuestion2Options);
      }
    }
  };

  return (
    <div className="frequency-calculator-modal ">
      <div className="mx-auto max-w-[550px] text-center">
        {eyebrow && (
          <Eyebrow
            className="mb-2 uppercase"
            onClick={() => {
              setRecommendedFilterFrequency(2000);
            }}
          >
            {showingResults && calculatorFrequency !== null
              ? 'Recommendation'
              : eyebrow}
          </Eyebrow>
        )}
        {title && (
          <Headline variant="h4" element="h4" className="mb-4">
            {showingResults && calculatorFrequency !== null
              ? 'Suggested Frequency'
              : title}
          </Headline>
        )}
        {showingResults && calculatorFrequency !== null
          ? ''
          : content && <Body>{content}</Body>}
        <div className="my-[40px] w-[100vw] -translate-x-1/2 border-b border-b-[#DDE2E7]" />
      </div>

      {showingResults && (
        <div className="text-center text-[18px]">
          <Body className="uppercase">{recommendationName}:</Body>
          <strong className="mb-10 mt-2 block text-[26px]">
            Every {calculatorFrequency} months
          </strong>
          <div className="relative mx-auto my-4 aspect-square w-[175px] ">
            <Image
              src={recommendationImage?.src}
              alt={recommendationImage?.alt}
              width="175px"
              height="175px"
              className="absolute h-full w-full object-contain mix-blend-multiply"
            />
          </div>
          <Button
            className="mx-auto mt-[40px] block"
            theme="primary"
            variant="filled"
            onClick={() => {
              closeModal();
            }}
          >
            Continue Shoppping
          </Button>
          <button
            type="button"
            className="mt-4 text-sm underline"
            onClick={() => {
              setShowingResults(false);
            }}
          >
            Retake Quiz
          </button>
        </div>
      )}

      {!showingResults && (
        <>
          {questions && (
            <form className="max-w-[650px]">
              {questions.map((question, questionIndex) => {
                const questionTitle = question?.question;
                const questionAnswers = question?.answers;
                return (
                  <div key={`question-${questionIndex}`}>
                    <h6 className="font-400 mb-2 mt-[40px] text-lg">
                      {questionIndex + 1}. {questionTitle}
                    </h6>
                    {questionAnswers.length > 0 && (
                      <ul className="question-answers mt-4 flex flex-wrap gap-4">
                        {questionAnswers.map((answer, answerIndex) => {
                          const questionAnswer = answer?.answer;
                          return (
                            <li
                              key={`question-${questionIndex}-answer-${answerIndex}`}
                            >
                              <label
                                className="cursor-pointer text-base font-light"
                                htmlFor={`question-${questionIndex}-answer-${answerIndex}`}
                              >
                                <input
                                  data-question={questionIndex}
                                  data-answer={answerIndex}
                                  id={`question-${questionIndex}-answer-${answerIndex}`}
                                  type={
                                    questionIndex === 0 ? 'radio' : 'checkbox'
                                  }
                                  name={`question-${questionIndex}`}
                                  value={questionAnswer}
                                  className="mr-2"
                                  onChange={handleFormInputChange}
                                  checked={
                                    (questionIndex === 0 &&
                                      answerIndex === question1AnswerIndex) ||
                                    (questionIndex === 1 &&
                                      question2Options.includes(answerIndex))
                                  }
                                />
                                {questionAnswer}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                );
              })}
            </form>
          )}
          {cta && (
            <Button
              className="mx-auto mt-[40px] block"
              theme="primary"
              variant="filled"
              onClick={() => {
                if (calculatorFrequency !== null) setShowingResults(true);
              }}
            >
              {cta}
            </Button>
          )}
        </>
      )}
    </div>
  );
}
