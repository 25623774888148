import { useCallback, useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { useSettings } from '@backpackjs/storefront';

export function useSearchArticleResults({ term, mounted = true }) {
  const settings = useSettings();

  const [articlesFuse, setArticlesFuse] = useState(null);
  const [articleResults, setArticleResults] = useState(null);
  const { articlesEnabled } = { ...settings?.search?.results };

  const setArticlesFuseOnMount = useCallback(async () => {
    try {
      if (articlesFuse) return;
      const response = await fetch('/json/articles-list.json');
      const list = await response.json();

      setArticlesFuse(
        new Fuse(list, {
          keys: ['title'],
          ignoreLocation: true,
          minMatchCharLength: 3,
          threshold: 0.3,
        })
      );
    } catch (error) {
      console.error(error.message);
    }
  }, [articlesFuse]);

  const searchArticlesOnTermChange = useCallback(() => {
    if (!articlesFuse) return;
    if (!term) {
      setArticleResults([]);
      return;
    }
    const results = articlesFuse.search(term);
    setArticleResults(results);
  }, [articlesFuse, term]);

  useEffect(() => {
    // if (!mounted || !pagesEnabled) return;
    setArticlesFuseOnMount();
  }, [mounted, articlesEnabled]);

  useEffect(() => {
    searchArticlesOnTermChange();
  }, [term, articlesFuse]);

  return { articleResults };
}
