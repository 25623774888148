import { useEffect, useState } from 'react';

// eslint-disable-next-line import/extensions
import ScrollyVideo from 'scrolly-video/dist/ScrollyVideo.cjs.jsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReviewStars, Image, Markdown } from '../../snippets';
import Schema from './ScrollyVideoSection.schema';
import ScrollyVideoMobileCard from './ScrollyVideoMobileCard';
import useMediaQuery from '../../utils/useMediaQuery';

const getCardWrapperClasses = (index) => {
  const justifyEndClass =
    index % 2 === 1 ? 'justify-end' : 'justify-start text-right';
  return `scrolly-video__card-wrap flex items-start z-[1] relative md:mx-[2rem] lg:mx-[0rem] h-[100vh] ${justifyEndClass}`;
};

export default function ScrollyVideoSection({ cms }) {
  const { videoLink, videoCards, eyebrowText, title, videoCardsMobile } = cms;

  const cardsWithReviews = videoCardsMobile.filter((card) => card.review);

  const isMobile = useMediaQuery('(max-width: 767px)');
  const [shouldShow, setShouldShow] = useState(false);
  useEffect(() => {
    if (isMobile) {
      setShouldShow(false);
    } else {
      setShouldShow(true);
    }
  }, [isMobile]);

  return (
    <div className="scrolly-video relative bg-white">
      <div className="scrolly-video__heading mx-auto mb-0 w-full bg-transparent px-2 pt-16 text-center text-[#202635] md:absolute md:left-1/2 md:top-[7rem] md:z-[1] md:-translate-x-1/2 md:-translate-y-1/2 md:pb-10">
        {eyebrowText && (
          <p className="text-md mb-4 max-sm:text-[0.813rem]">{eyebrowText}</p>
        )}
        {title && (
          <h2 className="text-[3.5rem] font-light leading-snug max-sm:text-[2rem]">
            {title}
          </h2>
        )}
      </div>

      {/* Desktop */}
      <div className="scrolly-video__desk px-contained hidden h-[700vh] md:block">
        {shouldShow ? <ScrollyVideo src={videoLink} /> : ''}
        {videoCards.map((card, index) => (
          <div
            key={index}
            className={getCardWrapperClasses(index)}
            style={{
              marginBottom: `${card.cardSpacing}vh`,
            }}
          >
            <div className="scrolly-video__card-info sticky top-[30vh] rounded-lg bg-gradient-to-t from-[#ffffff] to-[#e6f0f8] p-[18px] text-[#202635] shadow-xl sm:max-w-[275px] lg:max-w-[375px]">
              <div
                className={`mb-2 inline-flex items-center text-lg font-normal ${
                  index % 2 === 1 ? 'flex-row-reverse' : 'flex-row'
                }`}
              >
                {card.title && (
                  <h3 className="text-xl font-normal">{card.title}</h3>
                )}
                {card.icon && (
                  <Image
                    className={`mx-auto pt-0 max-sm:w-[18px] ${
                      index % 2 === 1 ? 'mr-2' : 'ml-2'
                    }`}
                    sizes="48px"
                    width="24px"
                    height="24px"
                    src={card.icon.src}
                    alt={card.icon.alt}
                  />
                )}
              </div>
              {card.subtitle && (
                <Markdown className="scrolly-video__desk__subtitle">
                  {card.subtitle}
                </Markdown>
              )}
              {card.reviewIntro && (
                <p className="text-md mb-4 font-normal text-[#0064B7]">
                  {card.reviewIntro}:
                </p>
              )}
              {card.review && (
                <div className="scrolly-video__card rounded-lg border-[0.0625rem] border-[#202635] p-[1rem] text-left">
                  <ReviewStars rating={card.rating} color="#2179C2" />
                  {card.reviewName && (
                    <p className="text-md mb-2 mt-2 font-normal">
                      {card.reviewName}
                    </p>
                  )}
                  {card.review && (
                    <p className="mb-4 text-sm font-light">{card.review}</p>
                  )}
                  {card.date && <p className="text-xs">{card.date}</p>}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Mobile */}
      <div className="scrolly-video__mob">
        {cardsWithReviews.length > 0 && (
          <Swiper slidesPerView={1.3} centeredSlides loop spaceBetween={7}>
            {videoCardsMobile.map((card, index) => (
              <div>
                {card.review && (
                  <SwiperSlide
                    key={index}
                    className="hidden pt-24 max-md:block"
                  >
                    <div className="mb-4 text-center">
                      {card.reviewIntro && (
                        <p className="text-md mb-4 font-normal text-[#0064B7]">
                          {card.reviewIntro}:
                        </p>
                      )}
                      {card.review && (
                        <div className="scrolly-video__card flex h-[17rem] flex-col items-start justify-center rounded-lg bg-[#E6F0F8] p-[25px] text-left shadow-lg">
                          <ReviewStars rating={card.rating} color="#2179C2" />
                          {card.reviewName && (
                            <p className="mb-2 mt-2 text-lg font-normal">
                              {card.reviewName}
                            </p>
                          )}
                          {card.review && (
                            <p className="text-md mb-4 font-light">
                              {card.review}
                            </p>
                          )}
                          {card.date && <p className="text-sm">{card.date}</p>}
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                )}
              </div>
            ))}
          </Swiper>
        )}

        <div className="hidden scrollbar-hide max-md:block">
          {videoCardsMobile.map((card, index) => {
            return (
              <ScrollyVideoMobileCard
                card={card}
                index={index}
                key={`scrolly-mobile-card-${index}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

ScrollyVideoSection.Schema = Schema;
