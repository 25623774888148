export default {
  label: 'Register',
  name: 'register',
  component: 'group',
  fields: [
    {
      label: 'Register Page Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Flyout Heading',
      name: 'flyoutHeading',
      component: 'textarea',
    },
    {
      label: 'Flyout Subeading',
      name: 'subheading',
      component: 'text',
    },
    {
      label: 'Login Text',
      name: 'loginText',
      component: 'text',
    },
    {
      label: 'Login Link Text',
      name: 'loginLinkText',
      component: 'text',
    },
  ],
  defaultValue: {
    heading: 'Create Account',
    subheading: 'Track orders and manage your subscription in your account.',
    loginText: 'Already have an account?',
    loginLinkText: 'Sign In',
  },
};
