import chunk from 'lodash.chunk';
import { useMemo, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useColorSwatches } from '../../hooks';
import {
  Button,
  Headline,
  Link,
  ProductItem,
  Spinner,
  Svg,
} from '../../snippets';
import Carousel from '../../snippets/Carousel/Carousel';
import Schema from './ProductCarousel.schema';

export default function ProductCarousel({ cms }) {
  const { heading, productItem, products, cta } = cms;
  const remappedProductsDesktop = useMemo(() => chunk(products, 4), [products]);

  return (
    <section className="pt-10">
      <div className="px-contained mx-auto">
        <div className="relative z-10 mb-10 flex items-center justify-between lg:pl-20">
          <Headline variant="h3">{heading}</Headline>
          {cta && (
            <div className="justify-center lg:mt-14 lg:hidden">
              <Link className="underline" href={cta.url}>
                {cta.text}
              </Link>
            </div>
          )}
        </div>
        <div className="hidden lg:block">
          <Carousel
            topArrows
            infiniteLoop
            itemsPerPageMultiplier={4}
            overwriteTotalItems={products.length}
          >
            {remappedProductsDesktop?.map((productChunk, index) => (
              <div className="grid grid-cols-4" key={index}>
                {productChunk?.map(({ product }, _index) => (
                  <div
                    className="group relative flex-1 shrink-0 border border-r-0  border-[#E9E9EB] p-6 text-left transition-all last:border-r"
                    key={_index}
                  >
                    <div className="pointer-events-none absolute left-0 top-0 z-10 h-full w-full bg-gradient-to-l from-[#F1DCC126] to-[#2179C226] opacity-0 bg-blend-multiply transition-opacity group-hover:opacity-100" />
                    <div className="relative z-0 [&_.product-item-media]:bg-background">
                      <ProductItem
                        disableBackground
                        disableHoverEffect
                        enabledColorNameOnHover={
                          productItem?.enabledColorNameOnHover
                        }
                        enabledColorSelector={productItem?.enabledColorSelector}
                        enabledQuickShop={productItem?.enabledQuickShop}
                        enabledStarRating={productItem?.enabledStarRating}
                        handle={product?.handle}
                        index={index}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </Carousel>
        </div>
        <div className="pb-10 lg:hidden">
          <ProductSliderMobile productItem={productItem} products={products} />
        </div>

        {cta?.url && cta?.text && (
          <div className="hidden justify-center lg:mt-14 lg:flex">
            <Button theme="primary" variant="filled" href={cta.url}>
              {cta.text}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
}

function ProductSliderMobile({ productItem, products }) {
  const { swatchesMap } = useColorSwatches();

  const [swiper, setSwiper] = useState(null);

  const slidesPerViewDesktop = 4;
  const slidesPerViewTablet = 3.4;
  const slidesPerViewMobile = 2.1;
  const isFullBleedAndCentered = false;
  const isLoop = false;
  const maxWidthClass = 'max-w-[var(--content-max-width)]';

  return (
    <div>
      {products && (
        <Swiper
          centeredSlides={
            isFullBleedAndCentered && products.length >= slidesPerViewMobile * 2
          }
          className={`relative mt-10 w-full ${maxWidthClass}`}
          grabCursor
          loop={isLoop && products.length >= slidesPerViewMobile * 2}
          modules={[Navigation]}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            disabledClass: 'cursor-not-allowed opacity-20',
          }}
          onSwiper={setSwiper}
          slidesOffsetAfter={isFullBleedAndCentered ? 0 : 16}
          slidesOffsetBefore={isFullBleedAndCentered ? 0 : 16}
          slidesPerView={slidesPerViewMobile}
          spaceBetween={16}
          breakpoints={{
            768: {
              centeredSlides:
                isFullBleedAndCentered &&
                products.length >= slidesPerViewTablet * 2,
              loop: isLoop && products.length >= slidesPerViewTablet * 2,
              slidesPerView: slidesPerViewTablet,
              spaceBetween: 20,
              slidesOffsetBefore: isFullBleedAndCentered ? 0 : 32,
              slidesOffsetAfter: isFullBleedAndCentered ? 0 : 32,
            },
            1024: {
              centeredSlides:
                isFullBleedAndCentered &&
                products.length >= slidesPerViewDesktop * 2,
              loop: isLoop && products.length >= slidesPerViewDesktop * 2,
              slidesPerView: slidesPerViewDesktop,
              spaceBetween: 20,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          }}
        >
          {swiper &&
            products.map(({ product }, index) => {
              return (
                <SwiperSlide key={index}>
                  <ProductItem
                    disableBackground
                    disableHoverEffect
                    enabledColorNameOnHover={
                      productItem?.enabledColorNameOnHover
                    }
                    enabledColorSelector={productItem?.enabledColorSelector}
                    enabledQuickShop={productItem?.enabledQuickShop}
                    enabledStarRating={productItem?.enabledStarRating}
                    handle={product?.handle}
                    index={index}
                    swatchesMap={swatchesMap}
                  />
                </SwiperSlide>
              );
            })}

          {products.length > slidesPerViewDesktop && (
            <div className="z-1 relative left-0 right-0 top-[calc(50%-28px)] max-lg:h-[50px] md:px-8 lg:absolute xl:px-14">
              <div
                className={`relative mx-auto justify-center gap-[40px] max-lg:mt-[100px] max-lg:flex max-sm:mt-[75px] ${maxWidthClass} ${
                  isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                }`}
              >
                <div
                  className={`swiper-button-prev relative left-0 top-0 !h-[3.5rem] !w-[3.5rem] rounded-full border border-border bg-white bg-gradient-to-r from-[#C1EFFA] to-[#8DCEF9] p-[1px] after:hidden lg:absolute lg:top-[calc(50%-1.6875rem)] lg:!flex ${
                    !isFullBleedAndCentered ? 'xl:-left-[1.6875rem]' : ''
                  }`}
                >
                  <div className="inner-content flex h-full w-full items-center justify-center rounded-full bg-white">
                    <Svg
                      className="w-[14px] text-black"
                      src="/svgs/arrow-left.svg#arrow-left"
                      title="Arrow Left"
                      viewBox="0 0 24 24"
                    />
                  </div>
                </div>

                <div
                  className={`swiper-button-next relative right-0 top-0 !h-[3.5rem] !w-[3.5rem] rounded-full border border-border bg-white bg-gradient-to-r from-[#C1EFFA] to-[#8DCEF9] p-[1px] after:hidden lg:absolute lg:top-[calc(50%-1.6875rem)] lg:!flex ${
                    !isFullBleedAndCentered ? 'xl:-right-[1.6875rem]' : ''
                  }`}
                >
                  <div className="inner-content flex h-full w-full items-center justify-center rounded-full bg-white">
                    <Svg
                      className="w-[14px] text-black"
                      src="/svgs/arrow-right.svg#arrow-right"
                      title="Arrow Right"
                      viewBox="0 0 24 24"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Swiper>
      )}

      {!swiper && (
        <div className="relative min-h-[20rem]">
          <Spinner
            width="32"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      )}
    </div>
  );
}

ProductCarousel.displayName = 'ProductCarousel';
ProductCarousel.Schema = Schema;
