import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination } from 'swiper/modules';
import { decode } from 'he';
import { useEWGContext } from '../../contexts/ewgContext';
import {
  Button,
  ContaminantResultCard,
  Eyebrow,
  Headline,
} from '../../snippets';
import { Schema } from './WIYWTopContaminants.schema';

export function WIYWTopContaminants() {
  const { EWGResultsTop3Contaminants, activeEWGSystem, EWGResults } =
    useEWGContext();

  const print = () => {
    window.print();
  };

  return (
    <>
      <div className="px-contained relative mx-auto max-w-[var(--content-max-width)] py-[30px] text-center print:hidden md:py-[60px]">
        <div className="top-contaminants-header">
          <Eyebrow className="uppercase">Top Contaminants</Eyebrow>
          <Headline variant="h3" element="h2">
            Most Harmful Contaminants in{' '}
            <span className="font-[500]">
              {decode(activeEWGSystem?.SystemName || 'N/A')}
            </span>
          </Headline>
        </div>
        {EWGResultsTop3Contaminants && (
          <>
            <div className="top-contaminants-body py-[60px]">
              {EWGResultsTop3Contaminants?.length > 0 && (
                <Swiper
                  className="w-full"
                  modules={[Pagination]}
                  pagination={{
                    el: '.top-contaminants-pagination-container',
                    type: 'bullets',
                    clickable: true,
                    bulletActiveClass: '!bg-[#202635]',
                    lockClass: 'hidden',
                    renderBullet(_, className) {
                      return `<span class="block !mx-2 bg-[#8a8d97] w-2 h-2 rounded-full ${className} opacity-100"></span>`;
                    },
                  }}
                  spaceBetween={20}
                  slidesPerView={1}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {EWGResultsTop3Contaminants.map(
                    (contaminant, contaminantIndex) => {
                      return (
                        <SwiperSlide
                          className="h-auto w-full self-stretch py-2"
                          key={contaminantIndex}
                        >
                          <ContaminantResultCard contaminant={contaminant} />
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
              )}
              <div className="top-contaminants-pagination-container mx-auto mt-8 flex justify-center lg:mt-16" />
            </div>

            <div className="top-contaminants-footer">
              <Button onClick={print} variant="filled" theme="primary">
                View the PDF
              </Button>
            </div>
          </>
        )}

        {!EWGResultsTop3Contaminants && (
          <div className="top-contaminants-body pt-10">
            <p className="mx-auto max-w-[520px] border border-solid border-error bg-[#f1e5e3] py-1 text-center text-error">
              No contaminants found
            </p>
          </div>
        )}
      </div>
      <div className="hidden py-10 print:block">
        <div className="top-contaminants-header">
          <div className="wiyw-dashboard-header text-center font-sans text-xl font-light">
            Most Harmful Contaminants in{' '}
            <span className="font-[500]">
              {decode(activeEWGSystem?.SystemName || 'N/A')}
            </span>
          </div>
        </div>
        {EWGResultsTop3Contaminants &&
          EWGResultsTop3Contaminants?.length > 0 && (
            <div className="top-contaminants-body flex pt-10">
              {EWGResultsTop3Contaminants.map(
                (contaminant, contaminantIndex) => {
                  return (
                    <div
                      key={`print-contaminant-result-card-${contaminantIndex}`}
                      className="w-1/3 px-2"
                    >
                      <ContaminantResultCard
                        contaminant={contaminant}
                        className="mb-3"
                      />
                    </div>
                  );
                }
              )}
            </div>
          )}

        {!EWGResultsTop3Contaminants && (
          <div className="top-contaminants-body pt-10">
            <p className="mx-auto max-w-[520px] border border-solid border-error bg-[#f1e5e3] py-1 text-center text-error">
              No contaminants found
            </p>
          </div>
        )}
      </div>
    </>
  );
}

WIYWTopContaminants.displayName = 'WIYWTopContaminants';
WIYWTopContaminants.Schema = Schema;
