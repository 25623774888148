import { useCustomer, useSettings } from '@backpackjs/storefront';

import { useState } from 'react';
import { Link } from '../Link';
import { Svg } from '../Svg';
import { useGlobalContext } from '../../contexts';
import { Image } from '../Image';

export function MenuSidebar({
  handleCloseSidebar,
  menuSidebarOpen,
  promobarDisabled,
  promobarHidden,
}) {
  const settings = useSettings();
  const {
    actions: { openCart },
  } = useGlobalContext();
  const customer = useCustomer();

  const { links: additionalLinks, menuItems } = { ...settings?.header?.menu };
  const { logo } = { ...settings?.general };
  const [openSubMenus, setOpenSubMenus] = useState([]);

  const toggleOpenSubNavMenu = (index) => {
    if (openSubMenus.includes(index)) {
      setOpenSubMenus(
        openSubMenus.filter((e) => {
          return e !== index;
        })
      );
    } else {
      setOpenSubMenus([...openSubMenus, index]);
    }
  };

  return (
    <div
      className={`fixed left-0 top-0 z-[60] h-full w-full lg:hidden ${
        menuSidebarOpen ? 'pointer-events-auto' : 'pointer-events-none'
      } ${
        promobarDisabled || promobarHidden ? '' : 'mt-[var(--promobar-height)]'
      }`}
    >
      {/* Overlay */}
      <div
        role="button"
        aria-hidden="true"
        aria-label="Close menu sidebar"
        tabIndex="-1"
        className={`absolute left-0 top-0 hidden h-full w-full bg-[rgba(0,0,0,0.3)] transition duration-200 md:block ${
          menuSidebarOpen ? 'opacity-100' : 'opacity-0'
        }`}
        onClick={handleCloseSidebar}
      />

      <aside
        className={`absolute right-full top-0 flex h-full w-full flex-col overflow-hidden bg-white transition md:max-w-[var(--sidebar-width)] ${
          menuSidebarOpen ? 'translate-x-full' : '-translate-x-0'
        }`}
      >
        {menuSidebarOpen && (
          <>
            <div className="relative flex h-[60px] items-center justify-between border-b border-b-border px-[18px]">
              <button
                className="flex-[0_0_80px]"
                aria-label="Close menu sidebar"
                onClick={handleCloseSidebar}
                type="button"
              >
                <Svg
                  className="w-5 text-primary"
                  src="/svgs/close.svg#close"
                  title="Close"
                  viewBox="0 0 24 24"
                />
              </button>

              <Link
                className="flex flex-[1_1_100%] justify-center"
                aria-label="Go to homepage"
                href="/"
                onClick={handleCloseSidebar}
              >
                <div className="w-[140px]">
                  <Image
                    priority
                    height="30"
                    width="140"
                    className="h-auto w-full"
                    alt="Clearly Filtered"
                    src={
                      logo?.src ||
                      'https://cdn.shopify.com/s/files/1/1011/0318/files/Primary_Logo_Horizontal_c3aceaee-7ecc-4fb8-90a6-98df0ee6f7c8.png?v=1690837284'
                    }
                    quality="90"
                  />
                </div>
              </Link>

              <div className="flex flex-[0_0_80px] justify-end gap-4">
                <Link
                  className="w-[17px] text-primary"
                  aria-label="Go to account page"
                  onClick={handleCloseSidebar}
                  href={customer ? '/account/orders' : '/account/login'}
                >
                  <Svg
                    className="w-full"
                    src="/svgs/account.svg#account"
                    title="Account"
                    viewBox="0 0 17 17"
                  />
                </Link>
                <div className="relative flex items-center justify-center">
                  <button
                    aria-label="Open cart"
                    className="w-[21px]"
                    onClick={openCart}
                    type="button"
                  >
                    <Svg
                      className="w-full text-text"
                      src="/svgs/cart.svg#cart"
                      title="Cart"
                      viewBox="0 0 21 19"
                    />
                  </button>
                </div>
              </div>
            </div>

            <div className="relative w-full flex-1 overflow-x-hidden">
              <div className="h-full w-full overflow-y-auto scrollbar-hide">
                <nav className="mb-8 flex">
                  <ul className="w-full flex-col">
                    {menuItems?.map((item, index) => {
                      const hasContent =
                        item.links?.length > 0 || item.imageLinks?.length > 0;

                      return (
                        <li
                          key={index}
                          className="flex min-h-[3.5rem] w-full flex-col border-b border-b-border"
                        >
                          {hasContent ? (
                            <>
                              <button
                                aria-label={item.menuItem.text}
                                className="flex h-14 w-full items-center justify-between gap-5 p-4"
                                onClick={() => {
                                  toggleOpenSubNavMenu(index);
                                }}
                                type="button"
                              >
                                <p className="text-nav flex-1 text-left text-xl font-light text-primary">
                                  {item.menuItem.text}
                                </p>

                                <Svg
                                  className={`w-5 text-primary transition-transform duration-200 ease-in-out ${
                                    openSubMenus.includes(index)
                                      ? 'rotate-180'
                                      : ''
                                  }`}
                                  src="/svgs/chevron-down.svg#chevron-down"
                                  title="Expand Navigation"
                                  viewBox="0 0 24 24"
                                />
                              </button>
                              <ul
                                className={`mb-4 ${
                                  openSubMenus.includes(index)
                                    ? 'block'
                                    : 'hidden'
                                }`}
                              >
                                {item.links.map((linkItem, linkIndex) => {
                                  return (
                                    <li key={`${linkIndex}-subnavlink`}>
                                      <Link
                                        aria-label={linkItem?.link?.text}
                                        className="flex w-full items-center px-4 py-2 text-base font-light text-primary"
                                        href={linkItem?.link?.url}
                                        onClick={() => {
                                          setOpenSubMenus([]);
                                          handleCloseSidebar();
                                        }}
                                      >
                                        {linkItem?.image?.src && (
                                          <div className="relative mr-4 aspect-square h-5 w-5">
                                            <Image
                                              height="264"
                                              width="330"
                                              className="absolute inset-0 m-auto box-border block h-0 max-h-full min-h-full w-0 min-w-full max-w-full border-none object-contain p-0 mix-blend-multiply"
                                              alt={linkItem?.link?.text}
                                              sizes="50px"
                                              src={linkItem?.image?.src}
                                              quality="90"
                                            />
                                          </div>
                                        )}
                                        {linkItem?.link?.text}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </>
                          ) : (
                            <Link
                              aria-label={item.menuItem.text}
                              className="text-nav flex h-14 w-full items-center p-4 text-xl font-light text-primary"
                              href={item.menuItem.url}
                              onClick={handleCloseSidebar}
                            >
                              {item.menuItem.text}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </nav>

                {additionalLinks?.length > 0 && (
                  <ul className="flex flex-col gap-[0.25rem] px-5 pb-16">
                    {additionalLinks.map(({ link, icon }, index) => {
                      return (
                        <li key={index} className="flex gap-[13px]">
                          {icon?.src && (
                            <div className="relative aspect-square w-[14px]">
                              <Image
                                fill
                                alt={link?.text}
                                className="!object-contain"
                                src={icon?.src}
                              />
                            </div>
                          )}
                          <Link
                            className="font-[300] text-text"
                            aria-label={link?.text}
                            href={link?.url}
                            onClick={handleCloseSidebar}
                          >
                            {link?.text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </>
        )}
      </aside>
    </div>
  );
}

MenuSidebar.displayName = 'MenuSidebar';
