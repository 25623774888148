const actionItemFields = [
  {
    label: 'Image',
    name: 'image',
    component: 'image',
  },
  {
    label: 'Title',
    name: 'title',
    component: 'text',
  },
  {
    component: 'link',
    name: 'link',
    label: 'Link',
    defaultValue: {
      text: 'Buy now',
      url: '/pages/about-us',
    },
  },
];

export default function Schema() {
  return {
    label: 'Image Actions Carousel',
    key: 'imageActionsCarousel',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/image_actions_carousel.png?v=1684843135',
    category: 'Slider',
    fields: [
      {
        label: 'Dark mode',
        name: 'darkMode',
        component: 'toggle',
        description: 'Use dark mode for this',
        defaultValue: false,
      },
      {
        component: 'link',
        name: 'mobileCta',
        label: 'Mobile CTA',
      },
      {
        label: 'Title',
        name: 'title',
        component: 'text',
      },
      {
        label: 'Action Items',
        name: 'actionItems',
        component: 'group-list',
        itemProps: {
          label: '{{item.title}}',
        },
        validate: {
          maxItems: 6,
        },
        fields: actionItemFields,
      },
    ],
  };
}
