const slideContent = {
  label: 'Slider Settings',
  name: 'slider',
  description: '',
  component: 'group',
  fields: [
    {
      label: 'Slides',
      name: 'slideItems',
      component: 'group-list',
      itemProps: {
        label: '{{item.title}}',
      },
      fields: [
        {
          label: 'Image',
          name: 'image',
          component: 'image',
        },
        {
          label: 'Title',
          name: 'title',
          component: 'text',
        },
        {
          label: 'Copy',
          name: 'copy',
          component: 'text',
        },
      ],
      defaultValue: [
        {
          title: 'BELIEF ONE',
          copy: 'Clean water. Clear conscience.',
        },
      ],
      defaultItem: {
        title: 'BELIEF ONE',
        copy: 'Clean water. Clear conscience.',
      },
    },
  ],
};

export const Schema = () => ({
  label: 'Image Slider',
  category: 'Slider',
  key: 'imageSlider',
  previewSrc:
    'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2010.46.01_20AM.png?v=1684864562',
  fields: [
    {
      label: 'Eyebrow',
      name: 'eyebrow',
      component: 'text',
      defaultValue: 'OUR MISSION',
    },
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Empower a Healthier You',
    },
    {
      label: 'Copy',
      name: 'copy',
      component: 'text',
      defaultValue:
        'We are on a mission to provide people with the tools to take control of their water and live healthier lives. It"s a lot easier to remove contaminants from the water you drink than to remove disease once it has entered the body.',
    },
    slideContent,
  ],
});
