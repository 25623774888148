import { useEffect } from 'react';
import {
  useCustomer,
  useCustomerLogout,
  useSettings,
} from '@backpackjs/storefront';
import { useGlobalContext } from '../../contexts';
import { useMultipass } from '../../hooks/useMultipass';
import { Button } from '../Button';
import { Link } from '../Link';
import { Svg } from '../Svg';
import { Headline } from '../Typography';

export function AccountMenu({ setActiveForm }) {
  const settings = useSettings();
  const customer = useCustomer();
  const { customerLogout } = useCustomerLogout();
  const {
    actions: { closeAccount },
  } = useGlobalContext();
  const multipass = useMultipass();

  const isSubscriber = customer?.tags?.filter((str) => {
    return (
      (typeof str === 'string' && str.indexOf('Active Subscriber') === 0) ||
      (typeof str === 'string' && str.indexOf('Inactive Subscriber') === 0)
    );
  });

  const { flyoutMenuItems } = {
    ...settings?.account?.menu,
  };

  const menuItems = flyoutMenuItems
    ?.filter((item) => {
      if (
        isSubscriber?.length === 0 &&
        item?.link?.url ===
          'https://www.clearlyfiltered.com/tools/recurring/portal/'
      ) {
        return;
      }
      return item;
    })
    .map((item) => {
      const { link } = item;
      return link;
    });

  useEffect(() => {
    if (!customer) {
      setActiveForm('login');
    }
  }, [customer]);

  const handleLogout = () => {
    customerLogout();
    closeAccount();
  };

  const handleMenuItemClick = async (e, url) => {
    if (url.includes('/tools/recurring/')) {
      e.preventDefault();

      const res = await multipass.generateUrl({
        customer: { email: customer?.email },
        returnTo: 'https://shop.clearlyfiltered.com/tools/recurring/login',
      });

      if (res?.url) {
        window.location.href = res.url;
      }
    }

    closeAccount();
  };

  return (
    customer && (
      <div className="flex flex-col  overflow-y-auto rounded px-4 py-3 md:px-8">
        <Headline variant="h5" className="lg:max-w-[350px]">
          Welcome Back{customer.firstName && `, ${customer.firstName}`}
        </Headline>

        {menuItems && (
          <ul className="mt-8">
            {menuItems.map((item) => (
              <li key={item.url}>
                <Link
                  className="mt-4 flex items-center justify-between border-b border-[#DDE2E7] pb-4 text-sm font-normal no-underline"
                  href={item.url}
                  newTab={item.newTab}
                  onClick={(e) => handleMenuItemClick(e, item.url)}
                >
                  <span className="text-lg">{item.text}</span>
                  <Svg
                    className="w-5 text-text"
                    src="/svgs/arrow-right.svg#arrow-right"
                    title="Close"
                    viewBox="0 0 24 24"
                  />
                </Link>
              </li>
            ))}
          </ul>
        )}

        <Button
          theme="primary"
          variant="filled"
          href="/account/address-book"
          className="mx-auto mt-12 flex w-full max-w-[210px] items-center justify-center"
          onClick={() => closeAccount()}
        >
          Go to My Account
        </Button>

        <button
          aria-label="Cancel"
          className="mt-6 text-center text-sm font-light tracking-[-.01em]"
          onClick={() => handleLogout()}
          type="button"
        >
          Not {customer.firstName}{' '}
          <span className="text-underline">Log Out</span>
        </button>
      </div>
    )
  );
}

AccountMenu.displayName = 'AccountMenu';
