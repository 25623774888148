import { useMemo } from 'react';
import {
  useCart,
  useCartAddItem,
  useProductInventoryByHandle,
  useProductItemByHandle,
  useSettings,
} from '@backpackjs/storefront';

import { Image } from '../Image';
import { Link } from '../Link';
import { useVariantPrices } from '../../hooks';

export function CartUpsell({ closeCart }) {
  const { cartAddItem } = useCartAddItem();
  const cart = useCart();
  const settings = useSettings();
  const { message, product } = { ...settings?.cart?.upsell };

  const { productItem } = useProductItemByHandle({
    handle: product?.handle,
  });
  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: product?.handle,
  });

  const selectedVariant = productItem?.variants?.[0];
  const variantInventory = inventory?.variants?.[selectedVariant?.id];
  const variantIsSoldOut =
    inventoryStatus?.success && !variantInventory?.availableForSale;
  const variantIsPreorder =
    !variantIsSoldOut && variantInventory?.currentlyNotInStock;
  const soldOutText = settings?.product?.addToCart?.soldOutText || 'Sold Out';
  const preorderText = settings?.product?.addToCart?.preorderText || 'Preorder';
  const addToCartText =
    settings?.product?.addToCart?.addToCartText || 'Add To Cart';
  const soldOutOrPreorderText = variantIsPreorder ? preorderText : soldOutText;
  const buttonText =
    variantIsSoldOut || variantIsPreorder
      ? soldOutOrPreorderText
      : addToCartText;

  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
  });

  const showUpsell = cart?.lines?.length > 0 && !!selectedVariant?.id;
  const image = product?.featuredImage;

  const productIsInCart = useMemo(() => {
    if (!showUpsell) return null;
    return !!cart.lines.find((item) => {
      return item.variant.product.handle === product.handle;
    });
  }, [cart?.updatedAt, product?.handle, showUpsell]);

  return showUpsell && !productIsInCart ? (
    <div className="flex flex-col gap-2 border-t border-t-border p-4">
      <h3 className="text-center text-xs font-normal">{message}</h3>

      <div className="flex items-center justify-center gap-4">
        <Link
          aria-label={product?.title}
          href={product?.path}
          onClick={closeCart}
          tabIndex="-1"
          className="w-10 bg-offWhite"
        >
          {image?.src && (
            <Image
              alt={product?.title}
              height={Math.floor(40 / (image.width / image.height))}
              src={image.src}
              width="40"
            />
          )}
        </Link>

        <div className="flex max-w-[25rem] flex-1 flex-col gap-2">
          <Link
            aria-label={product?.title}
            href={product?.path}
            onClick={closeCart}
          >
            <h4 className="text-xs font-bold">{product?.title}</h4>
          </Link>

          <div className="flex items-center justify-between gap-4">
            <button
              aria-label={buttonText}
              className="text-label-sm text-main-underline"
              disabled={variantIsSoldOut}
              onClick={() => {
                cartAddItem({
                  merchandiseId: selectedVariant?.id,
                  quantity: 1,
                });
              }}
              type="button"
            >
              {buttonText}
            </button>

            <div className="flex flex-1 flex-wrap justify-end gap-x-1">
              {compareAtPrice && (
                <p className="text-xs text-mediumGray line-through">
                  {compareAtPrice}
                </p>
              )}
              <p className="text-xs">{price}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

CartUpsell.displayName = 'CartUpsell';
