export default {
  label: 'Filter Replacement Calculator',
  name: 'filterReplacementCalculator',
  component: 'group',
  description: 'Settings for the filter replacement calculator modal.',
  fields: [
    {
      label: 'Calculator Eyebrow',
      name: 'eyebrow',
      component: 'text',
    },
    {
      label: 'Calculator Title',
      name: 'title',
      component: 'text',
    },
    {
      label: 'Calculator Content',
      name: 'content',
      component: 'text',
    },
    {
      label: 'Calculator CTA',
      name: 'cta',
      component: 'text',
    },
    {
      label: 'Calculator Questions',
      name: 'questions',
      component: 'group-list',
      description:
        'A list of questions to be shown on the calculator modal. The first question serves as the base calculation, and the number of options selected from the second question will serve to lower the frequency rate.',
      itemProps: {
        label: '{{item.question}}',
      },
      validate: {
        maxItems: 2,
      },
      fields: [
        {
          label: 'Question',
          name: 'question',
          component: 'text',
        },
        {
          label: 'Answers',
          name: 'answers',
          component: 'group-list',
          itemProps: {
            label: '{{item.answer}}',
          },
          fields: [
            {
              label: 'Answer',
              name: 'answer',
              component: 'text',
            },
          ],
        },
      ],
    },
  ],
};
