import { Eyebrow, Headline } from '../../../snippets/Typography';
import EmailInput from '../components/email-input';
import { Spinner } from '../../../snippets';

// generate jsdoc for props
/**
 * @typedef {Object} EmailCaptureProps
 * @property {string} email
 * @property {boolean} loading
 * @property {() => void} setEmail
 * @property {() => void} onSubmit
 * @property {() => void} onSkip
 * @property {Object} emailPage
 * @property {string} emailPage.eyebrow
 * @property {string} emailPage.heading
 * @property {string} emailPage.inputPlaceholder
 * @property {string} emailPage.ctaText
 * @property {string} emailPage.skipText
 * @property {string} emailPage.disclaimerText
 * @param {EmailCaptureProps} props
 * @returns {JSX.Element}
 */
export default function EmailCapture({
  emailPage,
  loading,
  onSubmit,
  email,
  setEmail,
  onSkip,
}) {
  return (
    <div className="px-contained flex min-h-screen flex-col items-center justify-center">
      <div className="max-w-[630px] text-center">
        <Eyebrow>{emailPage.eyebrow}</Eyebrow>
        <Headline variant="h3">{emailPage.heading}</Headline>

        <div className="mt-20">
          <EmailInput
            loading={loading}
            value={email}
            inputPlaceholder={emailPage.inputPlaceholder}
            onChange={(e) => setEmail(e.target.value)}
            onSubmit={() => onSubmit(email)}
            ctaText={emailPage.ctaText}
          />
        </div>

        <div className="mb-10 flex flex-col items-center">
          {loading ? (
            <span className="font-semibold hover:underline">
              <Spinner width="32" />
            </span>
          ) : (
            <span
              tabIndex={0}
              onClick={onSkip}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onSkip();
              }}
              role="button"
              className="font-semibold hover:underline"
            >
              {emailPage.skipText}
            </span>
          )}
        </div>

        <p className="text-xs font-light">{emailPage.disclaimerText}</p>
      </div>
    </div>
  );
}
