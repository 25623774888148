export function Schema({ pageHandle }) {
  if (pageHandle !== 'whats-in-your-water') return null;
  return {
    label: 'WIYW Top Contaminants',
    key: 'WIYWTopContaminants',
    category: 'WIYW',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/top-contaminants.png',
    fields: [],
  };
}
