/* eslint-disable jsx-a11y/media-has-caption */
import Vimeo from '@u-wave/react-vimeo';

export default function FiltrationTechnologyVideo({
  videoUrl,
  muted,
  loop,
  playsInline,
  className,
  controls,
  poster,
}) {
  if (videoUrl?.includes('vimeo'))
    return (
      <Vimeo
        responsive
        height="auto"
        video={videoUrl}
        loop={loop}
        muted={muted}
        className={className}
        controls={controls}
        background
      />
    );

  return (
    <video
      poster={poster}
      src={videoUrl}
      loop={loop}
      muted={muted}
      playsInline={playsInline}
      className={className}
      controls={controls}
    />
  );
}
