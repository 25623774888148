import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useGlobalContext } from '../../contexts';
import FrequencyCalculator from './FrequencyCalculator';

import { useGlobalProductPageContext } from '../../contexts/globalProductPageContext';

export function FrequencyCalculatorCTA({ frequencyCalculatorSettings }) {
  const { recommendedFilterFrequency, setRecommendedFilterFrequency } =
    useGlobalProductPageContext();

  const {
    actions: { openModal, closeModal },
  } = useGlobalContext();

  const {
    cta,
    title,
    content,
    responseMap,
    enableFrequencyCalculator,
    recommendationName,
    recommendationImage,
  } = frequencyCalculatorSettings;

  const handleFrequencyCalculatorModal = useCallback(() => {
    openModal(
      <FrequencyCalculator
        closeModal={closeModal}
        recommendationName={recommendationName}
        recommendationImage={recommendationImage}
        setRecommendedFilterFrequency={setRecommendedFilterFrequency}
        responseMap={responseMap}
      />
    );
  }, [responseMap]);

  return (
    enableFrequencyCalculator &&
    responseMap?.length > 0 && (
      <div className="pdp-frequency-calculator mt-6 rounded-[10px] border border-[#DDE2E7] bg-gradient-to-t from-[#F1DCC11A] to-[#2179C21A] p-[28px] text-center">
        <div className="mx-auto">
          {(title || recommendedFilterFrequency !== null) && (
            <div className="text-base md:text-lg">
              {recommendedFilterFrequency !== null ? (
                <>
                  <div className="mb-3 leading-tight">
                    Your Recommended Filter Delivery Frequency:
                  </div>
                  <strong className="block">
                    Every {recommendedFilterFrequency} Months
                  </strong>
                </>
              ) : (
                title
              )}
            </div>
          )}

          {content && recommendedFilterFrequency === null && (
            <div className="mt-4 text-sm font-light">{content}</div>
          )}

          {(cta || recommendedFilterFrequency !== null) && (
            <button
              type="button"
              onClick={() => {
                handleFrequencyCalculatorModal();
              }}
              className="mt-4 cursor-pointer text-textLink underline"
            >
              {recommendedFilterFrequency !== null
                ? 'Recalculate Your Recommendation'
                : cta}
            </button>
          )}
        </div>
      </div>
    )
  );
}

FrequencyCalculatorCTA.propTypes = {
  frequencyCalculatorSettings: PropTypes.shape({
    cta: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    responseMap: PropTypes.array,
    enableFrequencyCalculator: PropTypes.bool,
    recommendationName: PropTypes.string,
    recommendationImage: PropTypes.object,
  }),
};
