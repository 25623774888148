import PropTypes from 'prop-types';

export default function Eyebrow(props) {
  const { Element = 'p', className, children } = props;

  return (
    <Element
      className={`font-sans text-[0.813rem] font-[200] leading-[1.113rem] tracking-[4%] lg:text-[1.125rem] lg:leading-[1.54rem] ${className}`}
    >
      {children}
    </Element>
  );
}

Eyebrow.propTypes = {
  Element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'p']),
  children: PropTypes.node.isRequired,
};
