import React from 'react';
import { useArticle } from '@backpackjs/storefront';
import { Schema } from './ArticleHero.schema';
import { Image, Headline, Link } from '../../snippets';
import { Svg } from '../../snippets/Svg';
import { ArticleHeroShare } from './ArticleHeroShare';

export function ArticleHero({ cms }) {
  const {
    articleTime,
    title,
    mediaImage,
    mediaVideo,
    mediaPlacement,
    backgroundColor,
    centerContent,
  } = cms;

  const hasMedia = mediaImage || mediaVideo;

  const articleSettings = useArticle();

  return (
    <div className="relative text-primary">
      <div
        className={`absolute inset-0 bg-[#D9D9D9] bg-gradient-to-r from-[#2179C2] via-[#8DCEF9] to-[#F1DCC1] ${
          backgroundColor === 'blue' ? 'opacity-[15%]' : 'opacity-[0%]'
        } ${
          hasMedia
            ? 'from-[#00000080] to-[#00000000]'
            : 'bg-[#D9D9D9] from-[#2179C2] via-[#8DCEF9] to-[#F1DCC1] text-center opacity-20'
        }`}
      />
      <div
        className="absolute left-0 top-0 z-[-1] h-full w-full bg-cover bg-center"
        style={{
          backgroundImage: `${
            backgroundColor === 'red'
              ? 'url(https://cdn.shopify.com/s/files/1/1011/0318/files/pexels-photo-7512939.png?v=1686175785)'
              : null
          }`,
        }}
      />
      <div
        className={`${hasMedia ? 'md:flex' : ''} ${
          mediaPlacement && mediaPlacement === 'left' ? 'flex-row-reverse' : ''
        } relative overflow-hidden`}
      >
        <Link
          className="absolute left-12 top-8 hidden cursor-pointer lg:flex"
          href="/blogs/all"
        >
          <Svg
            className="w-5 text-text"
            src="/svgs/arrow-left.svg#arrow-left"
            title="Arrow Left"
            viewBox="0 0 24 24"
          />
          <p className="pl-6 font-light">Back to blog</p>
        </Link>

        <div
          className={`md:px-contained flex flex-col items-center px-[18px] py-4 text-left lg:py-28 ${
            hasMedia ? 'md:flex-[0_0_50%]' : 'md:text-center'
          } ${centerContent ? '!text-center' : ''}`}
        >
          <div
            className={`mx-auto flex w-full max-w-[975px] grow flex-col justify-center md:text-text ${
              hasMedia ? 'text-white' : 'text-text'
            }`}
          >
            {articleSettings?.category && articleTime && (
              <p className="font-momo !lg:text-[14px] mb-5 text-sm uppercase tracking-[0.04em] text-[#202635]">
                {articleSettings.category} •{' '}
                <span className="text-shadowGray">{articleTime} MIN READ</span>
              </p>
            )}

            {title && (
              <Headline
                className="mb-6 capitalize text-[#202635]"
                variant="h2"
                element="h2"
              >
                {title}
              </Headline>
            )}
          </div>
          <ArticleHeroShare
            link={`/articles/${articleSettings?.handle}`}
            centerContent={centerContent}
            title={title}
          />
        </div>

        {hasMedia ? (
          <div className="relative inset-0 z-0 md:flex-[0_0_50%] lg:px-12 lg:py-28">
            <div className="relative aspect-[762/576] min-h-full max-w-full">
              {mediaImage?.src && !mediaVideo ? (
                <Image
                  fill
                  alt={title}
                  sizes="(min-width: 1024px) 40vw, 100vw"
                  src={mediaImage?.src}
                />
              ) : null}

              {mediaVideo ? (
                <video
                  src={mediaVideo}
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="h-full w-full object-cover"
                />
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

ArticleHero.displayName = 'ArticleHero';
ArticleHero.Schema = Schema;
