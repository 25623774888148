import { useSettings } from '@backpackjs/storefront';
import { useEffect, useState } from 'react';
import { useVariantPrices } from '../../hooks';
import { Headline } from '../../snippets';
import { OkendoStarRating } from '../../snippets/Okendo/OkendoStarRating';
import { useGlobalProductPageContext } from '../../contexts/globalProductPageContext';

export function ProductHeader({
  product,
  selectedVariant,
  purchaseType,
  productType,
  productBannerText,
  customSavePercentageText,
}) {
  const { discount } = useGlobalProductPageContext();

  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
    purchaseType,
    isPdp: true,
  });

  const [displayPrice, setDisplayPrice] = useState(price);
  const [displayComparePrice, setDisplayComparePrice] =
    useState(compareAtPrice);
  const [savingsPercentage, setSavingsPercentage] = useState(compareAtPrice);

  useEffect(() => {
    if (!price) {
      return;
    }

    setDisplayPrice(price);
    setDisplayComparePrice(compareAtPrice);

    if (discount?.enableDiscount) {
      if (!compareAtPrice) {
        setDisplayComparePrice(price);
      }

      let discountPrice = parseFloat(price.replace('$', ''));
      if (discount.discountType === 'fixed') {
        discountPrice -= discount.discountAmount;
      } else {
        discountPrice -= discountPrice * (discount.discountAmount / 100);
      }
      discountPrice = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
        .format(discountPrice)
        .replace('.00', '');
      setDisplayPrice(discountPrice);

      setSavingsPercentage(`${discount.discountAmount}%`);
    } else if (compareAtPrice) {
      const compareAtPriceNew = compareAtPrice.replace('$', '');
      const priceNew = price.replace('$', '');
      const percentageSaved =
        ((compareAtPriceNew - priceNew) / compareAtPriceNew) * 100;
      setSavingsPercentage(`${percentageSaved}%`);
    }
  }, [price, compareAtPrice, discount, product]);

  const settings = useSettings();
  const { enabledStarRating } = { ...settings?.product?.reviews };
  const [hasReviews, setHasReviews] = useState(false);

  useEffect(() => {
    const metaFields = product?.metafields;
    metaFields?.forEach((metaField) => {
      if (metaField.key === 'ProductReviewsWidgetSnippet') {
        setHasReviews(true);
      }
    });
  }, [product]);

  return (
    <div className="product-header text-primary md:pl-4 lg:pl-10 xl:pl-16">
      <div className="product-header__eyebrow mb-4 flex items-center gap-x-2 md:flex-row">
        {enabledStarRating && hasReviews && (
          <button
            className="product-header__ratings"
            aria-label="Scroll to product reviews"
            type="button"
          >
            <OkendoStarRating
              className="[&_span]:text-[14px] [&_span]:font-light [&_span]:md:text-[20px]"
              productId={product?.legacyResourceId}
            />
          </button>
        )}
      </div>

      {product?.title && (
        <Headline
          element="h1"
          variant="h3"
          className="product-header__title mb-6"
        >
          {product.title}
        </Headline>
      )}

      <div
        className={`product-header__price-container mt-2 flex min-h-[1.5rem] gap-2 text-[20px] ${
          productType === 'featured' || productType === 'onetimeOnly'
            ? 'justify-between'
            : ''
        }`}
      >
        <div className="product-header__pricing flex gap-2">
          {displayComparePrice && (
            <div className="product-header__compare-price text-mediumGray line-through">
              {displayComparePrice}
            </div>
          )}

          <div className="product-header__price">{displayPrice}</div>
        </div>

        {productType && displayComparePrice && price && (
          <span className="text-sm uppercase text-[#0064B7] text-right font-mono">
            {customSavePercentageText 
              ? customSavePercentageText.replace(/(\{\{\s?amount\s?\}\})/gi, savingsPercentage)
              : `Save ${savingsPercentage}${productType === 'featured' ? ' w/ subscription!' : '!'}`}
          </span>
        )}


      </div>

      {['featured', 'onetimeOnly'].includes(productType) &&
        productBannerText && (
          <div className="product-header__banner mt-6 bg-[linear-gradient(270.3deg,_rgba(33,_121,_194,_0.25)_0%,_rgba(141,_206,_249,_0.25)_51.04%,_rgba(241,_220,_193,_0.25)_100%)] p-2 text-center">
            <p>{productBannerText}</p>
          </div>
        )}
    </div>
  );
}

ProductHeader.displayName = 'ProductHeader';
