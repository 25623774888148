export default function Schema() {
  return {
    label: 'Scrolly Video Section',
    key: 'scrolly-video-section',
    fields: [
      {
        label: 'Eyebrow Text',
        name: 'eyebrowText',
        component: 'text',
      },
      {
        label: 'Title',
        name: 'title',
        component: 'text',
      },
      {
        label: 'Video Link',
        name: 'videoLink',
        component: 'text',
      },
      {
        label: 'Video Cards',
        name: 'videoCards',
        component: 'group-list',
        validate: {
          maxItems: 4,
        },
        fields: [
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Icon',
            name: 'icon',
            component: 'image',
          },
          {
            label: 'Card Spacing (vh)',
            name: 'cardSpacing',
            component: 'number',
            defaultValue: '50',
          },
          {
            label: 'Subtitle',
            name: 'subtitle',
            component: 'markdown',
          },
          {
            label: 'Review Intro',
            name: 'reviewIntro',
            component: 'text',
          },
          {
            label: 'Rating',
            name: 'rating',
            component: 'number',
            defaultValue: 5,
          },
          {
            label: 'Review Name',
            name: 'reviewName',
            component: 'text',
          },
          {
            label: 'Review',
            name: 'review',
            component: 'textarea',
          },
          {
            label: 'Date',
            name: 'date',
            component: 'text',
          },
        ],
      },
      {
        label: 'Video Cards (Mobile)',
        name: 'videoCardsMobile',
        component: 'group-list',
        validate: {
          maxItems: 4,
        },
        fields: [
          {
            label: 'CardVideo Link',
            name: 'cardVideoLink',
            component: 'text',
          },
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Icon',
            name: 'icon',
            component: 'image',
          },
          {
            label: 'Subtitle',
            name: 'subtitle',
            component: 'markdown',
          },
          {
            label: 'Review Intro',
            name: 'reviewIntro',
            component: 'text',
          },
          {
            label: 'Rating',
            name: 'rating',
            component: 'number',
            defaultValue: 5,
          },
          {
            label: 'Review Name',
            name: 'reviewName',
            component: 'text',
          },
          {
            label: 'Review',
            name: 'review',
            component: 'textarea',
          },
          {
            label: 'Date',
            name: 'date',
            component: 'text',
          },
        ],
      },
    ],
  };
}
