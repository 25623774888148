import { Link } from '../Link';

export function Attribution({ caption, attribution, attributionLink }) {
  return (
    <div className="attribution mt-2">
      {caption && <p className="text-mediumGray">{caption}</p>}
      {attribution && (
        <Link
          href={attributionLink?.url ?? ''}
          newTab={attributionLink?.newTab ?? false}
          className="text-sm text-mediumGray"
        >
          {attribution}
        </Link>
      )}
    </div>
  );
}
