export const Schema = () => ({
  category: 'Article',
  label: 'Article Contaminant Facts',
  key: 'articleContaminantFacts',
  //   previewSrc:
  //     'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-24_20at_202.55.23_20PM.png?v=1684965343',
  fields: [
    {
      name: 'title',
      component: 'text',
      label: 'Title',
      description: 'Leave Blank to hide from Table of Contents',
    },
    {
      name: 'anchorId',
      component: 'text',
      label: 'Table Of Contents Title',
      description: 'Leave Blank to hide from Table of Contents',
    },
    {
      name: 'header',
      component: 'text',
      label: 'Header',
      defaultValue: 'Contamination Facts',
    },
    {
      label: 'Facts',
      name: 'facts',
      validate: {
        maxItems: 3,
      },
      component: 'group-list',
      itemProps: {
        label: '{{item.header}}',
      },
      fields: [
        {
          label: 'Header',
          name: 'header',
          component: 'text',
        },
        {
          label: 'Copy',
          name: 'copy',
          component: 'text',
        },
      ],
      defaultValue: [
        {
          header: '50',
          copy: 'SATES AFFECTED',
        },
        {
          header: '252M',
          copy: 'PEOPLE EFFECTED',
        },
        {
          header: '7/10',
          copy: 'TOXICITY RATING',
        },
      ],
      defaultItem: {
        header: '50',
        copy: 'SATES AFFECTED',
      },
    },
    {
      component: 'group',
      name: 'guideline',
      label: 'Guideline',
      fields: [
        {
          name: 'guidelineheader',
          component: 'text',
          label: 'Guideline Header',
          defaultValue: 'Health Guideline vs Legal Limit',
        },
        {
          name: 'guidelineLeftSideHeading',
          component: 'text',
          label: 'Guideline Left Side Heading',
          defaultValue: 'Health Guideline',
        },
        {
          name: 'guidelineLeftSideCopy',
          component: 'text',
          label: 'Guideline Left Side Copy',
          defaultValue: 'none',
        },
        {
          name: 'guidelineLeftSideMarker',
          component: 'number',
          label: 'Guideline Left Side Marker (%)',
          description: 'Enter a number between 0 and 100',
          defaultValue: '0',
        },
        {
          name: 'guidelineLeftSideMarkerMobile',
          component: 'number',
          label: 'Guideline Left Side Marker on Mobile (%)',
          description: 'Enter a number between 0 and 100',
          defaultValue: '0',
        },
        {
          name: 'guidelineRightSideHeading',
          component: 'text',
          label: 'Guideline Right Side Heading',
          defaultValue: 'Legal Limit',
        },
        {
          name: 'guidelineRightSideCopy',
          component: 'text',
          label: 'Guideline Right Side Copy',
          defaultValue: '4ppm',
        },
        {
          name: 'guidelineRightSideMarker',
          component: 'number',
          label: 'Guideline Right Side Marker (%)',
          description: 'Enter a number between 0 and 100',
          defaultValue: '19',
        },
        {
          name: 'guidelineRightSideMarkerMobile',
          component: 'number',
          label: 'Guideline Right Side Marker on Mobile (%)',
          description: 'Enter a number between 0 and 100',
          defaultValue: '35',
        },
      ],
    },
    {
      label: 'Desktop Image',
      name: 'desktopImage',
      component: 'image',
    },
    {
      label: 'Mobile Image',
      name: 'mobileImage',
      component: 'image',
    },
  ],
});
