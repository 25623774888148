export const Schema = () => ({
  label: 'Common Contaminants',
  category: 'Blog',
  key: 'commonContaminants',
  previewSrc:
    'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-25_20at_209.57.41_20AM.png?v=1685033902',
  fields: [
    {
      label: 'Icon',
      name: 'icon',
      component: 'image',
    },
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Common Contaminants',
    },
    {
      label: 'Copy',
      name: 'copy',
      component: 'text',
      defaultValue:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae ',
    },
    {
      label: 'Call to action',
      name: 'cta',
      component: 'link',
    },
    {
      label: 'Contaminants',
      name: 'contaminants',
      validate: {
        maxItems: 16,
      },
      component: 'group-list',
      itemProps: {
        label: '{{item.title}}',
      },
      fields: [
        {
          label: 'Image',
          name: 'image',
          component: 'image',
        },
        {
          name: 'title',
          component: 'text',
          label: 'Title',
        },
        {
          name: 'category',
          component: 'text',
          label: 'Category',
        },
        {
          name: 'articleTime',
          component: 'text',
          label: 'Article Read Time',
        },
        {
          label: 'Call to action',
          name: 'cta',
          component: 'link',
        },
      ],
      defaultValue: [
        {
          title: 'Arsenic',
          category: 'Contaminants',
          articleTime: '5',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Mask_20group_20_2847_29.png?v=1685031478&width=960&quality=75',
          },
        },
        {
          title: 'Flouride',
          category: 'Contaminants',
          articleTime: '5',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Mask_20group_20_2847_29.png?v=1685031478&width=960&quality=75',
          },
        },
        {
          title: 'HAA5 (Haloacetic Acids)',
          category: 'Contaminants',
          articleTime: '5',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Mask_20group_20_2847_29.png?v=1685031478&width=960&quality=75',
          },
        },
        {
          title: 'PFAS / PFOA',
          category: 'Contaminants',
          articleTime: '5',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Mask_20group_20_2847_29.png?v=1685031478&width=960&quality=75',
          },
        },
        {
          title: 'Radiation',
          category: 'Contaminants',
          articleTime: '5',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Mask_20group_20_2847_29.png?v=1685031478&width=960&quality=75',
          },
        },
        {
          title: 'Lead',
          category: 'Contaminants',
          articleTime: '5',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Mask_20group_20_2847_29.png?v=1685031478&width=960&quality=75',
          },
        },
        {
          title: 'Chromium 6',
          category: 'Contaminants',
          articleTime: '5',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Mask_20group_20_2847_29.png?v=1685031478&width=960&quality=75',
          },
        },
        {
          title: 'Human Hormones',
          category: 'Contaminants',
          articleTime: '5',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Mask_20group_20_2847_29.png?v=1685031478&width=960&quality=75',
          },
        },
        {
          title: 'Pesticides',
          category: 'Contaminants',
          articleTime: '5',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Mask_20group_20_2847_29.png?v=1685031478&width=960&quality=75',
          },
        },
        {
          title: 'Total Trihalomethanes (TTHMs)',
          category: 'Contaminants',
          articleTime: '5',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Mask_20group_20_2847_29.png?v=1685031478&width=960&quality=75',
          },
        },
      ],
    },
    {
      label: 'Slider Settings',
      name: 'slider',
      component: 'group',
      description: 'Slider style, slides per view',
      fields: [
        {
          label: 'Slider Style',
          name: 'sliderStyle',
          component: 'select',
          description:
            'Loop and centered settings only apply if the number of products is at least twice the number of slides per view',
          options: [
            { label: 'Contained', value: 'contained' },
            { label: 'Contained w/ Loop', value: 'containedWithLoop' },
            { label: 'Full Bleed, Centered w/ Loop', value: 'fullBleed' },
            {
              label: 'Full Bleed, Centered w/ Loop & Gradient',
              value: 'fullBleedWithGradient',
            },
          ],
        },
        {
          label: 'Slides Per View (desktop)',
          name: 'slidesPerViewDesktop',
          component: 'number',
        },
        {
          label: 'Slides Per View (tablet)',
          name: 'slidesPerViewTablet',
          component: 'number',
        },
        {
          label: 'Slides Per View (mobile)',
          name: 'slidesPerViewMobile',
          component: 'number',
        },
      ],
      defaultValue: {
        sliderStyle: 'contained',
        slidesPerViewDesktop: 4,
        slidesPerViewTablet: 3.4,
        slidesPerViewMobile: 1.4,
      },
    },
  ],
});
