import { useCallback, useState } from 'react';
import {
  useCartAddItem,
  useProductInventoryByHandle,
  useSettings,
} from '@backpackjs/storefront';

import { useGlobalContext } from '../../contexts';

export function useAddToCart(
  {
    addToCartText: addToCartTextOverride,
    selectedVariant,
    selectedSellingPlan,
    purchaseType,
    showPrice,
    price,
  } = {
    addToCartTextOverride: '',
    selectedVariant: null,
  }
) {
  const {
    actions: { openCart, openModal },
  } = useGlobalContext();
  const { cartAddItem } = useCartAddItem();
  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });
  const settings = useSettings();

  const [isAdding, setIsAdding] = useState(false);

  const isLoading = !inventoryStatus?.success;
  const variantInventory = inventory?.variants?.[selectedVariant?.id];
  const variantIsSoldOut = !isLoading && !variantInventory?.availableForSale;
  const variantIsPreorder =
    !variantIsSoldOut && variantInventory?.currentlyNotInStock;
  const enabledNotifyMe = settings?.product?.backInStock?.enabled;

  let buttonText = '';
  if (variantIsPreorder) {
    buttonText = settings?.product?.addToCart?.preorderText || 'Preorder';
  } else if (variantIsSoldOut) {
    buttonText = enabledNotifyMe
      ? settings?.product?.backInStock?.notifyMeText || 'Notify Me'
      : settings?.product?.addToCart?.soldOutText || 'Sold Out';
  } else {
    buttonText =
      addToCartTextOverride ||
      settings?.product?.addToCart?.addToCartText ||
      'Add To Cart';

    if (showPrice && price) {
      buttonText += ` — ${price}`;
    }
  }

  const handleAddToCart = useCallback(async () => {
    if (!selectedVariant?.id) return;
    setIsAdding(true);

    const cartItem = {
      merchandiseId: selectedVariant.id,
      quantity: 1,
    };

    if (
      purchaseType &&
      purchaseType === 'subscription' &&
      selectedSellingPlan?.sellingPlan?.id
    ) {
      cartItem.sellingPlanId = selectedSellingPlan.sellingPlan.id;
    }

    await cartAddItem(cartItem);

    setIsAdding(false);
    openCart();
  }, [
    isAdding,
    isLoading,
    selectedVariant?.id,
    purchaseType,
    selectedSellingPlan,
  ]);

  const handleNotifyMe = useCallback(
    async (component) => {
      if (!selectedVariant?.id || isLoading) return;
      openModal(component);
    },
    [isLoading, selectedVariant?.id]
  );

  return {
    state: {
      buttonText,
      isAdding,
      isLoading,
      isNotifyMe: variantIsSoldOut && enabledNotifyMe,
      isSoldOut: variantIsSoldOut,
      subtext: settings?.product?.addToCart?.subtext,
    },
    actions: {
      handleAddToCart,
      handleNotifyMe,
    },
  };
}
