import { useSettings } from '@backpackjs/storefront';
import Head from 'next/head';

export function StorefrontHead() {
  const settings = useSettings();
  const { favicon } = { ...settings?.general };

  return (
    <Head>
      {/* fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />

      {/* favicon */}
      <link rel="shortcut icon" href={favicon?.src || '/icons/favicon.svg'} />

      {/* additional open graph */}
      <meta
        property="og:site_name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />

      {/* pwa (progressive web app) */}
      <meta name="theme-color" content="#000000" />

      <link rel="manifest" href="/manifest.json" />

      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/icons/icon-384x384.png"
      />
      <meta
        name="application-name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta
        name="apple-mobile-web-app-title"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="msapplication-tap-highlight" content="no" />

      <script
        type="text/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
              !function(){var o=window.tdl=window.tdl||[];if(o.invoked)window.console&&console.error&&console.error("Tune snippet has been included more than once.");else{o.invoked=!0,o.methods=["init","identify","convert"],o.factory=function(n){return function(){var e=Array.prototype.slice.call(arguments);return e.unshift(n),o.push(e),o}};for(var e=0;e<o.methods.length;e++){var n=o.methods[e];o[n]=o.factory(n)}o.init=function(e){var n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://js.go2sdk.com/v2/tune.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(n,t),o.domain=e}}}();
              tdl.init("https://aspireiq.go2cloud.org")
              tdl.identify()
            `,
        }}
      />

      <script
        type="text/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
              var AddShoppersWidgetOptions = { 'loadCss': false, 'pushResponse': false };
              (!function(){
                var t=document.createElement("script");
                t.type="text/javascript",
                t.async=!0,
                t.id="AddShoppers",
                t.src="https://shop.pe/widget/widget_async.js#651a8d0ac0de54f227b7a798",
                document.getElementsByTagName("head")[0].appendChild(t)
              }());
            `,
        }}
      />
    </Head>
  );
}

StorefrontHead.displayName = 'StorefrontHead';
