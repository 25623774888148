import { useEffect, useState } from 'react';
import { Schema } from './Newsletter.schema';
import { Image, Svg } from '../../snippets';
import { Headline, Body } from '../../snippets/Typography';
import { useDataLayerActions } from '../../hooks';

export function Newsletter({ cms }) {
  const { sendSubscribeEvent } = useDataLayerActions();
  const { image, heading, copy, disclaimer } = cms;
  const [formEmail, setFormEmail] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleNewsletterSignup = async (event) => {
    event.preventDefault();
    const klaviyoResponse = await fetch('/api/klaviyo-subscribe', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        emailAddress: formEmail,
      }),
    });

    if (klaviyoResponse.status === 200) {
      setIsSuccessful(true);
      setFormEmail('');
      sendSubscribeEvent({ email: formEmail });
    }
  };

  return (
    <div className="newseletter relative flex min-h-[475px] items-center justify-center lg:min-h-[620px]">
      {image?.src && (
        <div className="absolute mx-auto h-[475px] w-full lg:h-[620px] ">
          <Image
            src={image.src}
            alt="icon"
            fill
            className="mx-auto mb-4 object-cover"
          />
        </div>
      )}
      <div className="px-contained relative mx-auto max-w-[1200px] overflow-hidden py-12">
        <div className="absolute left-4 top-0 h-full w-full max-w-[calc(100%-2em)] bg-gradient-to-r from-[#2179C2] via-[#8DCEF9] to-[#F1DCC1] opacity-30 lg:left-0 lg:max-w-full" />
        <div className="absolute left-4 top-0 h-full w-full max-w-[calc(100%-2em)] bg-[#fff] opacity-70 lg:left-0 lg:max-w-full" />
        <div className="lx:px-16 relative z-10 flex flex-wrap items-center justify-between px-6 py-0 lg:flex-nowrap lg:py-20">
          <div
            id="email-signup-left"
            className="mb-4 flex-[0_0_100%] text-xl font-[300] lg:mb-0 lg:flex-[0_0_45%] lg:text-[28px]"
          >
            <Headline variant="h4" className="max-w-[360px]">
              {heading}
            </Headline>
            <Body variant="medium" className="mt-6 max-w-[370px]">
              {copy}
            </Body>
            {/* {subtext && <p className="text-[#A6A8AE]">{subtext}</p>} */}
          </div>

          <div
            id="email-signup-right"
            className="flex flex-[0_0_100%] flex-col justify-between lg:flex-[0_0_55%] [&_svg]:text-black"
          >
            <div className="flex justify-between border-b border-b-[#202635]">
              <form
                className="klaviyo-form-PAPDgq w-full"
                onSubmit={handleNewsletterSignup}
              >
                <div className="flex justify-between">
                  <input
                    type="email"
                    value={formEmail}
                    placeholder="Enter your email"
                    onChange={(event) => {
                      setFormEmail(event.target.value);
                    }}
                  />
                  <button type="submit">
                    {' '}
                    <Svg
                      className="w-[24px] text-white"
                      src="/svgs/arrow-right.svg#arrow-right"
                      title="Submit"
                      viewBox="0 0 24 24"
                    />
                  </button>
                </div>
              </form>
            </div>
            {isSuccessful && (
              <div className="py-6">
                Thanks for subscribing! Your submission was received
                successfully.
              </div>
            )}
            <Body variant="xs" className="mt-6">
              {disclaimer}
            </Body>
          </div>
        </div>
      </div>
    </div>
  );
}

Newsletter.displayName = 'Newsletter';
Newsletter.Schema = Schema;
