import { useSettings } from '@backpackjs/storefront';
import Button from '../Button/Button';
import { Link } from '../Link';

export function CartEmpty({ closeCart }) {
  const settings = useSettings();
  const { links, message } = { ...settings?.cart?.emptyCart };

  return (
    <ul className="absolute left-1/2 top-10 flex w-full -translate-x-1/2  flex-col items-center gap-5 px-5">
      <h3 className="text-center text-[28px] font-light  tracking-[-0.01em]">
        You have no items in your cart
      </h3>

      {links?.map(({ link }, index) => {
        return (
          <li key={index}>
            <Link
              aria-label={link?.text}
              className="btn-primary"
              href={link?.url}
              newTab={link?.newTab}
              onClick={closeCart}
            >
              {link?.text}
            </Link>
          </li>
        );
      })}

      <Button href="/collections/shop-all" variant="filled" theme="primary">
        Shop All Products
      </Button>

      <div className="mt-8 h-[1px] w-full bg-[#DDE2E7]" />
    </ul>
  );
}

CartEmpty.displayName = 'CartEmpty';
