import { useSettings } from '@backpackjs/storefront';
import dynamic from 'next/dynamic';

const BeamCumulativeImpact = dynamic(
  () => import('@beamimpact/web-sdk/dist/react/cumulative-impact'),
  { loading: () => null, ssr: false }
);

export function BeamCumulativeImpactWrapper() {
  const settings = useSettings();
  const beamSettings = settings?.beam;

  return (
    !!beamSettings && (
      <BeamCumulativeImpact
        apiKey={beamSettings.beamApiKey}
        chainId={beamSettings.beamChainId}
        storeId={beamSettings.beamStoreId}
        baseUrl="https://api.beamimpact.com"
        lang="en"
      />
    )
  );
}
