import { useState } from 'react';
import IntlTelInput from 'intl-tel-input/react/build/IntlTelInput.cjs';
import { Button, Svg } from '../../snippets';
import { Headline, Subheading } from '../../snippets/Typography';
import 'intl-tel-input/build/css/intlTelInput.css';
import { useDataLayerActions } from '../../hooks';

export function PhoneForm({ settings }) {
  const {
    heading,
    subheading,
    buttonText,
    disclaimer,
    checkMessage,
    replyMessage,
    successButton,
    keyword = '',
  } = settings;
  const { sendSubscribeEvent } = useDataLayerActions();

  const [formNumber, setFormNumber] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleNewsletterSignup = async (event) => {
    event.preventDefault();
    const phoneNumber = `${formNumber}`.replace(/\D/g, '');
    const payload = { phoneNumber };

    if (keyword) {
      payload.keyword = keyword;
    }

    const klaviyoResponse = await fetch('/api/postscript-subscribe', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phoneNumber }),
    });

    if (klaviyoResponse.status === 200) {
      setIsSuccessful(true);
      setFormNumber('');
      sendSubscribeEvent({ phone: phoneNumber });
    }
  };

  return (
    <div className="newsletter-extended-phone-form text-center">
      {!isSuccessful ? (
        <>
          {subheading && (
            <Subheading variant="large" className="mb-4 px-4 uppercase">
              {subheading}
            </Subheading>
          )}
          {heading && (
            <Headline variant="h2" className="mb-8 max-w-[700px] px-4">
              {heading}
            </Headline>
          )}
          {!isSuccessful && (
            <form
              className="klaviyo-form w-full px-8"
              onSubmit={handleNewsletterSignup}
            >
              <div className="mb-8 flex justify-between border-b border-[#4d515d33] pb-4">
                <IntlTelInput
                  onChangeNumber={setFormNumber}
                  className="w-full bg-transparent text-lg font-light outline-none lg:text-xl"
                  initOptions={{
                    initialCountry: 'us',
                    utilsScript:
                      'https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.16/build/js/utils.js',
                    containerClass: 'w-full font-light',
                    showSelectedDialCode: true,
                  }}
                />
                <button type="submit">
                  {' '}
                  <Svg
                    className="w-[24px] text-black"
                    src="/svgs/arrow-right.svg#arrow-right"
                    title="Submit"
                    viewBox="0 0 24 24"
                  />
                </button>
              </div>
              {disclaimer && (
                <div
                  className="mx-auto mb-8 max-w-[600px] text-left text-xs lg:text-sm"
                  dangerouslySetInnerHTML={{
                    __html: disclaimer,
                  }}
                />
              )}
              {buttonText && (
                <Button
                  ariaLabel="submit"
                  type="submit"
                  theme="primary"
                  variant="filled"
                  className="w-full"
                >
                  {buttonText}
                </Button>
              )}
            </form>
          )}
        </>
      ) : (
        <div className="px-8">
          {subheading && (
            <Subheading variant="large" className="mb-4 uppercase">
              {checkMessage}
            </Subheading>
          )}
          {heading && (
            <Headline variant="h2" className="mb-16 max-w-[700px]">
              {replyMessage}
            </Headline>
          )}
          {successButton.url && successButton.text && (
            <Button
              href={successButton.url}
              theme="primary"
              variant="filled"
              className="w-full"
              wrapperClassName="w-full"
            >
              {successButton.text}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
