export const Schema = ({ settings }) => {
  const contaminantSettings = settings?.settings?.contaminantSettings;
  const contaminants = contaminantSettings?.contaminants;
  const contaminantList = [...contaminants]
    ?.sort((a, b) => {
      return (
        a?.category?.localeCompare(b?.category) ||
        a?.name?.localeCompare(b?.name)
      );
    })
    .map((contaminant) => {
      return {
        component: 'group',
        name: contaminant.name,
        label: contaminant.name,
        description: contaminant.category,
        fields: [
          {
            component: 'toggle',
            name: 'enabled',
            label: 'Enabled?',
          },
          {
            component: 'text',
            name: 'challengeWater',
            label: 'Challenge Water',
            description: `Global: ${contaminant.challengeWater ?? 'N/A'}`,
            defaultValue: contaminant.challengeWater,
          },
          {
            component: 'text',
            name: 'filteredWater',
            label: 'Filtered Water',
            description: `Global: ${contaminant.filteredWater ?? 'N/A'}`,
            defaultValue: contaminant.filteredWater,
          },
          {
            component: 'text',
            name: 'percentRemoved',
            label: 'Percent Removed',
            description: `Global: ${contaminant.percentRemoved ?? 'N/A'}`,
            defaultValue: contaminant.percentRemoved,
          },
        ],
      };
    });

  return {
    label: 'Lab Results',
    key: 'lab-results',
    category: 'Lab Results',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/lab-results.png?v=1685124975',
    fields: [
      {
        component: 'group',
        name: 'contaminants',
        label: 'Contaminants',
        fields: contaminantList,
      },
      {
        component: 'group',
        name: 'search',
        label: 'Search',
        fields: [
          {
            component: 'text',
            name: 'searchPlaceholderText',
            label: 'Search Placeholder Text',
            defaultValue: 'Search Test Results...',
          },
          {
            component: 'text',
            name: 'noResultsHeading',
            label: 'No Results Heading',
            defaultValue: 'Uh-oh. We couldnt fine anything for {search term}',
          },
          {
            component: 'markdown',
            name: 'noResultsContent',
            label: 'No Results Content',
            defaultValue:
              "Please double check to make sure that your search is spelled correctly. If you are still not seeing a particular contaminant, please contact our support team and let us know which contaminant you're looking to remove. We value this feedback and use this when testing. Thank you!",
          },
          {
            component: 'group-list',
            name: 'topSearchTerms',
            label: 'Top Search Terms',
            itemProps: {
              label: '{{item.searchTerm}}',
            },
            fields: [
              {
                component: 'text',
                name: 'searchTerm',
                label: 'Search Term',
              },
            ],
          },
        ],
      },
    ],
  };
};
