import { Svg } from '../Svg';

export function SearchHeader({ closeSearch }) {
  return (
    <div className="relative flex flex-end flex-row-reverse h-14 items-center justify-between px-8">
      <button
        aria-label="Close Search"
        className=""
        onClick={closeSearch}
        type="button"
      >
        <Svg
          className="w-5 text-text"
          src="/svgs/close.svg#close"
          title="Close"
          viewBox="0 0 24 24"
        />
      </button>

      <p className="text-base uppercase tracking-[.02em] text-primaryLight">Search</p>
    </div>
  );
}

SearchHeader.displayName = 'SearchHeader';
