export const Schema = () => ({
  category: 'Collection',
  label: 'Collection Nav',
  key: 'collection-nav',
  previewSrc:
    'https://cdn.shopify.com/s/files/1/1011/0318/files/collection-nav.png',
  fields: [
    {
      label: 'Menu Links',
      name: 'links',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
        {
          label: 'Mobile Image',
          name: 'mobileImage',
          component: 'image',
        },
        {
          label: 'Mobile Text',
          name: 'mobileText',
          component: 'text',
        },
      ],
    },
  ],
});
