import { Schema } from './ArticleBlockQuote.schema';

export function Blockquote({ children }) {
  return (
    <div className="relative mb-8 font-light text-[#3383C5] lg:mb-16 lg:text-[42px] lg:leading-[56px]">
      <div className="h-[1px] w-full bg-gradient-to-r from-[#2179C2] via-[#8DCEF9] to-[#F1DCC1]" />
      <blockquote className="py-16 font-light text-[#3383C5] lg:text-[42px] lg:leading-[56px]">
        {children}
      </blockquote>
      <div className="h-[1px] w-full bg-gradient-to-r from-[#F1DCC1] via-[#8DCEF9] to-[#2179C2]" />
    </div>
  );
}
export function ArticleBlockQuote({ cms }) {
  const { quote } = cms;

  return <Blockquote>{quote}</Blockquote>;
}

ArticleBlockQuote.displayName = 'ArticleBlockQuote';
ArticleBlockQuote.Schema = Schema;
