import { useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

export function useEWGResults(page) {
  const settings = useSettings();
  const EWGapiKey = process.env.NEXT_PUBLIC_EWG_API_KEY;
  const [loading, setLoading] = useState(false);
  const [EWGResults, setEWGResults] = useState(null);
  const [EWGSystems, setEWGSystems] = useState(null);
  const [activeEWGSystem, setActiveEWGSystem] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const { contaminantSettings } = { ...settings };
  const router = useRouter();
  const setEWGSystemsFromZip = useCallback(async () => {
    if (
      (page?.handle === 'whats-in-your-water' ||
        page?.handle === 'whats-in-your-water-quiz') &&
      zipCode?.length === 5
    ) {
      // Let's check to see if we have any systems already tied to this zipCode in localStorage, if we do use those.
      const localStorageData = localStorage.getItem(`EWG-${zipCode}`)
        ? JSON.parse(localStorage.getItem(`EWG-${zipCode}`))
        : null;

      if (
        localStorageData &&
        localStorageData?.zipCode &&
        localStorageData?.activeEWGSystem &&
        localStorageData?.EWGSystems
      ) {
        console.log('Setting EWG Systems from LocalStorage');
        setEWGSystems(localStorageData?.EWGSystems);
        setActiveEWGSystem(localStorageData?.activeEWGSystem);
      }

      // Otherwise, set the Systems from EWG based on the current zipCode, then set the active System to the first one.
      else {
        console.log('Fetching EWG Systems from API');
        const systemsListEndpoint = `https://waterapi.ewg.org/zip_systems.php?zip=${zipCode}&key=${EWGapiKey}`;
        const systemsListResponse = await fetch(systemsListEndpoint);
        const systemsListResponseJSON = await systemsListResponse.json();
        if (systemsListResponseJSON?.SystemList) {
          setEWGSystems(systemsListResponseJSON?.SystemList);
          setActiveEWGSystem(systemsListResponseJSON?.SystemList?.[0]);
        }
      }
    }
  }, [zipCode]);

  // Set the EWG Result Set from the current active System.
  const setEWGResultsFromActiveSystem = useCallback(async () => {
    if (
      (page?.handle === 'whats-in-your-water' ||
        page?.handle === 'whats-in-your-water-quiz') &&
      activeEWGSystem?.PWS
    ) {
      // Let's first check to see if we've saved any previous results for this activeSystem in localStorage
      const localStorageData = localStorage.getItem(`EWG-${zipCode}`)
        ? JSON.parse(localStorage.getItem(`EWG-${zipCode}`))
        : null;

      if (
        localStorageData &&
        localStorageData?.EWGResults?.[activeEWGSystem?.PWS]
      ) {
        console.log('Setting EWG Data from LocalStorage');
        setLoading(true);
        setEWGResults(localStorageData?.EWGResults?.[activeEWGSystem?.PWS]);
        setLoading(false);
      } else {
        console.log('Fetching EWG Data from API');
        setLoading(true);
        const systemsDataEndpoint = `https://waterapi.ewg.org/systeminformation.php?pws=${activeEWGSystem?.PWS}&key=${EWGapiKey}`;
        const systemsDataResponse = await fetch(systemsDataEndpoint);
        const systemsDataResponseJSON = await systemsDataResponse.json();
        setLoading(false);
        if (systemsDataResponseJSON?.ContaminantList?.length > 0) {
          // Loop through the data we get back from EWG and append any Clearly Filtered Data that we have.
          systemsDataResponseJSON.ContaminantList =
            systemsDataResponseJSON.ContaminantList.map((EWGContaminant) => {
              let foundClearlyFilteredData = false;
              const EWGContaminantName =
                EWGContaminant?.ContaminantName?.toLowerCase();
              contaminantSettings?.contaminants?.forEach((contaminant) => {
                const contaminantName = contaminant?.name?.toLowerCase();
                if (EWGContaminantName === contaminantName) {
                  foundClearlyFilteredData = contaminant;
                }
              });
              if (foundClearlyFilteredData) {
                return {
                  ...EWGContaminant,
                  ClearlyFilteredData: foundClearlyFilteredData,
                };
              }
              return EWGContaminant;
            });
        }

        const localStorageResultsData = localStorage.getItem(`EWG-${zipCode}`)
          ? JSON.parse(localStorage.getItem(`EWG-${zipCode}`))
          : null;
        const localStorageDataToSave = {
          zipCode,
          EWGSystems,
          activeEWGSystem,
          EWGResults: {
            ...localStorageResultsData?.EWGResults,
            [activeEWGSystem?.PWS]: systemsDataResponseJSON,
          },
        };

        localStorage.setItem(
          `EWG-${zipCode}`,
          JSON.stringify(localStorageDataToSave)
        );
        setEWGResults(systemsDataResponseJSON);
      }
    }
  }, [activeEWGSystem]);

  // On router query change, let's check for a zip code and set it if we're on the What's in your water page.
  useEffect(() => {
    if (
      (page?.handle === 'whats-in-your-water' ||
        page?.handle === 'whats-in-your-water-quiz') &&
      router?.query?.zip &&
      router?.query?.zip.length === 5
    ) {
      setZipCode(router.query.zip);
    }
  }, [router?.query?.zip]);

  // On zipCode change, let's set the EWG Systems based on our zipCode
  useEffect(() => {
    if (
      (page?.handle === 'whats-in-your-water' ||
        page?.handle === 'whats-in-your-water-quiz') &&
      zipCode?.length === 5
    ) {
      setEWGSystemsFromZip();
    }
  }, [zipCode]);

  // When our EWG System changes, let's fetch our new data
  useEffect(() => {
    if (
      (page?.handle === 'whats-in-your-water' ||
        page?.handle === 'whats-in-your-water-quiz') &&
      activeEWGSystem
    ) {
      setEWGResultsFromActiveSystem();
    }
  }, [activeEWGSystem]);

  // Which Contaminants Exceed the EWG Recommended Guidelines?
  const EWGResultsExceedingGuidelines = useMemo(() => {
    if (
      EWGResults?.ContaminantList &&
      EWGResults?.ContaminantList?.length > 0
    ) {
      return EWGResults?.ContaminantList?.filter((contaminant) => {
        return (
          contaminant?.SystemAverage > contaminant?.ContaminantHGValue &&
          contaminant.ContaminantHGValue > 0
        );
      });
    }
    return null;
  }, [EWGResults?.ContaminantList]);

  // Which Contaminants are Within the EWG Recommended Guidelines?
  const EWGResultsWithinGuidelines = useMemo(() => {
    if (
      EWGResults?.ContaminantList &&
      EWGResults?.ContaminantList?.length > 0
    ) {
      return EWGResults?.ContaminantList?.filter((contaminant) => {
        return !(
          contaminant?.SystemAverage > contaminant?.ContaminantHGValue &&
          contaminant.ContaminantHGValue > 0
        );
      });
    }
    return null;
  }, [EWGResults?.ContaminantList]);

  // Contaminants that have been removed by Clearly Filtered as specified in the contaminants Global Site Settings.
  const EWGResultsRemovedContaminants = useMemo(() => {
    if (
      EWGResults?.ContaminantList &&
      EWGResults?.ContaminantList?.length > 0
    ) {
      return EWGResults?.ContaminantList?.filter((contaminant) => {
        return !!contaminant?.ClearlyFilteredData?.percentRemoved;
      });
    }
    return null;
  }, [EWGResults?.ContaminantList]);

  // Determines the top 3 contaminants by topRankingRate.
  const EWGResultsTop3Contaminants = useMemo(() => {
    if (
      EWGResults?.ContaminantList &&
      EWGResults?.ContaminantList?.length > 0
    ) {
      return EWGResults?.ContaminantList.sort((a, b) => {
        const aTopRankingRate = a?.ClearlyFilteredData?.topRankingRate
          ? a.ClearlyFilteredData.topRankingRate
          : 999999999;
        const bTopRankingRate = b?.ClearlyFilteredData?.topRankingRate
          ? b.ClearlyFilteredData.topRankingRate
          : 999999999;
        if (aTopRankingRate < bTopRankingRate) {
          return -1;
        }
        if (aTopRankingRate > bTopRankingRate) {
          return 1;
        }
        return 0;
      }).slice(0, 3);
    }
    return null;
  }, [EWGResults?.ContaminantList]);

  return useMemo(() => {
    return {
      loading, // Whether we're currently loading data from EWG.
      zipCode, // The zipCode we used to generate the EWG Results
      setZipCode, // The method by which we can change the current zipCode.
      EWGSystems, // The systems that came back from EWG given the provided zipCode above.
      activeEWGSystem, // Which system is currently active from the EWGSystems list above. (Default to 1st in list)
      setActiveEWGSystem, // The method by which we can change the activeEWGSystem.
      contaminantSettings, // The Global contaminant settings provided by the Clearly Filtered team in the CMS.
      EWGResults, // The EWGResult set provided the activeEWGSystem.
      EWGResultsExceedingGuidelines, // Computed contaminants that exceed the EWG Guidelines.
      EWGResultsWithinGuidelines, // Computed contaminants that are within the EWG Guidelines.
      EWGResultsRemovedContaminants, // Computed which contaminants from EWG Results are removed by Clearly Filtered.
      EWGResultsTop3Contaminants, // Computed which contaminants are deemed to be the top 3 ranked or scariest
    };
  }, [
    zipCode,
    setZipCode,
    EWGSystems,
    loading,
    activeEWGSystem,
    setActiveEWGSystem,
    contaminantSettings,
    EWGResults,
    EWGResultsExceedingGuidelines,
    EWGResultsWithinGuidelines,
    EWGResultsRemovedContaminants,
    EWGResultsTop3Contaminants,
  ]);
}
