import { useState } from 'react';
import { Body, Markdown, Svg } from '../../snippets';
import { RebuyUpsell } from '../../snippets/Rebuy/RebuyUpsell';
import { CrossSellProduct } from '../../snippets/CrossSellProduct';

export function ProductDetailAccordion({
  accordion,
  headerBgColor,
  headerTextColor,
  recommendedProducts,
}) {
  const { body, defaultOpen, header, isUpsell, crossSellProducts } = accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="pdp-accordion-inner">
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="pdp-accordion-inner__button flex h-14 w-full items-center justify-between gap-x-4 border-t border-t-[#2026351A] py-8"
        onClick={() => setIsOpen(!isOpen)}
        style={{ backgroundColor: headerBgColor, color: headerTextColor }}
        type="button"
      >
        <Body
          className="font-base font-normal uppercase max-sm:text-xs max-sm:font-light"
          element="div"
        >
          {header}
        </Body>

        {isOpen ? (
          <Svg
            className="w-4 text-current"
            src="/svgs/chevron-up.svg#chevron-up"
            title="Up"
            viewBox="0 0 24 24"
          />
        ) : (
          <Svg
            className="w-4 text-current"
            src="/svgs/chevron-down.svg#chevron-down"
            title="Down"
            viewBox="0 0 24 24"
          />
        )}
      </button>

      <div
        className={`pdp-accordion-inner__body py-4 pb-8 [&_h1]:mb-3 [&_h1]:text-sm [&_h2]:mb-3 [&_h2]:text-sm [&_h3]:mb-3 [&_h3]:text-sm [&_h4]:mb-3 [&_h4]:text-sm [&_h5]:mb-3 [&_h5]:text-sm [&_h6]:mb-3 [&_h6]:text-sm [&_ol]:!pl-4 [&_ol]:text-sm [&_p]:mb-3 [&_p]:text-sm [&_ul]:!pl-4 [&_ul]:text-sm ${
          isOpen ? 'block' : 'hidden'
        } [&_p]:!text-base [&_p]:max-sm:!text-sm [&_ul.recommended-products]:!pl-0`}
      >
        <Markdown>{body}</Markdown>

        {isUpsell && (
          <ul className="recommended-products mt-2">
            {recommendedProducts?.data?.map((item, itemIndex) => {
              return (
                <RebuyUpsell
                  key={`recommended-product-${itemIndex}`}
                  recommendedProduct={item}
                />
              );
            })}
          </ul>
        )}

        {crossSellProducts && Object.keys(crossSellProducts).length > 0 && (
          <div className="grid max-w-[366px] grid-cols-2 gap-[26px]">
            {crossSellProducts.map(
              (crossSellProduct, crossSellProductIndex) => {
                return (
                  <CrossSellProduct
                    key={`cross-sell-product-${crossSellProductIndex}`}
                    product={crossSellProduct}
                  />
                );
              }
            )}
          </div>
        )}
      </div>
    </div>
  );
}

ProductDetailAccordion.displayName = 'ProductDetailAccordion';
