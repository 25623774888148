export const Schema = () => ({
  label: 'Blog Featured Post',
  category: 'Blog',
  key: 'blogFeaturedPost',
  fields: [
    {
      label: 'Image',
      name: 'image',
      component: 'image',
    },
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
      defaultValue:
        'PFAS "Forever Chemicals" Contamination Worse than Previously Thought',
    },
    {
      name: 'copy',
      component: 'text',
      label: 'Copy',
      defaultValue:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae ',
    },
    {
      name: 'category',
      component: 'text',
      label: 'Category',
      defaultValue: 'US VS THEM',
    },
    {
      name: 'articleTime',
      component: 'text',
      label: 'Article Read Time',
      defaultValue: '5',
    },
    {
      label: 'Call to action',
      name: 'cta',
      component: 'link',
    },
  ],
});
