export const Schema = () => ({
  label: 'Newsletter (Extended)',
  category: 'Newsletter',
  key: 'newsletterExtended',
  fields: [
    {
      label: 'Background Image',
      name: 'image',
      component: 'image',
    },
    {
      component: 'select',
      name: 'formType',
      label: 'Form Type',
      options: [
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Phone',
          value: 'phone',
        },
      ],
      defaultValue: 'email',
    },
    {
      component: 'group',
      name: 'emailForm',
      label: 'Email Form',
      description: 'Settings for the email signup form',
      fields: [
        {
          component: 'text',
          name: 'subheading',
          label: 'Subheading',
        },
        {
          component: 'text',
          name: 'heading',
          label: 'Heading',
        },
        {
          component: 'text',
          name: 'placeholder',
          label: 'Placeholder',
        },
        {
          component: 'text',
          name: 'buttonText',
          label: 'Button Text',
        },
        {
          component: 'text',
          name: 'successMessage',
          label: 'Success Message',
        },
        {
          component: 'text',
          name: 'listId',
          label: 'Klaviyo List ID',
        },
      ],
      defaultValue: {
        subheading: 'Upgrade now with',
        heading: '10% off your first order of a new filtration system',
        placeholder: 'Email address',
        buttonText: 'Get my Code',
        successMessage:
          'Thanks for subscribing! Your submission was received successfully.',
      },
    },
    {
      component: 'group',
      name: 'phoneForm',
      label: 'Phone Form',
      description: 'Settings for the phone number signup form',
      fields: [
        {
          component: 'text',
          name: 'subheading',
          label: 'Subheading',
        },
        {
          component: 'text',
          name: 'heading',
          label: 'Heading',
        },
        {
          component: 'text',
          name: 'buttonText',
          label: 'Button Text',
        },
        {
          component: 'html',
          name: 'disclaimer',
          label: 'Disclaimer',
        },
        {
          component: 'text',
          name: 'checkMessage',
          label: 'Check Message',
        },
        {
          component: 'text',
          name: 'replyMessage',
          label: 'Reply Message',
        },
        {
          component: 'link',
          name: 'successButton',
          label: 'Success Button',
        },
        {
          component: 'text',
          name: 'keyword',
          label: 'PostScript Keyword',
        },
      ],
      defaultValue: {
        subheading: 'Sign up for texts & get',
        heading: '10% off your first order of a new filtration system',
        placeholder: '201 - 555 - 1234',
        buttonText: 'Get my Code',
        disclaimer:
          '<p>*By providing your phone number, you agree to receive recurring automated marketing text messages (e.g. cart reminders) from this shop and third parties acting on its behalf. Consent is not a condition to obtain goods or services. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. You also agree to the <a href="/pages/terms-conditions">Terms of Service</a> and <a href="/pages/privacy-policy">Privacy Policy</a>.</p>',
        checkMessage: 'Check your 📲 and',
        replyMessage: 'Reply “Y” to finish signing up & get your code',
        successButton: {
          text: 'Got it, Thanks!',
          url: '/',
        },
      },
    },
  ],
});
