export const Schema = () => ({
  category: 'Article',
  label: 'Article Block Quote',
  key: 'articleBlockQuote',
  //   previewSrc:
  //     'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-24_20at_202.55.23_20PM.png?v=1684965343',
  fields: [
    {
      name: 'title',
      component: 'text',
      label: 'Title',
      description: 'Leave Blank to hide from Table of Contents',
    },
    {
      name: 'quote',
      component: 'text',
      label: 'Quote',
      defaultValue:
        'Many dissolved solids naturally increase water’s conductivity. Therefore, TDS meters measure conductivity—not contaminants—to estimate TDS levels.',
    },
  ],
});
