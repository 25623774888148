/* eslint-disable @typescript-eslint/no-empty-function */
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../snippets/Button/Button';

export default function EmailInput({
  value = '',
  loading = false,
  onChange = () => {},
  onSubmit = () => {},
  ctaText = '',
  inputPlaceholder = 'E-mail address',
}) {
  // local clone of value to debounce changes
  const [localValue, setLocalValue] = useState(value);

  const debouncedSave = useCallback(
    debounce((nextValue) => setLocalValue(nextValue), 500),
    []
  );

  useEffect(() => {
    debouncedSave(value);
  }, [value]);

  const emailValidation = useMemo(() => {
    // if value is empty, user didn't touch the input yet
    if (localValue?.length === 0) return { valid: true };

    // test if value is a valid e-mail
    if (/\S+@\S+\.\S+/.test(localValue)) return { valid: true };

    // if value is not a valid e-mail, return error message
    return {
      valid: false,
      message: '*Please enter a valid e-mail',
    };
  }, [localValue]);

  return (
    <>
      <div className="relative w-full">
        <div
          className={[
            'w-full border border-[#DDE2E7] bg-stone-50 px-9 py-4 transition-all focus-within:ring hover:ring',
            emailValidation?.valid ? '' : 'ring ring-red-400',
          ].join(' ')}
        >
          <input
            value={value}
            onChange={onChange}
            placeholder={inputPlaceholder}
            className={[
              'text-gray-800 w-full bg-stone-50 text-[18px] font-light leading-relaxed outline-none',
            ].join(' ')}
          />
        </div>
        {!emailValidation?.valid && (
          <p className="mt-2 text-base text-red-400">
            {emailValidation?.message || 'Please enter a valid e-mail'}
          </p>
        )}
      </div>

      <div className="my-4">
        <Button
          variant="filled"
          theme="primary"
          disabled={localValue.length === 0 || !emailValidation?.valid}
          onClick={onSubmit}
          loading={loading}
        >
          {ctaText || 'Continue'}
        </Button>
      </div>
    </>
  );
}
