import { useEffect, useMemo, useState } from 'react';
import { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Badges } from '../../../snippets';
import { ProductImage } from './ProductImage';
import { ProductMediaThumbnails } from './ProductMediaThumbnails';
import { ProductVideo } from './ProductVideo';

export function ProductMedia({ selectedMedia, selectedVariant }) {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  // Reset the active index when the selected color changes
  useEffect(() => {
    if (!swiper) return;
    setActiveIndex(0);
    swiper?.slideTo(0);
  }, [selectedVariant?.selectedOptionsMap?.Color, swiper]);

  return (
    <div className="grid grid-cols-1 justify-between gap-8">
      {/* Primary Gallery Item */}
      <div className="order-0">
        <div className="relative bg-[#E6F0F8]">
          <Swiper
            onSwiper={setSwiper}
            modules={[A11y, Pagination]}
            onSlideChange={(_swiper) => {
              setActiveIndex(_swiper.realIndex);
            }}
            slidesPerView={1}
            grabCursor
            lazy={{
              loadPrevNext: true,
            }}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
            }}
            className="max-md:!pb-5 md:pb-0 [&_.swiper-wrapper]:flex [&_.swiper-wrapper]:items-center"
          >
            {selectedMedia?.map((media, index) => {
              return (
                <SwiperSlide key={media.id}>
                  <div className="p-12">
                    {media.mediaContentType === 'VIDEO' ? (
                      <ProductVideo media={media} />
                    ) : (
                      <ProductImage media={media} priority={index === 0} />
                    )}
                  </div>
                </SwiperSlide>
              );
            })}

            <div className="active-bullet-black swiper-pagination bottom-[25px] flex w-full justify-center gap-2.5 pb-4 pt-4 mix-blend-multiply md:hidden" />
          </Swiper>

          <div className="pointer-events-none absolute left-0 top-0 z-[1] p-2.5 xs:p-4 md:p-3 xl:p-4">
            <Badges tags={selectedVariant?.product?.tags} />
          </div>
        </div>
      </div>

      {/* Gallery Thumbnails */}
      <div className="relative order-1 hidden w-full overflow-x-auto scrollbar-hide md:block ">
        {selectedMedia?.length > 0 && (
          <ProductMediaThumbnails
            activeIndex={activeIndex}
            selectedMedia={selectedMedia}
            setActiveIndex={setActiveIndex}
            swiper={swiper}
          />
        )}
      </div>
    </div>
  );
}

ProductMedia.displayName = 'ProductMedia';
