import { Schema } from './Legal.schema';
import { LegalMenu } from './LegalMenu';
import { LegalMenuItem } from './LegalMenuItem';
import { Markdown as MarkdownSnippet } from '../../snippets';

export function Legal({ cms }) {
  const { body, legalMenuItems } = cms;

  return (
    <div className="legal lg:px-contained overflow-hidden bg-clearBlue lg:flex lg:pt-16">
      <div className="legal-menu relative lg:w-1/3">
        <LegalMenu menuItems={legalMenuItems} />
        <p className="mb-4 hidden text-lg uppercase lg:block">Legal</p>
        <ul className="hidden max-w-xs lg:block">
          {legalMenuItems?.map((item, index) => {
            return <LegalMenuItem item={item} key={index} />;
          })}
        </ul>
      </div>
      <div className="legal__markdown my-4 max-w-xl px-4 md:my-2 lg:w-2/3 lg:px-0 xl:my-12">
        <MarkdownSnippet>{body}</MarkdownSnippet>
      </div>
    </div>
  );
}

Legal.displayName = 'Legal';
Legal.Schema = Schema;
