import { useEffect } from 'react';

export const useCjAffiliate = () => {
  useEffect(() => {
    const setCjCookie = async () => {
      await fetch('/api/set-cj-cookie');
    };

    (async () => {
      await setCjCookie();
    })();
  }, []);
};
