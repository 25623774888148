import { useCallback } from 'react';
import { Schema } from './ProductThreeColumnDrawers.schema';
import { Body, Eyebrow, Headline, Image, Subheading } from '../../snippets';
import { useGlobalContext } from '../../contexts';
import { FiltrationTechnologyDrawer } from '../FiltrationTechnology/FiltrationTechnologyDrawer';

export function ProductThreeColumnDrawers({ cms }) {
  const { 
    heading, 
    eyebrowText, 
    columns 
  } = cms;
  const {
    actions: { openDrawer },
  } = useGlobalContext();

  const triggerDrawerOpen = useCallback(
    (drawerSettings) => {
      openDrawer(
        <FiltrationTechnologyDrawer
          content={drawerSettings}
          className="[&_em]:not-italic [&_em]:text-secondary"
        />
      );
    },
    [columns]
  );
  return (
    <div className="px-contained mx-auto max-w-[var(--content-max-width)] py-[30px] md:py-[45px]">
      <div className="grid gap-3 lg:mb-24 mb-14 text-center text-[#202635]">
        {eyebrowText && <Eyebrow>{eyebrowText}</Eyebrow>}
        {heading && <Headline variant="h2">{heading}</Headline>}
      </div>
      {columns?.length > 0 && (
        <div className="three-column-columns-wrapper">
          <div className="three-column-columns grid grid-cols-1 gap-[22px] md:grid-cols-3">
            {columns?.map((column, columnIndex) => {
              const { image, heading, subHeading, cta, drawerSettings } =
                column;
              return (
                <div
                  key={`column-${columnIndex}`}
                  className="three-column-column text-center max-md:mb-6"
                >

                  {image?.src && <div className="relative aspect-square">
                    <Image
                        src={image?.src}
                        alt={heading}
                        fill
                        sizes="(min-width: 1024px) 40vw, 100vw"
                        className="object-cover"
                    />
                  </div>}

                  {heading && (
                    <Subheading
                      className="mt-[25px] font-medium"
                      variant="medium"
                    >
                      {heading}
                    </Subheading>
                  )}
                  {subHeading && (
                    <Body variant="small" className="mt-[10px]">
                      {subHeading}
                    </Body>
                  )}
                  {cta && (
                    <button
                      onClick={() => {
                        triggerDrawerOpen(drawerSettings);
                      }}
                      type="button"
                      className="mt-[20px] text-textLink underline underline-offset-4"
                    >
                      {cta}
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

ProductThreeColumnDrawers.displayName = 'ProductThreeColumnDrawers';
ProductThreeColumnDrawers.Schema = Schema;
