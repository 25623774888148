import { useEffect, useState } from 'react';

import { useCustomer } from '@backpackjs/storefront';
import { useGlobalContext } from '../../contexts';
import { Svg } from '../Svg';
import { AccountMenu } from './AccountMenu';
import { CreateAccount } from './CreateAccount';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';

export function Account({ promobarHidden }) {
  const {
    state: { accountOpen },
    actions: { closeAccount },
  } = useGlobalContext();
  const customer = useCustomer();
  const [activeForm, setActiveForm] = useState('login');

  useEffect(() => {
    if (customer) {
      setActiveForm('currentUser');
    }
  }, [customer]);

  return (
    <aside
      className={`fixed left-full top-0 z-[70] flex h-full w-full flex-col overflow-hidden bg-background bg-clearBlue pt-8 transition md:max-w-[500px] ${
        accountOpen
          ? `${
              promobarHidden
                ? 'mt-[58px] md:mt-[var(--header-height)]'
                : 'mt-[calc(58px+var(--promobar-height))] md:mt-[calc(var(--header-height)+var(--promobar-height))]'
            } pointer-events-auto -translate-x-full`
          : 'pointer-events-none translate-x-0'
      }`}
    >
      <div className="flex-end relative flex h-14 flex-row-reverse items-center justify-between px-4 lg:px-8">
        <button
          aria-label="Close Account"
          className=""
          onClick={closeAccount}
          type="button"
        >
          <Svg
            className="w-5 text-text"
            src="/svgs/close.svg#close"
            title="Close"
            viewBox="0 0 24 24"
          />
        </button>

        <p className="text-base uppercase tracking-[.02em] text-primaryLight">
          Account
        </p>
      </div>

      {activeForm === 'login' && <Login setActiveForm={setActiveForm} />}

      {activeForm === 'create' && (
        <CreateAccount setActiveForm={setActiveForm} />
      )}

      {activeForm === 'password' && (
        <ForgotPassword setActiveForm={setActiveForm} />
      )}

      {activeForm === 'currentUser' && (
        <AccountMenu setActiveForm={setActiveForm} />
      )}
    </aside>
  );
}

Account.displayName = 'Account';
