export function Schema() {
  return {
    label: 'Technology 50/50 Split',
    key: 'uniqueTechnology',
    category: 'Product',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/unique-technology.png',
    fields: [
      {
        label: 'Dark Mode',
        name: 'darkMode',
        description: 'Set The Background dark and text white',
        component: 'toggle',
        defaultValue: false,
      },
      {
        name: 'image',
        label: 'Image',
        component: 'image',
      },
      {
        label: 'Image (mobile)',
        name: 'mobileImage',
        component: 'image',
      },
      {
        label: 'Image Alignment',
        name: 'imageAlignment',
        component: 'select',
        options: [
          {
            label: 'Left',
            value: 'left',
          },
          {
            label: 'Right',
            value: 'right',
          },
        ],
        defaultValue: 'left',
      },
      {
        name: 'eyebrow',
        label: 'Eyebrow Text',
        component: 'text',
      },
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
      },
      {
        name: 'content',
        label: 'Content',
        component: 'markdown',
      },
    ],
  };
}
