import { store, useCustomer } from '@backpackjs/storefront';
import { useCallback, useEffect, useState } from 'react';

export function useDataLayerAccount({
  baseEcommerce,
  DEBUG,
  generateUserProperties,
  userDataEvent,
  userDataEventTriggered,
}) {
  const customer = useCustomer();

  const accessToken = store.recoil.useRecoilValue(store.state.accessToken);
  const [loggedIn, setLoggedIn] = useState(false);
  const [registered, setRegistered] = useState(false);

  const klaviyoUserLogin = () => {
    const _learnq = window._learnq || [];
    _learnq.push([
      'identify',
      {
        $email: customer.email,
        $first_name: customer.firstName,
        $last_name: customer.lastName,
      },
    ]);
  };

  const loggedInEvent = useCallback(({ ecommerce, userProperties }) => {
    const event = {
      event: 'dl_login',
      user_properties: userProperties,
      ecommerce,
    };

    window.ElevarDataLayer = window.ElevarDataLayer ?? [];
    window.ElevarDataLayer.push(event);
    if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
  }, []);

  const registeredEvent = useCallback(({ ecommerce, userProperties }) => {
    const event = {
      event: 'dl_sign_up',
      user_properties: userProperties,
      ecommerce,
    };

    window.ElevarDataLayer = window.ElevarDataLayer ?? [];
    window.ElevarDataLayer.push(event);
    if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
  }, []);

  useEffect(() => {
    const customerLoggedIn = PubSub.subscribe(
      'CUSTOMER_LOGGED_IN',
      async () => {
        setLoggedIn(true);
      }
    );
    const customerRegistered = PubSub.subscribe(
      'CUSTOMER_REGISTERED',
      async () => {
        setRegistered(true);
      }
    );
    return () => {
      if (customerLoggedIn) {
        PubSub.unsubscribe(customerLoggedIn);
      }
      if (customerRegistered) {
        PubSub.unsubscribe(customerRegistered);
      }
    };
  }, []);

  // Generate new base data after customer login and customer profile is ready
  // Trigger 'dl_user_data' and 'dl_login' events
  useEffect(() => {
    if (
      !baseEcommerce ||
      !customer ||
      !accessToken?.token ||
      !loggedIn ||
      !userDataEventTriggered
    ) {
      return;
    }

    (async () => {
      const userProperties = await generateUserProperties({
        customer,
        accessToken: accessToken.token,
      });
      userDataEvent({ userProperties, ecommerce: baseEcommerce });
      loggedInEvent({ userProperties, ecommerce: baseEcommerce });

      klaviyoUserLogin();
    })();
  }, [
    !!customer,
    accessToken?.token,
    !!baseEcommerce,
    loggedIn,
    userDataEventTriggered,
  ]);

  // Generate new base data after customer register and customer profile is ready
  // Trigger 'dl_user_data' and 'dl_sign_up' events
  useEffect(() => {
    if (
      !baseEcommerce ||
      !customer ||
      !accessToken?.token ||
      !registered ||
      !userDataEventTriggered
    ) {
      return;
    }

    (async () => {
      const userProperties = await generateUserProperties({
        customer,
        accessToken: accessToken.token,
      });
      userDataEvent({ userProperties, ecommerce: baseEcommerce });
      registeredEvent({ userProperties, ecommerce: baseEcommerce });
      setRegistered(false);
    })();
  }, [
    !!customer,
    accessToken?.token,
    !!baseEcommerce,
    registered,
    userDataEventTriggered,
  ]);
}
