import { useEffect, useState } from 'react';
import { useGlobalProductPageContext } from '../../contexts/globalProductPageContext';
import { Body } from '../Typography';
import { RechargeSubscriptionWidgetDetails } from './RechargeSubscriptionWidgetDetails';

export function RechargeSubscriptionWidget({
  widgetContent,
  widgetType,
  originalDiscountPrice,
}) {
  const {
    discount,
    selectedVariant,
    purchaseType,
    setPurchaseType,
    setSelectedSellingPlan,
    selectedSellingPlan,
    recommendedFilterFrequency,
  } = useGlobalProductPageContext();

  // Select the desired allocation plan by default, OR set it to the recommendedFilterFrequency
  const setSellingPlan = (variant, frequency) => {
    if (!frequency || !variant) {
      return;
    }
    const recommendedSellingPlan = variant.sellingPlanAllocations?.filter(
      (sellingPlan) => {
        return (
          parseInt(
            sellingPlan.sellingPlan.options[0]?.value.split(' ').shift(),
            10
          ) === frequency
        );
      }
    )?.[0];
    if (recommendedSellingPlan?.sellingPlan) {
      setSelectedSellingPlan(recommendedSellingPlan);
    } else {
      setSelectedSellingPlan(variant.sellingPlanAllocations[0]);
    }
  };

  const [hasSetInitialFreq, sethasSetInitialFreq] = useState(false);
  // Run this effect when the selectedVariant changes
  useEffect(() => {
    if (!recommendedFilterFrequency || hasSetInitialFreq) return;
    setSellingPlan(selectedVariant, recommendedFilterFrequency);

    sethasSetInitialFreq(true);
  }, [selectedVariant]);

  // Run this effect when the recommendedFilterFrequency changes
  useEffect(() => {
    if (!selectedVariant) return;

    setSellingPlan(selectedVariant, recommendedFilterFrequency);

    sethasSetInitialFreq(true);
  }, [recommendedFilterFrequency]);

  const [variantPrice, setvariantPrice] = useState(null);
  useEffect(() => {
    if (!selectedVariant?.priceV2) return;

    let price = parseFloat(selectedVariant?.priceV2?.amount);
    if (discount?.enableDiscount) {
      if (discount.discountType === 'fixed') {
        price -= discount.discountAmount;
      } else {
        price -= price * (discount.discountAmount / 100);
      }
    }

    setvariantPrice(
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: selectedVariant?.priceV2?.currencyCode,
      }).format(price)
    );
  }, [selectedVariant?.priceV2]);

  const [regularPrice, setRegularPrice] = useState(null);
  const [comparePrice, setComparePrice] = useState(null);
  useEffect(() => {
    if (!selectedSellingPlan?.priceAdjustments.length) return;

    const priceAdjustment = selectedSellingPlan.priceAdjustments[0];

    if (priceAdjustment?.compareAtPrice?.amount) {
      setComparePrice(
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: priceAdjustment?.compareAtPrice?.currencyCode,
        }).format(priceAdjustment?.compareAtPrice?.amount)
      );
    }

    if (priceAdjustment?.price?.amount) {
      let price = parseFloat(priceAdjustment?.price?.amount);
      if (discount?.enableDiscount) {
        if (discount.discountType === 'fixed') {
          price -= discount.discountAmount;
        } else {
          price -= price * (discount.discountAmount / 100);
        }
      }

      setRegularPrice(
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: priceAdjustment?.price?.currencyCode,
        }).format(price)
      );
    }
  }, [selectedSellingPlan?.priceAdjustments]);

  const {
    subscribeSaveSubText,
    shipsNow,
    shipsRecurringly,
    overrideRecurringPrice,
    overrideRecurringPriceTitle,
    overrideSelectionText,
  } = {
    ...widgetContent,
  };

  const setSelectedSellingPlanByID = (sellingPlanID) => {
    setSelectedSellingPlan(
      selectedVariant?.sellingPlanAllocations.filter((sellingPlan) => {
        return sellingPlanID === sellingPlan?.sellingPlan.id;
      })[0]
    );
  };

  const [recurringPrice, setRecurringPrice] = useState(null);
  useEffect(() => {
    if (overrideRecurringPrice) {
      setRecurringPrice(
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(overrideRecurringPrice)
      );
    }
  }, [overrideRecurringPrice]);

  const sellingPlanNameOverride = (sellingPlanName) => {
    if (!overrideSelectionText) return sellingPlanName;

    if (sellingPlanName.includes('Delivery every')) {
      return sellingPlanName.replace('Delivery every', overrideSelectionText);
    }

    return sellingPlanName;
  };

  return (
    <div className="recharge-subscription-widget mb-4">
      {selectedVariant?.sellingPlanAllocations &&
        selectedVariant?.sellingPlanAllocations.length > 0 && (
          <>
            <h3 className="recharge-subscription-widget__header mb-0 text-[11px] font-normal uppercase leading-6 md:mb-4 md:text-base">
              Choose Your Plan
            </h3>
            <ul className="recharge-subscription-widget__options recharge-container grid grid-cols-1 gap-[20px]">
              {/* CHOOSE PURCHASE TYPE - One Time */}
              <li
                className={`recharge-subscription-widget__option border ${
                  purchaseType === 'one-time'
                    ? 'border-2 border-primary bg-white'
                    : 'border-[#DDE2E7] bg-[#E6F0F8]'
                }`}
              >
                <label
                  htmlFor="purchase_type_one-time"
                  className="recharge-block recharge-block__one-time flex cursor-pointer items-center justify-between p-4"
                >
                  <div className="flex items-center">
                    <input
                      id="purchase_type_one-time"
                      type="radio"
                      name="purchase_type"
                      value="one-time"
                      className="recharge-block__radio-input hidden"
                      checked={purchaseType === 'one-time'}
                      onChange={() => {
                        setPurchaseType('one-time');
                      }}
                    />
                    <div
                      className={`mr-4 h-[20px] w-[20px] rounded-full ${
                        purchaseType === 'one-time'
                          ? 'border-[6px] border-textLink'
                          : 'border-[1px]'
                      }`}
                    />
                    One-time purchase
                  </div>
                  {variantPrice && <div>{variantPrice}</div>}
                </label>
              </li>
              <li
                className={`recharge-subscription-widget__option border ${
                  purchaseType === 'subscription'
                    ? 'border-2 border-primary bg-white'
                    : 'border-[#DDE2E7] bg-[#E6F0F8]'
                }`}
              >
                {/* CHOOSE PURCHASE TYPE - Subscription */}
                <label
                  htmlFor="purchase_type_subscription"
                  className="recharge-block recharge-block__autodeliver flex cursor-pointer items-center justify-between p-4"
                >
                  <div className="mr-4 flex items-center">
                    <input
                      id="purchase_type_subscription"
                      type="radio"
                      name="purchase_type"
                      value="subscription"
                      className="recharge-block__radio-input hidden"
                      checked={purchaseType === 'subscription'}
                      onChange={() => {
                        setPurchaseType('subscription');
                      }}
                    />
                    <div
                      className={`mr-4 h-[20px] w-[20px] shrink-0 rounded-full ${
                        purchaseType === 'subscription'
                          ? 'border-[6px] border-textLink'
                          : 'border-[1px]'
                      }`}
                    />
                    <div>
                      Autoship & Save{' '}
                      {
                        selectedSellingPlan?.sellingPlan?.priceAdjustments?.[0]
                          ?.adjustmentValue?.adjustmentPercentage
                      }
                      %
                      {subscribeSaveSubText && (
                        <div className="block text-[#4D515D]">
                          <Body variant="xs">{subscribeSaveSubText}</Body>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex">
                      <div className="flex">
                        {/* Strike Through Price */}
                        {comparePrice && (
                          <div className="text-shadowGray line-through">
                            {comparePrice}
                          </div>
                        )}
                        {/* Regular Price */}
                        {regularPrice && (
                          <div className="ml-4">{regularPrice}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </label>
                {
                  /* Subscription Frequency Options */
                  purchaseType === 'subscription' && (
                    <div>
                      {widgetType !== 'featured' && (
                        <div className="relative p-4 before:pointer-events-none before:absolute before:right-8 before:top-5 before:scale-x-75 before:scale-y-[0.40] before:content-['▲'] after:pointer-events-none after:absolute after:bottom-5 after:right-8 after:scale-x-75 after:scale-y-[0.4] after:content-['▼'] lg:pl-12">
                          <select
                            id="pdp-subscription-widget"
                            value={selectedSellingPlan?.sellingPlan?.id}
                            onChange={(event) => {
                              setSelectedSellingPlanByID(event.target.value);
                            }}
                            className="w-full cursor-pointer appearance-none rounded-[10px] border border-primary bg-[var(--clearBlue)] px-4 py-2"
                          >
                            {selectedVariant?.sellingPlanAllocations.map(
                              (sellingPlan, sellingPlanIndex) => {
                                return (
                                  <option
                                    key={`selling-plan-${sellingPlanIndex}`}
                                    value={sellingPlan?.sellingPlan?.id}
                                  >
                                    {sellingPlanNameOverride(
                                      sellingPlan?.sellingPlan?.name
                                    )}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      )}

                      {(shipsNow?.show || shipsRecurringly?.show) && (
                        <RechargeSubscriptionWidgetDetails
                          selectedVariant={selectedVariant}
                          selectedSellingPlan={selectedSellingPlan}
                          shipsNow={shipsNow}
                          shipsRecurringly={shipsRecurringly}
                          widgetType={widgetType}
                          originalDiscountPrice={originalDiscountPrice}
                          comparePrice={comparePrice}
                        />
                      )}
                    </div>
                  )
                }
              </li>
            </ul>
            {purchaseType === 'subscription' &&
              recurringPrice &&
              widgetType !== 'featured' &&
              recurringPrice !== regularPrice && (
                <div className="mt-7">
                  <hr className="border-[#DDE2E7]" />
                  <div className="mt-4 text-[var(--primary)]">
                    <div className="mb-2 flex justify-between text-base font-medium md:text-lg">
                      <span>Today&rsquo;s Total</span>
                      <span>{regularPrice}</span>
                    </div>
                    <div className="flex justify-between text-sm font-light md:text-base">
                      <span>
                        {overrideRecurringPriceTitle ?? 'Recurring Price'}
                      </span>
                      <span>{recurringPrice}</span>
                    </div>
                  </div>
                </div>
              )}
          </>
        )}
    </div>
  );
}
