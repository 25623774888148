export function Schema({ collection }) {
  if (!collection) return null;

  return {
    category: 'Collection',
    label: 'Collection',
    key: 'collection',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/collections.png?v=1684883680',
    fields: [
      {
        component: 'toggle',
        name: 'seprateByType',
        label: 'Separate Products By Type',
        toggleLabels: {
          true: 'Enable',
          false: 'Disable',
        },
        defaultValue: true,
      },
    ],
  };
}
