export function PlayButton(props) {
  const { className } = props;
  return (
    <div
      className={`relative h-11 w-11 overflow-hidden rounded-full border border-[#C1EFFA] lg:h-20 lg:w-20 ${className}`}
    >
      <div className="absolute left-1/2 top-1/2 h-11 w-11 -translate-x-1/2 -translate-y-1/2 transform bg-[#ffffffb3] blur lg:h-20 lg:w-20" />
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        <svg
          className="h-[13px] w-[13px] lg:h-[22px] lg:w-[18px]"
          viewBox="0 0 18 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 11L9.2855e-07 21.3923L1.83707e-06 0.607695L18 11Z"
            fill="#3383C5"
          />
        </svg>
      </div>
    </div>
  );
}
