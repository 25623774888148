import { useCallback, useEffect, useRef, useState } from 'react';

import { getIdFromString, mapProductPageVariant } from './utils';

export function useDataLayerProduct({
  baseEcommerce,
  DEBUG,
  userDataEvent,
  userProperties,
}) {
  const productHandleRef = useRef(null);
  const productBundleRef = useRef(null);

  const [viewedProductVariant, setViewedProductVariant] = useState(null);
  const [viewedProduct, setViewedProduct] = useState(null);
  const [viewedProductBundleVariants, setViewedProductBundleVariants] =
    useState(null);

  const klaviyoViewProduct = useCallback(({ product: _product }) => {
    const _learnq = window._learnq || [];

    _learnq.push([
      'track',
      'Viewed Product',
      {
        Name: _product.title,
        ProductID: getIdFromString(_product.id),
        Categories:
          _product.collections === undefined
            ? null
            : _product.collections.map((a) => a.handle),
        ImageUrl: _product.featuredImage.src,
        Url: window.location.href,
        Brand: _product.vendor,
        Price: _product.variants[0].priceV2.amount,
        CompareAtPrice: _product.variants[0].compareAtPriceV2.amount,
      },
    ]);

    _learnq.push([
      'trackViewedItem',
      {
        Title: _product.title,
        ItemId: getIdFromString(_product.id),
        Categories:
          _product.collections === undefined
            ? null
            : _product.collections.map((a) => a.handle),
        ImageUrl: _product.featuredImage.src,
        Url: window.location.href,
        Metadata: {
          Brand: _product.vendor,
          Price: _product.variants[0].priceV2.amount,
          CompareAtPrice: _product.variants[0].compareAtPriceV2.amount,
        },
      },
    ]);
  }, []);

  const postScriptViewProduct = useCallback(({ product: _product }) => {
    if (!window.postscript) return;

    const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
    const list = previousPath?.startsWith('/collections') ? previousPath : '';
    const variant = _product.variants[0];

    window.postscript.event('page_view', {
      shop_id: '11478', // your Postscript Shop ID
      url: window.location.href, // the current page
      search_params: { variant: getIdFromString(variant.id) },
      page_type: 'product',
      referrer: `${window.location.origin}${list}`, // the referring page
      resource: {
        // information about the product
        category: _product.productType,
        name: _product.title,
        price_in_cents: parseFloat(variant.priceV2.amount) * 100,
        resource_id: parseInt(getIdFromString(_product.id), 10),
        resource_type: 'product',
        sku: null,
        variant_id: parseInt(getIdFromString(variant.id), 10),
        vendor: _product.vendor,
      },
    });
  }, []);

  const viewProductEvent = useCallback(
    ({ ecommerce, variant, userProperties: _userProperties }) => {
      if (!variant) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list = previousPath?.startsWith('/collections') ? previousPath : '';
      const event = {
        event: 'dl_view_item',
        user_properties: _userProperties,
        ecommerce: {
          ...ecommerce,
          detail: {
            actionField: { list, action: 'detail' },
            products: [variant].map(mapProductPageVariant(list)),
          },
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  const viewProductBundleEvent = useCallback(
    ({ ecommerce, variants, userProperties: _userProperties }) => {
      if (!variants?.length) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list =
        previousPath?.startsWith('/collections') ||
        (previousPath?.startsWith('/pages') && previousPath?.includes('kits'))
          ? previousPath
          : '';
      const event = {
        event: 'dl_view_item',
        user_properties: _userProperties,
        ecommerce: {
          ...ecommerce,
          detail: {
            actionField: { list, action: 'detail' },
            products: variants.map(mapProductPageVariant(list)),
          },
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  // Subscribe to PubSub topic for 'dl_view_item' event
  useEffect(() => {
    const viewProductVariant = PubSub.subscribe(
      'VIEW_PRODUCT_PAGE',
      async (event, { product, variant }) => {
        setViewedProductVariant(variant);
        setViewedProduct(product);
      }
    );
    const viewProductBundle = PubSub.subscribe(
      'VIEW_PRODUCT_BUNDLE_PAGE',
      async (event, variants) => {
        setViewedProductBundleVariants(variants);
      }
    );
    return () => {
      if (viewProductVariant) {
        PubSub.unsubscribe(viewProductVariant);
      }
      if (viewProductBundle) {
        PubSub.unsubscribe(viewProductBundle);
      }
    };
  }, []);

  useEffect(() => {
    if (!viewedProduct) return;
    klaviyoViewProduct({ product: viewedProduct });
    postScriptViewProduct({ product: viewedProduct });
  }, [viewedProduct?.id]);

  // Trigger 'dl_user_data' and 'dl_view_item' events on product change after base data is ready
  useEffect(() => {
    const pageHandle = window.location.pathname.split('/').pop();
    if (
      !baseEcommerce ||
      !userProperties ||
      !viewedProductVariant ||
      productHandleRef.current === pageHandle
    )
      return;
    userDataEvent({ ecommerce: baseEcommerce, userProperties });
    viewProductEvent({
      ecommerce: baseEcommerce,
      variant: viewedProductVariant,
      userProperties,
    });
    productHandleRef.current = pageHandle;
  }, [!!baseEcommerce, viewedProductVariant?.product?.id, !!userProperties]);

  // Trigger 'dl_user_data' and 'dl_view_item' events on viewedProductVariant change after base data is ready
  useEffect(() => {
    const productBundlePathname = window.location.pathname;
    if (
      !baseEcommerce ||
      !userProperties ||
      !viewedProductBundleVariants?.length ||
      productBundleRef.current === productBundlePathname
    )
      return;
    userDataEvent({ ecommerce: baseEcommerce, userProperties });
    viewProductBundleEvent({
      ecommerce: baseEcommerce,
      variants: viewedProductBundleVariants,
      userProperties,
    });
    productBundleRef.current = productBundlePathname;
  }, [!!baseEcommerce, viewedProductBundleVariants, !!userProperties]);
}
