import { useRef } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Headline, Link, ProductItem } from '../../snippets';
import { ArrowButton } from '../../snippets/Button';
import useMediaQuery from '../../utils/useMediaQuery';
import { Schema } from './RelatedProducts.schema';

export function RelatedProducts({ cms }) {
  const { sectionTitle, sectionLink, relatedProducts } = { ...cms };
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const isMediumScreen = useMediaQuery('(min-width: 640px)');

  return (
    <section className="featured-products px-contained py-[32px] md:py-[62px]">
      {
        /* Section Header */
        (sectionTitle || sectionLink?.url) && (
          <div className="featured-products__header mb-6 flex items-center justify-between">
            <Headline element="h3" variant="h3" className="title">
              {sectionTitle}
            </Headline>
            {sectionLink?.url && (
              <Link
                aria-label={sectionLink?.text}
                href={sectionLink?.url}
                newTab={sectionLink?.newTab}
                tabIndex="-1"
              >
                <Button theme="dark" variant="outlined">
                  {sectionLink?.text}
                </Button>
              </Link>
            )}
          </div>
        )
      }

      <Swiper
        slidesPerView={isMediumScreen ? 4 : 2}
        spaceBetween={18}
        modules={[Navigation]}
        slideNextClass="bottom_swiper-slide-next"
        slidePrevClass="bottom_swiper-slide-prev"
        navigation={{
          enabled: true,
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onResize={(swiper) => {
          swiper.params.navigation.enabled = swiper.enable;
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.enabled = true;
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
      >
        {relatedProducts?.map((featuredProduct, index) => {
          return (
            <SwiperSlide key={`product-grid-item-${index}`}>
              <ProductItem
                disableHoverEffect
                enabledQuickShop={false}
                enabledStarRating
                disa
                handle={featuredProduct?.featuredProduct?.handle}
                index={index}
              />
            </SwiperSlide>
          );
        })}
        <div className="mt-8 flex justify-center gap-8 md:hidden">
          <ArrowButton arrowDirection="left" ref={navigationPrevRef} />
          <ArrowButton arrowDirection="right" ref={navigationNextRef} />
        </div>
      </Swiper>
    </section>
  );
}

RelatedProducts.displayName = 'RelatedProducts';
RelatedProducts.Schema = Schema;
