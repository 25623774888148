export const Schema = () => ({
  category: 'Lab Results',
  label: 'Lab Results Overview',
  key: 'labResultsOverview',
  previewSrc:
    'https://cdn.shopify.com/s/files/1/1011/0318/files/lab-results-overview.png?v=1685040204',
  fields: [
    {
      label: 'Section Title',
      name: 'sectionTitle',
      component: 'text',
      defaultValue: 'Performance Data',
    },
    {
      label: 'Featured Image',
      name: 'featuredImage',
      component: 'image',
    },
    {
      label: 'Value Props',
      name: 'valueProps',
      component: 'group-list',
      itemProps: {
        label: 'Prop: {{item.propTitle}}',
      },
      validate: {
        maxItems: 6,
      },
      fields: [
        {
          label: 'Prop Title',
          name: 'propTitle',
          component: 'text',
        },
        {
          label: 'Prop Value',
          name: 'propValue',
          component: 'text',
        },
      ],
    },
    {
      label: 'Asset Link',
      name: 'assetLink',
      component: 'link',
    },
    {
      label: 'Section Disclosure',
      name: 'sectionDisclosure',
      component: 'textarea',
      defaultValue:
        'Tests performed in accordance with NSF Standards 42, 53 & 401 for water quality and the reduction of chemicals and contaminants. The water was spiked with the substances indicated below and then passed through the filter. The results are stated in the report below. All contaminants were reduced to a concentration equal to or less than the permissible limits set forth by NSF.',
    },
  ],
});
