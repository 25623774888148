const handleize = (string) => {
  string.toLowerCase().replace(/ /g, '-');
  return `toc-${string
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/-$/, '')
    .replace(/^-/, '')}`;
};

export default handleize;
