import { useProductByHandle } from '@backpackjs/storefront';
import { useEffect, useMemo, useState } from 'react';

import { Schema } from './FeaturedProduct.schema';

import { GlobalProductPageContext } from '../../contexts/globalProductPageContext';
import { useSubscriptionWidget } from '../../hooks';
import { ProductDetails } from './ProductDetails';
import { ProductHeader } from './ProductHeader';
import { ProductMedia } from './ProductMedia';

export function FeaturedProduct({ cms }) {
  const {
    discount,
    featuredProduct,
    linkedProductGroups,
    productBannerText,
    bottomBannerText,
    bottomBannerHeading,
    productDisplayType,
    productDescription,
    productFeatures,
    sectionId,
    customSavePercentageText,
  } = { ...cms };

  const handle = featuredProduct?.handle;
  const { product } = useProductByHandle({ handle });
  const [recommendedFilterFrequency, setRecommendedFilterFrequency] =
    useState(null);
  const [selectedSellingPlan, setSelectedSellingPlan] = useState(null);
  const [purchaseType, setPurchaseType] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(product);
  const { productSubscriptionWidget } = useSubscriptionWidget(selectedProduct);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [productByline, setProductByline] = useState(null);
  const [productMedia, setProductMedia] = useState(product?.media);

  useEffect(() => {
    if (product) {
      setSelectedProduct(product);
    }
  }, [product]);

  useEffect(() => {
    if (selectedProduct) {
      const hasOneVariant = selectedProduct?.variants?.length === 1;
      setSelectedVariant(hasOneVariant ? selectedProduct?.variants[0] : null);
      setSelectedOptions(
        hasOneVariant ? selectedProduct?.variants[0]?.selectedOptionsMap : null
      );
      setProductMedia(selectedProduct?.media);

      if (productDisplayType === 'onetimeOnly') {
        setPurchaseType('one-time');
      } else {
        setPurchaseType(
          selectedProduct?.sellingPlanGroupCount > 0
            ? 'subscription'
            : 'one-time'
        );
      }
    }
  }, [selectedProduct]);

  const stickyTopClass = 'md:top-[calc(var(--header-height)+2.5rem)] xl:top-[calc(var(--header-height)+3rem)]';

  const globalProductData = useMemo(
    () => ({
      discount,
      selectedProduct,
      selectedSellingPlan,
      setSelectedSellingPlan,
      setSelectedProduct,
      purchaseType,
      setPurchaseType,
      recommendedFilterFrequency,
      setRecommendedFilterFrequency,
      selectedVariant,
      productSubscriptionWidget,
      productByline,
      setProductByline,
    }),
    [
      discount,
      selectedProduct,
      selectedSellingPlan,
      purchaseType,
      recommendedFilterFrequency,
      selectedVariant,
      productSubscriptionWidget,
      productByline,
    ]
  );

  return (
    <GlobalProductPageContext.Provider value={globalProductData}>
      <section
        id={sectionId}
        className="featured-product px-contained py-[32px] md:py-[62px] bg-white"
      >
        <div className="product__top-section md:px-contained mt-4 max-w-[var(--content-max-width)] md:mx-auto md:pb-12">
          <div className="mx-auto flex flex-col md:flex-row">
            <div className="product__media-section order-0 mb-6 md:mb-0 md:flex-[0_0_50%] xl:flex-[0_0_55%]">
              <div className={`md:sticky ${stickyTopClass}`}>
                <ProductMedia
                  selectedMedia={productMedia}
                  selectedVariant={selectedVariant}
                  product={selectedProduct}
                />
              </div>
            </div>

            <div className="product__details-section order-1 px-4 md:flex-[0_0_50%] xl:flex-[0_0_45%]">
              <div className={`flex flex-col md:sticky ${stickyTopClass}`}>
                <ProductHeader
                  selectedSellingPlan={selectedSellingPlan}
                  purchaseType={purchaseType}
                  product={selectedProduct}
                  selectedVariant={selectedVariant}
                  productType={productDisplayType}
                  productBannerText={productBannerText}
                  customSavePercentageText={customSavePercentageText}
                />

                <ProductDetails
                  product={selectedProduct}
                  linkedProductGroups={linkedProductGroups}
                  selectedOptions={selectedOptions}
                  selectedVariant={selectedVariant}
                  productType={productDisplayType}
                  setSelectedOptions={setSelectedOptions}
                  productDescription={productDescription}
                  productFeatures={productFeatures}
                  discount={discount}
                  bottomBannerText={bottomBannerText}
                  bottomBannerHeading={bottomBannerHeading}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </GlobalProductPageContext.Provider>
  );
}

FeaturedProduct.Schema = Schema;
