import { useState } from 'react';
import { Svg } from '../../snippets/Svg';
import { Markdown, Image } from '../../snippets';

export function ContactFormContent({ content, open = false }) {
  const [isOpen, setIsOpen] = useState(open);

  return (
    content && (
      <div className="mb-6 flex border-b border-zinc-200 pb-6">
        <div className="pr-4 lg:pt-2">
          {content.image && (
            <Image
              className="mx-auto lg:mb-4"
              sizes="32px"
              width="32px"
              height="32px"
              src={content.image.src}
              alt="icon"
            />
          )}
        </div>
        <div className="w-full">
          {content.title && (
            <button
              type="button"
              className="mb-2 flex w-full cursor-pointer items-center justify-between text-2xl font-light tracking-[-.02em] lg:cursor-default"
              onClick={() => setIsOpen(!isOpen)}
              onKeyDown={() => setIsOpen(!isOpen)}
            >
              <span>{content.title}</span>
              <span className="lg:hidden">
                <Svg
                  className={`${!isOpen ? 'block' : 'hidden'} w-5 text-text`}
                  src="/svgs/chevron-down.svg#chevron-down"
                  title="Close"
                  viewBox="0 0 24 24"
                />
                <Svg
                  className={`${isOpen ? 'block' : 'hidden'} w-5 text-text`}
                  src="/svgs/chevron-up.svg#chevron-up"
                  title="Close"
                  viewBox="0 0 24 24"
                />
              </span>
            </button>
          )}

          {content.body && (
            <div className={`${isOpen ? 'block' : 'hidden'} lg:block`}>
              <Markdown className="[&_p]:!font-extralight">
                {content.body}
              </Markdown>
            </div>
          )}
        </div>
      </div>
    )
  );
}

ContactFormContent.displayName = 'Contact Form Content';
