import Script from 'next/script';

export function Beam() {
  return (
    <Script
      id="beam-session"
      type="module"
      dangerouslySetInnerHTML={{
        __html: `
        import { createSession } from 'https://production-beam-widgets.beamimpact.com/web-sdk/v1.24.0/dist/integrations/session.esm.js';
        import {
            events,
            setCookieValue,
        } from 'https://production-beam-widgets.beamimpact.com/web-sdk/v1.24.0/dist/integrations/utils.esm.js';

        const storeDomain = window.location.host.includes('localhost:') ? 'localhost' : window.location.host;
        const beamApiKey = 'dDTsAdyADu1n.e27fbf81-504b-46e4-9e1c-e9c51400d112';
        const cookieName = 'beam_session';

        createSession({
            domain: storeDomain,
            apiKey: beamApiKey,
            cookieName,
        });

        // On cart change, update the cart Id in the beam session cookie
        window.addEventListener(events.BeamCartChangeEvent.eventName, async (event) => {
            const cartId = event?.detail?.cartId;
            if (cartId) {
                setCookieValue({
                name: cookieName,
                value: cartId,
                path: '/',
                domain: storeDomain,
                });
            }
        })
        `,
      }}
    />
  );
}
