import PropTypes from 'prop-types';

export function Spinner({ className, width = 20, stroke = 'currentColor' }) {
  return (
    <svg
      className={className}
      fill="none"
      height={`${width}`}
      role="img"
      stroke={stroke}
      strokeWidth="4"
      viewBox="0 0 32 32"
      width={`${width}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Loading</title>
      <circle
        cx="16"
        cy="16"
        fill="none"
        opacity="0.125"
        r="12"
        strokeWidth="4"
      />
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="12"
        strokeDasharray="20 110"
        strokeWidth="4"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="750ms"
          from="0 16 16"
          repeatCount="indefinite"
          to="360 16 16"
          type="rotate"
        />
      </circle>
    </svg>
  );
}

Spinner.displayName = 'Spinner';
Spinner.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stroke: PropTypes.string,
};
