const mediaContent = {
  label: 'Media Settings',
  name: 'media',
  component: 'group',
  fields: [
    {
      label: 'Image 1',
      name: 'image1',
      component: 'image',
    },
    {
      label: 'Image 2',
      name: 'image2',
      component: 'image',
    },
    {
      label: 'Image 3',
      name: 'image3',
      component: 'image',
    },
    {
      label: 'Image 4',
      name: 'image4',
      component: 'image',
    },
  ],
};

export function Schema() {
  return {
    category: 'Text',
    label: 'Career Block',
    key: 'careerBlock',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2010.47.08_20AM.png?v=1684864571',
    fields: [
      {
        label: 'Eyebrow',
        name: 'eyebrow',
        component: 'text',
        defaultValue: 'CAREERS',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Lorem Ipsum Dolor Sit Amet',
      },
      {
        label: 'Copy',
        name: 'copy',
        component: 'text',
        defaultValue:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.',
      },
      {
        label: 'Call To Action',
        name: 'cta',
        component: 'link',
      },
      mediaContent,
    ],
  };
}
