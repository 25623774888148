import { Schema } from './ArticleFiftyFifty.schema';
import {
  Attribution,
  Body,
  Button,
  Eyebrow,
  Headline,
  Image,
  Link,
} from '../../snippets';
import handleize from '../../utils/handleize';

export function ArticleFiftyFifty({ cms }) {
  const {
    title,
    anchorId,
    heading,
    eyebrow,
    copy,
    link,
    image,
    caption,
    attribution,
    attributionLink,
  } = cms;

  return (
    <div className="relative mb-8 border-b border-t border-[#DDE2E7] lg:mb-16">
      {anchorId && <div id={handleize(anchorId)} className="absolute top-16" />}
      {title && (
        <Headline
          variant="h4"
          element="h4"
          className="border-t border-[#DDE2E7] pb-4 pt-12 lg:pb-16"
        >
          {title}
        </Headline>
      )}
      <div className="items-center overflow-hidden py-12 lg:flex lg:py-12">
        <div className="lg:w-1/2">
          {image && (
            <div className="mb-6 lg:mb-0">
              <Link href={link?.url ?? ''} className="lg:block">
                <Image
                  className=""
                  sizes="48px"
                  width="370px"
                  height="370px"
                  src={image.src}
                />
              </Link>

              {(caption || attribution) && (
                <Attribution
                  caption={caption}
                  attribution={attribution}
                  attributionLink={attributionLink}
                />
              )}
            </div>
          )}
        </div>
        <Link href={link?.url ?? ''} className="lg:w-1/2 lg:pl-8">
          <Eyebrow className="mb-4">{eyebrow}</Eyebrow>
          <Headline variant="h5" element="h5">
            {heading}
          </Headline>
          <Body variant="medium" className="mx-auto mb-6 mt-6 lg:mb-12">
            {copy}
          </Body>
          {link && (
            <div className="cta-group flex gap-2">
              <Button variant="filled" theme="primary">
                {link.text}
              </Button>
            </div>
          )}
        </Link>
      </div>
    </div>
  );
}

ArticleFiftyFifty.displayName = 'ArticleFiftyFifty';
ArticleFiftyFifty.Schema = Schema;
