export default function Schema() {
  return {
    label: 'Quiz',
    key: 'quiz',
    category: 'Content',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/quizz.png?v=1684786495',
    fields: [
      {
        label: 'Eyebrow Text',
        name: 'eyebrowText',
        component: 'text',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Body Text',
        name: 'body',
        component: 'markdown',
      },
      {
        label: 'Action Button',
        name: 'actionButton',
        component: 'link',
      },
      {
        label: 'Background Video URL',
        name: 'backgroundVideoUrl',
        component: 'text',
        defaultValue:
          'https://cdn.shopify.com/videos/c/o/v/6a6773d78b664a3aae88a75d18f73247.mp4',
      },
      {
        label: 'Background Image',
        name: 'backgroundImage',
        component: 'image',
      },
      {
        label: 'Cities',
        name: 'cities',
        component: 'group-list',
        fields: [
          {
            label: 'Contaminants Box Title',
            name: 'contaminantsBoxTitle',
            component: 'text',
          },
          {
            label: 'Contaminants Box Content',
            name: 'contaminantsBoxContent',
            component: 'markdown',
          },
          {
            label: 'City',
            name: 'city',
            component: 'text',
          },
          {
            label: 'Total contaminants found',
            name: 'totalContaminantsFound',
            component: 'number',
          },
          {
            label: 'Percentage of contaminants',
            description: 'Pick a number between 0 and 100',
            name: 'percentageOfContaminants',
            component: 'number',
            defaultValue: 0,
          },
        ],
      },
    ],
  };
}
