export const Schema = () => {
  return {
    label: 'Lab Results Navigation',
    key: 'labResultsNavigation',
    category: 'Lab Results',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/lab-results-nav.png?v=1685037549',
    fields: [
      {
        name: 'heading',
        component: 'text',
        label: 'Heading',
      },
      {
        label: 'Menu Links',
        name: 'links',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
        ],
      },
    ],
  };
};
