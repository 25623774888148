import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { SearchHeader } from './SearchHeader';
import { SearchList } from './SearchList';
import { SearchFilterMenu } from './SearchFilterMenu';
import { SearchEmptyResults } from './SearchEmptyResults';

import { Collection } from '../../snippets';
import { Schema } from './SearchResults.schema';
import {
  useDataLayerActions,
  useSearchCollectionResults,
  useSearchProductResults,
  useSearchPageResults,
  useSearchArticleResults,
} from '../../hooks';
import { fetchProductsFromHandles } from '../../utils';

export function SearchResults() {
  const { query } = useRouter();
  const { sendViewSearchResultsEvent } = useDataLayerActions();
  const [rawTerm, setRawTerm] = useState(query.term);
  const [term, setTerm] = useState(rawTerm);
  const [totalCount, setTotalCount] = useState(0);
  const [emptyCollectionProducts, setEmptyCollectionProducts] = useState(null);
  const [searchType, setSearchType] = useState('products');
  const [products, setProducts] = useState(null);

  const { productResults } = useSearchProductResults({
    term,
    mounted: true,
  });

  const { collectionResults } = useSearchCollectionResults({
    term,
  });
  const { pageResults } = useSearchPageResults({
    term,
  });

  const { articleResults } = useSearchArticleResults({
    term,
  });

  useEffect(() => {
    // debounce raw term
    const handler = window.requestTimeout(() => {
      setTerm(rawTerm);
      setTotalCount(
        productResults?.length +
          collectionResults?.length +
          articleResults?.length +
          pageResults?.length
      );
    }, 300);
    return () => {
      window.clearRequestTimeout(handler);
    };
  }, [rawTerm, productResults, collectionResults, articleResults, pageResults]);

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: productResults,
      isSearchPage: true,
      searchTerm: term,
    });
  }, [productResults, sendViewSearchResultsEvent]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch('/json/collections/frontpage.json');
      const json = await data.json();

      setEmptyCollectionProducts(json.products);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (term?.length < 3) {
      setTotalCount(0);
    }
  }, [term]);

  useEffect(() => {
    if (!productResults) {
      setProducts(null);
      return;
    }
    const getFullProducts = async () => {
      const handles = productResults.map(({ item }) => item?.handle);
      const fullProducts = await fetchProductsFromHandles(handles);
      setProducts(fullProducts);
    };
    getFullProducts();
  }, [productResults]);

  return (
    <div className="bg-clearBlue">
      <SearchHeader
        rawTerm={rawTerm}
        setRawTerm={setRawTerm}
        productResults={productResults}
        collectionResults={collectionResults}
        pageResults={pageResults}
        articleResults={articleResults}
        totalCount={totalCount}
      />
      <div className="">
        {productResults?.length > 0 ||
        collectionResults?.length > 0 ||
        articleResults?.length > 0 ||
        pageResults?.length > 0 ? (
          <div className="px-contained mb-8 lg:flex">
            <SearchFilterMenu
              setSearchType={setSearchType}
              searchType={searchType}
              productResults={productResults}
              collectionResults={collectionResults}
              pageResults={pageResults}
              articleResults={articleResults}
            />
            <div className="w-full">
              {searchType === 'products' && (
                <SearchList
                  emptyCollectionProducts={emptyCollectionProducts}
                  results={productResults}
                  resultName="Products"
                  rawTerm={rawTerm}
                  products={products}
                />
              )}

              {searchType === 'collections' && (
                <SearchList
                  results={collectionResults}
                  resultUrl="collections"
                  resultName="Collection"
                  resultCTA="View  Collection"
                />
              )}

              {searchType === 'blog-articles' && (
                <SearchList
                  results={articleResults}
                  resultUrl="articles"
                  resultName="Blog Articles"
                  resultCTA="Read Article"
                />
              )}

              {searchType === 'pages' && (
                <SearchList
                  results={pageResults}
                  resultUrl="pages"
                  resultName="Pages"
                  resultCTA="View Page"
                />
              )}
            </div>
          </div>
        ) : (
          emptyCollectionProducts && (
            <SearchEmptyResults
              collectionProducts={emptyCollectionProducts}
              term={term}
            />
          )
        )}
      </div>
    </div>
  );
}

SearchResults.displayName = 'SearchResults';
SearchResults.Schema = Schema;
