import { Spinner } from '../Spinner';
import { Svg } from '../Svg';

export function QuantitySelector({
  handleDecrement,
  handleIncrement,
  isUpdatingItem,
  item,
}) {
  const { variant, quantity } = item;

  return (
    <div className="flex w-[75px] items-center justify-between rounded-full border border-[#BCBEC2]">
      <button
        aria-label={`Reduce quantity of ${variant.product.title} by 1 to ${
          quantity - 1
        }`}
        className="relative h-8 w-8 transition disabled:opacity-50 md:hover:border-gray"
        disabled={isUpdatingItem}
        onClick={handleDecrement}
        type="button"
      >
        <Svg
          className="absolute top-1/2 left-1/2 w-4 -translate-x-1/2 -translate-y-1/2 text-text"
          src="/svgs/minus.svg#minus"
          title="Minus"
          viewBox="0 0 24 24"
        />
      </button>

      <div className="relative flex flex-1 items-center justify-center">
        {isUpdatingItem ? (
          <Spinner stroke="var(--gray)" />
        ) : (
          <p className="w-full text-center outline-none">{quantity}</p>
        )}
      </div>

      <button
        aria-label={`Increase quantity of ${variant.product.title} by 1 to ${
          quantity + 1
        }`}
        className="relative h-8 w-8  transition disabled:opacity-50 md:hover:border-gray"
        disabled={isUpdatingItem}
        onClick={handleIncrement}
        type="button"
      >
        <Svg
          className="absolute top-1/2 left-1/2 w-4 -translate-x-1/2 -translate-y-1/2 text-text"
          src="/svgs/plus.svg#plus"
          title="Plus"
          viewBox="0 0 24 24"
        />
      </button>
    </div>
  );
}

QuantitySelector.displayName = 'QuantitySelector';
