import { useSettings } from '@backpackjs/storefront';
import dynamic from 'next/dynamic';

const BeamProductDetailsPage = dynamic(
  () => import('@beamimpact/web-sdk/dist/react/product-details-page'),
  { loading: () => null, ssr: false }
);

export function BeamProductDetailsPageWrapper() {
  const settings = useSettings();
  const beamSettings = settings?.beam;

  return (
    !!beamSettings && (
      <div className="my-[40px] border border-[#83888E] bg-[#E9F0F7]">
        <BeamProductDetailsPage
          apiKey={beamSettings.beamApiKey}
          storeId={beamSettings.beamStoreId}
          baseUrl="https://api.beamimpact.com"
        />
      </div>
    )
  );
}
