// import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';
import { Image, Collection, Link, Svg } from '../../snippets';

const collectionItem = (item) => {
  const [collectionImage, setCollectionImage] = useState(null);

  useEffect(() => {
    const getCollection = async () => {
      const response = await fetch(`/json/collections/${item.handle}.json`);
      const data = await response.json();

      setCollectionImage(data?.image?.src);
    };

    getCollection();
  }, [collectionImage]);

  return (
    <Link
      className=""
      aria-label={item.title}
      href={`/collections/${item.handle}`}
    >
      <div>
        {collectionImage && (
          <div className="mb-6 lg:mb-0 lg:block">
            <Image
              className=""
              sizes="48px"
              width="425px"
              height="425px"
              src={collectionImage}
            />
          </div>
        )}
        <p className="mt-4 text-xl font-light tracking-[-.02em]">
          {item.title}
        </p>
      </div>
    </Link>
  );
};

const listItem = (item, resultCTA, resultUrl) => {
  return (
    <div className="mb-6 w-8/12 border-b border-zinc-200 pb-6">
      {item?.category && (
        <div className="mb-2 text-2xs font-normal uppercase tracking-[.04em] lg:text-sm">
          {item.category}
        </div>
      )}
      <div className="mb-4 text-2xl font-light tracking-[.02em] lg:text-4xl">
        {item.title}
      </div>
      {item?.description && (
        <p className="mb-2 text-xs lg:text-base">{item.description}</p>
      )}
      <Link
        aria-label={resultCTA}
        className="color-link mt-4 block flex items-center text-sm  font-normal text-textLink no-underline lg:text-lg"
        href={`/${resultUrl}/${item.handle}`}
      >
        <span className="pr-4">{resultCTA}</span>
        <span>
          <Svg
            className="inherit w-5"
            src="/svgs/arrow-right.svg#arrow-right"
            title="Close"
            viewBox="0 0 24 24"
          />
        </span>
      </Link>
    </div>
  );
};

export function SearchList({
  results,
  resultName,
  resultCTA,
  resultUrl,
  emptyCollectionProducts,
  products,
}) {
  return (
    <div className="">
      {results?.length > 0 &&
        (() => {
          if (resultName === 'Products' && results.length && products) {
            return (
              <Collection
                emptyCollectionProducts={emptyCollectionProducts}
                handle="search"
                products={products}
                showHeading={false}
              />
            );
          }
          if (resultName === 'Collection' && results.length) {
            return (
              <div className="grid-cols-3 gap-x-5 lg:grid">
                {results.map(({ item }, index) => {
                  return (
                    <Fragment key={index}>{collectionItem(item)}</Fragment>
                  );
                })}
              </div>
            );
          }
          return results.map(({ item }, index) => {
            return (
              <Fragment key={index}>
                {listItem(item, resultCTA, resultUrl)}
              </Fragment>
            );
          });
        })()}
    </div>
  );
}

SearchList.displayName = 'SearchHeader';
