import { useSettings } from '@backpackjs/storefront';
import { Image } from '../Image';
import { Link } from '../Link';

export function FooterQuizWidget() {
  const settings = useSettings();
  const { link, title, bgColor, bgImage } = {
    ...settings?.footer?.quizWidgetSettings,
  };

  return (
    <div id="footer-quiz-widget">
      <div
        style={{ backgroundColor: bgColor }}
        className="aspect-[340/220] w-full p-[1.5rem]"
      >
        <div className="relative flex h-full min-h-[220px] w-full items-center justify-center text-center">
          {bgImage?.src && (
            <Image
              className="opacity-50 mix-blend-multiply"
              alt="Contaminants Quiz Background"
              fill
              sizes="(min-width: 1024px) 40vw, 100vw"
              src={bgImage.src}
            />
          )}

          <div className="relative">
            {title && (
              <div className="mb-3 text-[22px] font-[350]">{title}</div>
            )}
            {link && (
              <Link
                aria-label={link?.text}
                className="w-full font-[350] underline"
                href={link?.url}
                newTab={link?.newTab}
              >
                {link.text}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

FooterQuizWidget.displayName = 'FooterQuizWidget';
