import {
  useCart,
  useCartAddDiscount,
  useCartRemoveDiscount,
} from '@backpackjs/storefront';
import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import { Svg } from '../Svg';

export function CartDiscount() {
  const [discountCode, setDiscountCode] = useState('');
  const [applied, setApplied] = useState(false);
  const [recentlyApplied, setRecentlyApplied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { cartAddDiscount, ...addDiscountStatus } = useCartAddDiscount();
  const { cartRemoveDiscount, ...removeDiscountStatus } =
    useCartRemoveDiscount();

  const handleApplyDiscount = async () => {
    await cartAddDiscount({
      discountCode,
    });

    /* if (addDiscountStatus?.success === false) {
      // TODO: set error here
    } */

    setApplied(true);
    setRecentlyApplied(true);
    setTimeout(() => {
      setRecentlyApplied(false);
    }, 3000);
  };

  const handleRemoveDiscount = async () => {
    await cartRemoveDiscount();
    setApplied(false);
    setDiscountCode('');
  };

  const handleSubmit = async () => {
    if (applied) {
      await handleRemoveDiscount();
    } else {
      await handleApplyDiscount();
    }
  };

  const cart = useCart();

  useEffect(() => {
    if (cart?.discountCodes.length) {
      setApplied(true);
      setDiscountCode(cart?.discountCodes[0].code);

      if (discountCode === cart?.discountCodes[0].code) {
        setDiscountCode(cart?.discountCodes[0].code);
      }
    }
  }, [cart, discountCode]);

  return (
    <div className="discount-code-wrapper bg-[#F2F7FB] p-4">
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for discount code}`}
        className="flex w-full items-center justify-between gap-x-4 text-left"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <p className="text-[16px] font-[400] tracking-[-0.01em]">
          Discount Code
        </p>
        {isOpen ? (
          <Svg
            className="w-4 text-current"
            src="/svgs/minus.svg#minus"
            title="Minus"
            viewBox="0 0 24 24"
          />
        ) : (
          <Svg
            className="w-4 text-current"
            src="/svgs/plus.svg#plus"
            title="Plus"
            viewBox="0 0 24 24"
          />
        )}
      </button>

      <div
        className={`pb-2 pt-4 lg:max-w-[410px] ${isOpen ? 'block' : 'hidden'}`}
      >
        <div className="input-group relative">
          <input
            aria-label="Discount Code"
            className={`w-full min-w-0 flex-1 rounded-full border border-primary px-4 py-3 text-base outline-none lg:h-[50px] ${
              applied ? 'text-mediumGray' : ''
            }`}
            placeholder="Discount Code"
            onChange={({ target }) => setDiscountCode(target.value)}
            value={discountCode}
            readOnly={applied}
          />
          <Button
            disabled={!discountCode.length || addDiscountStatus?.loading}
            theme={`${!applied ? 'secondary' : 'primary'}`}
            variant="filled"
            className="absolute right-[3px] top-[3px] flex h-[44px] !px-7 !text-[17px] lg:!py-2"
            onClick={() => handleSubmit()}
          >
            {recentlyApplied ? (
              <Svg
                className="mr-3 w-[25px] text-white"
                src="/svgs/checkmark.svg#checkmark"
                title="Check"
                viewBox="0 0 24 24"
              />
            ) : null}
            <span>
              {/* eslint-disable-next-line no-nested-ternary */}
              {recentlyApplied ? 'Applied' : applied ? 'Remove' : 'Submit'}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}
