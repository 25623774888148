import React from 'react';
import { Image, Svg } from '../../../snippets';
import { useGlobalContext } from '../../../contexts';

export function ProductMediaThumbnails({ activeIndex, selectedMedia, swiper }) {
  const {
    actions: { openModal },
  } = useGlobalContext();

  const handleModal = (imageSrc = null, videoSources = null) => {
    // example modal
    openModal(
      <div>
        <div className="p-6">
          {imageSrc && (
            <div className="modal-image-wrapper relative flex aspect-video h-[40rem] w-full max-w-[1200px] items-center justify-center">
              <Image
                alt="Image Preview"
                fill
                sizes="(min-width: 1440px) 900px, (min-width: 768px) 50vw, 100vw"
                className="!object-contain mix-blend-multiply"
                src={imageSrc}
              />
            </div>
          )}
          {videoSources && (
            <div className="modal-video-wrapper">
              {videoSources.map((source) => {
                if (source?.format === 'mp4') {
                  return (
                    <video
                      autoPlay
                      playsInline
                      className="h-full w-full object-cover"
                    >
                      <source src={source?.url} type="video/mp4" />
                    </video>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <ul className="grid grid-cols-2 gap-8">
      {selectedMedia?.map(
        ({ id, mediaContentType, preview, product, sources }, index) => {
          const previewSrc = preview?.image?.previewSrc;
          const videoClasses = mediaContentType === 'VIDEO' ? 'col-span-2' : '';
          const firstClasses = index === 0 ? 'md:hidden' : '';
          return (
            <li key={id} className={`${videoClasses} ${firstClasses}`}>
              <button
                aria-label={`Slide to product image ${index + 1}`}
                className={`relative flex h-full w-full items-center justify-center overflow-hidden bg-[#E6F0F8] transition
                ${
                  mediaContentType === 'VIDEO'
                    ? 'aspect-[893/595]'
                    : 'aspect-square'
                }
                `}
                onClick={() => {
                  if (mediaContentType !== 'VIDEO' && previewSrc) {
                    handleModal(preview?.image?.src);
                  } else handleModal(null, sources);
                  swiper.slideTo(index);
                }}
                type="button"
              >
                {previewSrc && (
                  <Image
                    alt={product.title}
                    fill
                    className={` ${
                      mediaContentType === 'VIDEO'
                        ? ''
                        : '!object-contain !mix-blend-multiply'
                    }`}
                    sizes="(min-width: 1440px) 500px, (min-width: 768px) 50vw, 100vw"
                    priority={index < 2}
                    src={preview?.image?.src}
                  />
                )}

                {mediaContentType === 'VIDEO' && (
                  <div className="absolute bottom-[30px] right-[30px] flex h-[47px] w-[47px] items-center justify-center rounded-full bg-gradient-to-t from-[#8DCEF9] to-[#C1EFFA]">
                    <div className="flex h-full w-full scale-95 items-center justify-center rounded-full bg-white">
                      <Svg
                        className="w-[14px] text-textLink"
                        src="/svgs/play.svg#play"
                        title="Play"
                        viewBox="0 0 24 24"
                      />
                    </div>
                  </div>
                )}
              </button>
            </li>
          );
        }
      )}
    </ul>
  );
}

ProductMediaThumbnails.displayName = 'ProductMediaThumbnails';
