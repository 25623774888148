import { useCallback, useEffect, useRef, useState } from 'react';
import { useCart } from '@backpackjs/storefront';
import { useRouter } from 'next/router';

import { mapLineItem } from './utils';

const IGNORED_PATHS = ['account', 'cart', 'collections', 'products'];

export function useDataLayerCustomer({
  baseEcommerce,
  DEBUG,
  isPageAsProduct,
  isPageAsCollection,
  userProperties,
}) {
  const asPathRef = useRef(null);
  const cart = useCart();
  const router = useRouter();
  const asPath = router.asPath.split('?')[0];

  const [delayForContextIsFinished, setDelayForContextIsFinished] =
    useState(false);
  const [userDataEventTriggered, setUserDataEventTriggered] = useState(false);

  const userDataEvent = useCallback(
    ({ ecommerce, userProperties: _userProperties }) => {
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list =
        (window.location.pathname.startsWith('/collections') &&
          window.location.pathname) ||
        (previousPath?.startsWith('/collections') && previousPath) ||
        '';
      const event = {
        event: 'dl_user_data',
        user_properties: _userProperties,
        cart_total: cart?.estimatedCost?.totalAmount?.amount || '0.0',
        ecommerce: {
          ...ecommerce,
          cart_contents: {
            products: cart?.lines?.map(mapLineItem(list)) || [],
          },
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
      setUserDataEventTriggered(true);
      setDelayForContextIsFinished(false);
    },
    [cart?.updatedAt]
  );

  // Force delay on user data event
  useEffect(() => {
    setTimeout(() => setDelayForContextIsFinished(true), 1000);
  }, [asPath]);

  // Trigger 'dl_user_data' event on path change after base data is ready,
  // excluding account and paths that trigger event directly before their respective events
  useEffect(() => {
    if (
      !delayForContextIsFinished ||
      !baseEcommerce ||
      !userProperties ||
      IGNORED_PATHS.includes(asPath.split('/')[1]) ||
      asPath.startsWith('/pages/search') ||
      isPageAsProduct ||
      isPageAsCollection ||
      asPath === asPathRef.current
    )
      return undefined;

    userDataEvent({ ecommerce: baseEcommerce, userProperties });

    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [
    asPath,
    !!baseEcommerce,
    delayForContextIsFinished,
    isPageAsProduct,
    isPageAsCollection,
    !!userProperties,
  ]);

  // Trigger 'dl_user_data' event on account pages after base data is ready,
  // excluding after login/register events
  useEffect(() => {
    if (
      !delayForContextIsFinished ||
      !baseEcommerce ||
      !userProperties ||
      !asPath.startsWith('/account') ||
      asPath === asPathRef.current
    )
      return undefined;
    userDataEvent({ ecommerce: baseEcommerce, userProperties });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [asPath, !!baseEcommerce, delayForContextIsFinished, !!userProperties]);

  return { userDataEvent, userDataEventTriggered };
}
