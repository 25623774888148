import { useCallback } from 'react';
import {
  useLocalizedVariant,
  useProductItemByHandle,
} from '@backpackjs/storefront';

import { Image } from '../Image';
import { Link } from '../Link';
import { useDataLayerActions, useVariantPrices } from '../../hooks';
import { OkendoStarRating } from '../Okendo/OkendoStarRating';

export function SearchItem({ closeSearch, index, item, searchTerm }) {
  const { productItem } = useProductItemByHandle({ handle: item.handle });

  const { price, compareAtPrice } = useVariantPrices({
    variant: productItem?.variants?.[0],
  });

  const { localized } = useLocalizedVariant({
    variant: productItem?.variants?.[0],
  });

  const { sendClickProductItemEvent } = useDataLayerActions();

  const url = `/products/${item.handle}`;
  const color = productItem?.variants?.[0]?.selectedOptionsMap?.Color;
  const image = productItem?.images?.[0];

  const handleClick = useCallback(() => {
    sendClickProductItemEvent({
      isSearchResult: true,
      listIndex: index,
      localized,
      product: productItem,
      searchTerm,
      selectedVariant: productItem?.variants?.[0],
    });
    closeSearch();
  }, [index, localized, productItem?.id, searchTerm]);

  return (
    <Link
      aria-label={`View ${productItem?.title}`}
      className="relative grid grid-cols-[5.5rem_1fr] items-start gap-3"
      href={url}
      onClick={handleClick}
    >
      {image?.src && (
        <Image
          alt={productItem?.title}
          className="bg-offWhite"
          height={Math.floor(88 / (image.width / image.height))}
          src={image.src}
          width="88"
        />
      )}

      <div className="justify-fle flex flex-col gap-3">
        <div>
          <div className="okendo-widget-container text-primary [&_.oke-sr-count]:font-light [&_.oke-sr-count_span]:!text-[12px] [&_svg]:h-[13px] ">
            <OkendoStarRating
              className="!text-[0px] [&_.oke-sr]:inline [&_[data-oke-container]>div]:text-[20px] [&_span]:font-light"
              productId={productItem?.legacyResourceId}
            />
          </div>
          <h4 className="text-base font-light text-primary">
            {productItem?.title}
          </h4>

          {color && <p className="text-sm text-shadowGray">{color}</p>}
        </div>

        <div className="flex flex-wrap gap-x-1.5">
          {compareAtPrice && (
            <p className="text-sm text-mediumGray line-through">
              {compareAtPrice}
            </p>
          )}
          {price && <p className="text-sm text-primary">From {price}</p>}
        </div>
      </div>
    </Link>
  );
}

SearchItem.displayName = 'SearchItem';
