export function useMultipass() {
  const generateUrl = async ({ customer, returnTo }) => {
    const apiEndpoint = '/api/multipass-login';

    const currentTime = new Date();

    const customerData = {
      ...customer,
      created_at: currentTime,
      return_to: returnTo,
    };

    const response = await fetch(apiEndpoint, {
      method: 'POST',
      body: JSON.stringify({ data: customerData }),
    });

    const data = await response.json();

    if (!data) {
      throw new Error('No data returned from fetchReviewAggregate');
    }

    return data;
  };

  return { generateUrl };
}
