export default function Schema() {
  return {
    label: 'Whats In Your Water Quiz',
    key: 'wiyw-quiz',
    fields: [
      {
        label: 'Landing Page',
        name: 'landingPage',
        component: 'group',
        fields: [
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Eyebrow',
            name: 'eyebrow',
            component: 'text',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Bullets',
            name: 'bullets',
            component: 'group-list',
            fields: [
              {
                label: 'Bullet',
                name: 'bullet',
                component: 'text',
              },
            ],
          },
          {
            label: 'Disclaimer Text',
            name: 'disclaimerText',
            component: 'textarea',
          },
        ],
      },
      {
        label: 'Quiz',
        name: 'quiz',
        component: 'group',
        fields: [
          {
            label: 'Questions Pages',
            name: 'questionsPages',
            component: 'group-list',
            fields: [
              {
                label: 'Heading',
                name: 'heading',
                component: 'text',
              },
              {
                label: 'Subheading',
                name: 'subheading',
                component: 'text',
              },
              {
                label: 'Choices',
                name: 'choices',
                component: 'select',
                options: [
                  {
                    label: 'Single Choice',
                    value: 'single',
                  },
                  {
                    label: 'Multiple Choice',
                    value: 'multiple',
                  },
                ],
                defaultValue: 'multiple',
              },
              {
                label: 'Columns',
                name: 'columns',
                component: 'select',
                options: [
                  {
                    label: '1',
                    value: '1',
                  },
                  {
                    label: '2',
                    value: '2',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                ],
              },
              {
                label: 'Options',
                name: 'options',
                component: 'group-list',
                fields: [
                  {
                    label: 'Option Text',
                    name: 'optionText',
                    component: 'text',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: 'Email Entry Page',
        name: 'emailEntryPage',
        component: 'group',
        fields: [
          {
            label: 'Eyebrow',
            name: 'eyebrow',
            component: 'text',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Input Placeholder',
            name: 'inputPlaceholder',
            component: 'text',
          },
          {
            label: 'CTA Text',
            name: 'ctaText',
            component: 'text',
          },
          {
            label: 'Skip Text',
            name: 'skipText',
            component: 'text',
          },
          {
            label: 'Disclaimer Text',
            name: 'disclaimerText',
            component: 'text',
            defaultValue:
              'By providing your email, you agree to receive promotional emails and updates from Clearly Filtered.',
          },
        ],
      },
    ],
  };
}

// generate a jsdoc for a react fucntional component that will receive the above data as props inside of an object called cms
