import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Svg } from '../Svg';

export function ReviewStars({
  color = 'var(--text)',
  rating = 0,
  size = 'large', // small | large
}) {
  const stars = useMemo(() => {
    const fullStar = {
      key: 'star-full',
      label: 'Full Star',
    };
    const emptyStar = {
      key: 'star-empty',
      label: 'Empty Star',
    };

    return [...Array(5).keys()].map((index) => {
      const diff = rating - index;
      if (diff < 1 && diff > 0) {
        return {
          key: 'half-star',
          label: 'Half Star',
          percent: Math.round(diff * 100),
        };
      }

      return diff >= 1 ? fullStar : emptyStar;
    });
  }, [rating]);

  const classBySize = {
    small: {
      gap: 'gap-0.5',
      width: 'w-3',
    },
    large: {
      gap: 'gap-1',
      width: 'w-4',
    },
  };

  return (
    <ul className={`flex items-center ${classBySize[size]?.gap}`}>
      {stars.map(({ key, label, percent }, index) =>
        percent ? (
          <li className="relative" key={index}>
            <Svg
              className={`absolute left-0 top-0 z-10 ${classBySize[size]?.width}`}
              src="/svgs/star-empty.svg#star-empty"
              style={{ color }}
              title={label}
              viewBox="0 0 24 24"
            />
            <Svg
              className={`relative z-20 ${classBySize[size]?.width}`}
              src="/svgs/star-full.svg#star-full"
              style={{
                color,
                clipPath: percent ? `inset(0 ${100 - percent}% 0 0)` : 'unset',
              }}
              title={label}
              viewBox="0 0 24 24"
            />
          </li>
        ) : (
          <li key={index}>
            <Svg
              className={`${classBySize[size]?.width}`}
              src={`/svgs/${key}.svg#${key}`}
              style={{ color }}
              title={label}
              viewBox="0 0 24 24"
            />
          </li>
        )
      )}
    </ul>
  );
}

ReviewStars.displayName = 'ReviewStars';
ReviewStars.propTypes = {
  color: PropTypes.string,
  rating: PropTypes.number,
  size: PropTypes.oneOf(['small', 'large']),
};
