import { useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';

import { PlayButton, Image } from '../../snippets';

export function ProductSupportVideoBanner(props) {
  const { videoContent } = props;

  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className="mx-4 mb-6 lg:mx-0">
      <div className="relative h-0 overflow-hidden pb-[56.25%]">
        {isPlaying && videoContent.videoid ? (
          <Vimeo
            video={videoContent.videoid}
            width="100%"
            height="100%"
            autoplay
            muted
            responsive
            className="vimeo-player absolute inset-0"
          />
        ) : (
          <button
            type="button"
            className="absolute inset-0 cursor-pointer"
            onClick={() => setIsPlaying(true)}
          >
            {videoContent.videoimage?.src && (
              <Image
                className="absolute inset-0 object-cover"
                fill
                src={videoContent.videoimage.src}
              />
            )}
            <div className="absolute top-0 left-0 w-full p-4 text-center lg:px-12 lg:pt-12">
              {videoContent.videotitle && (
                <p className="font-white text-base tracking-[-0.01em] text-white lg:text-2xl">
                  {videoContent.videotitle}{' '}
                </p>
              )}
              {videoContent.videocopy && (
                <p className="font-white text-xs font-light text-white lg:text-base">
                  {videoContent.videocopy}{' '}
                </p>
              )}
            </div>
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
              <PlayButton />
            </div>
          </button>
        )}
      </div>
    </div>
  );
}
