import { useEffect } from 'react';

import { ColorVariantOptions } from './ColorVariantOptions';

export function ColorVariantSelector({
  enabledColorNameOnHover,
  product,
  setVariantFromColor,
  swatchesMap,
  variantFromColor,
  className,
}) {
  const hasMultipleColorsFromProduct =
    !product?.grouping?.isTransformed && product?.optionsMap?.Color?.length > 1;
  const hasMultipleColorsFromGrouping =
    product?.grouping?.isTransformed &&
    product.grouping.optionsMap.Color?.length > 1;
  const hasMultipleColors =
    hasMultipleColorsFromProduct || hasMultipleColorsFromGrouping;

  // sets initial variant from initial color
  useEffect(() => {
    if (!product || !hasMultipleColors) return;
    setVariantFromColor(product.variants?.[0] || null);
  }, [product?.id, hasMultipleColors]);

  return hasMultipleColors && variantFromColor ? (
    <div className={`mt-3.5 ${className}`}>
      <ColorVariantOptions
        enabledColorNameOnHover={enabledColorNameOnHover}
        product={product}
        setVariantFromColor={setVariantFromColor}
        swatchesMap={swatchesMap}
        variantFromColor={variantFromColor}
      />
    </div>
  ) : null;
}

ColorVariantSelector.displayName = 'ColorVariantSelector';
