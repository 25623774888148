import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  useCustomerRecoverPassword,
  useSettings,
} from '@backpackjs/storefront';
import { Headline } from '../Typography';
import { Spinner } from '../Spinner';
import { Button } from '../Button';
import { Svg } from '../Svg';

export function ForgotPassword({ setActiveForm }) {
  const { finished, recoverPassword, reset, resetTimer, started, success } =
    useCustomerRecoverPassword();
  const settings = useSettings();

  const [message, setMessage] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { heading, postSubmissionText, subtext } = {
    ...settings?.account?.forgot,
  };

  const buttonText = useMemo(() => {
    if (finished) {
      resetTimer.current = setTimeout(() => {
        if (reset) reset();
        clearTimeout(resetTimer.current);
      }, 3000);
      return success ? 'Submitted' : 'Submitted';
    }
    return started ? 'Submitting' : 'Submit';
  }, [finished, started, success]);

  const handleRecoverPassword = useCallback(async (data) => {
    const { email } = data;
    await recoverPassword({ email });
  }, []);

  useEffect(() => {
    if (finished) {
      setMessage(postSubmissionText);
    }
  }, [finished]);

  return (
    <div className="flex flex-col  overflow-y-auto rounded px-4 py-3 md:px-8">
      <Headline variant="h5" className="lg:max-w-[350px]">
        {heading}
      </Headline>

      <p className="mt-6 text-left text-sm font-light">{subtext}</p>

      <form
        className="mx-auto mt-6 flex w-full flex-col gap-4"
        onSubmit={handleSubmit(handleRecoverPassword)}
      >
        <label
          className="relative flex w-full flex-col-reverse  gap-x-1 gap-y-1.5 justify-self-stretch"
          htmlFor="email"
        >
          <input
            className={`w-full !min-w-0 border border-[#DDE2E7] px-3 py-[19px] text-sm font-light !outline-0 ${
              errors.email ? '!border-[#ED7C5C]' : null
            } ${message ? '!border-[#5ABE8A] !text-[#5ABE8A]' : null}`}
            name="email"
            placeholder="email"
            type="text"
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
          />
          {message && (
            <span className="absolute right-4 top-1/2  -translate-y-1/2 transform">
              <Svg
                className="w-5 text-text"
                src="/svgs/green-checkmark.svg#checkmark"
                title="Success icon"
                viewBox="0 0 18 18"
              />
            </span>
          )}
        </label>
        {errors.email && (
          <p className="text-[14px] text-[#ED7C5C]">
            *Please enter a valid email address
          </p>
        )}
        {message && (
          <p className="text-[14px] text-[#5ABE8A]">
            Success! Please check your email
          </p>
        )}

        <Button
          theme="primary"
          variant="filled"
          type="submit"
          className="mx-auto mt-12 flex w-full max-w-[210px] items-center justify-center"
        >
          {started ? <Spinner /> : 'Submit'}
        </Button>
      </form>

      <button
        className="mt-6 text-center text-sm font-light tracking-[-.01em]"
        onClick={() => setActiveForm('login')}
        type="button"
      >
        Already a member?{' '}
        <span className="text-underline font-base">Log In</span>
      </button>

      <button
        className="mt-6 text-center text-sm font-light tracking-[-.01em]"
        onClick={() => setActiveForm('create')}
        type="button"
      >
        Don&lsquo;t have an account?{' '}
        <span className="text-underline font-base">Sign Up</span>
      </button>

      {/* {message && <p className="mt-4 text-center text-sm">{message}</p>} */}
    </div>
  );
}

ForgotPassword.displayName = 'ForgotPasswordForm';
