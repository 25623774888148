import { Schema } from './ArticleImage.schema';
import { Image, Link, Attribution } from '../../snippets';

export function ArticleImage({ cms }) {
  const { image, imageLink, caption, attribution, attributionLink } = cms;

  return (
    <div className="mb-8 lg:mb-16">
      {image && (
        <Link href={imageLink?.url ?? ''}>
          <Image src={image.src} width={image.width} height={image.height} />
        </Link>
      )}
      {(caption || attribution) && (
        <Attribution
          caption={caption}
          attribution={attribution}
          attributionLink={attributionLink}
        />
      )}
    </div>
  );
}

ArticleImage.displayName = 'ArticleImage';
ArticleImage.Schema = Schema;
