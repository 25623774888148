export const Schema = () => ({
  label: 'Blog Hero',
  key: 'blogHero',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Water Blog Home',
    },
    {
      label: 'Copy',
      name: 'copy',
      component: 'text',
      defaultValue:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
    },
    {
      label: 'Background Video URL',
      name: 'backgroundVideoUrl',
      description: 'Video will always be muted and autoplay with loop',
      component: 'text',
    },
    {
      label: 'Background Video URL (Mobile)',
      name: 'backgroundVideoUrlMobile',
      component: 'text',
    },
    {
      label: 'Background Image',
      name: 'backgroundImage',
      component: 'image',
    },
    {
      label: 'Background Image (Mobile)',
      name: 'backgroundImageMobile',
      component: 'image',
    },
  ],
});
