import { Eyebrow, Headline, Image, Markdown } from '../../snippets';
import useMediaQuery from '../../utils/useMediaQuery';
import { Schema } from './ProductUniqueTechnology.schema';

export function ProductUniqueTechnology({ cms }) {
  const {
    eyebrow,
    heading,
    image,
    imageAlignment,
    content,
    darkMode,
    mobileImage,
  } = cms;
  const isMobile = useMediaQuery('(max-width: 767px)');
  return (
    <section className={darkMode ? 'dark' : ''}>
      <div className="dark:bg-[#202635] dark:text-white">
        <div
          className={[
            'px-contained mx-auto max-w-[var(--content-max-width)] pb-[30px] md:pb-[45px] md:pt-[90px]',
          ].join(' ')}
        >
          <div className="unique-technology grid grid-cols-1 lg:grid-cols-2">
            <div
              className={`content-column flex items-center border-t border-t-[#FFFFFF33] p-4 pt-[60px] sm:p-[36px] md:px-[50px] md:py-[100px] lg:order-1 lg:aspect-square lg:border-t-0 ${
                imageAlignment === 'left' ? 'lg:order-1' : 'lg:order-0'
              }`}
            >
              <div>
                {eyebrow && (
                  <Eyebrow className="mb-4 uppercase">{eyebrow}</Eyebrow>
                )}
                {heading && (
                  <Headline
                    variant="h3"
                    element="h2"
                    className="mb-[40px] max-md:text-[22px]"
                  >
                    {heading}
                  </Headline>
                )}

                {(image || mobileImage) && (
                  <div
                    className={`image-column order-0 relative block py-6 max-lg:aspect-square lg:hidden ${
                      imageAlignment === 'left' ? 'lg:order-0' : 'lg:order-1'
                    }`}
                  >
                    <Image
                      src={
                        isMobile && mobileImage ? mobileImage.src : image?.src
                      }
                      alt={heading}
                      fill
                      sizes="(min-width: 991px) 60vw, 100vw"
                      className="!object-contain"
                    />
                  </div>
                )}

                {content && (
                  <div className="text-[14px] sm:text-[18px] [&_p:not(:first-child)_strong]:border-t [&_p:not(:first-child)_strong]:border-t-[#FFFFFF33] [&_p:not(:first-child)_strong]:pt-6 [&_strong]:mt-6 [&_strong]:block [&_strong]:text-[16px]">
                    <Markdown>{content}</Markdown>
                  </div>
                )}
              </div>
            </div>

            {(image || mobileImage) && (
              <div
                className={`image-column order-0 relative hidden max-lg:aspect-square lg:block ${
                  imageAlignment === 'left' ? 'lg:order-0' : 'lg:order-1'
                }`}
              >
                <Image
                  src={isMobile && mobileImage ? mobileImage.src : image?.src}
                  alt={heading}
                  fill
                  sizes="(min-width: 991px) 60vw, 100vw"
                  className="!object-contain"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

ProductUniqueTechnology.displayName = 'ProductUniqueTechnology';
ProductUniqueTechnology.Schema = Schema;
