export function JobListing({ job }) {
  const { title, department, board_code: boardCode, type, city, state } = job;

  return (
    <div className="relative mb-12 border-b border-zinc-100 pb-4 transition duration-300">
      <p className="letter mb-2 text-sm font-normal leading-6 tracking-[.04em] lg:text-lg">
        {department}
      </p>
      <h5 className="mb-4 text-3xl font-light tracking-[-.01em]">{title}</h5>
      <p className="font-normal">{state ? `${city}, ${state}` : city}</p>
      <p className="mt-2 font-normal">{type}</p>
      <p>
        <a
          href={`https://clearlyfiltered.applytojob.com/apply/${boardCode}`}
          target="_blank"
          rel="noreferrer"
        >
          View Details
        </a>
      </p>
    </div>
  );
}

JobListing.displayName = 'Job Listing';
