import { COLORS } from './common';

export default {
  label: 'Header',
  name: 'header',
  component: 'group',
  description: 'Menu, promobar',
  fields: [
    {
      label: 'Promobar',
      name: 'promobar',
      component: 'group',
      description: 'Enable, messages, colors, slider settings',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Autohide',
          name: 'autohide',
          component: 'toggle',
          description:
            'Hides promobar after scrolling away from top of the page',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Autoplay Delay',
          name: 'delay',
          component: 'number',
          description: 'Delay between transitions (in ms)',
          defaultValue: 5000,
        },
        {
          label: 'Speed',
          name: 'speed',
          component: 'number',
          description: 'Duration of transition between slides (in ms)',
          defaultValue: 500,
        },
        {
          label: 'Messages',
          name: 'messages',
          component: 'group-list',
          itemProps: {
            label: '{{item.message}}',
          },
          fields: [
            {
              label: 'Message',
              name: 'message',
              component: 'text',
            },
            {
              label: 'Link (optional)',
              name: 'link',
              component: 'link',
              description: 'Link wrapping entire message',
            },
          ],
          defaultItem: {
            message: 'Free shipping on orders over $100. Shop Now',
          },
        },
      ],
    },
    {
      label: 'Menu',
      name: 'menu',
      component: 'group',
      description: 'Menu items, products slider, links',
      fields: [
        {
          label: 'Menu Items',
          name: 'menuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.menuItem.text}}',
          },
          fields: [
            {
              label: 'Menu Item',
              name: 'menuItem',
              component: 'link',
            },
            {
              label: 'Submenu Links',
              name: 'links',
              component: 'group-list',
              itemProps: {
                label: '{{item.link.text}}',
              },
              description:
                'The standard desktop menu. This menu will always be used for mobile. Use Icon Menu Links below for an alternative desktop style.',
              fields: [
                {
                  label: 'Link',
                  name: 'link',
                  component: 'link',
                },
                {
                  label: 'Submenu Item Icon',
                  name: 'image',
                  component: 'image',
                },
                {
                  label: 'Subnav Cards',
                  name: 'subnavCards',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.title}}',
                  },
                  validate: {
                    maxItems: 3,
                  },
                  fields: [
                    {
                      label: 'Enable Gradient Background',
                      name: 'enableGradient',
                      component: 'toggle',
                      defaultValue: false,
                    },
                    {
                      label: 'Image',
                      name: 'image',
                      component: 'image',
                    },
                    {
                      label: 'Title',
                      name: 'title',
                      component: 'text',
                    },
                    {
                      label: 'Link',
                      name: 'link',
                      component: 'link',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Icon Menu Links',
              name: 'iconMenuLinks',
              component: 'group-list',
              description:
                'An alternate icon-style menu that will take precedence over Submenu Links on desktop if available.',
              itemProps: {
                label: '{{item.link.text}}',
              },
              fields: [
                {
                  label: 'Icon',
                  name: 'icon',
                  component: 'image',
                  description:
                    'Icons should be black and have transparent backgrounds to work properly here.',
                },
                {
                  label: 'Link',
                  name: 'link',
                  component: 'link',
                },
              ],
            },
            {
              label: 'Insert Logo Before',
              name: 'logoBefore',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
          ],
          defaultItem: {
            menuItem: { text: 'Shop', url: '/collections/all' },
          },
        },
        {
          label: 'Addtional Links',
          name: 'links',
          description: 'Visible only in menu sidebar',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          fields: [
            {
              label: 'Icon',
              name: 'icon',
              component: 'image',
            },
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
        },
      ],
    },
    {
      label: 'Appearance',
      name: 'appearance',
      description: 'Update how the header appears per page.',
      component: 'group-list',
      itemProps: {
        label: 'Page: {{item.affectedPage.text}}',
      },
      fields: [
        {
          label: 'Affected Page',
          name: 'affectedPage',
          description: 'Which page should these styles apply to?',
          component: 'link',
        },
        {
          label: 'Transparent Mode',
          name: 'transparentMode',
          description:
            'Should the header sit atop content, and have a transparent background? (Disappears on scroll)',
          component: 'toggle',
          defaultValue: false,
        },
        {
          label: 'Transparent Mode Text Color',
          name: 'transparentModeTextColor',
          description:
            "What color should the text be if 'Transparent Mode' is enabled above?",
          component: 'select',
          defaultValue: 'dark',
          options: [
            {
              label: 'Light',
              value: 'light',
            },
            {
              label: 'Dark',
              value: 'dark',
            },
          ],
        },
      ],
    },
  ],
};
