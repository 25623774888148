import { useMemo } from 'react';
import {
  useCartCount as useBPCartCount,
  useCartItems,
} from '@backpackjs/storefront';

export const useCartCount = () => {
  const cartItems = useCartItems();
  const cartCount = useBPCartCount();

  return useMemo(() => {
    const routeProducts = cartItems?.filter(
      (item) => item.variant.product.handle === 'routeins'
    );

    const routeQty = routeProducts?.length
      ? routeProducts.reduce((acc, item) => acc + item.quantity, 0)
      : 0;

    return cartCount - routeQty;
  }, [cartCount, cartItems]);
};
