import { Markdown } from '../../snippets';

export function CareerListing({ item }) {
  const { header, department, body, jobTime, jobLocation } = item;

  return (
    <div className={`mb-12 pb-4 relative transition duration-300 border-b border-zinc-100`}>
      <p className='mb-2 letter text-sm font-normal leading-6 tracking-[.04em] lg:text-lg'>{department}</p>
      <h5 className='mb-4 text-3xl font-light tracking-[-.01em]'>{header}</h5>
      <Markdown>{body}</Markdown>
      <p className='mt-2 font-normal'>{jobTime}</p>
      <p className='font-normal'>{jobLocation}</p>
    </div>
  );
}

CareerListing.displayName = 'Career Listing';
