import { useSettings } from '@backpackjs/storefront';

import { CollectionDropdownFilter } from './CollectionDropdownFilter';
import { CollectionFiltersSummary } from './CollectionFiltersSummary';
import { CollectionMobileFilters } from './CollectionMobileFilters';

export function CollectionFilters({
  collectionCount,
  collectionFiltersData,
  swatchesMap,
}) {
  const settings = useSettings();
  const showCount = settings?.collection?.filters?.showCount;
  const {
    state: { activeFilters, filters, filtersMap },
    actions: { addFilter, removeFilter, clearFilters },
  } = collectionFiltersData;

  return (
    <div className="flex flex-col gap-5">
      {/* mobile */}
      <div className="relative z-30 md:hidden">
        <CollectionMobileFilters
          activeFilters={activeFilters}
          addFilter={addFilter}
          clearFilters={clearFilters}
          collectionCount={collectionCount}
          filters={filters}
          filtersMap={filtersMap}
          removeFilter={removeFilter}
          showCount={showCount}
          swatchesMap={swatchesMap}
        />
      </div>

      {/* desktop */}
      <div className="flex flex-col">
        <div className="mx-4 flex items-center justify-between border-b border-b-[#E9E9EB] pb-4 md:pb-8">
          <div className="text-shadowGray">{collectionCount} Items</div>
          <ul className="flex justify-end gap-[20px] max-md:hidden">
            {filters.map((filter, index) => {
              if (!filter.values.length || filter.values.length === 1)
                return null;

              return (
                <li key={index}>
                  <CollectionDropdownFilter
                    activeFilters={activeFilters}
                    addFilter={addFilter}
                    defaultOpen={filter.defaultOpen}
                    filter={filter}
                    removeFilter={removeFilter}
                    showCount={showCount}
                    swatchesMap={swatchesMap}
                  />
                </li>
              );
            })}
          </ul>
        </div>

        <div className="">
          <CollectionFiltersSummary
            activeFilters={activeFilters}
            clearFilters={clearFilters}
            filtersMap={filtersMap}
            removeFilter={removeFilter}
          />
        </div>
      </div>
    </div>
  );
}

CollectionFilters.displayName = 'CollectionFilters';
