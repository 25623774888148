// menu
export default {
  label: 'Account Menu',
  name: 'menu',
  component: 'group',
  fields: [
    {
      label: 'Menu items',
      name: 'menuItems',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
      ],
      defaultItem: {
        link: { text: 'New Menu Item', url: '' },
      },
      defaultValue: [
        { link: { text: 'Order History', url: '/account/orders' } },
        { link: { text: 'Address Book', url: '/account/address-book' } },
        { link: { text: 'Edit Profile', url: '/account/profile' } },
      ],
    },
    {
      label: 'Flyout Navigation Items',
      name: 'flyoutMenuItems',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
      ],
      defaultItem: {
        link: { text: 'New Menu Item', url: '' },
      },
      defaultValue: [
        { link: { text: 'Order History', url: '/account/orders' } },
        { link: { text: 'Address Book', url: '/account/address-book' } },
        { link: { text: 'Edit Profile', url: '/account/profile' } },
      ],
    },
    {
      label: 'Help Heading',
      name: 'helpHeading',
      component: 'text',
      defaultValue: 'Need Help?',
    },
    {
      label: 'Help Email',
      name: 'helpEmail',
      component: 'text',
      defaultValue: 'info@clearlyfiltered.com',
    },
    {
      label: 'Help Phone',
      name: 'helpPhone',
      component: 'text',
      defaultValue: '1-888-867-5309',
    },
    {
      label: 'Call Hours',
      name: 'callHours',
      component: 'text',
      defaultValue: 'M-F 9am-9pm PST',
    },
    {
      label: 'Chat Hours',
      name: 'chatHours',
      component: 'text',
      defaultValue: 'M-F 9am-9pm PST',
    },
  ],
};
