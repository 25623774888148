import { useEffect, useRef } from 'react';

import { useInView } from 'react-intersection-observer';

import { Image, Markdown } from '../../snippets';

export default function ScrollyVideoMobileCard({ card, index }) {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const videoRef = useRef(null);

  useEffect(() => {
    if (!videoRef.current) return;

    if (inView) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [ref, inView]);

  const isFirstItem = index === 0;

  return (
    <div
      key={index}
      className="sticky top-0 flex h-screen flex-col items-center justify-center bg-white pb-20 pt-[var(--header-height)]"
      ref={ref}
    >
      {!isFirstItem && (
        <div className="absolute top-0 h-[5vh] w-full bg-gradient-to-t from-[#ffffff] to-[#e6f0f8]" />
      )}
      <video
        className="mx-auto w-full max-w-[400px]"
        ref={videoRef}
        src={card.cardVideoLink}
        muted
        loop
        playsInline
      />
      <div className="scroll-video__mob__bottom mx-5 mt-4 text-center">
        {card.icon && (
          <Image
            className="mx-auto mb-2 pt-0 max-sm:w-[24px]"
            sizes="48px"
            width="24px"
            height="24px"
            src={card.icon.src}
            alt={card.icon.alt}
          />
        )}
        {card.title && (
          <h3 className="mb-2 text-[18px] font-normal">{card.title}</h3>
        )}
        {card.subtitle && (
          <Markdown className="scrolly-video__mob__subtitle">
            {card.subtitle}
          </Markdown>
        )}
      </div>
    </div>
  );
}
