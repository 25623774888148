import { Schema } from './PageBanner.schema';

export function PageBanner({ cms }) {
  const { heading, copy, bgGradientStart, bgGradientEnd } = cms;

  return (
    <div className="relative bg-accent3Opacity20">
      <div
        style={{
          backgroundImage: `linear-gradient(to right, ${bgGradientStart}, ${bgGradientEnd})`,
        }}
        className="page-banner__background absolute h-full w-full bg-[#D9D9D9] bg-gradient-to-r opacity-20"
      />
      <div className="px-contained overflow-hidden pb-24 pt-24">
        <h4 className="mb-4 text-center text-4xl font-light tracking-[-.02em] max-md:text-[30px]">
          {heading}
        </h4>
        <p className="mx-auto max-w-3xl text-center">{copy}</p>
      </div>
    </div>
  );
}

PageBanner.displayName = 'Page Banner';
PageBanner.Schema = Schema;
