import { useMemo, useState } from 'react';

import { CollectionDropdownFilter } from './CollectionDropdownFilter';
import { CollectionFiltersSummary } from './CollectionFiltersSummary';
import { CollectionMenuSidebar } from '../CollectionMenuSidebar';
import { Svg } from '../../Svg';

export function CollectionMobileFilters({
  activeFilters,
  addFilter,
  clearFilters,
  collectionCount,
  filters,
  filtersMap,
  removeFilter,
  showCount,
  swatchesMap,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const totalFilters = useMemo(() => {
    return Object.values(activeFilters || {}).reduce((acc, filterValues) => {
      return acc + filterValues.length;
    }, 0);
  }, [activeFilters]);

  return (
    <>
      <button
        aria-label="Open filters sidebar"
        className="mx-auto mt-8 flex w-full max-w-[230px] items-center justify-center rounded-[68px] border border-[#BCBEC2] px-[64px] py-[10px] text-sm font-light"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <Svg
          className="mr-4 w-[26px] text-black"
          src="/svgs/filter.svg#filter"
          title="Filter"
          viewBox="0 0 26 26"
        />

        <div className="flex flex-1 items-center gap-2 overflow-hidden xs:gap-3">
          Filter {totalFilters > 0 ? `(${totalFilters})` : null}
        </div>
      </button>

      <CollectionMenuSidebar
        totalFilters={totalFilters}
        clearFilters={clearFilters}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Filters"
      >
        <div className="sticky top-0 z-[1] border-b border-border bg-background p-4 pt-5 max-md:hidden">
          <div className="mb-4 flex justify-between gap-2">
            <h3 className="text-nav">
              Filters Summary{' '}
              <span className="text-xs">
                {totalFilters ? `(${totalFilters})` : ''}
              </span>
            </h3>

            {totalFilters > 0 && (
              <button
                className="text-label text-main-underline"
                onClick={clearFilters}
                type="button"
              >
                Clear All
              </button>
            )}
          </div>

          <div className="max-h-[4.5rem] min-h-[2rem] overflow-y-auto scrollbar-hide">
            {totalFilters ? (
              <CollectionFiltersSummary
                activeFilters={activeFilters}
                filtersMap={filtersMap}
                removeFilter={removeFilter}
              />
            ) : (
              <p className="leading-[2rem] text-mediumGray">
                No filters selected yet
              </p>
            )}
          </div>
        </div>

        <ul>
          {filters.map((filter, index) => {
            if (!filter.values.length || filter.values.length === 1)
              return null;

            return (
              <li key={index}>
                <CollectionDropdownFilter
                  activeFilters={activeFilters}
                  addFilter={addFilter}
                  defaultOpen
                  filter={filter}
                  removeFilter={removeFilter}
                  showCount={showCount}
                  swatchesMap={swatchesMap}
                />
              </li>
            );
          })}
        </ul>
      </CollectionMenuSidebar>
    </>
  );
}

CollectionMobileFilters.displayName = 'CollectionMobileFilters';
