import { Badges } from '../../Badges';
import { Button } from '../../Button';
import { Image } from '../../Image';
import { Spinner } from '../../Spinner';
import { ProductItemVideo } from './ProductItemVideo';
import { useProductItemMedia } from './useProductItemMedia';

export function ProductItemMedia({
  product,
  variant,
  variantFromColor,
  disableHoverEffect = false,
  disableBackground = false,
}) {
  const { primary } = useProductItemMedia({
    product,
    variant,
  });

  return (
    <div
      className={`${disableHoverEffect ? '' : 'group/media'} ${
        disableBackground ? '' : 'bg-[#e6f0f7]'
      } product-item-media relative flex aspect-square h-full w-full items-end justify-center`}
    >
      <div className="absolute inset-0 bg-gradient-to-r from-[#2179C233] via-[#8DCEF933] to-[#F1DCC133] opacity-0 transition-opacity duration-200 group-hover/media:lg:opacity-100" />
      {primary.media &&
        (primary.isVideo ? (
          <ProductItemVideo autoPlay media={primary.media} />
        ) : (
          <Image
            className={`${
              disableBackground ? '' : 'mix-blend-multiply'
            } !object-contain !mix-blend-multiply`}
            alt={variant?.product?.title}
            fill
            sizes="(min-width: 768px) 33vw, 50vw"
            src={variantFromColor?.image?.src || primary.media.image.src}
          />
        ))}

      {/* Hover Container - Learn More */}
      <div className="learn-more-hover relative hidden p-[38px] opacity-0 transition-opacity duration-200 group-hover/media:opacity-100 md:block">
        <Button
          variant="filled"
          theme="primary"
          className="relative overflow-hidden bg-gradient-to-r from-[#C1EFFA] to-[#8DCEF9] px-[64px] !text-[17px] text-[#121D29] !shadow-none before:absolute before:left-[50%] before:top-[50%] before:h-[calc(100%-4px)] before:w-[calc(100%-4px)] before:-translate-x-[50%] before:-translate-y-[50%] before:rounded-full before:bg-white"
        >
          <div className="relative text-primary">Learn More</div>
        </Button>
      </div>

      {/* loader */}
      {!primary.media && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-gray">
          <Spinner />
        </div>
      )}

      <div className="pointer-events-none absolute left-0 top-0 z-[1] p-2.5 xs:p-3 xl:p-4">
        <Badges
          className="max-xl:text-label-sm gap-2 xs:gap-2 [&_div]:max-xl:px-1.5 [&_div]:max-xl:py-0.5"
          tags={variant?.product?.tags}
        />
      </div>
    </div>
  );
}

ProductItemMedia.displayName = 'ProductItemMedia';
