import { useEffect, useState } from 'react';
import { MenuDrawerSubnavCard } from './MenuDrawerSubnavCard';

export function MenuDrawerSubnavCards({
  menuDrawerContent,
  hoveredLink,
  onClick,
}) {
  const [subnavCards, setSubnavCards] = useState([]);

  useEffect(() => {
    setSubnavCards([]);
    menuDrawerContent?.links.forEach((subnavItem, hoveredLinkIndex) => {
      const cards = { ...subnavItem?.subnavCards };
      Object.keys(cards).forEach((key) => {
        const subnavCard = { ...cards[key] };
        subnavCard.hoveredLinkIndex = hoveredLinkIndex;
        setSubnavCards((previous) => {
          return [...previous, subnavCard];
        });
      });
    });
  }, [menuDrawerContent]);

  return (
    <div id="subnav-cards" className="flex gap-[40px]">
      {subnavCards.map((card, index) => {
        return hoveredLink === card.hoveredLinkIndex ? (
          <MenuDrawerSubnavCard
            onClick={onClick}
            key={`subnav-card-${index}`}
            cardDetails={card}
          />
        ) : null;
      })}
    </div>
  );
}

MenuDrawerSubnavCards.displayName = 'MenuDrawerSubnavCards';
