import { useVariantPrices } from '../../hooks';

export function ProductItemPrice({ variant, className }) {
  const { price, compareAtPrice } = useVariantPrices({
    variant,
  });

  return (
    <div className={`mt-1 flex flex-wrap gap-x-1 ${className}`}>
      <div className="text-[10px] font-light md:text-base">
        From
        {compareAtPrice && (
          <span className="text-mediumGray line-through">
            {' '}
            {compareAtPrice}
          </span>
        )}
        <span> {price}</span>
      </div>
    </div>
  );
}

ProductItemPrice.displayName = 'ProductItemPrice';
