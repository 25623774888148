const slideContent = {
  label: 'Slider Settings',
  name: 'slider',
  description: '',
  component: 'group',
  fields: [
    {
      label: 'Slides',
      name: 'slideItems',
      component: 'group-list',
      itemProps: {
        label: '{{item.title}}',
      },
      validate: {
        maxItems: 5,
      },
      fields: [
        {
          label: 'Image',
          name: 'image',
          component: 'image',
        },
        {
          label: 'Title',
          name: 'title',
          component: 'text',
        },
        {
          label: 'Copy',
          name: 'copy',
          component: 'text',
        },
      ],
      defaultValue: [
        {
          title: 'Company Name',
          copy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
        },
      ],
      defaultItem: {
        title: 'Company Name',
        copy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
      },
    },
  ],
};

export const Schema = () => ({
  label: 'Partner Slider',
  category: 'Slider',
  key: 'partnerSlider',
  previewSrc:
    'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2010.47.01_20AM.png?v=1684864568',
  fields: [
    {
      label: 'Eyebrow',
      name: 'eyebrow',
      component: 'text',
      defaultValue: 'OUR PARTNERS',
    },
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Making an Impact',
    },
    slideContent,
  ],
});
