import { Image } from '../../../snippets/Image';
import { Svg } from '../../../snippets/Svg/Svg';
import { Body, Eyebrow, Headline } from '../../../snippets/Typography';
import ZipInput from '../components/zip-input';

/**
 * @typedef {Object} LandingPageProps
 * @property {Object} landingPage
 * @property {Object} landingPage.image
 * @property {string} landingPage.image.src
 * @property {string} landingPage.image.alt
 * @property {string} landingPage.eyebrow
 * @property {string} landingPage.heading
 * @property {string[]} landingPage.bullets
 * @property {string} landingPage.disclaimerText
 * @property {string} zip
 * @property {(zip: string) => void} setZip
 * @property {() => void} onSubmit
 * @param {LandingPageProps} props
 * @returns {JSX.Element}
 */
export default function LandingPage({ landingPage, zip, setZip, onSubmit }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div className="relative row-start-2 min-h-[100vw] overflow-hidden md:row-start-1 lg:min-h-screen">
        <Image
          alt={landingPage.image.alt}
          fill
          className="object-cover"
          src={landingPage.image.src}
        />
      </div>

      <div className="flex items-center justify-center p-6 md:p-10 lg:p-20">
        <div className="max-w-[630px] space-y-6">
          <div className="text-center md:text-left">
            <Eyebrow>{landingPage.eyebrow}</Eyebrow>
            <Headline variant="h3">{landingPage.heading}</Headline>
          </div>

          <ZipInput
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            onSubmit={onSubmit}
          />

          <hr className="my-20 border-[#DDE2E7]" />

          <ul className="space-y-6">
            {landingPage.bullets.map(({ bullet }, index) => (
              <li className="flex items-center" key={index}>
                <Svg
                  className="mr-2 inline-block h-[17px] w-[17px] shrink-0 text-[#3383C5]"
                  title="Arrow"
                  src="/svgs/circle-check.svg#circle-check"
                  viewBox="0 0 17 17"
                />

                <Body variant="small">{bullet}</Body>
              </li>
            ))}
          </ul>

          <Body variant="xs">{landingPage.disclaimerText}</Body>
        </div>
      </div>
    </div>
  );
}
