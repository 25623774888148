/* eslint-disable react/no-unstable-nested-components */
import { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import PropTypes from 'prop-types';
import { Blockquote } from '../../sections/ArticleBlockQuote/ArticleBlockQuote';

export const Markdown = forwardRef(
  ({ centerAllText, children, components = {}, className }, ref) => {
    const remappedComponents = {
      a(data) {
        const content = data.children[0];
        const link = data.href;

        if (!link.includes('clearlyfiltered.com')) {
          return (
            <a href={link} target="_blank" rel="noreferrer">
              {content}
            </a>
          );
        }

        return <a href={data.href}>{content}</a>;
      },
      blockquote(data) {
        return <Blockquote>{data.children}</Blockquote>;
      },
      ...components,
    };
    return (
      <div
        ref={ref}
        className={`markdown [&>:first-child]:mt-0 [&>:last-child]:mb-0 [&_a]:underline [&_blockquote_p]:lg:text-[42px] [&_blockquote_p]:lg:leading-[56px] [&_h1]:mb-6 [&_h1]:text-center [&_h2]:mb-5 [&_h2]:mt-8 [&_h3]:mb-6 [&_h3]:mt-6 [&_h4]:mb-4 [&_h4]:mt-4 [&_h5]:mb-4 [&_h5]:mt-3 [&_h5]:font-[400] [&_h5]:tracking-[0.01em] [&_h5]:lg:text-[20px] [&_h6]:mb-4 [&_h6]:mt-4 [&_li>p]:mb-0 [&_li]:mb-2 [&_ol>li]:list-decimal [&_ol]:mb-4 [&_ol]:pl-8 [&_p]:mb-4 [&_p]:lg:text-[20px] [&_p]:lg:leading-[28px] [&_ul>li]:list-disc [&_ul]:mb-4 [&_ul]:pl-8 ${className} ${
          centerAllText
            ? '[&>h2]:text-center [&>h3]:text-center [&>h4]:text-center [&>h5]:text-center [&>h6]:text-center [&>p]:text-center'
            : ''
        }`}
      >
        <ReactMarkdown
          remarkPlugins={[remarkGfm, remarkBreaks]}
          components={remappedComponents}
        >
          {children}
        </ReactMarkdown>
      </div>
    );
  }
);

Markdown.displayName = 'Markdown';
Markdown.propTypes = {
  centerAllText: PropTypes.bool,
  children: PropTypes.string,
  components: PropTypes.object,
};
