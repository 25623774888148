export default {
  label: 'Contaminants',
  name: 'contaminantSettings',
  component: 'group',
  description: 'List of contaminants referenced throughout the site.',
  fields: [
    {
      label: 'Contaminant Categories',
      name: 'contaminantCategories',
      component: 'group-list',
      itemProps: {
        label: '{{item.categoryName}}',
      },
      fields: [
        {
          label: 'Category Name',
          name: 'categoryName',
          component: 'text',
        },
        {
          label: 'Category Description',
          name: 'categoryDescription',
          component: 'textarea',
        },
        {
          label: 'Challenge Water Units',
          name: 'challengeWaterUnits',
          component: 'text',
          defaultValue: '(mg/L)',
        },
        {
          label: 'Filtered Water Units',
          name: 'filteredWaterUnits',
          component: 'text',
          defaultValue: '(mg/L)',
        },
      ],
    },
    {
      label: 'Contaminants',
      name: 'contaminants',
      component: 'group-list',
      itemProps: {
        label: '{{item.name}}',
      },
      fields: [
        {
          label: 'Contaminant Name',
          name: 'name',
          component: 'text',
        },
        {
          label: 'Top Ranking Contaminant?',
          name: 'isTop',
          component: 'toggle',
          defaultValue: false,
          description:
            'Should this contaminant be categorized as a "Top" contaminant. This is largely used on the What\'s in your Water page.',
        },
        {
          label: 'Top Ranking Rate',
          name: 'topRankingRate',
          component: 'number',
          defaultValue: 50,
          description:
            "If this is a top contaminant, what's it's rank? Used to determine the order of priority",
        },
        {
          label: 'Contaminant Category',
          name: 'category',
          component: 'text',
          description:
            'This value should match the Contaminant Category name EXACTLY.',
        },
        {
          label: 'Aliases',
          name: 'aliases',
          description: 'What other names does this contaminant go by?',
          component: 'group-list',
          itemProps: {
            label: '{{item.alias}}',
          },
          fields: [
            {
              component: 'text',
              name: 'alias',
              label: 'Alias Name',
            },
          ],
        },
        {
          component: 'text',
          name: 'challengeWater',
          label: 'Challenge Water',
        },
        {
          component: 'text',
          name: 'filteredWater',
          label: 'Filtered Water',
        },
        {
          component: 'text',
          name: 'percentRemoved',
          label: 'Percent Removed',
        },
        {
          component: 'text',
          name: 'healthConcern',
          label: 'Health Concern',
        },
      ],
    },
  ],
};
