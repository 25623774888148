/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo } from 'react';
import { Link } from '../Link';

const ArrowButton = forwardRef(
  ({ onClick, type, href, arrowDirection, ...props }, ref) => {
    const Wrapper = useMemo(
      () =>
        href
          ? function LinkWrapper({ children }) {
              return <Link href={href}>{children}</Link>;
            }
          : function ButtonWrapper({ children }) {
              return children;
            },
      [href]
    );

    return (
      <Wrapper>
        <button
          ref={ref}
          onClick={onClick}
          {...props}
          className="flex h-[3.125rem] w-[3.125rem] items-center justify-center rounded-full bg-gradient-to-r from-[#C1EFFA] to-[#8DCEF9] p-[2px] text-center font-sans text-base text-[#202635] transition-colors hover:text-[#3383C5] disabled:cursor-not-allowed disabled:opacity-50"
          // eslint-disable-next-line react/button-has-type
          type={type}
        >
          <div className="inner-content flex h-full w-full items-center justify-center rounded-full bg-white">
            {arrowDirection === 'right' ? (
              <svg
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8534 5.35373L7.35351 9.85348C7.25969 9.9473 7.13245 10 6.99977 10C6.86709 10 6.73985 9.9473 6.64603 9.85348C6.55222 9.75966 6.49951 9.63242 6.49951 9.49975C6.49951 9.36707 6.55222 9.23983 6.64603 9.14602L10.2928 5.49997H0.499984C0.36738 5.49997 0.240207 5.4473 0.146442 5.35353C0.0526768 5.25977 0 5.1326 0 5C0 4.8674 0.0526768 4.74023 0.146442 4.64647C0.240207 4.5527 0.36738 4.50003 0.499984 4.50003H10.2928L6.64603 0.85398C6.55222 0.760165 6.49951 0.632925 6.49951 0.50025C6.49951 0.367576 6.55222 0.240335 6.64603 0.14652C6.73985 0.0527047 6.86709 0 6.99977 0C7.13245 0 7.25969 0.0527047 7.35351 0.14652L11.8534 4.64627C11.8998 4.6927 11.9367 4.74784 11.9619 4.80854C11.9871 4.86924 12 4.9343 12 5C12 5.0657 11.9871 5.13076 11.9619 5.19146C11.9367 5.25215 11.8998 5.3073 11.8534 5.35373Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              <svg
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.146638 5.35373L4.64649 9.85348C4.74031 9.9473 4.86755 10 5.00023 10C5.13291 10 5.26015 9.9473 5.35397 9.85348C5.44778 9.75966 5.50049 9.63242 5.50049 9.49975C5.50049 9.36707 5.44778 9.23983 5.35397 9.14602L1.70721 5.49997H11.5C11.6326 5.49997 11.7598 5.4473 11.8536 5.35353C11.9473 5.25977 12 5.1326 12 5C12 4.8674 11.9473 4.74023 11.8536 4.64647C11.7598 4.5527 11.6326 4.50003 11.5 4.50003H1.70721L5.35397 0.85398C5.44778 0.760165 5.50049 0.632925 5.50049 0.50025C5.50049 0.367576 5.44778 0.240335 5.35397 0.14652C5.26015 0.0527047 5.13291 0 5.00023 0C4.86755 0 4.74031 0.0527047 4.64649 0.14652L0.146638 4.64627C0.100151 4.6927 0.0632725 4.74784 0.0381107 4.80854C0.0129499 4.86924 0 4.9343 0 5C0 5.0657 0.0129499 5.13076 0.0381107 5.19146C0.0632725 5.25215 0.100151 5.3073 0.146638 5.35373Z"
                  fill="currentColor"
                />
              </svg>
            )}
          </div>
        </button>
      </Wrapper>
    );
  }
);

ArrowButton.propTypes = {
  arrowDirection: PropTypes.oneOf(['left', 'right']),
  href: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

export default ArrowButton;
