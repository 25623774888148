import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Carousel, Image } from '../../snippets';
import useMediaQuery from '../../utils/useMediaQuery';
/**
 * @typedef Image
 * @type {object}
 * @property {string} src - The image source.
 * @property {string} alt - The image alt text.
 */

/**
 * @typedef OnChange
 * @type {function}
 * @param {number} index - The index of the current slide.
 */

/**
 * Step carousel/slider made for product detail page.
 * @param {object} props - The props that were defined by the caller of this component.
 * @param {Array.<Image>} props.images - The content that will came from PACK.
 * @param {OnChange} props.onChange - The onChange function.
 * @param {number} props.currentSlide - The current slide.
 *
 * @component
 * @example
 * const images = cms.images;
 * return (
 *  <ProductStepsImageCarousel
 *    images={images}
 *    onChange={console.log}
 *  />
 * )
 */
function ProductStepsImageCarousel({ images, onChange, currentSlide }) {
  let imageArray = [];
  if (typeof images[0] === 'string') {
    imageArray = images;
  } else {
    imageArray = images.map((image) => image.src ?? null);
  }

  const isMobile = useMediaQuery('(max-width: 767px)');

  const [showArrows, setShowArrows] = useState(false);
  useEffect(() => {
    setShowArrows(!isMobile);
  }, [isMobile]);

  return (
    <div className="-mx-4 md:mx-0">
      <Carousel
        showArrows={showArrows}
        emulateTouch
        showIndicators={false}
        onChange={onChange}
        selectedItem={currentSlide}
      >
        {imageArray.map((imageSrc, index) => (
          <div
            key={index}
            className="relative mx-auto h-[240px] w-full bg-[#EDF3F9] dark:bg-[#202635] md:h-[480px] md:max-w-[var(--content-max-width)] lg:h-[630px]"
          >
            {imageSrc && (
              <Image
                src={imageSrc}
                alt="Product step image"
                fill
                style={{ objectFit: 'contain' }}
              />
            )}
          </div>
        ))}
      </Carousel>
    </div>
  );
}

ProductStepsImageCarousel.propTypes = {
  /**
   * Images array
   */
  images: PropTypes.array,
};

ProductStepsImageCarousel.defaultProps = {
  images: [],
};

export default ProductStepsImageCarousel;
