import { Svg, Image } from '../../snippets';

export function FiltrationTechnologyVideoPreview({ content }) {
  return (
    <div className="relative cursor-pointer overflow-hidden rounded-2xl">
      <div className="absolute inset-0 h-full w-full bg-gradient-to-b from-[#00000000] to-[#000000CC]" />
      <Image
        src={content.image.src}
        alt={content.image.alt ?? 'Filtration Technology Video Preview'}
        width={350}
        height={200}
      />
      <div className="absolute bottom-5 left-0 flex w-full justify-center">
        <span className="mr-3">{content.ctaText}</span>
        <Svg
          className="h-[26px] w-[26px] text-text"
          src="/svgs/play-2.svg#play-2"
          title="Close"
          viewBox="0 0 26 26"
        />
      </div>
    </div>
  );
}
