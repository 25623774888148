'use client';

import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import {
  useCart,
  useCartAddAttributes,
  useCartRemoveAttributes,
  useSettings,
} from '@backpackjs/storefront';

const BeamSelectNonprofit = dynamic(
  () => import('@beamimpact/web-sdk/dist/react/select-nonprofit'),
  { loading: () => null, ssr: false }
);

// export const BeamSelectNonprofitWrapper: FC = () => {
export function BeamSelectNonprofitWrapper() {
  const cart = useCart();
  const { cartAddAttributes } = useCartAddAttributes();
  const { cartRemoveAttributes } = useCartRemoveAttributes();

  const settings = useSettings();
  const beamSettings = settings?.beam;

  // Optional: state for handling nonprofit selection in app
  const [selectedNonprofit, setSelectedNonprofit] = useState({
    id: null,
    name: null,
  });

  const handleNonprofitSelect = async (event) => {
    const { selectedNonprofitId, nonprofitName, selectionId } = event.detail;
    // Nonprofit details can be displayed in a message, saved on server, etc.
    setSelectedNonprofit({
      name: nonprofitName,
      id: selectedNonprofitId,
    });

    const attributes = {
      beam_cart_id: cart.id,
      beam_chain_id: beamSettings.beamChainId,
      beam_store_id: parseInt(beamSettings.beamStoreId, 10),
      beam_nonprofit_id: selectedNonprofitId,
      beam_selection_id: selectionId,
    };

    await cartRemoveAttributes({
      attributeKeys: [
        'beam_cart_id',
        'beam_chain_id',
        'beam_store_id',
        'beam_nonprofit_id',
        'beam_selection_id',
      ],
    });

    // Update your cart with Beam metadata
    await cartAddAttributes({
      attributes: Object.entries(attributes).map(([key, value]) => ({
        key,
        value: value.toString(),
      })),
    });
  };

  return (
    <BeamSelectNonprofit
      apiKey={beamSettings.beamApiKey}
      storeId={parseInt(beamSettings.beamStoreId, 10)}
      onNonprofitSelect={handleNonprofitSelect}
      class="font-sans"
    />
  );
}
