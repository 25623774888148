import Script from 'next/script';

export function Refersion() {
  return (
    <Script
      id="refersion-script"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          !(function (e, n, t, i, o, c, s, a) {
            ;(e.TrackingSystemObject = 'r'),
              ((s = n.createElement(t)).async = 1),
              (s.src = 'https://cdn.refersion.com/refersion.js'),
              (s.onload = function () {
                // Replace with your Refersion Public API Key
                r.pubKey = '${process.env.NEXT_PUBLIC_REFERSION_KEY}'

                // Uncomment next line if you need to debug during testing
                // r.settings.dbg_mode = true;

                r.settings.fp_off = true

                r.initializeXDLS().then(() => {
                  r.launchDefault()
                })
              }),
              (a = n.getElementsByTagName(t)[0]).parentNode.insertBefore(s, a)
          })(window, document, 'script')
          `,
      }}
    />
  );
}
