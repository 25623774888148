/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from '../Link';

export default function ProductCta(props) {
  const { children, onClick, type, href } = props;

  const Wrapper = useMemo(
    () =>
      href
        ? function LinkWrapper({ children }) {
            return <Link href={href}>{children}</Link>;
          }
        : function ButtonWrapper({ children }) {
            return children;
          },
    [href]
  );

  return (
    <Wrapper>
      <button
        onClick={onClick}
        className="flex min-w-[200px] items-center justify-center rounded-full bg-gradient-to-r from-[#C1EFFA] to-[#8DCEF9] p-[3px] text-center font-sans text-base text-[#202635]"
        // eslint-disable-next-line react/button-has-type
        type={type}
      >
        <div className="inner-content h-full w-full rounded-full bg-white px-[34px] pt-[14px] pb-[12px]">
          {children}
        </div>
      </button>
    </Wrapper>
  );
}

ProductCta.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};
