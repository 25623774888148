import { useCart, useCartAddAttributes } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { useEWGContext } from '../../contexts/ewgContext';
import Schema from './WIYWQuiz.schema';
import EmailCapture from './pages/email';
import LandingPage from './pages/landing';
import Step from './pages/step';
/**
 * @typedef {Object} WIYWQuizProps
 * @property {Object} cms
 * @property {Object} cms.landingPage
 * @property {Object} cms.landingPage.image
 * @property {string} cms.landingPage.image.src
 * @property {string} cms.landingPage.image.alt
 * @property {string} cms.landingPage.eyebrow
 * @property {string} cms.landingPage.heading
 * @property {string[]} cms.landingPage.bullets
 * @property {string} cms.landingPage.disclaimerText
 * @property {Object} cms.quiz
 * @property {Object[]} cms.quiz.questionsPages
 * @property {string} cms.quiz.questionsPages[].heading
 * @property {string} cms.quiz.questionsPages[].subheading
 * @property {string} cms.quiz.questionsPages[].columns
 * @property {'single'|'multiple'} cms.quiz.questionsPages[].choices
 * @property {string[]} cms.quiz.questionsPages[].options
 * @property {Object} cms.emailEntryPage
 * @property {string} cms.emailEntryPage.eyebrow
 * @property {string} cms.emailEntryPage.heading
 * @property {string} cms.emailEntryPage.inputPlaceholder
 * @property {string} cms.emailEntryPage.ctaText
 * @param {WIYWQuizProps} props
 * @returns {JSX.Element}
 */
export default function WIYWQuiz({ cms }) {
  const cart = useCart();
  const { push, query } = useRouter();
  const initialStep = 0;
  const [step, setStep] = useState(initialStep);
  const [zip, setZip] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useLocalStorageState('quizEmail', {
    defaultValue: '',
  });

  const { cartAddAttributes } = useCartAddAttributes();

  const { setZipCode } = useEWGContext();

  // get titles of all questions
  const questionTitles = cms.quiz.questionsPages.map((page) => page.heading);

  // On Step change, send dataLayer event containing the previous question and answer
  useEffect(() => {
    if (step > 1 && step < cms.quiz.questionsPages.length + 2) {
      const previousQuestion = cms.quiz.questionsPages[step - 2].heading;
      const previousQuestionAnswers = JSON.parse(
        localStorage.getItem(`WIYWQuiz-${previousQuestion}`)
      );
      const previousQuestionAnswersObject = {};
      previousQuestionAnswers.forEach((answer, answerIndex) => {
        previousQuestionAnswersObject[`${answerIndex}`] = answer.trim();
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'dl_quiz_question_answered',
        quiz_zipcode: zip,
        quiz_question: previousQuestion,
        quiz_answers: previousQuestionAnswersObject,
        quiz_step: step - 1,
      });
    }
  }, [step]);

  const startQuiz = () => {
    if (!zip) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'dl_quiz_started',
      quiz_zipcode: zip,
    });
  };

  const finishQuiz = async (type = 'skip') => {
    const checkoutData = questionTitles.map((title) => {
      const answers = localStorage.getItem(`WIYWQuiz-${title}`);
      return {
        key: title,
        value: JSON.parse(answers)?.join(', '),
      };
    });

    await cartAddAttributes({
      attributes: checkoutData,
    });

    if (!zip) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'dl_quiz_submit',
      quiz_zipcode: zip,
      submit_type: type,
    });
  };

  const handleQuizSubmit = async (userEmail) => {
    try {
      setLoading(true);

      // get answers for each question and reduce to an object
      const klaviyoData = questionTitles
        .map((title) => {
          const answers = localStorage.getItem(`WIYWQuiz-${title}`);
          return {
            [title]: JSON.parse(answers)?.join(', '),
          };
        })
        .reduce((acc, curr) => ({ ...acc, ...curr }), { zip_code: zip });

      // post answers to klaviyo
      const url =
        'https://a.klaviyo.com/client/subscriptions/?company_id=JXsSet';
      const data = {
        data: {
          type: 'subscription',
          attributes: {
            list_id: 'QTUx59',
            custom_source: 'WIYW Quiz Flow',
            email: userEmail,
            properties: klaviyoData,
          },
        },
      };

      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          revision: '2023-06-15',
          'Content-Type': 'application/json',
        },
      });

      finishQuiz('email');

      // redirect to results page
      push(`/pages/whats-in-your-water?zip=${zip}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSkipQuiz = async () => {
    setLoading(true);

    await finishQuiz('skip');

    setLoading(true);

    push(`/pages/whats-in-your-water?zip=${zip}`);
  };

  useEffect(() => {
    if (zip && zip?.length === 5) {
      // If the current saved Zip code is different from the one entered, reset the quiz answers.
      const currentZipCode = localStorage.getItem('WIYWQuiz-zipCode');
      if (currentZipCode !== zip) {
        questionTitles.forEach((title) => {
          localStorage.removeItem(`WIYWQuiz-${title}`);
        });
        localStorage.removeItem('quizEmail');
      }
      localStorage.setItem('WIYWQuiz-zipCode', zip);
      setZipCode(zip);
    }
  }, [zip]);

  useEffect(() => {
    if (query.retake) {
      localStorage.removeItem('quizEmail');
      questionTitles.forEach((title) => {
        localStorage.removeItem(`WIYWQuiz-${title}`);
      });
    }
  }, [query]);

  if (step === 0)
    return (
      <LandingPage
        landingPage={cms.landingPage}
        zip={zip}
        setZip={setZip}
        onSubmit={() => {
          startQuiz();
          setStep((s) => s + 1);
        }}
      />
    );
  if (step > cms.quiz.questionsPages.length)
    return (
      <EmailCapture
        loading={loading}
        emailPage={cms.emailEntryPage}
        email={email}
        setEmail={setEmail}
        onSubmit={handleQuizSubmit}
        onSkip={handleSkipQuiz}
      />
    );
  if (step > 0)
    return (
      <Step
        onBack={() => setStep(step - 1)}
        onSubmit={() => setStep(step + 1)}
        setStep={setStep}
        step={step}
        choices={cms.quiz.questionsPages[step - 1].choices}
        steps={cms.quiz.questionsPages.length}
        heading={cms.quiz.questionsPages[step - 1].heading}
        subheading={cms.quiz.questionsPages[step - 1].subheading}
        options={cms.quiz.questionsPages[step - 1].options}
        columns={cms.quiz.questionsPages[step - 1].columns}
      />
    );

  return <div>not found</div>;
}

WIYWQuiz.propTypes = {
  cms: PropTypes.shape({
    landingPage: PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
      eyebrow: PropTypes.string,
      heading: PropTypes.string,
      bullets: PropTypes.arrayOf(
        PropTypes.shape({
          bullet: PropTypes.string,
        })
      ),
      disclaimerText: PropTypes.string,
    }),
    quiz: PropTypes.shape({
      questionsPages: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          subheading: PropTypes.string,
          columns: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              optionText: PropTypes.string,
            })
          ),
        })
      ),
    }),
    emailEntryPage: PropTypes.shape({
      eyebrow: PropTypes.string,
      heading: PropTypes.string,
      inputPlaceholder: PropTypes.string,
      ctaText: PropTypes.string,
    }),
  }),
};

WIYWQuiz.displayName = 'WIYWQuiz';
WIYWQuiz.Schema = Schema;
