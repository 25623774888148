import { useState } from 'react';
import { Button, Svg } from '../../snippets';
import { Headline, Subheading } from '../../snippets/Typography';
import { useDataLayerActions } from '../../hooks';

export function EmailForm({ settings }) {
  const {
    heading,
    subheading,
    placeholder,
    buttonText,
    successMessage,
    listId = '',
  } = settings;
  const { sendSubscribeEvent } = useDataLayerActions();

  const [formEmail, setFormEmail] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleNewsletterSignup = async (event) => {
    event.preventDefault();
    const payload = { emailAddress: formEmail };
    if (listId) {
      payload.listId = listId;
    }

    const klaviyoResponse = await fetch('/api/klaviyo-subscribe', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (klaviyoResponse.status === 200) {
      setIsSuccessful(true);
      setFormEmail('');
      sendSubscribeEvent({ email: formEmail });
    }
  };

  return (
    <div className="text-center">
      {subheading && (
        <Subheading variant="large" className="mb-4 px-4 uppercase">
          {subheading}
        </Subheading>
      )}
      {heading && (
        <Headline variant="h2" className="mb-8 max-w-[700px] px-4">
          {heading}
        </Headline>
      )}
      {!isSuccessful && (
        <form
          className="klaviyo-form w-full px-8"
          onSubmit={handleNewsletterSignup}
        >
          <div className="mb-8 flex justify-between border-b border-[#4d515d33] pb-4">
            <input
              type="email"
              className="w-full bg-transparent text-lg font-light outline-none lg:text-xl"
              value={formEmail}
              placeholder={placeholder || 'Email address'}
              onChange={(event) => {
                setFormEmail(event.target.value);
              }}
            />
            <button type="submit">
              {' '}
              <Svg
                className="w-[24px] text-black"
                src="/svgs/arrow-right.svg#arrow-right"
                title="Submit"
                viewBox="0 0 24 24"
              />
            </button>
          </div>
          {buttonText && (
            <Button
              ariaLabel="submit"
              type="submit"
              theme="primary"
              variant="filled"
              className="w-full"
            >
              {buttonText}
            </Button>
          )}
        </form>
      )}
      {isSuccessful && successMessage && (
        <div className="py-6">{successMessage}</div>
      )}
    </div>
  );
}
