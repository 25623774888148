import { Link, Svg } from '../../snippets';

export function SearchHeader({ rawTerm, setRawTerm, totalCount }) {
  return (
    <div className="px-contained py-contained">
      <div
        className={`${
          totalCount > 0
            ? 'border-b border-zinc-200 pb-8'
            : 'mb-6 border-b border-zinc-200 pb-6'
        }  `}
      >
        <div className="mb-6 flex gap-2 text-sm">
          <Link aria-label="Home" className="text-[#20263580]" href="/">
            <p>Home</p>
          </Link>
          <span>/</span>
          <p className="text-primary">Search</p>
        </div>
        <h3 className="text-[26px] font-light tracking-[-.01em] text-primary md:mb-8 md:text-[42px]">
          Search Results
        </h3>
        <p className="color-primaryLight font-primaryLight mb-4 text-primaryLight text-primaryLight">
          Showing {totalCount} results for “[{rawTerm}]”
        </p>
        <div className="relative flex justify-between gap-3 rounded-full border border-border bg-white pl-3 pr-4 lg:max-w-3xl">
          <Svg
            className="w-5 text-text"
            src="/svgs/search.svg#search"
            title="Search"
            viewBox="0 0 24 24"
          />
          <input
            aria-label="Search here"
            className="min-w-0 flex-1 py-3 text-base outline-none"
            placeholder="Search here"
            onChange={(e) => setRawTerm(e.target.value)}
            value={rawTerm}
          />
        </div>
      </div>
    </div>
  );
}

SearchHeader.displayName = 'SearchHeader';
