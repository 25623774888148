export const Schema = () => ({
  category: 'Collection',
  label: 'Collection Hero',
  key: 'collection-hero',
  previewSrc:
    'https://cdn.shopify.com/s/files/1/1011/0318/files/collection-hero.png',
  fields: [
    {
      name: 'superTitle',
      component: 'text',
      label: 'Super Title',
    },
    {
      name: 'title',
      component: 'text',
      label: 'Title',
    },
    {
      name: 'content',
      component: 'textarea',
      label: 'Content',
    },
    {
      name: 'leftCTA',
      component: 'link',
      label: 'Left CTA Button',
    },
    {
      name: 'rightCTA',
      component: 'link',
      label: 'Right CTA Button',
    },
    {
      name: 'mediaImage',
      component: 'image',
      label: 'Accompanying Image',
    },
    {
      name: 'mediaVideo',
      component: 'text',
      label: 'Accompanying Video',
      description:
        'Accompanying Video will always be used in place of Accompanying Image.',
    },
    {
      name: 'mediaPlacement',
      component: 'select',
      label: 'Media Placement',
      options: [
        {
          label: 'Left',
          value: 'left',
        },
        {
          label: 'Right',
          value: 'right',
        },
      ],
      defaultValue: 'left',
    },
  ],
});
