import body from '../../utils/schemas/body';
import eyebrow from '../../utils/schemas/eyebrow';
import headline from '../../utils/schemas/headline';

const banner = [
  {
    label: 'Video URL',
    name: 'videoUrl',
    description: 'If a video is provided, it will be used instead of the image',
    defaultValue:
      'https://cdn.shopify.com/videos/c/o/v/865f2843e03344b1abdd4193e9e5c34d.mp4',
    component: 'text',
  },
  {
    label: 'Image',
    name: 'image',
    description: 'If a video is provided, it will be used instead of the image',
    component: 'image',
  },
  {
    label: 'Offer',
    name: 'offer',
    component: 'group',
    fields: [
      headline,
      body,
      {
        label: 'Product',
        component: 'productSearch',
        name: 'productName',
      },
      {
        label: 'Price',
        name: 'price',
        component: 'text',
      },
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Multiply image?',
        description: 'Add mix-blend-mode: multiply to image',
        name: 'multiplyImage',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
      },
      {
        label: 'Calls to Action',
        name: 'ctas',
        component: 'group-list',
        description: 'Max of two buttons',
        itemProps: {
          label: '{{item.link.text}}',
        },
        defaultItem: {
          link: { text: 'Shop All', url: '' },
          theme: 'primary',
          variant: 'filled',
        },
        validate: {
          maxItems: 2,
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Regular Link',
            name: 'isRegularLink',
            component: 'toggle',
            defaultValue: false,
            description:
              'Display the link as a regular underlined link. Ignores the button Theme and Variant settings below when enabled.',
          },
          {
            label: 'Button Theme',
            name: 'theme',
            component: 'select',
            options: [
              { label: 'Primary', value: 'primary' },
              { label: 'Secondary', value: 'secondary' },
              { label: 'Light', value: 'light' },
            ],
          },
          {
            label: 'Button Variant',
            name: 'variant',
            component: 'select',
            options: [
              { label: 'Filled', value: 'filled' },
              { label: 'Outlined', value: 'outlined' },
            ],
          },
        ],
      },
    ],
  },
];

const banners = {
  label: 'Banners',
  name: 'banners',
  component: 'group-list',
  fields: banner,
};

export default function Schema() {
  return {
    label: 'Product Promo Banner Carousel',
    key: 'productPromoBannerCarousel',
    category: 'Product',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/product_promo_banner.png?v=1686314381',
    fields: [eyebrow, headline, banners],
  };
}
