import { Body, Markdown } from '../../snippets';

export default function QuizContaminantsWeRemoveBox({
  contaminantsBoxTitle,
  contaminantsBoxContent,
}) {
  return (
    <div className="mb-10 flex max-w-[520px] rounded-2xl border border-[#2179C2]">
      <div className="grid gap-3 px-6 py-5">
        <Body variant="small">{contaminantsBoxTitle}</Body>
        <Body element="div" variant="xs">
          <Markdown className="[&_p]:lg:text-sm [&_p]:lg:font-extralight [&_p]:lg:leading-5">
            {contaminantsBoxContent}
          </Markdown>
        </Body>
      </div>
      <div className="hidden h-full w-[1px] bg-[#2179C2] lg:block" />
      <div className="hidden shrink-0 items-center justify-center px-[34px] lg:flex ">
        <img
          src="https://cdn.shopify.com/s/files/1/1011/0318/files/What_sInYourWaterIcon_2.png?v=1705690049"
          alt=""
          className="aspect-square w-20 shrink-0 object-contain"
        />
      </div>
    </div>
  );
}
