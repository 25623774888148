import { decode } from 'he';
import React, { useMemo, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FacebookShareButton } from 'react-share';
import { Tooltip } from 'react-tooltip';
import { useEWGContext } from '../../contexts/ewgContext';
import { Body, Eyebrow, Headline, Image, Link, Svg } from '../../snippets';
import { Schema } from './WIYWDashboard.schema';

export function WIYWDashboard({ cms }) {
  const { backgroundImage, backgroundVideoUrl } = { ...cms };
  const {
    EWGResults,
    EWGSystems,
    activeEWGSystem,
    setActiveEWGSystem,
    EWGResultsExceedingGuidelines,
    EWGResultsRemovedContaminants,
  } = useEWGContext();

  let EWGResultsPercentRemovedContaminants = null;
  if (
    EWGResultsExceedingGuidelines?.length &&
    EWGResultsExceedingGuidelines?.length > 0 &&
    EWGResults?.ContaminantList?.length
  ) {
    /*
    EWGResultsPercentRemovedContaminants =
      (EWGResultsExceedingGuidelines.length /
        EWGResults.ContaminantList.length) *
      100;
    */

    // set 40 as the base for amount of contaminants in the water
    EWGResultsPercentRemovedContaminants =
      (EWGResultsExceedingGuidelines.length / 40) * 100;
  }

  if (EWGResultsPercentRemovedContaminants > 100) {
    EWGResultsPercentRemovedContaminants = 100;
  }

  const [copyUrlTooltip, setCopyUrlTooltip] = useState('Copy URL');
  const shareUrl = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window.location.href;
    }
    return '';
  }, []);

  const copyUrl = () => {
    navigator.clipboard.writeText(shareUrl);
    setCopyUrlTooltip('Copied!');
    setTimeout(() => {
      setCopyUrlTooltip('Copy URL');
    }, 2000);
  };

  const print = () => {
    window.print();
  };

  return (
    <>
      <div className="relative bg-primary print:hidden">
        {backgroundImage?.src && !backgroundVideoUrl ? (
          <Image
            fill
            alt="Background Image"
            sizes="100vw"
            src={backgroundImage?.src}
          />
        ) : null}
        {backgroundVideoUrl ? (
          <video
            src={backgroundVideoUrl}
            autoPlay
            muted
            loop
            playsInline
            className="absolute h-full w-full object-cover"
          />
        ) : null}
        <div className="px-contained relative mx-auto max-w-[1200px] pb-[30px] pt-[120px] text-white md:pb-[45px] md:pt-[180px]">
          <div className="wiyw-dashboard-header text-center">
            <div className="uppercase">Your Results</div>
            <Headline variant="h3" element="h1">
              Contaminants Detected in{' '}
              <span className="font-[500]">
                {decode(activeEWGSystem?.SystemName || 'N/A')}
              </span>
            </Headline>
            <div className="mt-[22px] flex items-center justify-center gap-[23px] text-[16px] font-light">
              Share:
              <ul className="flex gap-[23px]">
                <li>
                  <FacebookShareButton url={shareUrl}>
                    <Svg
                      className="w-[18px]"
                      src="/svgs/noprecache/social/facebook-thin.svg#facebook-thin"
                      title="Share on Facebook"
                      viewBox="0 0 18 18"
                    />
                  </FacebookShareButton>
                </li>
                <li>
                  <button
                    className="cursor-pointer"
                    type="button"
                    onClick={print}
                  >
                    <Svg
                      className="w-[19px]"
                      src="/svgs/noprecache/social/print.svg#print"
                      title="Print this Page"
                      viewBox="0 0 19 16"
                    />
                  </button>
                </li>
                <li>
                  <Tooltip delayHide={500} content="Copy URL" id="copy-url" />
                  <button
                    className="cursor-pointer"
                    type="button"
                    onClick={copyUrl}
                  >
                    <Svg
                      className="w-[15px]"
                      src="/svgs/noprecache/social/link.svg#link"
                      title="Share this Page"
                      viewBox="0 0 15 17"
                      data-tooltip-id="copy-url"
                      data-tooltip-content={copyUrlTooltip}
                      data-tooltip-place="top"
                    />
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className="wiyw-dashboard-main my-[40px] flex flex-wrap items-center justify-between border-b border-t border-[#FFFFFF1A] py-[40px] text-center md:flex-nowrap">
            <div className="dashboard-left flex flex-[0_0_100%] flex-col p-[20px] md:flex-[0_0_50%] md:border-r md:border-r-[#FFFFFF1A]">
              <div className="relative aspect-[16/9] h-full w-full overflow-hidden xs:aspect-[16/8] sm:aspect-[16/6] md:aspect-video lg:aspect-[16/7]">
                <div className="relative mx-auto flex max-w-[368px] items-center justify-center [&_.CircularProgressbar-path]:!mix-blend-overlay [&_svg]:h-full [&_svg]:w-full">
                  <CircularProgressbar
                    value={EWGResultsPercentRemovedContaminants}
                    circleRatio={0.5}
                    strokeWidth={4}
                    styles={buildStyles({
                      rotation: 3 / 4,
                      strokeLinecap: 'round',
                      pathTransitionDuration: 0.5,
                      textSize: '1.5rem',
                      trailColor: '#8DCEF933',
                      pathColor: '#CCE0F1',
                    })}
                  />
                  <div className="absolute -translate-y-[200%] text-[110px] font-light sm:text-[120px]">
                    {EWGResultsExceedingGuidelines?.length
                      ? EWGResultsExceedingGuidelines.length
                      : 'N/A'}
                  </div>
                </div>
              </div>
              <div>
                <Headline element="h5" variant="h5" className="!text-[22px]">
                  Contaminants
                </Headline>
                <Eyebrow className="uppercase">
                  Exceed EWG Health Guidelines
                </Eyebrow>
                <div className="mx-auto my-[20px] w-[15px] border-t-[3px] border-t-[#FFFFFF1A]" />

                {EWGResults && EWGResults?.ContaminantList?.length > 0 && (
                  <Body variant="medium" className="mb-2">
                    {EWGResults?.ContaminantList?.length} Total Contaminants in
                    Your Water
                  </Body>
                )}

                {EWGResultsRemovedContaminants &&
                  EWGResultsRemovedContaminants?.length > 0 && (
                    <Body variant="medium">
                      {EWGResultsRemovedContaminants?.length} Removed by Clearly
                      Filtered
                    </Body>
                  )}
              </div>
            </div>

            <div className="dashboard-right flex-[0_0_100%] border-t border-t-[#FFFFFF1A] pt-[40px] text-left md:flex-[0_0_50%] md:border-t-0 md:pt-0">
              <div className="grid grid-cols-2 md:pl-[30px]">
                <div className="dashboard-water-provider border-b border-r border-b-[#FFFFFF1A] border-r-[#FFFFFF1A] p-[14px] sm:p-[20px]">
                  <Eyebrow className="uppercase">Water Provider</Eyebrow>
                  <div className="mt-2 font-light">
                    {activeEWGSystem?.SystemName
                      ? decode(activeEWGSystem?.SystemName)
                      : 'N/A'}
                  </div>
                </div>

                <div className="dashboard-population-affected border-b border-b-[#FFFFFF1A] p-[14px] sm:p-[20px]">
                  <Eyebrow className="uppercase">Population Affected</Eyebrow>
                  <div className="mt-2 font-light">
                    {activeEWGSystem?.Population
                      ? activeEWGSystem?.Population?.toLocaleString('en-US')
                      : 'N/A'}
                  </div>
                </div>

                <div className="dashboard-data-available border-r border-r-[#FFFFFF1A] p-[14px] sm:p-[20px]">
                  <Eyebrow className="uppercase">Data Available</Eyebrow>
                  <div className="mt-2 font-light">
                    {EWGResults?.ContaminantList?.[0]?.ContaminantTimeFrame
                      ? EWGResults?.ContaminantList?.[0]?.ContaminantTimeFrame
                      : 'N/A'}
                  </div>
                </div>

                <div className="dashboard-water-source p-[14px] sm:p-[20px]">
                  <Eyebrow className="uppercase">Water Source</Eyebrow>
                  <div className="mt-2 font-light">
                    {activeEWGSystem?.PrimSource_fulltext
                      ? activeEWGSystem?.PrimSource_fulltext
                      : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wiyw-dashboard-footer text-center">
            <div className="mb-[26px] font-mono text-sm uppercase">
              View water providers in your area
            </div>
            <div className="no-scrollbar overflow-x-auto">
              {EWGSystems && EWGSystems.length > 0 && (
                <ScrollContainer>
                  <ul className="flex gap-[20px] sm:flex-wrap sm:justify-center">
                    {EWGSystems.map((EWGSystem, EWGSystemIndex) => {
                      return (
                        <li key={`ewg-system-${EWGSystemIndex}`}>
                          <button
                            type="button"
                            onClick={() => {
                              setActiveEWGSystem(EWGSystem);
                            }}
                            className={`${
                              activeEWGSystem?.PWS === EWGSystem?.PWS
                                ? 'border-[#ffffff] bg-[#FFFFFF]  text-primary'
                                : 'text-white hover:bg-[#FFFFFF1A]'
                            } whitespace-nowrap rounded-full border px-3 py-2 text-xs lg:px-4 lg:py-2 lg:text-sm`}
                          >
                            {decode(EWGSystem?.SystemName || 'N/A')}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </ScrollContainer>
              )}
            </div>
            <div className="mt-[40px] flex items-center justify-center text-center">
              <Link
                className="flex cursor-pointer"
                href="/pages/whats-in-your-water-quiz?retake=true"
              >
                <Svg
                  className="w-[13px]"
                  src="/svgs/restart.svg#restart"
                  title="Retake Quiz"
                  viewBox="0 0 13 12"
                />
                <span className="ml-4 underline underline-offset-2">
                  Retake Quiz
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* print block */}
      <div className="hidden print:block">
        <div className="px-contained relative mx-auto max-w-[1200px] !pt-10 pb-10 text-black">
          <div className="wiyw-dashboard-header text-center font-sans text-xl font-light">
            Contaminants Detected in{' '}
            <span className="font-[500]">
              {decode(activeEWGSystem?.SystemName || 'N/A')}
            </span>
          </div>

          <div className="wiyw-dashboard-main my-0 flex flex-wrap items-center justify-between border-b border-t border-[#FFFFFF1A] py-[40px] text-center">
            <div className="dashboard-left flex w-2/5 flex-col pr-6">
              <div className="flex items-center justify-center text-4xl font-light">
                {EWGResultsExceedingGuidelines?.length
                  ? EWGResultsExceedingGuidelines.length
                  : 'N/A'}
              </div>
              <div>
                <Eyebrow className="mb-2">
                  Contaminants Exceed EWG Health Guidelines
                </Eyebrow>

                {EWGResults && EWGResults?.ContaminantList?.length > 0 && (
                  <Body variant="medium" className="mb-2">
                    {EWGResults?.ContaminantList?.length} Total Contaminants in
                    Your Water
                  </Body>
                )}

                {EWGResultsRemovedContaminants &&
                  EWGResultsRemovedContaminants?.length > 0 && (
                    <Body variant="medium">
                      {EWGResultsRemovedContaminants?.length} Removed by Clearly
                      Filtered
                    </Body>
                  )}
              </div>
            </div>

            <div className="dashboard-right w-3/5 border-l py-6 pl-6 text-left">
              <div className="grid grid-cols-2 gap-6">
                <div className="dashboard-water-provider border-b border-r border-b-[#FFFFFF1A] border-r-[#FFFFFF1A]">
                  <Eyebrow className="uppercase">Water Provider</Eyebrow>
                  <div className="mt-1 text-sm font-light">
                    {activeEWGSystem?.SystemName
                      ? decode(activeEWGSystem?.SystemName)
                      : 'N/A'}
                  </div>
                </div>

                <div className="dashboard-population-affected border-b border-b-[#FFFFFF1A]">
                  <Eyebrow className="uppercase">Population Affected</Eyebrow>
                  <div className="mt-1 text-sm font-light">
                    {activeEWGSystem?.Population
                      ? activeEWGSystem?.Population?.toLocaleString('en-US')
                      : 'N/A'}
                  </div>
                </div>

                <div className="dashboard-data-available border-r border-r-[#FFFFFF1A]">
                  <Eyebrow className="uppercase">Data Available</Eyebrow>
                  <div className="mt-1 text-sm font-light">
                    {EWGResults?.ContaminantList?.[0]?.ContaminantTimeFrame
                      ? EWGResults?.ContaminantList?.[0]?.ContaminantTimeFrame
                      : 'N/A'}
                  </div>
                </div>

                <div className="dashboard-water-source">
                  <Eyebrow className="uppercase">Water Source</Eyebrow>
                  <div className="mt-1 text-sm font-light">
                    {activeEWGSystem?.PrimSource_fulltext
                      ? activeEWGSystem?.PrimSource_fulltext
                      : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

WIYWDashboard.displayName = 'WIYWDashboard';
WIYWDashboard.Schema = Schema;
