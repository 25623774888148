import { useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';
import kebabCase from 'lodash.kebabcase';

export function useThemeColors() {
  const settings = useSettings();
  const { buttons, colors } = { ...settings?.theme };

  useEffect(() => {
    if (!buttons) return;
    Object.entries(buttons).forEach(([buttonType, buttonColors]) => {
      Object.entries(buttonColors).forEach(([key, value]) => {
        document.documentElement.style.setProperty(
          `--${kebabCase(buttonType)}-btn-${kebabCase(key)}`,
          value || 'transparent'
        );
      });
    });
  }, [buttons]);

  useEffect(() => {
    if (!colors) return;
    Object.entries(colors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(
        `--${kebabCase(key)}`,
        value || null
      );
    });
  }, [colors]);
}
