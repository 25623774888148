import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Svg } from '../../../snippets/Svg';

/**
 * @typedef {Object} ZipInputProps
 * @property {string} value
 * @property {(e: React.ChangeEvent<HTMLInputElement>) => void} onChange
 * @property {string} [placeholder]
 * @property {string} [className]
 * @property {string} [style]
 * @property {string} [id]
 * @property {string} [name]
 * @property {string} [type]
 * @property {boolean} [required]
 * @property {boolean} [disabled]
 * @property {() => void} onSubmit
 * @param {ZipInputProps} props
 * @returns {JSX.Element}
 */
export default function ZipInput({ value = '', onChange, onSubmit, ...props }) {
  // local clone of value to debounce changes
  const [localValue, setLocalValue] = useState(value);

  const debouncedSave = useCallback(
    debounce((nextValue) => setLocalValue(nextValue), 250),
    []
  );

  useEffect(() => {
    debouncedSave(value);
  }, [value]);

  const zipValidation = useMemo(() => {
    // if value is empty, user didn't touch the input yet
    if (localValue?.length === 0) return { valid: true };

    // test if value is a valid zip code
    if (/^\d{5}(?:[-\s]\d{4})?$/gm.test(localValue)) return { valid: true };

    // if value is not a valid zip code, return error message
    return {
      valid: false,
      message: '*Please enter a valid zip code',
    };
  }, [localValue]);

  return (
    <div className="relative w-full">
      <div
        className={[
          'mb-4 w-full rounded-[65px] border-red-400 bg-stone-50 px-9 py-4 transition-all focus-within:ring hover:ring',
          zipValidation?.valid ? '' : 'ring ring-red-400',
        ].join(' ')}
      >
        <input
          value={value}
          onChange={onChange}
          placeholder="Zip Code"
          onKeyDown={(e) =>
            e.key === 'Enter' && zipValidation.valid && onSubmit()
          }
          {...props}
          className={[
            'text-gray-800 w-full bg-stone-50 text-[18px] font-light leading-relaxed outline-none',
          ].join(' ')}
        />

        <button
          onClick={onSubmit}
          type="button"
          disabled={!zipValidation?.valid || !value.length}
          className="absolute right-9 top-0 flex h-full items-center justify-center outline-none transition-all focus:scale-125 disabled:opacity-50"
        >
          <Svg
            className="w-5 text-text"
            src="/svgs/search.svg#search"
            title="Search"
            viewBox="0 0 24 24"
          />
        </button>
      </div>
      {!zipValidation?.valid && (
        <p className="absolute -bottom-6 mt-2 text-base text-red-400">
          {zipValidation?.message || 'Please enter a valid zip code'}
        </p>
      )}
    </div>
  );
}
