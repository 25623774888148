import Fuse from 'fuse.js';
import { useEffect, useRef, useState } from 'react';
import { useMergedContaminants } from '../../hooks/labResults/useMergedContaminants';
import { Body, Button, Headline, Svg } from '../../snippets';
import flattenContaminant from '../../utils/flattenContaminant';
import { Schema } from './LabResults.schema';

export function LabResults({ cms }) {
  const { contaminants, search } = cms;

  const mergedContaminants = useMergedContaminants(
    flattenContaminant(contaminants)
  );
  const [openAccordions, setOpenAccordions] = useState([]);

  // These handle which category / contaminants are current being searched upon
  const [searchedCategory, setSearchedCategory] = useState('');
  const [searchedContaminant, setSearchedContaminant] = useState('');
  const [foundContaminant, setFoundContaminant] = useState('');
  const [autoCompleteResults, setAutoCompleteResults] = useState([]);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);

  // Hide autocomplete search results on outside click & Blur
  const searchInput = useRef();
  useEffect(() => {
    const handleClick = (event) => {
      if (searchInput.current && !searchInput.current.contains(event.target)) {
        setAutoCompleteResults([]);
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [searchInput]);

  const scrollToSearchedCategory = (cat) => {
    const category = cat ?? searchedCategory;
    const categoryIndex = Object.keys(mergedContaminants)?.indexOf(category);
    if (categoryIndex >= 0) {
      setOpenAccordions([]);
      setTimeout(() => {
        document.getElementById(`category-${categoryIndex}`)?.scrollIntoView();
        setSearchedCategory(category);
        setOpenAccordions([category]);
      }, 0);
    }
  };

  const setAutoCompleteWithFuse = (contaminant) => {
    setAutoCompleteResults([]);
    setSearchedContaminant(contaminant);
    setFoundContaminant('');
    // Normalize our contaminants for use in Fuse Search
    let normalizedContaminants = [];

    Object.keys(mergedContaminants).forEach((category) => {
      normalizedContaminants = [
        ...normalizedContaminants,
        ...mergedContaminants[category].contaminants,
      ];
    });

    // adding categories to the suggestions list, in case the user searches for a category
    // instead of the direct contaminant name
    normalizedContaminants = [
      ...normalizedContaminants,
      ...Object.keys(mergedContaminants).map((category) => ({
        name: category,
        category,
        aliases: [category],
      })),
    ];

    const aliases = normalizedContaminants.filter((c) => c.aliases?.length > 1);
    const flatAliases = aliases
      .map((aliasContaminant) => [
        ...aliasContaminant.aliases.map((alias) => ({
          name: alias,
          isAlias: true,
          originalContaminant: aliasContaminant,
          category: aliasContaminant.category,
          aliases: [alias],
        })),
      ])
      .flat();

    // adding suggestions to the suggestions list, in case the user searches for a suggestion
    // instead of the direct contaminant name
    normalizedContaminants = [...normalizedContaminants, ...flatAliases];

    // Conduct a Fuse Search
    const fuse = new Fuse(normalizedContaminants, {
      shouldSort: true,
      threshold: 0.3,
      minMatchCharLength: 1,
      keys: ['name', 'aliases'], // Search by contaminant name & alias
    });
    const result = fuse.search(contaminant).slice(0, 8);
    setAutoCompleteResults(result);

    // If an exact match is found, let's just use that
    if (result[0]?.item?.name.toLowerCase() === contaminant.toLowerCase()) {
      // Reconnect aliases
      const resultContaminant = result[0].item.isAlias
        ? result[0].item.originalContaminant
        : result[0].item;

      setFoundContaminant(resultContaminant.name);
      setAutoCompleteResults([]);
      scrollToSearchedCategory(resultContaminant.category);
    }
  };

  // Handle toggling a category accordion
  const toggleAccordionItem = (categoryKey) => {
    const currentOpenAccordions = [...openAccordions];
    if (currentOpenAccordions.includes(categoryKey))
      currentOpenAccordions.splice(
        currentOpenAccordions.indexOf(categoryKey),
        1
      );
    else currentOpenAccordions.push(categoryKey);
    setOpenAccordions(currentOpenAccordions);
  };

  // Set the initial Category to be toggled Open
  useEffect(() => {
    setOpenAccordions([
      mergedContaminants[Object.keys(mergedContaminants)[0]]?.categoryName,
    ]);
  }, []);

  const sortSearchedContaminants = (a, b) => {
    if (a?.name === foundContaminant) return -1;
    if (b?.name === foundContaminant) return 1;
    return 0;
  };

  return (
    <section className="lab-results px-contained py-[32px] md:py-[62px]">
      {/* Search & Filtering */}
      <div className="lab-results__search-bar">
        {/* Search Input & Category Dropdown */}
        <div className="search-bar__inputs flex items-center justify-between border-b border-b-[#DDE2E7] pb-[14px]">
          {/* Search Input */}
          <div
            className="search-input relative flex-grow rounded-full border border-border bg-white p-[5px] md:border-none md:bg-transparent md:p-0"
            ref={searchInput}
          >
            <div className="flex">
              <Svg
                className="w-5 text-text"
                src="/svgs/search.svg#search"
                title="Search"
                viewBox="0 0 24 24"
              />
              <input
                autoComplete="off"
                id="lab-results-search-input"
                className="font-shadowGray w-full bg-transparent px-2 py-1 text-sm text-shadowGray outline-0 md:px-4 md:text-base"
                placeholder={search?.searchPlaceholderText || 'Search...'}
                type="text"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setAutoCompleteResults([]);
                  }
                }}
                onChange={(event) => {
                  setAutoCompleteWithFuse(event.target.value);
                }}
                value={searchedContaminant}
              />
            </div>

            {/* Auto-Complete Results */}
            {autoCompleteResults?.length > 0 && (
              <div
                id="lab-results-search-autocomplete"
                className="absolute z-10 rounded-[21px] border border-[#E9E9EB] bg-white px-[32px] py-[16px]"
              >
                {autoCompleteResults.map((result, autoCompleteIndex) => {
                  return (
                    <button
                      type="button"
                      key={`auto-complete-${autoCompleteIndex}`}
                      className="mb-[10px] block w-full text-left last:mb-0 "
                      onClick={() => {
                        setAutoCompleteResults([]);
                        setFoundContaminant(result.item.name);
                        setSearchedContaminant(result.item.name);
                        scrollToSearchedCategory(result.item.category);
                      }}
                    >
                      {result.item.name}
                    </button>
                  );
                })}
              </div>
            )}
          </div>

          {/* Category Dropdown */}
          <div
            className={`category-input ml-6 flex-[0_0_135px] md:flex-[0_0_213px] ${
              categoryDropdownOpen ? '' : 'flex justify-end'
            }`}
          >
            {Object.keys(mergedContaminants).length > 0 && (
              <div className="relative">
                {/* Selected Category */}
                <Button
                  theme="dark"
                  variant="outlined"
                  className={`relative z-20 w-full justify-start !px-[20px] !py-[10px] !text-left ${
                    categoryDropdownOpen ? 'bg-white' : 'bg-background'
                  }`}
                  onClick={() => {
                    setCategoryDropdownOpen(!categoryDropdownOpen);
                  }}
                >
                  <Body
                    variant="small"
                    element="span"
                    className="flex justify-between"
                  >
                    {searchedCategory || 'Categories'}
                    <Svg
                      className={`ml-6 w-4 text-text ${
                        categoryDropdownOpen
                          ? 'rotate-[270deg] scale-x-50 scale-y-75'
                          : 'rotate-90 scale-x-50 scale-y-75'
                      }`}
                      src="/svgs/play.svg#play"
                      title="Chevron"
                      viewBox="0 0 24 24"
                    />
                  </Body>
                </Button>

                {/* Category Dropdown Container */}
                <ul
                  className={`absolute z-10 w-full -translate-y-6 flex-col items-start gap-2 rounded-b-[21px] border border-[#E9E9EB] bg-white p-4 pt-6 ${
                    categoryDropdownOpen ? 'visible' : 'hidden'
                  }`}
                >
                  {Object.keys(mergedContaminants)?.map(
                    (contaminantCategory, categoryIndex) => (
                      <li key={`category-select-${categoryIndex}`}>
                        <button
                          type="button"
                          className="w-full py-1 text-left"
                          value={contaminantCategory}
                          onClick={() => {
                            setCategoryDropdownOpen(false);
                            setSearchedContaminant('');
                            setFoundContaminant('');
                            setAutoCompleteResults([]);
                            scrollToSearchedCategory(contaminantCategory);
                          }}
                        >
                          <Body variant="small" element="span">
                            {contaminantCategory}
                          </Body>
                        </button>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>

        {/* Top Searches */}
        <div className="search-bar__top-searches mt-[30px] flex flex-wrap items-center gap-[20px]">
          <div className="top-searches__label">Top Searches</div>
          <div className="top-searches__pills overflow-hidden">
            {search?.topSearchTerms &&
              Object.keys(search?.topSearchTerms).length > 0 && (
                <ul className="no-scrollbar flex gap-[20px] overflow-auto lg:flex-wrap">
                  {search.topSearchTerms.map(
                    (searchTermObject, searchTermIndex) => (
                      <li key={`search-term-${searchTermIndex}`}>
                        <Button
                          onClick={() => {
                            setAutoCompleteResults([]);
                            setSearchedContaminant(searchTermObject.searchTerm);
                            setFoundContaminant(searchTermObject.searchTerm);
                            Object.keys(mergedContaminants).forEach(
                              (contaminantCategory, categoryIndex) => {
                                mergedContaminants?.[
                                  contaminantCategory
                                ].contaminants.forEach((contaminantObject) => {
                                  if (
                                    searchTermObject.searchTerm ===
                                    contaminantObject.name
                                  ) {
                                    scrollToSearchedCategory(
                                      contaminantCategory
                                    );
                                  }
                                });
                              }
                            );
                          }}
                          variant="outlined"
                          theme="dark"
                          className="w-max !px-[20px] !py-[10px] !font-[#202635]"
                        >
                          {searchTermObject.searchTerm}
                        </Button>
                      </li>
                    )
                  )}
                </ul>
              )}
          </div>
        </div>

        {
          /* No Results Found */
          searchedContaminant !== '' &&
            foundContaminant === '' &&
            autoCompleteResults.length === 0 && (
              <div id="no-results" className="serach-bar__no-results mt-[40px]">
                {search?.noResultsHeading && (
                  <Headline
                    variant="h4"
                    element="h4"
                    className="no-results__heading"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: search.noResultsHeading.replace(
                          '{search term}',
                          `<span class="text-[#3383C5]">${searchedContaminant}</span>`
                        ),
                      }}
                    />
                  </Headline>
                )}
                {search?.noResultsContent && (
                  <Body variant="medium" className="no-results__content mt-4">
                    {search.noResultsContent}
                  </Body>
                )}
              </div>
            )
        }
      </div>

      {/* Primary Results Table */}
      <div className="lab-results__main-results overflow-auto pt-[54px]">
        {Object.keys(mergedContaminants).length > 0 && (
          <ul>
            {
              /* Loop over Categories first */
              Object.keys(mergedContaminants).map(
                (contaminantCategory, categoryIndex) => {
                  const category = mergedContaminants[contaminantCategory];
                  return (
                    <li
                      id={`category-${categoryIndex}`}
                      key={`contaminant-category-${categoryIndex}`}
                      className={`contaminant-category mb-[17px] rounded-[10px] bg-white p-[15px] md:mb-[30px] md:p-[30px] ${
                        openAccordions.includes(contaminantCategory)
                          ? ''
                          : '[&_.contaminant-category-details_.category-description]:hidden [&_.contaminants-section]:hidden'
                      } ${
                        contaminantCategory === searchedCategory
                          ? 'border border-primary'
                          : ''
                      }`}
                    >
                      <div className="contaminant-category-details">
                        <Headline
                          element="h3"
                          variant="h5"
                          className="category-name flex cursor-pointer items-center justify-between"
                          onClick={() =>
                            toggleAccordionItem(contaminantCategory)
                          }
                        >
                          {contaminantCategory}
                          <div className="h-[35px] w-[35px] rounded-full bg-gradient-to-t from-[#5881D9] to-[#C2F0FA] md:h-[50px] md:w-[50px]">
                            <div className="flex h-full w-full scale-95 items-center justify-center rounded-full bg-white">
                              <Svg
                                className={`w-[10px] text-text md:w-[15px] ${
                                  openAccordions.includes(contaminantCategory)
                                    ? 'rotate-180'
                                    : ''
                                }`}
                                src="/svgs/chevron-down.svg#chevron-down"
                                title="Chevron Down"
                                viewBox="0 0 24 24"
                              />
                            </div>
                          </div>
                        </Headline>
                        {mergedContaminants?.[contaminantCategory]
                          ?.categoryDescription && (
                          <Body
                            variant="small"
                            className="category-description w-full max-w-[calc(100%-75px)] pt-[24px] md:max-w-[833px]"
                          >
                            {
                              mergedContaminants[contaminantCategory]
                                .categoryDescription
                            }
                          </Body>
                        )}
                      </div>
                      <div className="contaminants-section overflow-x-auto pt-[32px] scrollbar-hide">
                        <div className="hidden flex-col md:flex md:min-w-[1100px]">
                          <ul
                            className="contaminants-heading order-1 flex justify-end text-[12px] font-light text-white md:text-[18px]"
                            style={{
                              background:
                                'linear-gradient(270deg, #5B71A1 0%, #536896 10.94%, #4D618E 19.27%, #354974 33.84%, #354C7A 50.38%, #3C5280 65.88%, #3A4C79 77.84%, #232F54 100%)',
                            }}
                          >
                            <li className="flex flex-[0_0_65%] items-center p-[10px] md:flex-auto lg:p-[22px]">
                              Contaminant
                            </li>
                            <li
                              className={`flex flex-[0_0_35%] flex-col items-center justify-center p-[10px] text-center md:flex-[0_0_200px] lg:p-[22px] `}
                            >
                              Challenge Water
                              {category.challengeWaterUnits && (
                                <span className="text-xs md:text-sm">
                                  &nbsp;
                                  {category.challengeWaterUnits}
                                </span>
                              )}
                            </li>
                            <li
                              className={`flex flex-[0_0_35%] flex-col items-center justify-center p-[10px] text-center md:flex-[0_0_200px] lg:p-[22px] `}
                            >
                              Filtered Water
                              {category.filteredWaterUnits && (
                                <span className="text-xs md:text-sm">
                                  &nbsp;
                                  {category.filteredWaterUnits}
                                </span>
                              )}
                            </li>
                            <li className="flex flex-[0_0_35%] flex-col items-center justify-center p-[10px] text-center md:flex-[0_0_200px] lg:p-[22px]">
                              % Removal
                            </li>
                            <li className="flex flex-[0_0_35%] flex-col items-center justify-center p-[10px] text-center md:flex-[0_0_200px] lg:p-[22px]">
                              Health Concern
                            </li>
                          </ul>

                          {
                            /* Loop over contaminants */
                            mergedContaminants[contaminantCategory]
                              ?.contaminants?.length > 0 &&
                              mergedContaminants[
                                contaminantCategory
                              ]?.contaminants.map(
                                (contaminant, contaminantIndex) => {
                                  return (
                                    <ul
                                      className={`contaminant flex justify-end border border-[#2026351A] ${
                                        contaminant?.name === foundContaminant
                                          ? 'order-2 bg-[#cce0f1]'
                                          : 'order-3'
                                      }`}
                                      key={`contaminant-${contaminantIndex}`}
                                    >
                                      <Body
                                        variant="medium"
                                        element="li"
                                        className="flex flex-[0_0_65%] items-center border-r border-[#2026351A] p-[10px] text-[12px] md:flex-auto md:text-[18px] lg:p-[22px]"
                                      >
                                        {contaminant?.name ?? ' '}
                                      </Body>
                                      <Body
                                        variant="medium"
                                        element="li"
                                        className="flex flex-[0_0_35%] items-center justify-center border-r border-[#2026351A] p-[10px] text-center text-[12px] md:flex-[0_0_200px] md:text-[18px] lg:p-[22px]"
                                      >
                                        {contaminant?.challengeWater ?? ' '}
                                      </Body>
                                      <Body
                                        variant="medium"
                                        element="li"
                                        className="flex flex-[0_0_35%] items-center justify-center border-r border-[#2026351A] p-[10px] text-center text-[12px] md:flex-[0_0_200px] md:text-[18px] lg:p-[22px]"
                                      >
                                        {contaminant?.filteredWater ?? ' '}
                                      </Body>
                                      <li className="flex flex-[0_0_35%] items-center justify-center border-r border-[#2026351A] p-[10px] text-center font-light md:flex-[0_0_200px] md:text-[28px] lg:p-[22px]">
                                        {contaminant?.percentRemoved ?? ' '}
                                      </li>
                                      <Body
                                        variant="medium"
                                        element="li"
                                        className="flex flex-[0_0_35%] items-center justify-center p-[10px] text-center text-[12px] md:flex-[0_0_200px] md:text-[18px] lg:p-[22px]"
                                      >
                                        {contaminant?.healthConcern ?? ' '}
                                      </Body>
                                    </ul>
                                  );
                                }
                              )
                          }
                        </div>

                        {/* Mobile View */}
                        <div className="flex gap-0 md:hidden [&_td]:whitespace-nowrap [&_td]:border [&_td]:border-[#2026351A] [&_td]:p-[10px] [&_th]:whitespace-nowrap [&_th]:text-[12px] [&_th]:font-light [&_th]:text-white">
                          <div className="flex overflow-auto">
                            <table>
                              <thead
                                style={{
                                  background:
                                    'linear-gradient(270deg, #5B71A1 0%, #536896 10.94%, #4D618E 19.27%, #354974 33.84%, #354C7A 50.38%, #3C5280 65.88%, #3A4C79 77.84%, #232F54 100%)',
                                }}
                              >
                                <tr>
                                  <th
                                    className="sticky left-0 max-w-[60vw] p-[10px]"
                                    style={{
                                      background:
                                        'linear-gradient(45deg, rgb(53 73 116), #3a4f7c)',
                                    }}
                                  >
                                    Contaminants
                                  </th>
                                  <th className="p-[10px]">% Removal</th>
                                  <th className="p-[10px]">
                                    Challenge Water
                                    {category.challengeWaterUnits && (
                                      <span className="text-xs md:text-sm">
                                        &nbsp;
                                        {category.challengeWaterUnits}
                                      </span>
                                    )}
                                  </th>
                                  <th className="p-[10px]">
                                    Filtered Water
                                    {category.filteredWaterUnits && (
                                      <span className="text-xs md:text-sm">
                                        &nbsp;
                                        {category.filteredWaterUnits}
                                      </span>
                                    )}
                                  </th>
                                  <th className="p-[10px]">Health Concern</th>
                                </tr>
                              </thead>

                              <tbody>
                                {mergedContaminants[contaminantCategory]
                                  ?.contaminants?.length > 0 &&
                                  mergedContaminants[
                                    contaminantCategory
                                  ]?.contaminants
                                    .sort(sortSearchedContaminants)
                                    .map((contaminant, contaminantIndex) => {
                                      return (
                                        <tr
                                          className={`p-[10px] md:items-center lg:p-[22px] lg:text-center ${
                                            contaminant?.name ===
                                            foundContaminant
                                              ? 'bg-[#cce0f1]'
                                              : ''
                                          }`}
                                          key={`mobile-contaminant-${contaminantIndex}`}
                                        >
                                          <td
                                            className={`sticky left-0 max-w-[60vw] overflow-hidden text-ellipsis ${
                                              contaminant?.name ===
                                              foundContaminant
                                                ? 'bg-[#cce0f1]'
                                                : 'bg-white'
                                            }`}
                                          >
                                            <Body variant="small">
                                              {contaminant.name}
                                            </Body>
                                          </td>
                                          <td className="text-center">
                                            <Body variant="medium">
                                              {contaminant.percentRemoved}
                                            </Body>
                                          </td>
                                          <td className="text-center">
                                            <Body variant="small">
                                              {contaminant.challengeWater}
                                            </Body>
                                          </td>
                                          <td className="text-center">
                                            <Body variant="small">
                                              {contaminant.filteredWater}
                                            </Body>
                                          </td>
                                          <td className="text-center">
                                            <Body variant="small">
                                              {contaminant.healthConcern}
                                            </Body>
                                          </td>
                                        </tr>
                                      );
                                    })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                }
              )
            }
          </ul>
        )}
      </div>
    </section>
  );
}

LabResults.displayName = 'LabResults';
LabResults.Schema = Schema;
