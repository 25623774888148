import { Image } from '../../snippets';
import { Headline } from '../../snippets/Typography';
import handleize from '../../utils/handleize';
import useMediaQuery from '../../utils/useMediaQuery';
import { Schema } from './ArticleContaminantFacts.schema';

export function ArticleContaminantFacts({ cms }) {
  const {
    title,
    anchorId,
    header,
    facts,
    guideline,
    guidelineheader: oldGuidelineHeader,
    desktopImage,
    mobileImage,
  } = cms;

  const guidelineheader = guideline?.guidelineheader || oldGuidelineHeader;
  const guidelineLeftSideHeading = guideline?.guidelineLeftSideHeading;
  const guidelineLeftSideCopy = guideline?.guidelineLeftSideCopy;
  const guidelineLeftSideMarker = guideline?.guidelineLeftSideMarker;
  const guidelineLeftSideMarkerMobile =
    guideline?.guidelineLeftSideMarkerMobile;
  const guidelineRightSideHeading = guideline?.guidelineRightSideHeading;
  const guidelineRightSideCopy = guideline?.guidelineRightSideCopy;
  const guidelineRightSideMarker = guideline?.guidelineRightSideMarker;
  const guidelineRightSideMarkerMobile =
    guideline?.guidelineRightSideMarkerMobile;

  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <div className="relative mb-8 font-light lg:mb-16">
      {anchorId && <div id={handleize(anchorId)} className="absolute top-16" />}
      {title && (
        <Headline
          variant="h4"
          element="h4"
          className="border-t border-[#DDE2E7] pb-4 pt-12 lg:pb-16"
        >
          {title}
        </Headline>
      )}
      <div className="h-[1px] w-full bg-[#DDE2E7]" />
      <Headline
        variant="h6"
        element="h6"
        className="pb-4 pt-8 text-primary max-md:!text-[16px] lg:pb-6"
      >
        {header}
      </Headline>
      <div className="mt-8 grid items-center justify-around gap-6 gap-y-10 pb-12 lg:grid-cols-3 lg:pb-24">
        {facts.map(({ header: headerText, copy }, index) => {
          return (
            <div
              key={index}
              className="rounded-lg bg-gradient-to-r from-[#F1DCC1] via-[#8DCEF9] to-[#2179C2] p-[1px] text-center"
            >
              <div className="rounded-lg bg-[#F2F7FB] px-6 py-4 text-primary">
                <Headline
                  variant="h3"
                  element="h3"
                  className="pb-4 max-lg:!text-[52px] lg:pb-4"
                >
                  {headerText}
                </Headline>
                <div className="h-[1px] w-full bg-gradient-to-r from-[#F1DCC1] via-[#8DCEF9] to-[#2179C2]" />
                <p className="pt-4 font-mono">{copy}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="h-[1px] w-full bg-[#DDE2E7] text-primary" />
      {guidelineheader ? (
        <Headline
          variant="h6"
          element="h6"
          className="pb-4 pt-8 max-md:!text-[16px] lg:pb-12"
        >
          {guidelineheader}
        </Headline>
      ) : (
        <div className="pb-4 pt-8 lg:pb-12" />
      )}
      {(guidelineLeftSideHeading ||
        guidelineLeftSideCopy ||
        guidelineRightSideHeading ||
        guidelineRightSideCopy) && (
        <div className="relative overflow-hidden border border-[#DDE2E7] pb-7">
          <div
            className="absolute bottom-0 left-0 right-0 h-7"
            style={{
              background:
                'linear-gradient(90deg, #F3DCC1 0%, #8DCFF9 50%, #207AC2 100%)',
            }}
          />

          <div
            className="absolute bottom-0 right-0 h-7 w-[1px] border-r border-dashed border-[#777]"
            style={{
              right: `${
                isMobile
                  ? guidelineRightSideMarkerMobile
                  : guidelineRightSideMarker
              }%`,
            }}
          />

          <div
            className="absolute bottom-0 right-0 h-7 w-[1px] border-r border-dashed border-[#777]"
            style={{
              left: `${
                isMobile
                  ? guidelineLeftSideMarkerMobile
                  : guidelineLeftSideMarker
              }%`,
            }}
          />
          <table className="w-full [&_td]:px-3 [&_td]:pb-2 [&_th]:px-3">
            <thead>
              <tr>
                <th
                  className="text-left"
                  style={{
                    width: `${
                      100 -
                      (isMobile
                        ? guidelineRightSideMarkerMobile
                        : guidelineRightSideMarker)
                    }%`,
                  }}
                >
                  <span className="tracking-wide font-mono text-xs font-normal uppercase md:text-sm">
                    {guidelineLeftSideHeading}
                  </span>
                </th>
                <th
                  className="text-left"
                  style={{
                    width: `${
                      isMobile
                        ? guidelineRightSideMarkerMobile
                        : guidelineRightSideMarker
                    }%`,
                  }}
                >
                  <span className="tracking-wide font-mono text-xs font-normal uppercase md:text-sm">
                    {guidelineRightSideHeading}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left">
                  <span className="text-sm md:text-lg">
                    {guidelineLeftSideCopy}
                  </span>
                </td>
                <td className="text-left">
                  <span className="text-sm md:text-lg">
                    {guidelineRightSideCopy}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {desktopImage && (
        <div className="relative hidden w-full lg:block">
          <Image
            width="765px"
            height="275px"
            className="border border-[#DDE2E7]"
            alt={guidelineheader}
            src={desktopImage?.src}
            quality="90"
          />
        </div>
      )}
      {mobileImage && (
        <div className="relative w-full pb-8 lg:hidden">
          <Image
            width="765px"
            height="275px"
            className="border border-[#DDE2E7]"
            alt={guidelineheader}
            src={mobileImage?.src}
            quality="90"
          />
        </div>
      )}
    </div>
  );
}

ArticleContaminantFacts.displayName = 'ArticleContaminantFacts';
ArticleContaminantFacts.Schema = Schema;
