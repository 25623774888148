export default function Schema() {
  return {
    label: 'Hero Banner With News Ticker',
    key: 'heroBannerWithNewsTicker',
    category: 'Hero',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/hero_banner_news_ticker.png?v=1684793147',
    fields: [
      {
        label: 'Light Mode',
        name: 'lightMode',
        component: 'toggle',
        description: 'Enable Light Text & Buttons.',
        defaultValue: false,
      },
      {
        label: 'Background Image',
        name: 'backgroundImage',
        component: 'image',
      },
      {
        label: 'Background Image (mobile)',
        name: 'backgroundMobileImage',
        component: 'image',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Call to action',
        name: 'cta',
        component: 'link',
      },
      {
        label: 'Newsticker',
        name: 'newsticker',
        component: 'group-list',
        fields: [
          {
            label: 'Icon',
            name: 'icon',
            component: 'image',
          },
          {
            label: 'Text',
            name: 'text',
            component: 'text',
          },
        ],
      },
    ],
  };
}
