export function Schema() {
  return {
    category: 'Text',
    label: 'Career Icons',
    key: 'careerIcons',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2011.49.39_20AM.png?v=1684867874',
    fields: [
      {
        label: 'Career Icon',
        name: 'careerIconItems',
        component: 'group-list',
        itemProps: {
          label: '{{item.header}}',
        },
        fields: [
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Header',
            name: 'header',
            component: 'text',
          },
          {
            label: 'Copy',
            name: 'copy',
            component: 'text',
          },
        ],
        defaultValue: [
          {
            header: 'A TEAM YOU WANT TO BE ON',
            copy: 'We’re fun, friendly, and flexible—and that’s not changing anytime soon. It’s part of our culture. ',
          },
        ],
        defaultItem: {
          header: 'A TEAM YOU WANT TO BE ON',
          copy: 'We’re fun, friendly, and flexible—and that’s not changing anytime soon. It’s part of our culture. ',
        },
      },
    ],
  };
}
