import { ArticleSchemaMarkup } from './ArticleSchemaMarkup';
import { BlogSchemaMarkup } from './BlogSchemaMarkup';
import { CollectionSchemaMarkup } from './CollectionSchemaMarkup';
import { MainSchemaMarkup } from './MainSchemaMarkup';
import { ProductSchemaMarkup } from './ProductSchemaMarkup';

export function SchemaMarkup(props) {
  const baseUrl = process.env.SITE_URL;
  const { article, blog, collection, page, product } = props;

  let resourceType = page?.resourceType;
  let pageUrl = '';

  if (page?.resourceType === 'home_page') {
    pageUrl = baseUrl;
  } else if (page?.resourceType === 'page') {
    pageUrl = `${baseUrl}/pages/${page.handle}`;
  } else if (page?.resourceType === 'product_page') {
    pageUrl = `${baseUrl}/products/${page.handle}`;
  } else if (page?.resourceType === 'collection_page') {
    pageUrl = `${baseUrl}/collections/${page.handle}`;
  } else if (article) {
    pageUrl = `${baseUrl}/articles/${article.handle}`;
    resourceType = 'article';
  } else if (blog) {
    pageUrl = `${baseUrl}/blogs/${blog.handle}`;
    resourceType = 'blog';
  } else {
    pageUrl = baseUrl;
  }

  return (
    <>
      <MainSchemaMarkup pageUrl={pageUrl} />

      {resourceType === 'article' && (
        <ArticleSchemaMarkup article={article} pageUrl={pageUrl} />
      )}

      {resourceType === 'blog' && (
        <BlogSchemaMarkup blog={blog} pageUrl={pageUrl} />
      )}

      {resourceType === 'collection_page' && (
        <CollectionSchemaMarkup collection={collection} pageUrl={pageUrl} />
      )}

      {resourceType === 'product_page' && (
        <ProductSchemaMarkup product={product} pageUrl={pageUrl} />
      )}
    </>
  );
}

SchemaMarkup.displayName = 'SchemaMarkup';
