const videoContent = {
  label: 'Video Settings',
  name: 'videoResources',
  description: 'Video Conent',
  component: 'group-list',
  fields: [
    {
      label: 'Video Title',
      name: 'videotitle',
      component: 'text',
      defaultValue: 'Video Title',
    },
    {
      label: 'Video Copy',
      name: 'videocopy',
      component: 'text',
      defaultValue: 'Video Copy',
    },
    {
      label: 'Video Image',
      name: 'videoimage',
      component: 'image',
      defaultValue:
        'https://cdn.shopify.com/s/files/1/1011/0318/files/Stainless_Steel_Fridge_Filter_700x_201.jpg?v=1686873500',
    },
    {
      label: 'Video Vimeo ID',
      name: 'videoid',
      component: 'text',
      defaultValue: '286409603',
    },
  ],
  defaultValue: [
    {
      videotitle: 'Video Title',
      videocopy: 'Video Copy',
      videoimage:
        'https://cdn.shopify.com/s/files/1/1011/0318/files/Stainless_Steel_Fridge_Filter_700x_201.jpg?v=1686873500',
      videolink: '286409603',
    },
  ],
};

const resourceSettings = {
  label: 'Resource Settings',
  name: 'resourceSettings',
  description: 'Resource Settings',
  component: 'group',
  fields: [
    {
      label: 'Resources',
      name: 'resources',
      component: 'group-list',
      fields: [
        {
          label: 'Resource Title',
          name: 'resourceTitle',
          component: 'text',
          defaultValue: 'Resource Title',
        },
        {
          label: 'Resource Link',
          name: 'resourceLink',
          component: 'text',
          defaultValue:
            'https://cdn.shopify.com/s/files/1/1011/0318/files/Printed_Testing_Data_Pitcher__02152023.pdf?v=1679588915',
        },
        {
          label: 'Resource Size',
          name: 'resourceSize',
          component: 'text',
          defaultValue: '1.2 MB',
        },
      ],
    },
  ],
};

const faqSettings = {
  label: 'FAQ Settings',
  name: 'faqSettings',
  description: 'FAQ Settings',
  component: 'group',
  fields: [
    {
      label: 'Faq Title',
      name: 'faqTitle',
      component: 'text',
      defaultValue: 'Help & FAQs',
    },
    {
      label: 'Faq Copy',
      name: 'faqCopy',
      component: 'markdown',
      defaultValue:
        'Still have questions? We have a great Frequently Asked Questions (FAQs) section we update regularly that may be of help. Also feel free to contact us.',
    },
    {
      label: 'Call to action',
      name: 'faqCta',
      component: 'link',
    },
  ],
};

export function Schema() {
  return {
    category: 'Text',
    label: 'Product Support',
    key: 'productSupport',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2010.47.52_20AM.png?v=1684864586',
    fields: [
      {
        label: 'Eyebrow',
        name: 'eyebrow',
        component: 'text',
        defaultValue: 'Select the product you need help with:',
      },
      {
        label: 'Resources Header',
        name: 'header',
        component: 'text',
        defaultValue: 'User Manuals & Downloads',
      },
      {
        label: 'Empty Video Text',
        name: 'emptyVideoText',
        component: 'text',
        defaultValue: 'Videos Coming Soon',
        description: 'Will Display if no videos have a link',
      },
      {
        label: 'Product Support Items',
        name: 'productSupportItems',
        component: 'group-list',
        itemProps: {
          label: '{{item.header}}',
        },
        fields: [
          {
            label: 'Header',
            name: 'header',
            component: 'text',
          },
          {
            label: 'Product image',
            name: 'productImage',
            component: 'image',
          },
          faqSettings,
          videoContent,
          resourceSettings,
        ],
      },
    ],
  };
}
