export default {
  label: 'Login',
  name: 'login',
  component: 'group',
  fields: [
    {
      label: 'Login Page Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Flyout Heading',
      name: 'flyoutHeading',
      component: 'textarea',
    },
    {
      label: 'Flyout Subeading',
      name: 'subheading',
      component: 'text',
    },
    {
      label: 'Forgot Password Text',
      name: 'forgotText',
      component: 'text',
    },
    {
      label: 'Create Account Text',
      name: 'createText',
      component: 'text',
    },
    {
      label: 'Create Account Link Text',
      name: 'createLinkText',
      component: 'text',
    },
    {
      label: 'Unidentified Customer Error Text',
      name: 'unidentifiedCustomerText',
      component: 'text',
    },
  ],
  defaultValue: {
    heading: 'Sign In',
    subheading: 'Track orders and manage your subscription in your account.',
    forgotText: 'Forgot Password',
    createText: `Don't have an account?`,
    createLinkText: 'Create Account',
    unidentifiedCustomerText: 'The email and password do not match',
  },
};
