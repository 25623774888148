import { Schema } from './LabResultsOverview.schema';
import { Body, Eyebrow, Headline, Image, Link, Svg } from '../../snippets';

export function LabResultsOverview({ cms }) {
  const {
    assetLink,
    valueProps,
    sectionTitle,
    sectionDisclosure,
    featuredImage,
  } = cms;
  return (
    <section className="lab-results-overview border-t border-[#DDE2E7] py-[62px]">
      <div className="px-contained mx-auto max-w-[1155px]">
        {
          /* Section Title */
          sectionTitle && (
            <Headline
              element="h2"
              variant="h3"
              className="lab-results__title mb-7 text-center"
            >
              {sectionTitle}
            </Headline>
          )
        }

        {
          /* Value Props */
          valueProps?.length > 0 && (
            <div className="lab-results__value-props-wrapper rounded-[17px] bg-[#D9D9D9] bg-gradient-to-l from-[#2179C2] via-[#8DCEF9] to-[#F1DCC1] p-[1px]">
              <div className="rounded-[16px] bg-[var(--clearBlue)] p-[20px] md:flex">
                {featuredImage && (
                  <div className="mx-auto flex w-full max-w-[200px] flex-[0_0_200px] items-center p-4 pl-0">
                    <div className="relative aspect-[200/208] w-full">
                      <Image
                        fill
                        alt={sectionTitle || 'Performance Data'}
                        className="object-contain mix-blend-multiply"
                        sizes="(min-width: 1440px) 300px, (min-width: 768px) 50vw, 100vw"
                        src={featuredImage?.src}
                        quality="90"
                      />
                    </div>
                  </div>
                )}

                <ul className="lab-results__value-props flex-auto border-l-[#2026351A] md:grid md:grid-cols-3 md:border-l md:pl-[20px]">
                  {valueProps.map((valueProp, index) => {
                    return (
                      <li
                        key={`value-prop-${index}`}
                        className="lab-results__value-prop border-b border-l-[#2026351A] border-b-[#2026351A] p-4 last:border-b-0 md:min-h-[125px] md:border-l md:[&:nth-child(3n+1)]:border-l-0 md:[&:nth-last-child(-n+3)]:border-b-0 "
                      >
                        {valueProp?.propTitle && (
                          <Eyebrow className="uppercase text-textLink">
                            {valueProp.propTitle}
                          </Eyebrow>
                        )}
                        {valueProp?.propValue && (
                          <Body variant="small">{valueProp.propValue}</Body>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )
        }

        {
          /* Disclosure & Asset Link */
          (sectionDisclosure || assetLink?.url) && (
            <div className="lab-results__disclosure mt-[22px] items-center justify-between gap-[22px] md:flex">
              {sectionDisclosure && (
                <Body variant="xs" className="flex-auto max-md:mb-[26px]">
                  {sectionDisclosure}
                </Body>
              )}
              {assetLink?.url && (
                <Link
                  className="flex flex-[1_0_280px] items-center justify-center rounded-[68px] border border-primary py-[16px] px-[35px] text-[17px] max-md:max-w-[212px] max-md:text-xs"
                  aria-label={assetLink?.text}
                  href={assetLink?.url}
                  newTab={assetLink?.newTab}
                >
                  <Svg
                    className="mr-[22px] h-[25px] w-[25px] text-textLink"
                    src="/svgs/noprecache/icons/download.svg#download"
                    title="Download"
                    viewBox="0 0 25 25"
                  />
                  {assetLink?.text}
                </Link>
              )}
            </div>
          )
        }
      </div>
    </section>
  );
}

LabResultsOverview.displayName = 'LabResultsOverview';
LabResultsOverview.Schema = Schema;
