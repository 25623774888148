import { COLORS } from '../../settings/common';

export const Schema = () => ({
  label: 'Hero Banner',
  key: 'hero-banner',
  category: 'Hero',
  previewSrc:
    'https://cdn.shopify.com/s/files/1/1011/0318/files/hero_banner.png?v=1686314605',
  fields: [
    {
      label: 'Eyebrow Text',
      name: 'eyebrowText',
      component: 'text',
    },
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Heading Variant',
      name: 'headingVariant',
      component: 'select',
      required: true,
      defaultValue: {
        value: 'h1',
        label: 'H1',
      },
      options: [
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
      ],
    },
    {
      label: 'Extra Top Padding',
      name: 'extraTopPadding',
      description: 'For use when navigation overlays banner',
      component: 'toggle',
      defaultValue: false,
    },
    {
      label: 'Fixed banner height (mobile)',
      name: 'mobileFixedBannerHeight',
      defaultValue: 474,
      component: 'number',
    },
    {
      label: 'Fixed heading font size (mobile)',
      name: 'mobileFixedBannerFontSize',
      component: 'number',
    },
    {
      label: 'Subheading (Desktop & Tablet)',
      name: 'subheading',
      component: 'text',
    },
    {
      label: 'Subheading (Mobile)',
      name: 'mobileSubheading',
      component: 'text',
    },
    {
      label: 'Enable Button Spacing',
      name: 'enableButtonSpacing',
      component: 'toggle',
      description: 'Add extra spacing between the subtext and the button.',
    },
    {
      label: 'Call to Action Buttons',
      name: 'ctas',
      component: 'group-list',
      description: 'Max of two buttons',
      itemProps: {
        label: '{{item.link.text}}',
      },
      validate: {
        maxItems: 2,
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
        {
          label: 'Link ID',
          name: 'linkId',
          component: 'text',
          description: 'Add the section ID you would like the button to scroll down to.',
        },
        {
          label: 'Button Theme',
          name: 'theme',
          component: 'select',
          options: [
            { label: 'Primary', value: 'primary' },
            { label: 'Secondary', value: 'secondary' },
            { label: 'Light', value: 'light' },
          ],
        },
        {
          label: 'Button Variant',
          name: 'variant',
          component: 'select',
          options: [
            { label: 'Filled', value: 'filled' },
            { label: 'Outlined', value: 'outlined' },
          ],
        },
      ],
      defaultItem: {
        link: { text: 'Shop All', url: '' },
      },
    },
    {
      label: 'Background Video URL',
      name: 'backgroundVideoUrl',
      description: 'Video will always be muted and autoplay with loop',
      component: 'text',
      required: true,
    },
    {
      label: 'Background Video URL (Mobile)',
      name: 'backgroundVideoUrlMobile',
      component: 'text',
    },
    {
      label: 'Background Image',
      name: 'backgroundImage',
      component: 'image',
    },
    {
      label: 'Background Image (Mobile)',
      name: 'backgroundImageMobile',
      component: 'image',
    },
    {
      label: 'Enable Full Width Button (Mobile)',
      name: 'enableFullWidthButton',
      description: 'Toggle full width button on mobile.',
      component: 'toggle',
      defaultValue: false,
    },
    {
      label: 'Vertical Content Alignment (desktop only)',
      name: 'verticalAlignment',
      component: 'select',
      options: [
        { label: 'Top', value: 'lg:justify-start' },
        { label: 'Center', value: 'lg:justify-center' },
        { label: 'Bottom', value: 'lg:justify-end' },
      ],
      defaultValue: 'justify-end',
    },
    {
      label: 'Horizontal Content Alignment',
      name: 'horizontalAlignment',
      component: 'select',
      options: [
        { label: 'Left', value: 'items-start' },
        { label: 'Center', value: 'text-center items-center' },
        { label: 'Right', value: 'text-right items-end' },
      ],
      defaultValue: 'items-center',
    },
    {
      label: 'Text Color',
      name: 'color',
      component: 'select',
      options: COLORS,
      defaultValue: 'var(--white)',
    },
    {
      label: 'Scroll Indicator',
      name: 'scrollIndicator',
      description: 'Show the scroll indicator',
      component: 'toggle',
      defaultValue: false,
    },
    {
      component: 'group',
      name: 'countdown',
      label: 'Countdown Timer',
      fields: [
        {
          label: 'Enable Timer',
          name: 'enable',
          description: 'Display a countdown timer on the banner',
          component: 'toggle',
          defaultValue: false,
        },
        {
          component: 'text',
          name: 'datetime',
          label: 'Date & Time',
          description: 'Must match format: MM/DD/YYYY HH:mm:ss',
          defaultValue: '07/12/2026 12:00:00',
        },
      ],
    },
    {
      component: 'group',
      name: 'signup',
      label: 'Signup Form',
      fields: [
        {
          label: 'Enable Signup Form',
          name: 'enable',
          description: 'Display a signup form on the banner',
          component: 'toggle',
          defaultValue: false,
        },
        {
          component: 'text',
          name: 'placeholder',
          label: 'Placeholder',
        },
        {
          component: 'text',
          name: 'buttonText',
          label: 'Button Text',
        },
        {
          component: 'text',
          name: 'successMessage',
          label: 'Success Message',
        },
        {
          component: 'text',
          name: 'listId',
          label: 'Klaviyo List ID',
        },
        {
          label: 'Success CTA Link',
          name: 'successCtaLink',
          component: 'link',
          description:
            '(optional) replaces the form submit button on successful submission',
        },
      ],
      defaultValue: {
        placeholder: 'Email address',
        buttonText: 'Get my Code',
        successMessage:
          'Thanks for subscribing! Your submission was received successfully.',
      },
    },
  ],
});
