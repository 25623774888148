import { useCart, useCartCount, useSettings } from '@backpackjs/storefront';

export function useBeamCartUpdate() {
  const cart = useCart();
  const cartCount = useCartCount();

  const settings = useSettings();
  const beamSettings = settings?.beam;

  const cartChangeTrigger = async () => {
    const { updateCart } = await import(
      '@beamimpact/web-sdk/dist/integrations/cart'
    );

    const BeamConfig = {
      apiKey: beamSettings.beamApiKey,
      storeId: parseInt(beamSettings.beamStoreId, 10),
      baseUrl: 'https://api.beamimpact.com',
      domain: 'clearlyfiltered.com',
    };

    const data = {
      cartId: cart.id,
      currencyCode: cart.estimatedCost.subtotalAmount.currencyCode,
      itemCount: cartCount,
      subtotal: parseFloat(cart.estimatedCost.subtotalAmount.amount),
    };

    updateCart(BeamConfig, data);
  };

  return { cartChangeTrigger };
}
