import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AltText, Body, Eyebrow, Headline, ReviewStars } from '../../snippets';
import { ArrowButton, Button } from '../../snippets/Button';
import useMediaQuery from '../../utils/useMediaQuery';
import Schema from './ReviewsSlider.schema';

export default function ReviewsSlider({ cms }) {
  const { 
    eyebrow, 
    title, 
    cta, 
    remove_linear_gradient, 
    reviews 
  } = cms;

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const isMobile = useMediaQuery('(max-width: 1023px)');

  const [renderClientSideComponent, setRenderClientSideComponent] =
    useState(false);

  useEffect(() => {
    setRenderClientSideComponent(true);
  }, []);

  return (
    <section className={`${remove_linear_gradient ? "bg-[#2179C21a]" : "bg-gradient-to-t from-[#2179C21a] to-[#F1DCC11a]"} pb-20 pt-28`}>
      <div className="mx-auto max-w-[var(--content-max-width)] text-center">
        <div className="grid gap-3 lg:mb-14">
          {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
          {title && <Headline variant="h3">{title}</Headline>}

          <div className="flex justify-center lg:hidden">
            {cta?.url && cta?.text && (
              <Button variant="filled" theme="primary" href={cta.url}>
                {cta.text}
              </Button>
            )}
          </div>
        </div>
        {renderClientSideComponent && (
          <Swiper
            modules={[Navigation]}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(_swiper) => {
              _swiper.params.navigation.prevEl = navigationPrevRef.current;
              _swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
            slidesPerView={isMobile ? 1.3 : 3}
            centeredSlides
            loop
            spaceBetween={1}
          >
            <div className="absolute left-[calc(32%)] top-0 z-10 hidden h-[80%] items-center lg:flex">
              <ArrowButton arrowDirection="left" ref={navigationPrevRef} />
            </div>
            <div className="absolute right-[calc(32%)] top-0 z-10 hidden h-[80%] items-center lg:flex">
              <ArrowButton arrowDirection="right" ref={navigationNextRef} />
            </div>
            {/*
              Q.: Why duplicate reviews array?
              A.: When using swiper with this loop and centered mode,
              the cut-off slide disappears when the loop is complete
              causing a visual glitch.
             */}
            {[...reviews, ...reviews].map((review, index) => (
              <SwiperSlide key={index} className="pt-24">
                {({ isActive }) => (
                  <>
                    <div className="mb-20 flex flex-col items-center">
                      <div
                        className="relative aspect-square w-[90%] transition-all lg:w-[calc(100%-10rem)]"
                        style={{
                          transform: isActive ? 'scale(1.1)' : 'scale(1)',
                        }}
                      >
                        {review.image && (
                          <Image
                            src={review.image.src}
                            alt={
                              review.image.alt ??
                              `Review image by ${review.name}`
                            }
                            layout="fill"
                            className="object-cover"
                          />
                        )}
                        {!isActive && (
                          <div className="absolute -bottom-6 left-0 hidden w-full text-left md:block">
                            <AltText className="!text-sm text-[#797D8699]">
                              {review.name}
                            </AltText>
                          </div>
                        )}
                      </div>
                    </div>

                    {isActive && (
                      <div className="px-contained">
                        <div className="mb-3 mt-7 flex justify-center">
                          <ReviewStars rating={review.rating} color="#2179C2" />
                        </div>

                        <Body element="div">
                          <blockquote className="min-h-[120px] text-center">
                            {review.review}
                          </blockquote>
                        </Body>

                        <div className="mt-4">
                          <AltText className="!text-sm text-[#797D8699]">
                            {review.name}
                          </AltText>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <div className="mt-10 hidden justify-center lg:flex">
          {cta?.url && cta?.text && (
            <Button variant="filled" theme="primary" href={cta.url}>
              {cta.text}
            </Button>
          )}
        </div>
      </div>
    </section>
  );
}

ReviewsSlider.displayName = 'ReviewsSlider';
ReviewsSlider.Schema = Schema;
