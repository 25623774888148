/* eslint-disable react/no-unstable-nested-components */
import { Schema } from './CumulativeImpact.schema';
import { Markdown, Headline } from '../../snippets';
import { BeamCumulativeImpact } from '../../snippets/Beam';

export function CumulativeImpact({ cms }) {
  const { title, body } = cms;

  return (
    <div className="px-contained mx-auto max-w-[960px] overflow-hidden py-12 lg:py-24">
      {(title || body) && (
        <div className="header mb-12 text-center">
          {title && (
            <Headline variant="h2" element="h1" className="mb-10 lg:mb-14">
              {title}
            </Headline>
          )}

          {body && (
            <Markdown
              components={{
                h1(data) {
                  return (
                    <Headline element="h1" variant="h2">
                      {data.children}
                    </Headline>
                  );
                },
                h2(data) {
                  return (
                    <Headline element="h2" variant="h3">
                      {data.children}
                    </Headline>
                  );
                },
                h3(data) {
                  return (
                    <Headline element="h3" variant="h4">
                      {data.children}
                    </Headline>
                  );
                },
                h4(data) {
                  return (
                    <Headline element="h4" variant="h5">
                      {data.children}
                    </Headline>
                  );
                },
                h5(data) {
                  return (
                    <Headline element="h5" variant="h6">
                      {data.children}
                    </Headline>
                  );
                },
              }}
            >
              {body}
            </Markdown>
          )}
        </div>
      )}

      <BeamCumulativeImpact />
    </div>
  );
}

CumulativeImpact.displayName = 'CumulativeImpact';
CumulativeImpact.Schema = Schema;
