import { useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Schema } from './PartnerSlider.schema';
import { Image } from '../../snippets';
import { Headline, Eyebrow, Body } from '../../snippets/Typography';

function PartnerSlide(props) {
  const { image, title, copy } = props;
  return (
    <div className="relative mx-auto max-w-[720px] rounded-lg py-12">
      <div className="absolute top-0 h-full w-full rounded-lg bg-gradient-to-r from-[#C1EFFA] to-[#8DCEF9] opacity-30" />
      {image && (
        <div className="mb-6">
          <Image
            className="mx-auto"
            sizes="48px"
            width="108px"
            height="108px"
            src={image.src}
          />
        </div>
      )}
      {!image && <div className="mb-6 h-[108px] w-[108px]" />}
      <Headline variant="h5" element="h5">
        {title}
      </Headline>
      <Body variant="large" className="mx-auto mt-4 max-w-[500px]">
        {copy}
      </Body>
    </div>
  );
}

export function PartnerSlider({ cms }) {
  const { heading, eyebrow, slider } = cms;

  const [swiper, setSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(1);

  swiper?.on('slideChange', function () {
    setActiveSlideIndex(this.realIndex + 1);
  });

  return (
    <div className="relative bg-[#F2F7FB]">
      <div className="px-contained overflow-hidden pb-16 pt-12 text-center lg:pb-24 lg:pt-24">
        <Eyebrow className="mb-4">{eyebrow}</Eyebrow>
        <Headline variant="h3" element="h3">
          {heading}
        </Headline>

        <Swiper
          onSwiper={setSwiper}
          className="!static mx-auto mt-8 w-full max-w-[760px] overflow-visible lg:mt-16"
          centeredSlides
          grabCursor
          loop
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet(_, className) {
              return `<span class="!bg-primary ${className} rounded-full w-2 max-lg:!h-2 lg:w-full lg:rounded-none max-lg:!mx-2 lg:mx-0"></span>`;
            },
          }}
          modules={[Pagination]}
          slidesPerView={1}
          spaceBetween={16}
          breakpoints={{
            1024: {
              loop: true,
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
        >
          {slider?.slideItems.map(({ image, title, copy }, index) => {
            return (
              <SwiperSlide key={index}>
                <PartnerSlide
                  image={image}
                  title={title}
                  copy={copy}
                  key={index}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="swiper-pagination swiper-progress !static mx-auto mt-8 w-[900px]  max-w-[900px] flex-nowrap lg:mt-16 lg:flex" />

        <div className="relative mx-auto hidden max-w-[940px] lg:block">
          <div className="absolute right-[-25px] top-[-13px] text-sm font-normal">
            {activeSlideIndex} / {swiper?.slides.length}
          </div>
        </div>
      </div>
    </div>
  );
}

PartnerSlider.displayName = 'Partner Slider';
PartnerSlider.Schema = Schema;
