export default {
  label: 'Beam',
  name: 'beam',
  component: 'group',
  description: 'Global Beam settings',
  fields: [
    {
      label: 'API Key',
      name: 'beamApiKey',
      component: 'text',
    },
    {
      label: 'Store ID',
      name: 'beamStoreId',
      component: 'text',
    },
    {
      label: 'Chain ID',
      name: 'beamChainId',
      component: 'text',
    },
    {
      label: 'Store Top Domain',
      name: 'beamStoreTopDomain',
      component: 'text',
    },
    {
      label: 'Session ID Cookie',
      name: 'beamSessionIdCookie',
      component: 'text',
    },
  ],
};
