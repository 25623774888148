import { useEffect, useMemo, useRef, useState } from 'react';

export default function OkendoReviewsWidget({ productId }) {
  const widgetContainer = useRef(null);
  const widgetContainer2 = useRef(null);

  const [reviewsCount, setReviewsCount] = useState('...');

  const tabs = useMemo(() => ['Reviews', 'Questions'], []);

  const [tab, setTab] = useState('Reviews');

  const initialiseWidget = () => {
    window.okeWidgetApi.initWidget(widgetContainer.current);
    window.okeWidgetApi.initWidget(widgetContainer2.current);
  };

  useEffect(() => {
    if (window.okeWidgetApi?.initWidget) {
      initialiseWidget();
    } else {
      document.addEventListener('oke-script-loaded', initialiseWidget);
    }

    const interval = setInterval(() => {
      if (reviewsCount === '...')
        setReviewsCount(
          document
            .querySelector('.oke-w-reviews-count')
            ?.textContent?.replace(/[^\d]/gi, '') || '...'
        );
    }, 1000);
    return () => {
      document.removeEventListener('oke-script-loaded', initialiseWidget);
      clearInterval(interval);
    };
  }, [productId, tab]);

  return (
    <>
      <div className="mx-auto max-w-[1200px]">
        <div className="flex gap-2">
          {tabs.map((_tab) => (
            <span
              key={_tab}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => e.key === 'Enter' && setTab(_tab)}
              onClick={() => setTab(_tab)}
              className={[
                'border-b-2 py-3 text-[#202635]',
                tab === _tab ? 'border-[#DDE2E7]' : 'border-transparent',
              ].join(' ')}
            >
              {_tab}
              {_tab === 'Reviews' && (
                <span className="ml-2 inline-block bg-[#3383C5] px-2 py-0.5 text-white">
                  {reviewsCount}
                </span>
              )}
            </span>
          ))}
        </div>
      </div>
      <div className={tab === 'Reviews' ? 'block' : 'hidden'}>
        <div
          ref={widgetContainer2}
          data-oke-widget
          data-oke-reviews-product-id={`shopify-${productId}`}
        />
      </div>

      <div className={tab === 'Questions' ? 'block' : 'hidden'}>
        <div
          data-oke-questions-widget
          ref={widgetContainer}
          data-oke-reviews-product-id={`shopify-${productId}`}
        />
      </div>
    </>
  );
}
