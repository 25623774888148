import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, EffectFade, Autoplay, Navigation } from 'swiper';
import { useSettings } from '@backpackjs/storefront';
import { Svg } from '../Svg';
import { Link } from '../Link';

export function Promobar({
  promobarDisabled,
  promobarHidden,
  setPromobarHidden,
}) {
  const settings = useSettings();
  const { promobar } = { ...settings?.header };
  const { autohide, delay, enabled, messages, speed } = {
    ...promobar,
  };

  const [promoBarCloseClick, setPromoBarCloseClick] = useState(false);

  const swiperParams = {
    autoplay: {
      delay: delay || 5000,
      disableOnInteraction: false,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    loop: messages?.length > 1,
    modules: [A11y, Autoplay, EffectFade, Navigation],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    speed: speed || 500,
    style: {
      '--swiper-navigation-color': '#FFFFFF',
      '--swiper-navigation-size': '12px',
    },
  };

  const closePromoBar = () => {
    setPromoBarCloseClick(true);
    setPromobarHidden(true);
  };

  useEffect(() => {
    const setPromobarVisibility = () => {
      if (document.body.style.position === 'fixed') return;
      setPromobarHidden(window.scrollY > 48);
    };

    if (!autohide) {
      setPromobarHidden(false);
      window.removeEventListener('scroll', setPromobarVisibility);
      return undefined;
    }

    window.addEventListener('scroll', setPromobarVisibility);
    return () => {
      window.removeEventListener('scroll', setPromobarVisibility);
    };
  }, [autohide]);

  return (
    <div
      className={`relative overflow-hidden bg-[#CCE0F1] transition-[height] ease-out print:hidden ${
        promoBarCloseClick || promobarHidden || promobarDisabled
          ? 'h-0 duration-[50ms]'
          : 'h-[var(--promobar-height)] duration-300'
      }`}
    >
      {enabled && messages?.length ? (
        <>
          <Swiper
            {...swiperParams}
            className="swiper-wrapper-center relative flex h-full items-center"
          >
            {messages.map(({ message, link }, index) => {
              return (
                <SwiperSlide key={index} className="px-4">
                  <div className="px-contained flex min-h-full items-center justify-center text-center text-xs tracking-[0.04em] text-black sm:text-sm">
                    {link?.url ? (
                      <Link
                        aria-label={message}
                        className="select-none"
                        draggable={false}
                        href={link.url}
                        newTab={link.newTab}
                      >
                        {message}
                      </Link>
                    ) : (
                      <span>{message}</span>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}

            <button
              aria-label="See previous slide"
              className="swiper-button-prev !left-4 text-black md:!left-8 xl:!left-12"
              type="button"
            />

            <button
              aria-label="See next slide"
              className="swiper-button-next !right-12 text-black md:!right-16 xl:!right-20"
              type="button"
            />
          </Swiper>

          <Svg
            className="absolute !right-4 top-1/2 z-[10] w-5 -translate-y-1/2 cursor-pointer text-black text-text md:!right-8 xl:!right-12"
            src="/svgs/close.svg#close"
            title="Close"
            viewBox="0 0 24 24"
            onClick={() => closePromoBar()}
          />
        </>
      ) : null}
    </div>
  );
}

Promobar.displayName = 'Promobar';
