import { useProductByHandle } from '@backpackjs/storefront';
import { Image } from '../Image';
import { AddToCart } from '../AddToCart';

export function RebuyUpsell({ recommendedProduct }) {
  const fullProduct = useProductByHandle({
    handle: recommendedProduct?.handle,
  });
  return (
    <li className="mb-4 border border-[#DDE2E7] p-[14px] lg:px-[22px]">
      <div className="flex flex-row items-center justify-between">
        {
          /* Image */
          recommendedProduct?.image?.src && (
            <div className="relative aspect-square w-[70px] shrink-0 bg-background lg:w-[100px]">
              <Image
                alt={recommendedProduct?.title}
                className="absolute h-full w-full object-contain !mix-blend-multiply"
                width="150px"
                height="150px"
                src={recommendedProduct?.image?.src}
              />
            </div>
          )
        }

        {/* Card Details */}
        <div className="flex-1 px-2">
          {recommendedProduct?.title && (
            <div className="text-[13px] font-light lg:text-base">
              {recommendedProduct?.title}
            </div>
          )}

          {recommendedProduct?.variants?.[0]?.price && (
            <div className="mt-1 text-[10px] lg:mt-2 lg:text-sm">
              ${recommendedProduct?.variants?.[0]?.price}
            </div>
          )}
        </div>

        {
          /* Add to Cart Button */
          recommendedProduct?.variants?.[0]?.id && (
            <div className="max-sm:mt-4 [&_button]:mt-0 [&_button]:!py-2 [&_button_p]:text-[11px] [&_p]:!mb-0">
              <AddToCart
                addToCartText="Add"
                selectedVariant={fullProduct?.product?.variants?.[0]}
              />
            </div>
          )
        }
      </div>
    </li>
  );
}
