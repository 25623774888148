import Vimeo from '@u-wave/react-vimeo';
import { useState } from 'react';
import { Image, PlayButton } from '../../snippets';
import { Headline } from '../../snippets/Typography';
import handleize from '../../utils/handleize';
import { Schema } from './ArticleVideo.schema';

export function ArticleVideo({ cms }) {
  const { video, title, anchorId, image } = cms;
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    video && (
      <div className="relative mb-8 lg:mb-16">
        {anchorId && (
          <div id={handleize(anchorId)} className="absolute top-16" />
        )}
        {title && (
          <Headline
            variant="h4"
            element="h4"
            className="border-t border-[#DDE2E7] pb-4 pt-12 lg:pb-16"
          >
            {title}
          </Headline>
        )}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="relative aspect-video cursor-pointer md:h-[430px] lg:mx-0"
          onClick={() => setIsPlaying(true)}
        >
          {isPlaying && video ? (
            <Vimeo
              video={video}
              width="100%"
              height="100%"
              autoplay
              muted
              responsive
              className="vimeo-player inset-0 h-full [&>div]:h-full [&>div]:!p-0"
            />
          ) : (
            <>
              <Image
                className="absolute mx-auto w-full"
                fill
                src={image?.src}
              />
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                <PlayButton />
              </div>
            </>
          )}
        </div>
      </div>
    )
  );
}

ArticleVideo.displayName = 'ArticleVideo';
ArticleVideo.Schema = Schema;
