export function ProductResourceDownload(props) {
  const { resourceTitle, resourceLink, resourceSize } = props;

  return (
    <div className="mb-6 flex w-full items-center justify-between border-b border-[#2026351a] pb-6">
      <p className="w-9/12 text-lg lg:w-full">{resourceTitle}</p>
      <a
        aria-label={resourceTitle}
        href={resourceLink}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-col items-center justify-center"
      >
        <svg
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M37 23.3261V34.587C37 35.2269 36.7458 35.8407 36.2932 36.2932C35.8407 36.7458 35.2269 37 34.587 37H2.41304C1.77306 37 1.1593 36.7458 0.706764 36.2932C0.25423 35.8407 0 35.2269 0 34.587V23.3261C0 23.1128 0.0847437 22.9082 0.235588 22.7573C0.386433 22.6065 0.591021 22.5217 0.804348 22.5217C1.01767 22.5217 1.22226 22.6065 1.37311 22.7573C1.52395 22.9082 1.6087 23.1128 1.6087 23.3261V34.587C1.6087 34.8003 1.69344 35.0049 1.84428 35.1557C1.99513 35.3066 2.19972 35.3913 2.41304 35.3913H34.587C34.8003 35.3913 35.0049 35.3066 35.1557 35.1557C35.3066 35.0049 35.3913 34.8003 35.3913 34.587V23.3261C35.3913 23.1128 35.476 22.9082 35.6269 22.7573C35.7777 22.6065 35.9823 22.5217 36.1957 22.5217C36.409 22.5217 36.6136 22.6065 36.7644 22.7573C36.9153 22.9082 37 23.1128 37 23.3261ZM17.9309 23.8952C18.0056 23.9699 18.0943 24.0293 18.192 24.0698C18.2896 24.1102 18.3943 24.1311 18.5 24.1311C18.6057 24.1311 18.7104 24.1102 18.808 24.0698C18.9057 24.0293 18.9944 23.9699 19.0691 23.8952L27.1126 15.8517C27.1873 15.777 27.2466 15.6882 27.287 15.5906C27.3275 15.4929 27.3483 15.3883 27.3483 15.2826C27.3483 15.1769 27.3275 15.0723 27.287 14.9746C27.2466 14.877 27.1873 14.7883 27.1126 14.7135C27.0378 14.6388 26.9491 14.5795 26.8515 14.5391C26.7538 14.4986 26.6492 14.4778 26.5435 14.4778C26.4378 14.4778 26.3331 14.4986 26.2355 14.5391C26.1379 14.5795 26.0491 14.6388 25.9744 14.7135L19.3043 21.3836V0.804348C19.3043 0.591021 19.2196 0.386433 19.0688 0.235588C18.9179 0.0847437 18.7133 0 18.5 0C18.2867 0 18.0821 0.0847437 17.9312 0.235588C17.7804 0.386433 17.6957 0.591021 17.6957 0.804348V21.3836L11.0256 14.7135C10.8747 14.5626 10.67 14.4778 10.4565 14.4778C10.2431 14.4778 10.0384 14.5626 9.88745 14.7135C9.73652 14.8645 9.65173 15.0692 9.65173 15.2826C9.65173 15.4961 9.73652 15.7008 9.88745 15.8517L17.9309 23.8952Z"
            fill="#3383C5"
          />
        </svg>
        <p className="mt-2 whitespace-nowrap text-sm font-light">
          {resourceSize}
        </p>
      </a>
    </div>
  );
}
