import { useState } from 'react';
import { Headline } from '../../snippets/Typography';
import { Accordion } from './Accordion';
import { Schema } from './Accordions.schema';

export function Accordions({ cms }) {
  const { accordions, heading } = cms;
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-[#E6F0F8]">
      <div className="px-contained py-7 md:py-12 xl:py-20">
        <div className="mx-auto max-w-[1100px]">
          {heading && (
            <Headline variant="h4" element="h4" className="pb-12">
              {heading}
            </Headline>
          )}

          <ul className="grid grid-cols-1 gap-4">
            {accordions?.map((accordion, index) => {
              return (
                <li key={index}>
                  <Accordion
                    index={index}
                    activeIndex={activeIndex}
                    accordion={accordion}
                    handleClick={handleClick}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

Accordions.displayName = 'Accordions';
Accordions.Schema = Schema;
