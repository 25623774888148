import { useMemo } from 'react';
import { useCurrency } from '@backpackjs/storefront';

import {
  useDataLayerAccount,
  useDataLayerCart,
  useDataLayerCollection,
  useDataLayerCustomer,
  useDataLayerInit,
  useDataLayerProduct,
  useDataLayerSearch,
  useDataLayerSubscribe,
} from './hooks';

// Envs to set:
// * NEXT_PUBLIC_SITE_TITLE // provides backup brand name

// DEBUG via Elevar's Data Layer Listener, add to the console:
// * Turn on: window.ElevarDebugMode(true)
// * Turn off: window.ElevarDebugMode(false)

const DEBUG = false; // logs outside of Elevar. will log even if failed elevar event

export function useDataLayer({ page: pageProp, template }) {
  const currencyCode = useCurrency();

  const page = template === 'page' ? pageProp : undefined;

  const baseEcommerce = useMemo(() => {
    if (!currencyCode) return null;
    return {
      currencyCode,
    };
  }, [currencyCode]);

  const isPageAsProduct = useMemo(() => {
    if (!page) return false;
    const productTypeSections = ['']; // section template names
    return page.sections?.some(({ _template }) =>
      productTypeSections.includes(_template)
    );
  }, [page?.id]);

  const isPageAsCollection = useMemo(() => {
    if (!page) return false;
    const colTypeSections = ['']; // section template names
    return page.sections?.some(({ _template }) =>
      colTypeSections.includes(_template)
    );
  }, [page?.id]);

  const { generateUserProperties, userProperties } = useDataLayerInit({
    DEBUG,
  });

  const { userDataEvent, userDataEventTriggered } = useDataLayerCustomer({
    baseEcommerce,
    DEBUG,
    isPageAsProduct,
    isPageAsCollection,
    userProperties,
  });

  useDataLayerAccount({
    baseEcommerce,
    DEBUG,
    generateUserProperties,
    userDataEvent,
    userDataEventTriggered,
  });

  useDataLayerCart({
    baseEcommerce,
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerProduct({
    baseEcommerce,
    DEBUG,
    userDataEvent,
    userProperties,
  });

  useDataLayerCollection({
    baseEcommerce,
    DEBUG,
    isPageAsCollection,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSearch({
    baseEcommerce,
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSubscribe({
    baseEcommerce,
    DEBUG,
    userDataEventTriggered,
  });
}
