import { useState } from 'react';

import { CollectionFilterOption } from './CollectionFilterOption';
import { Svg } from '../../Svg';

export function CollectionDropdownFilter({
  activeFilters,
  addFilter,
  defaultOpen = false,
  filter,
  removeFilter,
  showCount,
  swatchesMap,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="relative mt-4">
      <button
        aria-label={filter.label}
        className={`relative z-10 mb-4 flex h-14 w-full items-center justify-between gap-4 border-b border-[#E9E9EB] bg-white px-[20px] py-[10px] text-left font-light max-md:px-4 ${
          isOpen
            ? 'md:rounded-[68px] md:border md:border-primary'
            : 'md:rounded-[68px] md:border'
        }`}
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <h3 className="mr-[12px] text-base font-light max-md:text-sm max-md:font-[400] max-md:uppercase">
          By {filter.label}
          <span className="ml-2 inline-block w-[40px]">
            {activeFilters[filter.name]?.length
              ? `(${activeFilters[filter.name]?.length})`
              : ''}
          </span>
        </h3>
        <Svg
          className={`w-4 text-text ${
            isOpen
              ? 'rotate-[270deg] scale-x-50 scale-y-75'
              : 'rotate-90 scale-x-50 scale-y-75'
          }`}
          src="/svgs/play.svg#play"
          title="Chevron"
          viewBox="0 0 24 24"
        />
      </button>

      <ul
        className={`w-full flex-col border-[#E9E9EB] bg-white md:absolute md:-translate-y-12 md:items-start md:gap-2 md:rounded-b-[21px] md:border md:p-4 md:pt-12 ${
          isOpen ? 'flex' : 'hidden'
        }`}
      >
        {filter.values.map(({ count, value }, index) => {
          return (
            value && (
              <li key={index}>
                <CollectionFilterOption
                  activeFilters={activeFilters}
                  addFilter={addFilter}
                  count={count}
                  isColor={filter.isColor}
                  name={filter.name}
                  removeFilter={removeFilter}
                  showCount={showCount}
                  swatchesMap={swatchesMap}
                  value={value}
                />
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
}

CollectionDropdownFilter.displayName = 'CollectionDropdownFilter';
