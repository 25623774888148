export function Schema() {
  return {
    category: 'Text',
    label: 'Career Listings',
    key: 'careerListings',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2011.49.49_20AM.png?v=1684867877',
    fields: [
      {
        label: 'Career Listing',
        name: 'careerListingItems',
        component: 'group-list',
        itemProps: {
          label: '{{item.header}}',
        },
        fields: [
          {
            label: 'Header',
            name: 'header',
            component: 'text',
          },
          {
            label: 'Department',
            name: 'department',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
          {
            label: 'Job Time',
            name: 'jobTime',
            component: 'text',
          },
          {
            label: 'Job Location',
            name: 'jobLocation',
            component: 'text',
          },
        ],
        defaultValue: [
          {
            header: 'Marketing - Social Media Manager',
            department: 'DEPARTMENT',
            jobTime: 'Full-Time',
            jobLocation: 'Rancho Santa Margarita',
          },
        ],
        defaultItem: {
          header: 'Marketing - Social Media Manager',
          department: 'DEPARTMENT',
          jobTime: 'Full-Time',
          jobLocation: 'Rancho Santa Margarita',
        },
      },
    ],
  };
}
