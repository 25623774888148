import buttonLink from '../../utils/schemas/button-link';

const videoPreviewSettings = {
  label: 'Video Preview Settings',
  name: 'videoPreviewSettings',
  component: 'group',
  description: 'video preview image, cta',
  fields: [
    {
      component: 'image',
      name: 'image',
      label: 'Video Image Thumbnail',
    },
    {
      component: 'text',
      name: 'ctaText',
      label: 'Call to Action Text',
      defaultValue: 'See it in action',
    },
  ],
};

const modalSettings = {
  label: 'Modal Settings',
  name: 'modalSettings',
  component: 'group',
  description: 'modal title, video url, cta, etc.',
  fields: [
    {
      label: 'Eyebrow Text',
      name: 'eyebrowText',
      component: 'text',
    },
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Video URL',
      name: 'videoUrl',
      component: 'text',
    },
    {
      label: 'Video Background',
      name: 'videoBackground',
      component: 'image',
      description: "Used as a placeholder when the video isn't playing",
    },
    {
      component: 'markdown',
      name: 'content',
      label: 'Content',
      description: 'Content to display under video',
      defaultValue:
        '# In its simplest form, Affinity Filtration Technology leverages 2 cutting-edge components:',
    },
    {
      label: 'Call to Action Buttons',
      name: 'ctas',
      component: 'group-list',
      description: 'Max of two buttons',
      itemProps: {
        label: '{{item.link.text}}',
      },
      defaultItem: {
        link: { text: 'How Our Technology Works', url: '' },
        theme: 'primary',
        variant: 'filled',
      },
      validate: {
        maxItems: 2,
      },
      fields: buttonLink,
    },
  ],
};

const callouts = {
  component: 'group-list',
  name: 'callouts',
  label: 'Callouts',
  description: 'Callout items under section',
  fields: [
    {
      component: 'image',
      name: 'image',
      label: 'SVG Image Icon',
    },
    {
      component: 'text',
      name: 'heading',
      label: 'Heading',
    },
    {
      component: 'textarea',
      name: 'content',
      label: 'Content',
    },
  ],
  defaultValue: [
    {
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Vector-1.svg?v=1684456136',
      },
      heading: 'Contaminant Magnet',
      content:
        'Our filter targets more contaminants than the 3 leading brand name water pitchers combined.',
    },
    {
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Vector-1.svg?v=1684456136',
      },
      heading: 'Targets 365+ Contaminants',
      content:
        'Our filter targets more contaminants than the 3 leading brand name water pitchers combined.',
    },
    {
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1011/0318/files/Vector-1.svg?v=1684456136',
      },
      heading: 'Contaminant Magnet',
      content:
        'Our filter targets more contaminants than the 3 leading brand name water pitchers combined.',
    },
  ],
};

export default function Schema() {
  return {
    label: 'Filtration Technology',
    key: 'filtration-technology',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/filtration_technology.png?v=1686314381',
    category: 'Content',
    fields: [
      {
        component: 'image',
        name: 'backgroundImage',
        label: 'Background Image',
      },
      {
        label: 'Background Video URL',
        name: 'backgroundVideoUrl',
        component: 'text',
      },
      {
        label: 'Eyebrow Text',
        name: 'eyebrowText',
        component: 'text',
        defaultValue: 'Affinity Filtration Technology',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Advanced filters that set a new standard',
      },
      {
        label: 'Subheading',
        name: 'subheading',
        component: 'textarea',
        defaultValue:
          'Our breakthrough filtration technology targets up to 73x more dangerous chemicals, contaminants, & toxins than typical carbon filters.',
      },
      {
        label: 'Call to Action Buttons',
        name: 'ctas',
        component: 'group-list',
        description: 'Max of two buttons',
        itemProps: {
          label: '{{item.link.text}}',
        },
        defaultItem: {
          link: { text: 'Our Technology', url: '' },
          theme: 'primary',
          variant: 'filled',
        },
        validate: {
          maxItems: 2,
        },
        fields: buttonLink,
      },
      {
        component: 'image',
        name: 'image',
        label: 'Image',
        description: 'Image to display in middle of this section',
      },
      videoPreviewSettings,
      modalSettings,
      callouts,
    ],
  };
}
