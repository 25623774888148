import { Schema } from './NewsletterExtended.schema';
import { Image } from '../../snippets';
import { EmailForm } from './EmailForm';
import { PhoneForm } from './PhoneForm';

export function NewsletterExtended({ cms }) {
  const { image, formType, emailForm, phoneForm } = cms;

  return (
    <div className="newsletter-extended relative flex min-h-[475px] items-center justify-center lg:min-h-[800px]">
      {image?.src && (
        <div className="absolute mx-auto h-full w-full">
          <Image
            src={image.src}
            alt="icon"
            fill
            className="mx-auto object-cover"
          />
        </div>
      )}
      <div className="px-contained relative mx-auto max-w-[1200px] overflow-hidden py-16 my-4">
        <div className="absolute left-4 top-0 h-full w-full max-w-[calc(100%-2em)] bg-[#ECF7FF] lg:left-0 lg:max-w-full" />
        <div className="relative z-10">
          {formType === 'email' ? (
            <EmailForm settings={emailForm} />
          ) : (
            <PhoneForm settings={phoneForm} />
          )}
        </div>
      </div>
    </div>
  );
}

NewsletterExtended.displayName = 'NewsletterExtended';
NewsletterExtended.Schema = Schema;
