import { useCallback, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';

export function useMenuDrawer() {
  const settings = useSettings();
  const { menuItems } = { ...settings?.header?.menu };

  const [menuDrawerIndex, setMenuDrawerIndex] = useState(null);

  const clearUnHoverTimer = useCallback(() => {
    if (window.unHover) {
      clearTimeout(window.unHover);
      window.unHover = null;
    }
  }, []);

  const handleMenuDrawerClose = useCallback(() => {
    setMenuDrawerIndex(null);
  }, []);

  const handleMenuDrawerStayOpen = useCallback(() => {
    clearUnHoverTimer();
    setMenuDrawerIndex(menuDrawerIndex);
  }, [menuDrawerIndex]);

  const handleMenuHoverIn = useCallback((index) => {
    clearUnHoverTimer();
    setMenuDrawerIndex(typeof index === 'number' ? index : null);
  }, []);

  const handleMenuHoverOut = useCallback(() => {
    clearUnHoverTimer();
    window.unHover = setTimeout(() => {
      setMenuDrawerIndex(null);
      clearUnHoverTimer();
    }, 100);
  }, []);

  return {
    state: {
      menuDrawerContent:
        typeof menuDrawerIndex === 'number'
          ? menuItems?.[menuDrawerIndex] || null
          : null,
      menuDrawerIndex,
    },
    actions: {
      handleMenuDrawerClose,
      handleMenuDrawerStayOpen,
      handleMenuHoverIn,
      handleMenuHoverOut,
    },
  };
}
