import PropTypes from 'prop-types';
import React from 'react';
import { Button, Eyebrow, Headline, Image } from '../../snippets';
import Schema from './ProductComparisonTable.schema';

/**
 * @typedef Link
 * @type {object}
 * @property {string} url - The image source.
 * @property {string} text - The image alt text.
 *
 *
 * @typedef Image
 * @type {object}
 * @property {string} src - The image source.
 * @property {string} alt - The image alt text.
 *
 * @typedef HeaderItem
 * @type {object}
 * @property {string} columnHeading - The column heading text.
 * @property {Image} image - The image object.
 *
 * @typedef RowColumn
 * @type {object}
 * @property {string} background - The background color.
 * @property {string} text - The text.
 * @property {Image} image - The image object.
 */

/**
 * @typedef RowItem
 * @type {object}
 * @property {string} rowHeading - The row heading text.
 * @property {RowColumn[]} rowColumns - The row columns array.
 */

/**
 * Step carousel/slider made for product detail page.
 * @param {object} props - The props that were defined by the caller of this component.
 * @param {object} props.cms - The content that will came from PACK.
 * @param {string} props.cms.eyebrow - The eyebrow text.
 * @param {string} props.cms.headline - The headline text.
 * @param {string} props.cms.disclaimerText - The disclaimer text.
 * @param {Link} props.cms.cta - The cta object.
 * @param {HeaderItem[]} props.cms.headerItems - The header items array.
 * @param {RowItem[]} props.cms.rows - The row items array.
 *
 * @component
 */
function ProductComparisonTable({ cms }) {
  const { eyebrow, headerItems, rows, headline, cta, disclaimerText } = cms;
  return (
    <section className="my-20">
      <div className="px-contained mx-auto max-w-[var(--content-max-width)]">
        <div className="mx-auto mb-8 max-w-3xl text-center">
          <div className="mb-4">
            <Eyebrow>{eyebrow}</Eyebrow>
          </div>
          <Headline variant="h4">{headline}</Headline>
        </div>
        <div className="-mx-4 overflow-auto md:mx-0">
          <div className="grid min-w-[600px] grid-cols-4">
            {headerItems?.map((headerItem, index) => (
              <div
                key={`header-item-${index}`}
                className={[
                  'col-span-1',
                  index === 0 ? 'col-start-2' : '',
                ].join(' ')}
              >
                <div className="flex h-full flex-col items-center justify-center p-4">
                  <div className="mb-4">
                    {headerItem?.image ? (
                      <Image
                        src={headerItem.image.src}
                        alt={headerItem.image.alt}
                        width={98}
                        height={98}
                        className="mix-blend-multiply"
                        style={{
                          height: '98px',
                          width: '98px',
                          objectFit: 'contain',
                        }}
                      />
                    ) : null}
                  </div>
                  <div className="text-center">
                    <p className="text-sm font-bold text-[#202635]">
                      {headerItem.columnHeading}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className=" min-w-[600px] border border-[#DDE2E7] ">
            {rows?.map((rowItem, index) => (
              <div
                key={`row-${index}`}
                className="group grid auto-rows-max grid-cols-4"
              >
                <div className={['col-span-1'].join(' ')}>
                  <div className="h-full border-b border-[#DDE2E7] px-3 py-8 group-last:border-none lg:px-16">
                    {rowItem?.rowHeading}
                  </div>
                </div>
                {rowItem?.rowColumns?.map((rowColumn, _index) => (
                  <div
                    key={`row-item-${_index}`}
                    className={[
                      'flex justify-center border-b border-[#DDE2E7] py-8 px-3 text-center group-last:border-none lg:px-10',
                      rowColumn?.background === 'gradient'
                        ? 'border-opacity-30 text-white'
                        : '',
                    ].join(' ')}
                    style={{
                      background:
                        rowColumn?.background === 'gradient'
                          ? 'linear-gradient(270deg, #5B71A1 0%, #536896 10.94%, #4D618E 19.27%, #354974 33.84%, #354C7A 50.38%, #3C5280 65.88%, #3A4C79 77.84%, #232F54 100%)'
                          : undefined,
                    }}
                  >
                    {rowColumn?.text}
                    {rowColumn?.image && (
                      <Image
                        src={rowColumn?.image?.src}
                        alt={rowColumn?.image?.alt}
                        width={25}
                        height={25}
                        className="pointer-events-none select-none"
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <p className="my-6 text-center text-xs">{disclaimerText}</p>
        <div className="flex justify-center">
          {cta && (
            <div>
              <Button theme="primary" variant="filled" href={cta.url}>
                {cta.text}
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

ProductComparisonTable.propTypes = {
  cms: PropTypes.shape({
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    disclaimerText: PropTypes.string,
    cta: PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
    }),
    headerItems: PropTypes.arrayOf(
      PropTypes.shape({
        columnHeading: PropTypes.string,
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        }),
      })
    ),
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        rowHeading: PropTypes.string,
        rowColumns: PropTypes.arrayOf(
          PropTypes.shape({
            background: PropTypes.string,
            text: PropTypes.string,
            image: PropTypes.oneOfType([
              PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
              }),
              PropTypes.string,
            ]),
          })
        ),
      })
    ),
  }),
};

ProductComparisonTable.defaultProps = {
  cms: {
    eyebrow: '',
    headline: '',
    disclaimerText: '',
    cta: {
      url: '',
      text: '',
    },
    headerItems: [],
    rows: [],
  },
};

ProductComparisonTable.displayName = 'ProductComparisonTable';
ProductComparisonTable.Schema = Schema;

export default ProductComparisonTable;
