import React from 'react';
import { useSettings } from '@backpackjs/storefront';
import { Body } from '../../snippets/Typography';
import Headline from '../../snippets/Typography/Headline';
import { Schema } from './BlogHero.schema';
import { BlogHeroSearch } from './BlogHeroSearch';
import HeroBackground from '../HeroBanner/HeroBackground';

export function BlogHero({ cms }) {
  const settings = useSettings();
  const { categories } = { ...settings?.blog };

  const {
    copy,
    heading,
    backgroundVideoUrl,
    backgroundVideoUrlMobile,
    backgroundImageMobile,
    backgroundImage,
  } = cms;

  return (
    <section className="relative h-[600px] bg-primary text-white md:h-[750px]">
      <div className="bg-content absolute inset-0 z-[1] h-full w-full bg-orange-300/10">
        <HeroBackground
          image={backgroundImage}
          mobileImage={backgroundImageMobile}
          video={backgroundVideoUrl}
          mobileVideo={backgroundVideoUrlMobile}
        />
      </div>
      <div className="px-contained lg:text-eye relative z-[2] flex h-full w-full max-w-[--var(content-max-width)] flex-col justify-between gap-y-4 pt-12 pb-8 md:pt-28 md:pb-12">
        <div className="flex h-full flex-col justify-center pt-14 lg:pt-0">
          <Headline
            variant="h1"
            element="h2"
            className="mx-auto mb-8 max-w-[785px] text-center"
          >
            {heading}
          </Headline>
          <Body className="mx-auto max-w-[900px] text-center">{copy}</Body>
        </div>

        <BlogHeroSearch categories={categories} />
      </div>
    </section>
  );
}

BlogHero.displayName = 'Blog Hero';
BlogHero.Schema = Schema;
