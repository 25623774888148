export const Schema = () => ({
  category: 'Article',
  label: 'Article References',
  key: 'articleReferences',
  //   previewSrc:
  //     'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-24_20at_202.55.23_20PM.png?v=1684965343',
  fields: [
    {
      name: 'title',
      component: 'text',
      label: 'Title',
      description: 'Leave Blank to hide from Table of Contents',
    },
    {
      name: 'anchorId',
      component: 'text',
      label: 'Table Of Contents Title',
      description: 'Leave Blank to hide from Table of Contents',
    },
    {
      label: 'References',
      name: 'refrerences',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      fields: [
        {
          label: 'External reference',
          name: 'link',
          component: 'link',
        },
        {
          label: 'Text only reference',
          name: 'text',
          component: 'text',
          description: 'Will replace external reference if filled',
        },
      ],
      defaultItem: {
        link: {
          text: 'US EPA. National Primary Drinking Water Regulations. Accessed September 2, 2019',
          url: 'https://www.epa.gov/ground-water-and-drinking-water/national-primary-drinking-water-regulations',
        },
      },
    },
  ],
});
