import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReviewStars } from '../ReviewStars';

export function ProductStars({ legacyResourceId }) {
  const [reviewAggregate, setReviewAggregate] = useState(null);

  const fetchReviewAggregate = useCallback(async (productId) => {
    try {
      if (!productId) return;

      const endpoint = '/api/reviews';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'getProductReviewAggregate',
          productId,
        }),
      };

      const response = await fetch(endpoint, options);
      const data = await response.json();
      if (!data) {
        throw new Error('No data returned from fetchReviewAggregate');
      }
      setReviewAggregate(data);
    } catch (error) {
      console.error(`fetchReviewAggregate error: ${error.message}`);
      throw error;
    }
  }, []);

  useEffect(() => {
    // // api is for reference purposes only
    // fetchReviewAggregate(legacyResourceId);

    // mock data in lieu of fetchReviewAggregate data
    setReviewAggregate({
      rating: 4.7,
      count: 105,
    });
  }, [legacyResourceId]);

  return (
    <div className="flex min-h-[1rem] flex-wrap items-center gap-1">
      {reviewAggregate && (
        <>
          <ReviewStars
            color="var(--secondary)"
            rating={reviewAggregate.rating}
            size="small"
          />

          <p className="ml-1 text-sm font-light text-primary">
            {reviewAggregate.count} Reviews
          </p>
        </>
      )}
    </div>
  );
}

ProductStars.displayName = 'ProductStars';
ProductStars.propTypes = {
  legacyResourceId: PropTypes.string,
};
