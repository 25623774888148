import { useSettings } from '@backpackjs/storefront';

import { EmailSignup } from './EmailSignup';
import { FooterLegal } from './FooterLegal';
import { FooterMenu } from './FooterMenu';
import { FooterSocial } from './FooterSocial';
import { FooterQuizWidget } from './FooterQuizWidget';

export function Footer() {
  const settings = useSettings();
  const { bgColor, textColor } = { ...settings?.footer };

  return (
    <footer
      id="footer"
      className="px-contained print:hidden"
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <div id="footer-top" className="pb-[40px] pt-[50px] lg:py-[120px]">
        <EmailSignup includeFooterContent />
      </div>
      <div id="footer-bottom" className="flex flex-wrap justify-between">
        <div id="footer-menu" className="flex-[0_0_100%] lg:flex-[0_0_75%]">
          <FooterMenu />
        </div>

        <div id="footer-quiz" className="hidden lg:block lg:flex-[0_0_25%]">
          <FooterQuizWidget />
        </div>
      </div>
      <div
        id="footer-legal"
        className="font flex flex-wrap justify-between py-[27px] font-light md:mt-[66px] md:border-t md:border-t-[#DDE2E71A]"
      >
        <div className="lg:order-0 order-1 flex flex-[0_0_100%] lg:flex-[0_0_75%] lg:justify-start">
          <FooterLegal />
        </div>
        <div className="order-0 mb-6 flex flex-[0_0_100%] lg:order-1 lg:mb-0 lg:flex-[0_0_25%] lg:justify-end">
          <FooterSocial />
        </div>
      </div>
    </footer>
  );
}

Footer.displayName = 'Footer';
