import PropTypes from 'prop-types';
import React from 'react';

export default function Headline(props) {
  const { variant, element, className, children, onClick, style } = props;

  const Element = element || variant;

  const variants = {
    h1: 'text-[2.625rem] leading-[2.625rem] lg:text-[4rem] lg:leading-[4.8rem]',
    h2: 'text-[1.875rem] leading-[2.1rem] lg:text-[3.5rem] lg:leading-[4.55rem]',
    h3: 'text-[1.625rem] leading-[2.113rem] lg:text-[2.625rem] lg:leading-[3.413rem]',
    h4: 'text-[1.375rem] leading-[1.788rem] lg:text-[2.25rem] lg:leading-[2.925rem]',
    h5: 'text-[1.25rem] leading-[1.5rem] lg:text-[1.75rem] lg:leading-[2.275rem]',
    h6: 'text-[1.125rem] leading-[1.5rem] lg:text-[1.25rem] lg:leading-[1.75rem]',
  };

  return (
    <Element
      onClick={onClick}
      className={`font-sans font-light tracking-[-0.02em] ${variants[variant]} ${className}`}
      style={style}
    >
      {children}
    </Element>
  );
}

Headline.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  children: PropTypes.node.isRequired,
};
