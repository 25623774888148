import Image from 'next/image';
import { Button, Link } from '../../snippets';
import { AltText, Headline } from '../../snippets/Typography';
import Schema from './DynamicBlogList.schema';

import articleList from '../../../public/json/articles-list.json'; // eslint-disable-line

export default function DynamicBlogList({ cms }) {
  const { title, viewAllLink, articleHandles = [] } = cms;

  const articles = articleHandles.reduce((reducer, articleHandle) => {
    const article = articleList.find(({ handle }) => handle === articleHandle);
    if (article) {
      const articleTime =
        article.tags
          .find((tag) => tag.includes('minutes:'))
          ?.replace(/\D/g, '') || '';

      reducer.push({
        title: article.title,
        blog: article.blog,
        articleTime,
        image: {
          src: article.seo.image,
          alt: article.title,
        },
        link: {
          url: `/articles/${article.handle}`,
        },
      });
    }

    return reducer;
  }, []);

  return (
    <section className="my-20 print:hidden">
      <div className="px-contained mx-auto max-w-[var(--content-max-width)]">
        <div className="mb-10 flex justify-between">
          {title && <Headline variant="h3">{title}</Headline>}
          {viewAllLink?.text && viewAllLink?.url && (
            <div className="hidden lg:block">
              <Button
                href={viewAllLink.url}
                variant="outlined"
                theme="secondary"
              >
                {viewAllLink.text}
              </Button>
            </div>
          )}
        </div>

        <div className="grid gap-6 gap-y-10 lg:grid-cols-3">
          {articles?.map((article, index) => (
            <Link key={`blog-card-${index}`} href={article.link?.url}>
              <div key={article.link?.url} className="group">
                {article.image?.src && (
                  <div className="relative aspect-[353/255] w-full md:aspect-square [&>span]:!h-full [&>span]:!w-full">
                    <Image
                      src={article.image.src}
                      alt={article.image.alt ?? article.title}
                      width={464}
                      height={464}
                      quality={75}
                      style={{ objectFit: 'cover', width: 100 }}
                      priority
                      className="object-cover"
                    />
                  </div>
                )}

                <div className="my-4 h-[1px] bg-[#20263533] transition-all group-hover:bg-gradient-to-r group-hover:from-[#F1DCC1] group-hover:to-[#2179C2]" />

                <div className="mb-2">
                  <AltText className="uppercase">
                    {article?.blog?.title}
                    {article?.articleTime && (
                      <span className="text-shadowGray">
                        • {article.articleTime} Min Read
                      </span>
                    )}
                  </AltText>
                </div>

                <Headline variant="h5">{article.title}</Headline>
              </div>
            </Link>
          ))}
          {viewAllLink?.text && viewAllLink?.url && (
            <div className="flex justify-center lg:hidden">
              <Button href={viewAllLink.url} variant="filled" theme="primary">
                {viewAllLink.text}
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

DynamicBlogList.displayName = 'DynamicBlogList';
DynamicBlogList.Schema = Schema;
