import { Image } from '../../snippets';
import Button from '../../snippets/Button/Button';
import { Body, Eyebrow, Headline } from '../../snippets/Typography';
import useMediaQuery from '../../utils/useMediaQuery';
import { Schema } from './FiftyFiftyImage.schema';

export function FiftyFiftyImage({ cms }) {
  const {
    imageFlipped,
    darkMode,
    heading,
    eyebrow,
    copy,
    ctaCopy,
    ctaUrl,
    image,
    imageEyebrow,
    imageHeading,
    ctaVariant,
    ctaTheme,
  } = cms;

  let textColor = 'text-[#202635]';
  let bgColor = 'bg-white';
  let buttonTheme = 'primary';

  if (darkMode) {
    textColor = 'text-[#fff]';
    bgColor = 'bg-[#202635]';
    buttonTheme = 'light';
  }

  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <div className={`relative ${bgColor}`}>
      <div
        className={`px-contained items-center overflow-hidden py-12 lg:flex lg:py-24 ${
          imageFlipped ? 'flex-row-reverse' : null
        }`}
      >
        {image && (
          <div className="relative flex aspect-square items-center justify-center lg:w-1/2">
            <Image
              className="absolute inset-0 h-full w-full object-cover object-center"
              sizes="48px"
              width="820px"
              height="725px"
              src={image.src}
            />
            <div className="relative w-full p-4 text-center">
              {imageEyebrow && (
                <Eyebrow className="mb-4 text-[12px] uppercase text-white md:text-[16px]">
                  {imageEyebrow}
                </Eyebrow>
              )}
              {imageHeading && (
                <div className="mx-auto max-w-[180px] rounded-[100px] border border-white p-2 text-[22px] font-light leading-none text-white md:max-w-[310px] md:text-[42px]">
                  {imageHeading}
                </div>
              )}
            </div>
          </div>
        )}

        <div
          className={`lg:w-1/2  ${
            imageFlipped ? 'lg:pr-24' : 'max-sm:pt-4 lg:pl-24'
          }`}
        >
          <Eyebrow className={`mb-4 ${textColor}`}>{eyebrow}</Eyebrow>
          <Headline
            variant={isMobile ? 'h4' : 'h3'}
            className={`${textColor}`}
            element="h3"
          >
            {heading}
          </Headline>
          <Body
            variant="medium"
            className={`mx-auto mb-6 mt-6 lg:mb-12 ${textColor}`}
          >
            {copy}
          </Body>
          {ctaCopy && ctaUrl && (
            <div className="mt-0 lg:mt-6">
              <Button
                href={ctaUrl}
                theme={ctaTheme || buttonTheme}
                variant={ctaVariant || 'outlined'}
              >
                {ctaCopy}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

FiftyFiftyImage.displayName = 'Fifty Fifty Image';
FiftyFiftyImage.Schema = Schema;
