import Vimeo from '@u-wave/react-vimeo';
import { useState } from 'react';
import {
  Body,
  Eyebrow,
  Headline,
  Image,
  Markdown,
  PlayButton,
} from '../../snippets';
import Schema from './VideoBanner.schema';

export default function VideoBanner({ cms }) {
  const { eyebrow, headline, content, videoUrl, image } = cms;
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <section className="bg-[#202635] py-20 text-[#FFFFFF]">
      <div className="px-contained mx-auto max-w-[var(--content-max-width)] space-y-10">
        <div className="mx-auto max-w-[55.75rem] space-y-6 text-center">
          <Eyebrow>{eyebrow}</Eyebrow>
          <Headline variant="h3">{headline}</Headline>
          <Body variant="medium" element="div">
            <Markdown>{content}</Markdown>
          </Body>
        </div>

        <div className="video group relative mx-auto aspect-video lg:w-2/3">
          <div
            className="relative h-full cursor-pointer lg:mx-0"
            onClick={() => setIsPlaying(true)}
            tabIndex={0}
            onKeyPress={(e) => e.key === 'Enter' && setIsPlaying(true)}
            role="button"
          >
            {isPlaying && videoUrl ? (
              <Vimeo
                video={videoUrl}
                width="100%"
                height="100%"
                autoplay
                muted
                responsive
                className="vimeo-player absolute inset-0"
              />
            ) : (
              <>
                {image?.src && (
                  <Image
                    className="absolute mx-auto w-full"
                    fill
                    src={image?.src}
                  />
                )}
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                  <PlayButton />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

VideoBanner.Schema = Schema;
