import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';
import PropTypes from 'prop-types';

import { CollectionFilters } from './CollectionFilters';
import { CollectionGrid } from './CollectionGrid';
import { CollectionSort } from './CollectionSort';
import { useCollectionFilters } from './useCollectionFilters';
import { useCollectionProducts } from './useCollectionProducts';
import { useColorSwatches } from '../../hooks';

export function Collection({
  handle,
  products,
  showHeading,
  emptyCollectionProducts,
}) {
  const { swatchesMap } = useColorSwatches();
  const settings = useSettings();
  const {
    filters: filtersSettings,
    pagination,
    promotion,
  } = { ...settings?.collection };

  const isSearchPage = handle === 'search';
  const enabledFilters = isSearchPage ? false : filtersSettings?.enabled;
  const id = isSearchPage ? products : handle;

  const promoTiles = useMemo(() => {
    if (!promotion?.campaigns?.length) return null;
    const campaign = promotion.campaigns.find(({ collections }) => {
      return collections?.some((colHandle) => colHandle.trim() === handle);
    });
    return campaign?.promoTiles || null;
  }, [handle, promotion?.campaigns]);

  const collectionFiltersData = useCollectionFilters({
    enabledFilters,
    id,
    products,
  });

  const collectionProductsData = useCollectionProducts({
    activeFilters: collectionFiltersData.state.activeFilters,
    id,
    pagination,
    products,
    promoTiles,
  });

  // Sort the Products By Product Type
  const unSortedProducts = [...collectionProductsData.state.filteredProducts];
  let sortedProducts = unSortedProducts.sort((a, b) => {
    if (a.productType < b.productType) return 1;
    if (a.productType > b.productType) return -1;
    return 0;
  });

  // Remove Products that do not contain FiltrationSystem:: tags for filtering, or are subscriptions
  sortedProducts = sortedProducts.filter((product) => {
    return (
      product?.tags?.length &&
      !product?.tags?.includes('Subscription') &&
      product?.publishedAt !== null
    );
  });

  // Set our collection's filteredProducts state to our freshly sorted group of products
  collectionProductsData.state.filteredProducts = sortedProducts;

  return (
    <div className="mx-auto grid">
      <div className="z-10">
        {enabledFilters && (
          <div className=" pb-4 md:pb-8">
            <CollectionFilters
              collectionFiltersData={collectionFiltersData}
              collectionCount={
                collectionProductsData?.state?.filteredProducts?.length ||
                products?.length
              }
              swatchesMap={swatchesMap}
            />
          </div>
        )}
      </div>

      <div className="relative">
        {isSearchPage && emptyCollectionProducts ? (
          <CollectionSort
            selectedSort={collectionProductsData.state.selectedSort}
            selectSort={collectionProductsData.actions.selectSort}
          />
        ) : null}

        <CollectionGrid
          showHeading={showHeading}
          activeFilters={collectionFiltersData.state.activeFilters}
          collectionProductsData={collectionProductsData}
          enabledFilters={enabledFilters}
          isSearchPage={isSearchPage}
          promoTiles={promoTiles}
          swatchesMap={swatchesMap}
        />
      </div>
    </div>
  );
}

Collection.displayName = 'Collection';
Collection.propTypes = {
  handle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
};
