export default {
  label: 'Search',
  name: 'search',
  component: 'group',
  description: 'Search results, autocomplete, suggestions',
  fields: [
    {
      label: 'Search Results',
      name: 'results',
      component: 'group',
      fields: [
        {
          label: 'Product Search Enabled',
          name: 'productsEnabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Collection Search Enabled',
          name: 'collectionsEnabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Page Search Enabled',
          name: 'pagessEnabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'No Results Text',
          name: 'noResultsText',
          component: 'text',
        },
      ],
      defaultValue: {
        productsEnabled: true,
        collectionsEnabled: true,
        noResultsText: 'No results found.',
      },
    },
    {
      label: 'Search Autocomplete',
      name: 'autocomplete',
      component: 'group',
      description:
        'Autogenerated list of suggested search terms matching current input',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Limit',
          name: 'limit',
          component: 'number',
        },
      ],
      defaultValue: {
        enabled: true,
        heading: 'Suggested Searches....',
        limit: 5,
      },
    },
    {
      label: 'Search Suggestions',
      name: 'suggestions',
      component: 'group',
      description:
        'Preset list of suggested search terms visible only when there are no results or search input',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Terms',
          name: 'terms',
          component: 'list',
          field: {
            component: 'text',
          },
        },
      ],
      defaultValue: {
        enabled: true,
        heading: 'Suggested Searches....',
        terms: ['Pitchers', 'Water Bottles', 'Fridge Filters'],
      },
    },
  ],
};
