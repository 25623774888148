import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useCartAddDiscount } from '@backpackjs/storefront';

import { useVariantPrices } from '../../hooks';
import { Button } from '../Button';
import { Link } from '../Link';
import { Spinner } from '../Spinner';
import { BackInStockModal } from './BackInStockModal';
import { useAddToCart } from './useAddToCart';

export function AddToCart({
  addToCartText,
  className,
  discountCode,
  isPdp,
  selectedVariant,
  selectedSellingPlan,
  purchaseType,
  productType,
  product,
  showPrice,
  minPrice,
  oosDisclaimer,
  oosDisclaimerColor,
  oosDisclaimerUrl,
  oosDisclaimerOpenNewTab,
  disclaimer,
  disclaimerColor,
  disclaimerUrl,
  disclaimerOpenNewTab,
}) {
  const { cartAddDiscount, ...addDiscountStatus } = useCartAddDiscount();

  const { price } = useVariantPrices({
    variant: selectedVariant,
    purchaseType,
    isPdp,
  });

  const {
    state: { buttonText, isAdding, isLoading, isNotifyMe, isSoldOut, subtext },
    actions: { handleAddToCart, handleNotifyMe },
  } = useAddToCart({
    addToCartText,
    selectedVariant,
    selectedSellingPlan,
    purchaseType,
    showPrice,
    price,
  });

  const addToCart = async () => {
    if (discountCode) {
      await cartAddDiscount({
        discountCode,
      });
    }

    await handleAddToCart();
  };

  const [cursorClass, setCursorClass] = useState('');
  useEffect(() => {
    if (isAdding) {
      setCursorClass('cursor-default');
    } else if (isLoading) {
      setCursorClass('cursor-wait');
    } else if ((isSoldOut && !isNotifyMe) || selectedVariant === null) {
      setCursorClass('cursor-not-allowed');
    } else {
      setCursorClass('');
    }
  }, [isAdding, isLoading, isSoldOut, isNotifyMe, selectedVariant]);

  const [displayText, setDisplayText] = useState('');
  useEffect(() => {
    if (!buttonText || selectedVariant === null) {
      setDisplayText('Unavailable');
      return;
    }

    if (
      ['oneTimeOnly', 'featured'].includes(productType) &&
      minPrice &&
      !isSoldOut
    ) {
      setDisplayText(`${buttonText} — ${minPrice}`);
      return;
    }

    setDisplayText(buttonText);
  }, [selectedVariant, minPrice, buttonText]);

  return (
    <div className="add-to-cart">
      <Button
        theme={`${isNotifyMe || isSoldOut ? 'secondary' : 'primary'}`}
        variant="filled"
        aria-label={buttonText}
        className={`add-to-cart-button relative mt-[40px] w-full max-md:h-[54px]  max-md:p-[10px_34px_13px] max-md:!text-[16px] ${cursorClass} ${className}`}
        disabled={isSoldOut && !isNotifyMe}
        onClick={() => {
          if (isNotifyMe && selectedVariant !== null) {
            handleNotifyMe(
              <BackInStockModal
                selectedVariant={selectedVariant}
                product={product}
              />
            );
          } else {
            addToCart();
          }
        }}
        type="button"
      >
        {isAdding ? (
          <Spinner />
        ) : (
          <p
            className={`transition ${
              isLoading ? 'opacity-30' : ''
            } max-md:h-[20px] max-md:!font-[400]`}
          >
            {displayText}
          </p>
        )}
      </Button>

      {isPdp && subtext && (
        <p className="add-to-cart__subtext mt-1 text-center text-xs">
          {subtext}
        </p>
      )}

      {isPdp && isSoldOut && (
        <div className="add-to-cart__disclaimer mt-[20px] flex items-center justify-center text-center text-[14px]">
          <div
            className="mr-[10px] h-[10px] w-[10px] rounded-full"
            style={{ backgroundColor: `${oosDisclaimerColor ?? '#F1DCC1'}` }}
          />
          {oosDisclaimerUrl ? (
            <Link
              className="underline"
              href={oosDisclaimerUrl}
              target={oosDisclaimerOpenNewTab ? '_blank' : '_self'}
            >
              {oosDisclaimer}
            </Link>
          ) : (
            oosDisclaimer
          )}
        </div>
      )}
      {isPdp && !isSoldOut && (
        <div className="add-to-cart__disclaimer mt-[20px] flex items-center justify-center text-center text-[14px]">
          <div
            className="mr-[10px] h-[10px] w-[10px] rounded-full"
            style={{ backgroundColor: `${disclaimerColor ?? '#F1DCC1'}` }}
          />
          {disclaimerUrl ? (
            <Link
              className="underline"
              href={disclaimerUrl}
              target={disclaimerOpenNewTab ? '_blank' : '_self'}
            >
              {disclaimer}
            </Link>
          ) : (
            disclaimer
          )}
        </div>
      )}
    </div>
  );
}

AddToCart.displayName = 'AddToCart';
AddToCart.propTypes = {
  addToCartText: PropTypes.string,
  className: PropTypes.string,
  isPdp: PropTypes.bool,
  selectedVariant: PropTypes.object,
};
