import { Schema } from './CareerBlock.schema';
import { Image } from '../../snippets';
import Button from '../../snippets/Button/Button';
import { Headline, Eyebrow, Body } from '../../snippets/Typography';

export function CareerBlock({ cms }) {
  const { heading, eyebrow, copy, cta, media } = cms;

  return (
    <div className="relative bg-accent3Opacity20">
      <div className="absolute inset-0 bg-[#D9D9D9] bg-gradient-to-t  from-[#2179C2] via-[#8DCEF9] to-[#F1DCC1] text-center opacity-20" />
      <div className="px-contained relative overflow-hidden py-72 text-center">
        <div className="p-8">
          <Eyebrow className="mb-4">{eyebrow}</Eyebrow>
          <Headline variant="h3" element="h3">
            {heading}
          </Headline>
          <Body
            variant="medium"
            className="mx-auto mb-6 mt-6 max-w-2xl lg:mb-12"
          >
            {copy}
          </Body>
          {cta && (
            <div className="mt-0 lg:mt-6">
              <Button href={cta?.url} theme="primary" variant="filled">
                {cta?.text}
              </Button>
            </div>
          )}
        </div>
        {media?.image1 && (
          <>
            <div className="absolute top-10 lg:hidden">
              <Image
                className=""
                sizes="48px"
                width="170px"
                height="96px"
                src={media.image1.src}
              />
            </div>
            <div className="absolute top-20 hidden lg:block">
              <Image
                className=""
                sizes="48px"
                width="369px"
                height="208px"
                src={media.image1.src}
              />
            </div>
          </>
        )}

        {media?.image2 && (
          <>
            <div className="absolute right-4 top-40 lg:hidden">
              <Image
                className=""
                sizes="48px"
                width="170px"
                height="96px"
                src={media.image2.src}
              />
            </div>
            <div className="absolute right-44 top-20 hidden lg:block">
              <Image
                className=""
                sizes="48px"
                width="369px"
                height="208px"
                src={media.image2.src}
              />
            </div>
          </>
        )}

        {media?.image3 && (
          <>
            <div className="absolute bottom-40 left-4 lg:hidden">
              <Image
                className=""
                sizes="48px"
                width="170px"
                height="96px"
                src={media.image3.src}
              />
            </div>
            <div className="absolute bottom-20 left-44 hidden lg:block">
              <Image
                className=""
                sizes="48px"
                width="369px"
                height="208px"
                src={media.image3.src}
              />
            </div>
          </>
        )}

        {media?.image4 && (
          <>
            <div className="absolute bottom-8 right-4 lg:hidden">
              <Image
                className=""
                sizes="48px"
                width="170px"
                height="96px"
                src={media.image4.src}
              />
            </div>
            <div className="absolute bottom-20 right-16 hidden lg:block">
              <Image
                className=""
                sizes="48px"
                width="369px"
                height="208px"
                src={media.image4.src}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

CareerBlock.displayName = 'Career Block';
CareerBlock.Schema = Schema;
