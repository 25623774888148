import { Button, Headline, Link, ProductItem } from '../../snippets';
import { Schema } from './FeaturedProducts.schema';

export function FeaturedProducts({ cms }) {
  const { sectionTitle, sectionLink, featuredProducts } = { ...cms };
  return (
    <section className="featured-products px-contained py-[32px] md:py-[62px]">
      {
        /* Section Header */
        (sectionTitle || sectionLink?.url) && (
          <div className="featured-products__header flex items-center justify-between">
            <Headline element="h3" variant="h3" className="title">
              {sectionTitle}
            </Headline>
            {sectionLink?.url && (
              <Link
                aria-label={sectionLink?.text}
                href={sectionLink?.url}
                newTab={sectionLink?.newTab}
                tabIndex="-1"
              >
                <Button theme="dark" variant="outlined">
                  {sectionLink?.text}
                </Button>
              </Link>
            )}
          </div>
        )
      }

      {
        /* Featured Products */
        featuredProducts?.length > 0 && (
          <div className="featured-products__grid mt-[53px] grid grid-cols-2 gap-[18px] lg:grid-cols-4">
            {featuredProducts.map((featuredProduct, index) => {
              return (
                <div key={`product-grid-item-${index}`}>
                  <ProductItem
                    enabledColorSelector
                    enabledQuickShop={false}
                    enabledStarRating
                    handle={featuredProduct?.featuredProduct?.handle}
                    index={index}
                  />
                </div>
              );
            })}
          </div>
        )
      }
    </section>
  );
}

FeaturedProducts.displayName = 'FeaturedProducts';
FeaturedProducts.Schema = Schema;
