import { useMemo } from 'react';
import equal from 'fast-deep-equal';
import { useSettings } from '@backpackjs/storefront';

import { Image, Link } from '../../../snippets';

function ColorOptionValue({ isSelected, swatch, value }) {
  return (
    <div
      className={`swatch-selector relative flex h-8 w-8 items-center justify-center overflow-hidden rounded-[50%] transition md:hover:border-text ${
        isSelected ? 'border border-text' : ''
      }`}
      style={{
        backgroundColor: swatch?.color,
      }}
    >
      {swatch?.image?.src && (
        <Image
          alt={value}
          className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
          height="32"
          src={swatch.image.src}
          width="32"
        />
      )}

      <div
        className={`absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 rounded-[50%] border-clearBlue transition-[border-width] duration-100 ${
          isSelected ? 'border-[3px]' : 'border-[0px]'
        }`}
      />
    </div>
  );
}

export function ProductColorOptionValue({
  inventory,
  isSelected,
  newSelectedOptions,
  product,
  setSelectedOptions,
  value,
}) {
  const settings = useSettings();
  const swatches = settings?.product?.colors?.swatches;
  const isFromGrouping = product.grouping?.isTransformed;

  const variantFromOptionValue = useMemo(() => {
    return product.variants?.find(({ selectedOptionsMap }) => {
      return equal(newSelectedOptions, selectedOptionsMap);
    });
  }, [newSelectedOptions, product.variants]);

  const optionValueIsAvailable =
    inventory?.variants?.[variantFromOptionValue?.id]?.availableForSale ||
    false;

  const swatch = useMemo(() => {
    if (!swatches) return null;
    return (
      swatches.find(
        ({ name }) => name?.trim().toLowerCase() === value.toLowerCase()
      ) || null
    );
  }, [swatches, value]);

  const url = useMemo(() => {
    if (!isFromGrouping) return null;

    const selectedVariantFromOptions = product.grouping.variants.find(
      ({ selectedOptionsMap }) => {
        return equal(newSelectedOptions, selectedOptionsMap);
      }
    );
    if (!selectedVariantFromOptions) return null;

    let params = '';
    if (typeof window !== 'undefined') {
      const { search } = window.location;
      params = new URLSearchParams(search);
      params.set('variant', selectedVariantFromOptions.legacyResourceId);
    }

    return `/products/${selectedVariantFromOptions.product.handle}?${params}`;
  }, [isFromGrouping, newSelectedOptions]);

  return isFromGrouping ? (
    <li className="product-option-item">
      <Link
        aria-label={value}
        href={url}
        onClick={(e) => {
          if (isSelected) e.preventDefault();
        }}
        scroll={false}
      >
        <ColorOptionValue
          isSelected={isSelected}
          swatch={swatch}
          value={value}
        />
      </Link>
    </li>
  ) : (
    variantFromOptionValue && (
      <li className="product-option-item">
        <button
          aria-label={value}
          onClick={() => {
            if (isSelected) return;
            setSelectedOptions(newSelectedOptions);
          }}
          className={`relative
            ${
              isSelected && !optionValueIsAvailable
                ? '[&>div]:!border-error'
                : ''
            }
            ${
              !optionValueIsAvailable
                ? 'after:absolute after:left-0 after:right-0 after:top-1/2 after:h-[2px] after:-translate-y-[1px] after:-rotate-45 after:scale-90 after:bg-clearBlue after:content-[""]'
                : ''
            }
          `}
          type="button"
        >
          <ColorOptionValue
            isSelected={isSelected}
            swatch={swatch}
            value={value}
          />
        </button>
      </li>
    )
  );
}

ProductColorOptionValue.displayName = 'ProductColorOptionValue';
ColorOptionValue.displayName = 'ColorOptionValue';
