const collection = {
  label: 'Collection',
  name: 'collection',
  fields: [
    {
      label: 'Name',
      name: 'name',
      component: 'text',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
    },
    {
      label: 'Link',
      name: 'link',
      component: 'link',
    },
  ],
};

export default function Schema() {
  return {
    label: 'Collections List',
    key: 'collections-list',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/collections_list.png?v=1686314380',
    category: 'Collection',
    fields: [
      {
        label: 'Dark mode',
        name: 'darkMode',
        component: 'toggle',
        description: 'Use dark mode for this',
        defaultValue: false,
      },
      {
        label: 'Collections',
        name: 'collections',
        component: 'group-list',
        itemProps: ({ id: key, name }) => ({
          label: name,
          key,
        }),
        fields: collection.fields,
      },
    ],
  };
}
