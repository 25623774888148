import { useProductByHandle } from '@backpackjs/storefront';
import { useEffect, useState } from 'react';
import { useColorSwatches } from '../../hooks';
import { Body, Button, Eyebrow, Headline, Link, Image } from '../../snippets';
import Carousel from '../../snippets/Carousel/Carousel';
import { ColorVariantSelector } from '../../snippets/ProductItem/ColorVariantSelector';
import { ProductItemMedia } from '../../snippets/ProductItem/ProductItemMedia/ProductItemMedia';
import useMediaQuery from '../../utils/useMediaQuery';
import Schema from './ProductPromoBannerCarousel.schema';

function Banner({ videoUrl, image, offer }) {
  const { swatchesMap } = useColorSwatches();

  const { product } = useProductByHandle({
    handle: offer?.productName?.handle,
  });

  const [variantFromColor, setVariantFromColor] = useState(null);

  const variant = variantFromColor || product?.variants?.[0];
  const productUrl = variant
    ? `/products/${variant.product?.handle}?variant=${variant.legacyResourceId}`
    : '';

  return (
    <div className="flex flex-col lg:h-[45.125rem] lg:flex-row">
      <div className="flex-1">
        {videoUrl && (
          <video
            src={videoUrl}
            autoPlay
            loop
            muted
            className="h-full w-full object-cover"
          />
        )}
        {!videoUrl && image && (
          <Image
            width={650}
            height={750}
            className="h-full w-full object-cover"
            alt={image.alt ?? `${offer?.headline} Image`}
            src={image.src}
          />
        )}
      </div>
      <div className="flex h-full w-full flex-1 items-center justify-center bg-[#E6F0F8] px-5 py-10 lg:px-0 lg:py-0">
        <div className="max-w-[520px]">
          <div className="flex flex-col gap-6">
            <div className="order-2 grid gap-4 text-center lg:order-1">
              <Headline variant="h4">{offer?.headline}</Headline>
              <Body variant="medium">{offer?.body}</Body>
              <Body variant="medium">{offer?.price}</Body>
            </div>
            <div
              className="pointer-events-none relative order-1 h-48 select-none lg:order-2 lg:h-60"
              style={{
                mixBlendMode: offer?.multiplyImage ? 'multiply' : 'normal',
              }}
            >
              <div className="relative flex h-full items-center justify-center">
                <ProductItemMedia
                  disableHoverEffect
                  disableBackground
                  product={product}
                  variant={variant}
                />
              </div>
            </div>

            <div className="order-2  mt-[-20px] flex justify-center lg:order-3 lg:mt-0">
              <ColorVariantSelector
                className="mb-2 mt-[22px]"
                enabledColorNameOnHover={false}
                product={product}
                setVariantFromColor={setVariantFromColor}
                swatchesMap={swatchesMap}
                variantFromColor={variantFromColor}
              />
            </div>

            {offer?.ctas && (
              <div className="cta-group order-3 flex items-center justify-center gap-4">
                <Button href={productUrl} theme="primary" variant="filled">
                  Shop now
                </Button>

                {offer?.ctas
                  .filter((cta) => {
                    return 'link' in cta;
                  })
                  .map((cta, index) => {
                    if (cta?.isRegularLink) {
                      return (
                        <Link
                          className="text-primary underline underline-offset-2 max-md:text-[14px]"
                          href={cta.link.url}
                          key={index}
                        >
                          {cta.link.text}
                        </Link>
                      );
                    }
                    return (
                      <Button
                        key={index}
                        href={cta.link.url}
                        variant={cta.variant}
                        theme={cta.theme}
                      >
                        {cta.link.text}
                      </Button>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ProductPromoBannerCarousel({ cms }) {
  const { eyebrow, headline, banners } = cms;
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const [renderClientSideComponent, setRenderClientSideComponent] =
    useState(false);

  useEffect(() => {
    setRenderClientSideComponent(true);
  }, []);

  return (
    <section className="my-8 lg:my-20">
      <div className="px-contained mx-auto max-w-[var(--content-max-width)]">
        <div className="mb-20 flex flex-col gap-10 text-center">
          <Eyebrow>{eyebrow}</Eyebrow>
          <Headline variant="h2" className="md:!text-[42px]">
            {headline}
          </Headline>
        </div>

        {renderClientSideComponent ? (
          <div>
            {isDesktop ? (
              <Carousel>
                {banners.map((banner, index) => (
                  <Banner key={index} {...banner} />
                ))}
              </Carousel>
            ) : (
              <div className="grid gap-10">
                {banners.map((banner, index) => (
                  <Banner key={index} {...banner} />
                ))}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </section>
  );
}

ProductPromoBannerCarousel.Schema = Schema;
