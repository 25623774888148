import { useCallback, useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { useSettings } from '@backpackjs/storefront';

export function useSearchCollectionResults({ term, mounted = true }) {
  const settings = useSettings();

  const [collectionsFuse, setCollectionsFuse] = useState(null);
  const [collectionResults, setCollectionResults] = useState(null);
  const { collectionsEnabled } = { ...settings?.search?.results };

  const setCollectionsFuseOnMount = useCallback(async () => {
    try {
      if (collectionsFuse) return;
      const response = await fetch('/json/collections-list.json');
      const list = await response.json();
      setCollectionsFuse(
        new Fuse(list, {
          keys: ['title'],
          ignoreLocation: true,
          minMatchCharLength: 3,
          threshold: 0.3,
        })
      );
    } catch (error) {
      console.error(error.message);
    }
  }, [collectionsFuse]);

  const searchCollectionsOnTermChange = useCallback(() => {
    if (!collectionsFuse) return;
    if (!term) {
      setCollectionResults([]);
      return;
    }
    const results = collectionsFuse.search(term);
    setCollectionResults(results);
  }, [collectionsFuse, term]);

  useEffect(() => {
    if (!mounted || !collectionsEnabled) return;
    setCollectionsFuseOnMount();
  }, [collectionsEnabled, mounted]);

  useEffect(() => {
    searchCollectionsOnTermChange();
  }, [term, collectionsFuse]);

  return { collectionResults };
}
