import { Schema } from './ArticleReferences.schema';
import { Headline } from '../../snippets/Typography';
import { Link } from '../../snippets';
import handleize from '../../utils/handleize';

export function ArticleReferences({ cms }) {
  const { title, anchorId, refrerences } = cms;

  return (
    <div className="relative mb-8 lg:mb-16">
      {anchorId && <div id={handleize(anchorId)} className="absolute top-16" />}
      {title && (
        <Headline
          variant="h4"
          element="h4"
          className="border-t border-[#DDE2E7] pb-4 pt-12 lg:pb-16"
        >
          {title}
        </Headline>
      )}
      {refrerences?.map(({ link, text }, index) => {
        return text ? (
          <p className="overflow-auto pb-4 font-light" key={index}>
            {index + 1}. {text}
          </p>
        ) : (
          <p className="overflow-auto pb-4 font-light" key={index}>
            {index + 1}. {link.text} <Link href={link?.url}>{link?.url}</Link>
          </p>
        );
      })}
    </div>
  );
}

ArticleReferences.displayName = 'ArticleReferences';
ArticleReferences.Schema = Schema;
