export const Schema = () => ({
  category: 'Article',
  label: 'Article Hero',
  key: 'articleHero',
  // previewSrc:
  //   'https://cdn.shopify.com/s/files/1/1011/0318/files/collection-hero.png',
  fields: [
    {
      name: 'category',
      component: 'text',
      label: 'Category',
      defaultValue: 'News',
    },
    {
      name: 'articleTime',
      component: 'text',
      label: 'Article Time',
      defaultValue: '5',
    },
    {
      name: 'title',
      component: 'text',
      label: 'Title',
    },
    {
      name: 'mediaImage',
      component: 'image',
      label: 'Accompanying Image',
    },
    {
      name: 'mediaVideo',
      component: 'text',
      label: 'Accompanying Video',
      description:
        'Accompanying Video will always be used in place of Accompanying Image.',
    },
    {
      label: 'Background Color',
      name: 'backgroundColor',
      component: 'select',
      options: [
        { label: 'Blue', value: 'blue' },
        { label: 'Red', value: 'red' },
      ],
      defaultValue: 'blue',
    },
    {
      name: 'mediaPlacement',
      component: 'select',
      label: 'Media Placement',
      options: [
        {
          label: 'Left',
          value: 'left',
        },
        {
          label: 'Right',
          value: 'right',
        },
      ],
      defaultValue: 'left',
    },
    {
      label: 'Center Content',
      name: 'centerContent',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
  ],
});
