export const Schema = () => ({
  category: 'Blog',
  label: 'Blog Featured Banner',
  key: 'blogFeaturedBanner',
  previewSrc:
    'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-24_20at_202.55.23_20PM.png?v=1684965343',
  fields: [
    {
      name: 'title',
      component: 'text',
      label: 'Title',
      defaultValue:
        'PFAS "Forever Chemicals" Contamination Worse than Previously Thought',
    },
    {
      name: 'category',
      component: 'text',
      label: 'Category',
      defaultValue: 'News',
    },
    {
      name: 'articleTime',
      component: 'text',
      label: 'Article Time',
      defaultValue: '5',
    },
    {
      label: 'Call to action',
      name: 'cta',
      component: 'link',
    },
    {
      name: 'image',
      component: 'image',
      label: 'Accompanying Image',
    },
  ],
});
