import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Svg } from '../../snippets/Svg';

export function LegalMenu({ menuItems }) {
  const router = useRouter();
  const [activeItem, setActiveItem] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    const findActiveItem = () => {
      const active = menuItems.filter(
        (item) => `/pages/${item.url}` === router.asPath
      );
      setActiveItem(active[0]);
    };

    findActiveItem();
  }, []);

  return (
    menuItems &&
    menuItems.length && (
      <div className="lg:hidden">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="flex cursor-pointer items-center justify-between border-b border-zinc-200 bg-white  p-4"
          onClick={() => setActiveMenu(!activeMenu)}
        >
          {activeItem?.header && (
            <h5 className="text-xl font-light  tracking-[-.01em]">
              {activeItem.header}
            </h5>
          )}
          <span>
            <Svg
              className={`${!activeMenu ? 'block' : 'hidden'} w-5 text-text`}
              src="/svgs/chevron-down.svg#chevron-down"
              title="Close"
              viewBox="0 0 24 24"
            />
            <Svg
              className={`${activeMenu ? 'block' : 'hidden'} w-5 text-text`}
              src="/svgs/chevron-up.svg#chevron-up"
              title="Close"
              viewBox="0 0 24 24"
            />
          </span>
        </div>
        <div className={`${activeMenu ? 'block' : 'hidden'}`}>
          {menuItems
            .filter((item) => `/pages/${item.url}` !== router.asPath)
            .map((item, index) => {
              return (
                <Link href={item.url} className="" key={index}>
                  <div className="flex cursor-pointer items-center justify-between border-b border-zinc-200 bg-white p-4  text-xl font-light  tracking-[-.01em]">
                    {item.header}
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    )
  );
}

LegalMenu.displayName = 'Legal Menu';
