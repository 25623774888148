export function Schema() {
  return {
    category: 'Image',
    label: 'Image Behind Text',
    key: 'imageBehindText',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2010.46.51_20AM.png?v=1684864565',
    fields: [
      {
        label: 'Eyebrow',
        name: 'eyebrow',
        component: 'text',
        defaultValue: 'Our Story',
      },
      {
        label: 'Copy',
        name: 'copy',
        component: 'text',
        defaultValue:
          'Clearly Filtered started in 2011 with the belief that water shouldn"t contain harmful chemicals, toxic heavy metals, or any other form of contamination. This philosophy has led to the development of one of the most advanced filtration technologies in the world.',
      },
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
    ],
  };
}
