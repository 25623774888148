export const Schema = () => ({
  label: 'Newsletter',
  category: 'Newsletter',
  key: 'newsletter',
  fields: [
    {
        label: 'Background Image',
        name: 'image',
        component: 'image',
    },
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
      defaultValue: 'Sign up for our monthly newsletter',
    },
    {
      name: 'copy',
      component: 'text',
      label: 'Copy',
      defaultValue:
        'Sign up to receive the latest news, lab tests, contaminants and much much much more.',
    },
    {
      name: 'disclaimer',
      component: 'text',
      label: 'Disclaimer',
      defaultValue:
        'By signing up you agree to our User Agreement Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
    },
  ],
});
