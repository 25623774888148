import { useMemo } from 'react';

import { Svg } from '../../Svg';

export function CollectionFiltersSummary({
  activeFilters,
  clearFilters,
  filtersMap,
  removeFilter,
}) {
  const activeFiltersList = useMemo(() => {
    const filterEntries = Object.entries(activeFilters);
    if (!filterEntries.length) return [];

    return filterEntries.reduce((acc, [filterKey, filterValues]) => {
      return [
        ...acc,
        ...filterValues.map((value) => {
          return {
            key: filterKey,
            value,
          };
        }),
      ];
    }, []);
  }, [activeFilters]);

  return activeFiltersList.length ? (
    <div className="p-4">
      <ul className="flex flex-wrap gap-2">
        {activeFiltersList.map(({ key, value }, index) => {
          return (
            <li key={index} className="max-w-full">
              <button
                className="group flex max-w-full items-center rounded-full border bg-[var(----clearBlue)] py-2 pl-2.5 pr-3 text-xs transition md:hover:bg-primary md:hover:text-white"
                onClick={() => removeFilter({ key, value })}
                type="button"
              >
                <div className="flex-1 truncate font-light">
                  <span>{filtersMap[key]?.label}:</span> {value}
                </div>

                <Svg
                  className="ml-1 w-2.5 text-text group-hover:text-white"
                  src="/svgs/close.svg#close"
                  title="Close"
                  viewBox="0 0 24 24"
                />
              </button>
            </li>
          );
        })}
        <li className="flex items-center">
          {clearFilters && (
            <button
              className="ml-4 border-l pl-2 font-light"
              onClick={clearFilters}
              type="button"
            >
              <span className="text-[var(--textLink)]">Clear All</span>
            </button>
          )}
        </li>
      </ul>
    </div>
  ) : null;
}

CollectionFiltersSummary.displayName = 'CollectionFiltersSummary';
