import { useMemo } from 'react';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

import { QuickShopOption } from './QuickShopOption';

export function QuickShopOptions({ quickShopMultiText, product, variant }) {
  const variantHandle = variant?.product?.handle;
  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: variantHandle,
    withInventory: false,
  });

  const option = useMemo(() => {
    if (!product) return { name: '', values: [], text: '' };
    const options = product?.grouping?.isTransformed
      ? product.grouping.options
      : product.options;
    const _option = options.find(({ name }) => name !== 'Color');
    return {
      name: _option?.name,
      values: _option?.values || [],
      text: quickShopMultiText?.replace('{{option}}', _option?.name),
    };
  }, [quickShopMultiText, product?.id]);

  const variants = product?.grouping?.isTransformed
    ? product.grouping.variants
    : product?.variants;

  return (
    <div className="group/quickshop relative flex h-[3.125rem] w-full items-center justify-center overflow-hidden rounded border border-black">
      <p className="btn-text truncate px-3">{option.text}</p>

      <ul
        className="invisible absolute inset-0 grid h-full w-full bg-background group-hover/quickshop:visible group-focus/quickshop:visible"
        style={{
          gridTemplateColumns: `repeat(${option.values.length}, 1fr)`,
        }}
      >
        {option.values.map((value) => {
          return (
            <li
              key={value}
              className="overflow-hidden border-r border-black last:border-none"
            >
              <QuickShopOption
                optionName="Size"
                inventory={inventory}
                inventoryStatus={inventoryStatus}
                variant={variant}
                value={value}
                variants={variants}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

QuickShopOptions.displayName = 'QuickShopOptions';
