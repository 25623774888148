import { Schema } from './FullBleedHero.schema';
import { Headline, Image } from '../../snippets';

export function FullBleedHero({ cms }) {
  const { headline, backgroundImage, backgroundVideoUrl } = cms;
  return (
    <div className="relative flex aspect-[388/505] items-center justify-center p-12 px-4 text-center sm:aspect-[1728/769]">
      {backgroundVideoUrl && (
        <video
          playsInline
          src={backgroundVideoUrl}
          autoPlay
          loop
          muted
          className="z-1 absolute left-0 top-0 h-full w-full object-cover"
        />
      )}
      {!backgroundVideoUrl && backgroundImage && (
        <Image
          src={backgroundImage?.src}
          alt="Full Bleed Background"
          fill
          sizes="100vw"
          className="object-cover"
        />
      )}
      <div className="absolute inset-0 bg-black opacity-50" />

      {headline && (
        <Headline
          element="h1"
          variant="h2"
          className="relative font-light text-white max-sm:text-[30px]"
        >
          {headline}
        </Headline>
      )}
    </div>
  );
}

FullBleedHero.displayName = 'FullBleedHero';
FullBleedHero.Schema = Schema;
