// https://clearly-filtered-dev.us.auth0.com/authorize?response_type=code&client_id=rguBLkw4a1pJe2Mwqf5VZQy8SmYUtoAN
// https://clearly-filtered-dev.us.auth0.com/v2/logout?response_type=code&client_id=rguBLkw4a1pJe2Mwqf5VZQy8SmYUtoAN

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { useCustomer } from '@backpackjs/storefront';

import { useAuth } from '../../hooks/useAuth';

// eslint-disable-next-line import/no-unresolved
import { AccountsAuthLoader } from '../../_components';

import { Button } from '../Button';
import { Body } from '../Typography';

export default function PasswordlessLogin() {
  const { query } = useRouter();
  const customer = useCustomer();
  const { getCustomerAccessTokenWithMultipass } = useAuth();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('email');
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  const checkMultipass = async (token) => {
    const res = await getCustomerAccessTokenWithMultipass(token);
    const accessToken = res?.body?.customerAccessToken;

    if (!accessToken) {
      console.log('no access token found');

      setError('email', {
        type: 'custom',
        message: 'no access token found',
      });

      setLoading(false);

      return;
    }

    const safeNewValue = {
      token: accessToken.accessToken,
      expires: accessToken.expiresAt,
    };

    // sets access token in localstorage
    const shopifyName = process.env.SHOPIFY_DOMAIN?.split('.')[0] || '';
    const key = `CustomerToken|${shopifyName}`;
    window.localStorage.setItem(key, JSON.stringify(safeNewValue));

    // reload page if setting customer doesn't work
    window.location = '/account?login=success';
  };

  const submitEmail = async (data) => {
    const { email } = data;

    const endpoint = '/api/auth0-submit-email';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    };

    const response = await fetch(endpoint, options);
    const resData = await response.json();

    if (resData.error_description) {
      setError('email', {
        type: 'custom',
        message: resData.error_description,
      });
      return;
    }

    if (resData.error) {
      setError('email', { type: 'custom', message: resData.error });
      return;
    }

    setStep('otp');
  };

  const submitOtp = async (data) => {
    const { email, otp } = data;

    const endpoint = '/api/auth0-submit-otp';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        code: otp,
      }),
    };

    const response = await fetch(endpoint, options);
    const authResult = await response.json();

    if (authResult.error_description) {
      setError('otp', {
        type: 'custom',
        message: authResult.error_description,
      });
      return;
    }

    if (authResult.error) {
      setError('otp', { type: 'custom', message: authResult.error });
      return;
    }

    if (authResult.id_token) {
      setLoading(true);

      const decoded = jwt_decode(authResult.id_token);

      checkMultipass(decoded.multipass_token);
    }
  };

  useEffect(() => {
    if (!query?.session_token) {
      return;
    }

    setLoading(true);

    checkMultipass(query.session_token);
  }, [query]);

  // useEffect(() => {
  //   if (customer) {
  //     window.location = '/account?login=success';
  //   }
  // }, [customer]);

  return (
    <>
      {!loading && step === 'email' && (
        <div>
          <form onSubmit={handleSubmit(submitEmail)}>
            <div className="mb-4 flex items-center">
              <hr className="grow border-[var(--text)]" />
              <Body variant="medium" className="px-4 text-center">
                Login with a one time code
              </Body>
              <hr className="grow border-[var(--text)]" />
            </div>

            {errors.email && (
              <p role="alert" className="mb-2 text-red-500">
                {errors.email?.message}
              </p>
            )}

            <input
              {...register('email')}
              id="email"
              type="email"
              placeholder="your@email.com"
              className="null w-full border border-[#DDE2E7] px-3 py-5 text-sm font-light !outline-0"
            />
            <Button
              theme="primary"
              variant="filled"
              type="submit"
              className="mx-auto mt-6 flex w-full max-w-[250px] items-center justify-center"
            >
              Send me a login code
            </Button>
          </form>
        </div>
      )}

      {!loading && step === 'otp' && (
        <div>
          <form onSubmit={handleSubmit(submitOtp)}>
            <p className="hidden">
              An email with the code has been sent to {watch('email')}
            </p>

            <div className="mb-4 flex items-center">
              <hr className="grow border-[var(--text)]" />
              <Body variant="medium" className="px-4 text-center">
                Login with a one time code
              </Body>
              <hr className="grow border-[var(--text)]" />
            </div>

            {errors.otp && (
              <p role="alert" className="mb-2 text-red-500">
                {errors.otp?.message}
              </p>
            )}

            <input
              {...register('otp')}
              type="text"
              placeholder="000000"
              className="null w-full border border-[#DDE2E7] px-3 py-5 text-sm font-light !outline-0"
            />
            <Button
              theme="primary"
              variant="filled"
              type="submit"
              className="mx-auto mt-6 flex w-full max-w-[250px] items-center justify-center"
            >
              Verify code
            </Button>

            <div className="mt-4 block text-center">
              <button type="button" onClick={() => setStep('email')}>
                didn&apos;t receive a code?
              </button>
            </div>
          </form>
        </div>
      )}

      {loading && <AccountsAuthLoader />}
    </>
  );
}
