export function Schema() {
  return {
    category: 'Text',
    label: 'Product Filter Replacement',
    key: 'productFilterReplacement',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2010.47.39_20AM.png?v=1684864582',
    fields: [
      {
        label: 'Eyebrow',
        name: 'eyebrow',
        component: 'text',
        defaultValue: 'FILTER REPLACEMENT MADE SIMPLE',
      },
      {
        label: 'Header',
        name: 'header',
        component: 'text',
        defaultValue: 'How Often Should I Replace My Filter?',
      },
      {
        label: 'Copy',
        name: 'copy',
        component: 'text',
        defaultValue:
          'How often varies based on your filtration system of choice and how much water you use. We recommend no more than 6 months or 180 gallons between filter changes. Select your filtration system, and we"ll help you find the right filter change frequency for your needs.',
      },
      {
        label: 'Product Filter Replacement Items',
        name: 'productFilterReplacementItems',
        component: 'group-list',
        itemProps: {
          label: '{{item.header}}',
        },
        fields: [
          {
            label: 'Header',
            name: 'header',
            component: 'text',
          },
          {
            label: 'Replacement Options',
            name: 'replacementOptions',
            description:
              'How often should filters be replaced based on the number of people in a household.',
            component: 'group-list',
            itemProps: {
              label:
                '{{item.numberOfPeople}} person(s) - {{item.replacementFrequency}}',
            },
            fields: [
              {
                label: 'Number of People',
                component: 'text',
                name: 'numberOfPeople',
                defaultValue: 1,
              },
              {
                label: 'Replacement Frequency',
                component: 'text',
                name: 'replacementFrequency',
                defaultValue: '6 Months',
              },
            ],
          },
          {
            label: 'Product Title',
            name: 'productTitle',
            component: 'text',
          },
          {
            label: 'Product SubTitle',
            name: 'productSubTitle',
            component: 'text',
          },
          {
            label: 'Product Price',
            name: 'productPrice',
            component: 'text',
          },
          {
            label: 'Product Image',
            name: 'productImage',
            component: 'image',
          },
          {
            label: 'CTA Copy',
            name: 'ctaCopy',
            component: 'text',
          },
          {
            label: 'CTA Url',
            name: 'ctaUrl',
            component: 'text',
          },
          {
            label: 'Link Copy',
            name: 'linkCopy',
            component: 'text',
          },
          {
            label: 'Link Url',
            name: 'linkUrl',
            component: 'text',
          },
        ],
        defaultValue: [
          {
            header: 'Picher',
            replacement1person: 'Replace every 6 months',
            replacement2person: 'Replace every 4 months',
            replacement3person: 'Replace every 3 months',
            replacement4person: 'Replace every 2 months',
            productTitle: 'Water Pitcher with Affinity Filtration Technology',
            productSubTitle:
              'Get clean and safe water on-the-go with our double-walled bottle',
            productPrice: '$XX.XX',
            ctaCopy: 'Shop Now',
            ctaUrl: '#',
            linkCopy: 'Replacement Filters',
            linkUrl: '#',
          },
          {
            header: 'Water Bottle',
            replacement1person: 'Replace every 6 months',
            replacement2person: 'Replace every 4 months',
            replacement3person: 'Replace every 3 months',
            replacement4person: 'Replace every 2 months',
            productTitle: 'Water Pitcher with Affinity Filtration Technology',
            productSubTitle:
              'Get clean and safe water on-the-go with our double-walled bottle',
            productPrice: '$XX.XX',
            ctaCopy: 'Shop Now',
            ctaUrl: '#',
            linkCopy: 'Replacement Filters',
            linkUrl: '#',
          },
          {
            header: 'Fridge',
            replacement1person: 'Replace every 6 months',
            replacement2person: 'Replace every 4 months',
            replacement3person: 'Replace every 3 months',
            replacement4person: 'Replace every 2 months',
            productTitle: 'Water Pitcher with Affinity Filtration Technology',
            productSubTitle:
              'Get clean and safe water on-the-go with our double-walled bottle',
            productPrice: '$XX.XX',
            ctaCopy: 'Shop Now',
            ctaUrl: '#',
            linkCopy: 'Replacement Filters',
            linkUrl: '#',
          },
          {
            header: 'Under Sink',
            replacement1person: 'Replace every 6 months',
            replacement2person: 'Replace every 4 months',
            replacement3person: 'Replace every 3 months',
            replacement4person: 'Replace every 2 months',
            productTitle: 'Water Pitcher with Affinity Filtration Technology',
            productSubTitle:
              'Get clean and safe water on-the-go with our double-walled bottle',
            productPrice: '$XX.XX',
            ctaCopy: 'Shop Now',
            ctaUrl: '#',
            linkCopy: 'Replacement Filters',
            linkUrl: '#',
          },
        ],
      },
    ],
  };
}
